// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import TitleSection from './TitleSection';
import PreviousTaskDetailsSection from './PreviousTaskDetailsSection';
import SourceSection from './SourceSection';

// Util(s)
import { getMovementMode, getCreatedAt, getSource } from '../../../../utils/PreviousTask/previousTaskUtil';

const PreviousTaskListCard = ({ task }) => {
  return (
    <div className="previous-task-list-card govuk-!-margin-bottom-5">
      <div className="card-container">
        <TitleSection movementMode={getMovementMode(task)} createdAt={getCreatedAt(task)} />
        <PreviousTaskDetailsSection task={task} />
        <SourceSection movementMode={getMovementMode(task)} source={getSource(task)} />
      </div>
    </div>
  );
};

PreviousTaskListCard.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default PreviousTaskListCard;
