// Global import(s)
import { Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

// Config(s)
import config from '../../../../../utils/config';

// Component
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';

// Hook(s)
import useFetchImage from '../../../../../utils/Hooks/useFetchImage';

// Styling
import './PassportPhoto.scss';

const PassportPhoto = ({ personPosition, photoContent }) => {
  const { url, isLoading } = useFetchImage(photoContent?.url);

  useEffect(() => {
    return () => URL.revokeObjectURL(url);
  }, []);

  if (!url) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="target-photo">
      <Link href={url} target="_blank">
        <img className="passport-photo hods-file-upload__thumb" src={url} alt=" " />
      </Link>
      <ComponentWrapper show={photoContent && config.enableUpliftTargetSheet}>
        <div className="govuk-!-padding-top-3">
          <div id={`passenger-${personPosition}-photo-source-label`}>
            Source
          </div>
          <div id={`passenger-${personPosition}-photo-source-value`}>
            {photoContent?.source}
          </div>
          <br />
          <div id={`passenger-${personPosition}-photo-date-label`}>
            Approximate date taken
          </div>
          <div id={`passenger-${personPosition}-photo-date-value`}>
            {photoContent?.approximateDateTaken}
          </div>
        </div>
      </ComponentWrapper>
    </div>
  );
};

PassportPhoto.propTypes = {
  personPosition: PropTypes.number,
  photoContent: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.string,
    filename: PropTypes.string,
    approximateDateTaken: PropTypes.string,
  }),
};

PassportPhoto.defaultProps = {
  personPosition: 0,
  photoContent: null,
};

export default memo(PassportPhoto);
