import React from 'react';
import PropTypes from 'prop-types';
import CommonButton from './CommonButton';

/**
 * Delete button component
 * @param label An alternative label for the button.
 * @param onClick A function to invoke.
 * @param props Component props.
 * @returns {JSX.Element} A JSX element.
 */
const DeleteButton = ({ label, onClick, ...props }) => {
  return (
    <CommonButton
      commonProps={{
        id: 'delete-button',
        className: 'govuk-button govuk-!-font-weight-bold',
        onClick,
        children: label || 'Delete',
        ...props,
      }}
    />
  );
};

export default DeleteButton;

DeleteButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
