import React from 'react';
import classNames from 'classnames';

// Config
import { DATE_FORMATS, STRINGS } from '../../../../utils/constants';

// Components
import PreviousTasksCount from '../../../../components/PreviousTasks/PreviousTasksCount';

// Utils/ Helpers
import DatetimeUtil from '../../../../utils/Datetime/datetimeUtil';
import { BookingUtil, CommonUtil, EnrichmentUtil } from '../../../../utils';
import toCoTravellers from './toCoTravellers';

export const toBookingBlock = (booking, datetimeList) => {
  return (
    <div>
      <h3 className="govuk-heading-s govuk-!-margin-bottom-1 govuk-!-font-size-16 govuk-!-font-weight-regular secondary-text">
        Booking
      </h3>
      <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
        <li className="list-item">Booked on {DatetimeUtil.format(BookingUtil.bookedAt(booking), DATE_FORMATS.SHORT)}</li>
        <li className="list-item">{DatetimeUtil.timeDiff(datetimeList, STRINGS.DEFAULT_BOOKING_STRING_PREFIX)}</li>
      </ul>
    </div>
  );
};

export const toCoTravellersBlock = (otherPersons) => {
  return (
    <div>
      <h3
        className={
          classNames('govuk-heading-s govuk-!-margin-bottom-1 govuk-!-font-size-16 govuk-!-font-weight-regular secondary-text',
            EnrichmentUtil.personsWithPreviousSeizures(otherPersons) ? 'font--red' : '')
        }
      >
        Co-travellers
      </h3>
      <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
        {toCoTravellers(otherPersons)}
      </ul>
      <ul className="govuk-body-s govuk-list">
        <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getTotalNumberOfPreviousTasks(otherPersons)} />
      </ul>
    </div>
  );
};
