import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const ContinueButton = ({ onClick }) => {
  return (
    <Button
      id="continue-button"
      onClick={onClick}
      aria-label="continue"
    >
      Continue
    </Button>
  );
};

ContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContinueButton;
