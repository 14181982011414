// Global import(s)
import { withCount } from 'pluralise';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config
import { TIME_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';

// Utils
import JourneyUtil from '../../../../../../utils/Journey/Uplift/journeyUtil';
import MovementUtil from '../../../../../../utils/Movement/Uplift/movementUtil';
import VehicleUtil from '../../../../../../utils/Vehicle/vehicleUtil';
import { getTaskId } from '../../../../../../utils/Task/taskUtil';
import { toEventTime } from '../../helper/toEventDate';

// Styling
import './TaskDetail.scss';

const TaskDetail = ({ task }) => {
  const taskId = getTaskId(task);
  const vehicle = VehicleUtil.get(task);
  const journey = JourneyUtil.get(task);
  const departureTime = JourneyUtil.departureTime(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);
  const vehicleRegistration = VehicleUtil.registration(vehicle);
  const groupSize = MovementUtil.groupSize(task);

  return (
    <div className="detail-container__outer">
      <div className="detail-container__inner general-aviation-details">
        <div className="task-detail">
          <div className="aircraft-crew-details" id={`aircraft-crew-details-${taskId}`}>
            <p id={`aircraft-registration-${taskId}`} className="ho-heading-s">
              <ComponentWrapper show={vehicleRegistration}>
                {vehicleRegistration}
              </ComponentWrapper>
            </p>
            <p id={`persons-on-board-${taskId}`} className="ho-body-xs">
              <ComponentWrapper show={groupSize}>
                {withCount(groupSize, '% person on board', '% persons on board')}
              </ComponentWrapper>
            </p>
          </div>
          <div className="journey-detail">
            <p id={`movement-journey-detail-${taskId}`} className="ho-body-xs">
              <ComponentWrapper show={departureTime && departureLocation}>
                <span className="departure-time">{toEventTime(departureTime, departureLocation, TIME_FORMATS.SUMMARY)}</span>&nbsp;&nbsp;
              </ComponentWrapper>
              <ComponentWrapper show={departureLocation}>
                <span className="ho-heading-m govuk-!-font-weight-bold">{departureLocation}</span>&nbsp;&nbsp;
              </ComponentWrapper>
              <ComponentWrapper show={departureLocation || arrivalLocation}>
                <span className="right-arrow">&#8594;</span>&nbsp;&nbsp;
              </ComponentWrapper>
              <ComponentWrapper show={arrivalLocation}>
                <span className="ho-heading-m govuk-!-font-weight-bold">{arrivalLocation}</span>&nbsp;&nbsp;
              </ComponentWrapper>
              <ComponentWrapper show={arrivalTime && arrivalLocation}>
                <span className="arrival-time">{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.SUMMARY)}</span>
              </ComponentWrapper>
            </p>
          </div>
          <div className="task-id-detail">
            <p id={`task-id-${taskId}`} className="ho-secondary-text-xs">
              TID: {taskId}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TaskDetail.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(TaskDetail);
