import { Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Styling
import './AgentIataModal.scss';

const AgentIataModal = ({ id, agentIata }) => {
  const [openModal, setOpenModal] = useState(false);

  const openNewTab = (url) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const onClick = async () => {
    await navigator?.clipboard?.writeText(agentIata)
      .then(() => setOpenModal(!openModal));

    setTimeout(() => {
      setOpenModal(false);
      openNewTab('https://store.iata.org/ieccacfree');
    }, 1000);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        id={`${id}-link`}
        onClick={async (e) => {
          e.preventDefault();
          await onClick();
        }}
      >
        {agentIata}
      </Link>
      <ComponentWrapper show={openModal}>
        <div id={id} className="govuk-grid-row agent-iata-modal">
          <p>{agentIata} copied to clipboard</p>
        </div>
      </ComponentWrapper>
    </>
  );
};

AgentIataModal.propTypes = {
  id: PropTypes.string,
  agentIata: PropTypes.string,
};

AgentIataModal.defaultProps = {
  id: 'agent-iata-modal',
  agentIata: null,
};

export default AgentIataModal;
