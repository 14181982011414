import { useEffect, useState } from 'react';

// Config
import config from '../config';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';

// Services(s)
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useRefDataFetch = (url, params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const keycloak = useKeycloak();
  const refDataClient = useAxiosInstance(keycloak, config.refdataApiUrl);

  useEffect(() => {
    const fetchData = async () => {
      await AxiosRequests.fetchRefData(refDataClient, url, params)
        .then((_data) => {
          setData(_data);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    };

    fetchData();
  }, [url]);

  return { data, isLoading };
};

export default useRefDataFetch;
