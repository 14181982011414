import dayjs from 'dayjs';
import { DATE_FORMATS, STRINGS } from '../constants';

const getTask = (previousTask) => {
  return previousTask?.task;
};

const getTarget = (previousTask) => {
  return previousTask?.target;
};

const getSource = (previousTask) => {
  return previousTask?.source;
};

const getMovementMode = (previousTask) => {
  return getTask(previousTask).movement?.mode;
};

const getCreatedAt = (previousTask) => {
  if (!getTask(previousTask)?.createdAt) {
    return STRINGS.UNKNOWN_TEXT;
  }

  return dayjs(getTask(previousTask).createdAt).format(DATE_FORMATS.SHORT_ALT);
};

const getRisks = (previousTask) => {
  return getTask(previousTask)?.risks;
};

const getTargeterAction = (previousTask) => {
  return getTask(previousTask)?.targeterAction;
};

const getFrontLineAction = (previousTask) => {
  return getTarget(previousTask)?.frontLineAction;
};

const getSeizures = (previousTask) => {
  return getTarget(previousTask)?.seizures;
};

const PreviousTaskUtil = {
  getTask,
  getTarget,
  getSource,
  getMovementMode,
  getCreatedAt,
  getRisks,
  getTargeterAction,
  getFrontLineAction,
  getSeizures,
};

export default PreviousTaskUtil;

export {
  getTask,
  getTarget,
  getSource,
  getMovementMode,
  getCreatedAt,
  getRisks,
  getTargeterAction,
  getFrontLineAction,
  getSeizures,
};
