// Global import(s)
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTask } from '../../context/TaskContext';
import { useView } from '../../context/ViewContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTask = (taskId, justificationId) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { setTask } = useTask();
  const { view } = useView();

  const getTask = async () => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return null;
    }

    return AxiosRequests.taskData(apiClient, taskId, justificationId);
  };

  const { data: task, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TASK, taskId, justificationId],
    queryFn: () => getTask(),
    initialData: null,
    cacheTime: 0,
    enabled: justificationId !== 'CREATE_JUSTIFICATION',
  });

  useEffect(() => {
    if (task) {
      setTask(task);
    }
  }, [task]);

  return { task, isLoading };
};

export default useFetchTask;
