/* eslint-disable no-use-before-define */
import { Checkboxes,
  DateInput,
  FormGroup,
  MultiSelectAutocomplete,
  Radios,
  Select,
  TextInput } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config(s)
import { COMPONENT_TYPES } from '../../../../utils/constants';

// Component(s)
import DateTime from '../../../DateTime/DateTime';

// Util(s)
import setupComponent from './setupComponent';

/**
 * Convert children configs into components
 *
 * @param {*} parent parent component which the childComponents will be under
 * @param {*} childComponents array of configurations for the child components
 * @param {*} data The filter  data
 */
const getChildJsx = (parent, childComponents, data) => {
  return (
    <>
      {childComponents.map((component, index) => {
        const { wrapperOptions, componentOptions } = setupComponent(
          data,
          component,
          parent.onChange,
        );

        return (
          <React.Fragment key={component.id}>
            {getComponent(index, component, wrapperOptions, componentOptions)}
          </React.Fragment>
        );
      })}
    </>
  );
};

const getTextInput = (key, wrapperOptions, componentOptions) => {
  return (
    <FormGroup key={key} {...wrapperOptions}>
      <TextInput {...componentOptions} />
    </FormGroup>
  );
};

const getDate = (key, wrapperOptions, componentOptions) => {
  return (
    <FormGroup key={key} {...wrapperOptions}>
      <DateInput {...componentOptions} />
    </FormGroup>
  );
};

const getDateTime = (key, wrapperOptions, componentOptions) => {
  return (
    <FormGroup key={key} {...wrapperOptions}>
      <DateTime {...componentOptions} />
    </FormGroup>
  );
};

const getCheckboxes = (key, wrapperOptions, componentOptions, data) => {
  componentOptions.options.forEach((option) => {
    if (!Array.isArray(option.nested)) {
      return;
    }
    option.children = getChildJsx(componentOptions, option.nested, data);
  });

  return (
    <FormGroup key={key} {...wrapperOptions}>
      <Checkboxes {...componentOptions} />
    </FormGroup>
  );
};

const getRadios = (key, wrapperOptions, componentOptions, data) => {
  componentOptions.options.forEach((option) => {
    if (!Array.isArray(option.nested)) {
      return;
    }
    option.children = getChildJsx(componentOptions, option.nested, data);
  });

  return (
    <FormGroup key={key} {...wrapperOptions}>
      <Radios {...componentOptions} />
    </FormGroup>
  );
};

const getAutocomplete = (key, wrapperOptions, componentOptions) => {
  return (
    <FormGroup key={key} {...wrapperOptions}>
      <MultiSelectAutocomplete {...componentOptions} />
    </FormGroup>
  );
};

const getSelect = (key, wrapperOptions, componentOptions) => {
  return (
    <FormGroup key={key} {...wrapperOptions}>
      <Select {...componentOptions} />
    </FormGroup>
  );
};

const getComponent = (key, component, wrapperOptions, componentOptions, data = null) => {
  switch (component.type) {
    case COMPONENT_TYPES.TEXT_INPUT: {
      return getTextInput(key, wrapperOptions, componentOptions);
    }
    case COMPONENT_TYPES.CHECKBOXES: {
      return getCheckboxes(key, wrapperOptions, componentOptions, data);
    }
    case COMPONENT_TYPES.RADIOS: {
      return getRadios(key, wrapperOptions, componentOptions, data);
    }
    case COMPONENT_TYPES.AUTOCOMPLETE: {
      return getAutocomplete(key, wrapperOptions, componentOptions);
    }
    case COMPONENT_TYPES.SELECT: {
      return getSelect(key, wrapperOptions, componentOptions);
    }
    case COMPONENT_TYPES.DATE: {
      return getDate(key, wrapperOptions, componentOptions);
    }
    case COMPONENT_TYPES.DATETIME: {
      return getDateTime(key, wrapperOptions, componentOptions);
    }
    default: {
      return null;
    }
  }
};

export default getComponent;
