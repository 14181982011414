import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Component(s)
import TaskNoteItem from './TaskNoteItem';

// Styling
import './TaskNotes.scss';

const TaskNotes = ({ task }) => {
  if (!task?.targeterNotes || !task?.targeterNotes?.length) {
    return null;
  }

  return (
    <div id="task-notes">
      <Heading id="notes-header" size="l">Notes</Heading>
      <div className="task-notes-container">
        {task.targeterNotes.map((note) => (
          <TaskNoteItem
            key={note.id}
            id={`note-item-${note.id}`}
            note={note}
          />
        ))}
      </div>
    </div>
  );
};

TaskNotes.propTypes = {
  task: PropTypes.shape({
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default TaskNotes;
