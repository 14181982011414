import { Footer as HodsFooter } from '@hods/components';
import { Utils } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config
import { PATHS } from '../../../../utils/constants';

// Styling
import './Footer.scss';

const DEFAULT_CLASS = 'hods-footer';

const Footer = ({ classBlock }) => {
  const classes = Utils.classBuilder(DEFAULT_CLASS, [], classBlock);

  const LINKS = [
    { href: '#', text: 'Feedback' },
    { href: '#', text: 'Help' },
    { href: `/v2${PATHS.ACCESSIBILITY_STATEMENT}`, text: 'Accessibility statement' },
    { href: 'https://www.gov.uk/', text: 'Gov.UK Home' },
  ];

  return (
    <HodsFooter
      className={`${classes()}`}
      navigation={LINKS}
    />
  );
};

Footer.propTypes = {
  classBlock: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

Footer.defaultProps = {
  classBlock: [],
};

export default Footer;
