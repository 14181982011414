import { useContext } from 'react';

import axios from 'axios';

import { ErrorAlertContext } from '../../context/ErrorAlertContext';

import ErrorHandler from './errorHandler';

export const updateTokenIfRequired = async (keycloak) => {
  if (keycloak.isTokenExpired(10)) {
    await keycloak
      .updateToken()
      .catch(() => {
        localStorage.clear();
        sessionStorage.clear();
        keycloak.logout({
          redirectUri: window.location.origin.toString(),
        });
      });
  }
  return keycloak.token;
};

const useAxiosInstance = (keycloak, baseURL = '/') => {
  const { setErrors } = useContext(ErrorAlertContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: keycloak ? `Bearer ${keycloak.token}` : undefined,
    },
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const token = await updateTokenIfRequired(keycloak);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      setErrors((prev) => {
        const formattedError = ErrorHandler.format(error);
        if (!ErrorHandler.containsError(prev, formattedError)) {
          return [
            ...(prev || []),
            {
              id: 'api-error',
              error: formattedError,
            },
          ];
        }

        return [...prev];
      });

      return Promise.reject(new Error(ErrorHandler.format(error)));
    },
  );

  return axiosInstance;
};

const AxiosInstance = {
  useAxios: useAxiosInstance,
};

export default AxiosInstance;

export { useAxiosInstance };
