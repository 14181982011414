import React from 'react';
import { FlightUtil, JourneyUtil, MovementUtil } from '../../../../../utils';

import { AircraftIcon, PersonIcon } from '../../../../../components/Icon/Icon';

import '../../assets/TaskSummary.scss';

const AirpaxTaskSummary = ({ version }) => {
  const journey = JourneyUtil.get(version);
  const flight = MovementUtil.movementFlight(version);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  return (
    <div id="details-task-summary" className="details-task-summary overflow-hidden">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-one-third voyage-description-container">
          <div id="voyage-mode-type-icon">
            <PersonIcon />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s govuk-!-margin-bottom-0 content-line-one">
              {MovementUtil.description(version)}
            </p>
            <p className="govuk-body-s govuk-!-margin-bottom-0 govuk-!-font-weight-bold">
              {MovementUtil.movementType(version)} {MovementUtil.status(version)}
            </p>
          </div>
        </div>
        <div className="govuk-grid-column-two-thirds voyage-details-container">
          <div id="voyage-movement-details">
            <p className="govuk-body-s content-line-one govuk-!-margin-bottom-0">
              {`${MovementUtil.airlineName(flight)} flight,
              ${MovementUtil.voyageText(arrivalTime, true, MovementUtil.iataToCity(JourneyUtil.arrivalLoc(journey)))}
              `}
            </p>
            <p className="govuk-body-s content-line-two govuk-!-margin-bottom-0">
              <span className="govuk-!-font-weight-bold">{FlightUtil.flightNumber(flight)}</span>
              <span className="dot" />
              {`${JourneyUtil.formatDepartureTime(journey)}`}
              <span className="dot" />
              <span className="govuk-!-font-weight-bold">{JourneyUtil.departureLoc(journey)}</span>
              <span className="right-arrow"> &#8594; </span>
              <span className="govuk-!-font-weight-bold"> {JourneyUtil.arrivalLoc(journey)}</span>
              <span className="dot" />
              {JourneyUtil.formatArrivalTime(journey)}
            </p>
          </div>
          <div id="voyage-movement-icon">
            <AircraftIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirpaxTaskSummary;
