const extractGroupCodes = (groups = []) => {
  if (!groups?.length) {
    return [];
  }

  return groups.map((group) => group.code);
};

const extractGroups = (userGroups, attrs = []) => {
  if (!Object.keys(userGroups || {})?.length || !Array.isArray(attrs) || !attrs.length) {
    return [];
  }

  return attrs
    .reduce((prev, attr) => {
      return [...prev, ...(userGroups[attr] || [])];
    }, [])
    .map((group) => {
      return {
        code: group.code,
        name: group.name,
      };
    });
};

const TargetGroupUtil = {
  groupCodes: extractGroupCodes,
  groups: extractGroups,
};

export default TargetGroupUtil;
