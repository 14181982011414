// Global imports
import React from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';

// Config(s)
import config from '../../utils/config';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { StompProvider } from '../../context/StompContext';

const withWebSocketSession = (BaseComponent) => {
  return () => {
    if (!config.enableWebSocket) {
      return <BaseComponent />;
    }

    const keycloak = useKeycloak();

    return (
      <StompSessionProvider
        url="/cop-targeting-web-socket/v2/targeting-events"
        connectHeaders={{ Authorization: keycloak.token }}
        onConnect={() => console.info('web socket connection established')}
      >
        <StompProvider>
          <BaseComponent />
        </StompProvider>
      </StompSessionProvider>
    );
  };
};

export default withWebSocketSession;
