import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './OfficialWarning.scss';

const OfficialWarning = ({ position, preview }) => {
  return (
    <span
      className={classNames('ho-heading-s', `official-warning${preview ? '-preview' : ''}`)}
      id={`official-warning__${position}`}
    >
      Official sensitive
    </span>
  );
};

OfficialWarning.propTypes = {
  position: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

OfficialWarning.defaultProps = {
  preview: false,
};

export default OfficialWarning;
