import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const CloseTaskButton = ({ onClick }) => {
  return (
    <Button
      id="close-task-button"
      onClick={onClick}
      aria-label="close task"
    >
      Close
    </Button>
  );
};

CloseTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseTaskButton;
