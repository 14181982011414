import { replaceInvalidValues } from '../../String/stringUtil';
import ValidateRefData from '../validate/validateRefData';
import toValueAndLabel from './toValueAndLabel';

// This is also the task-id
export const toId = (formData) => {
  if (!formData?.id) {
    return undefined;
  }

  return { id: formData.id };
};

export const toTelephone = (issuingHub) => {
  if (typeof issuingHub.telephone === 'string') {
    return issuingHub.telephone;
  }

  return issuingHub.telephone
    .map((number) => number.replace(/'(.*)'/, '$1'))
    .join(' / ');
};

export const toIssuingHub = (formData) => {
  if (!formData?.issuingHub) {
    return undefined;
  }

  return {
    issuingHub: {
      ...formData.issuingHub,
      ...toValueAndLabel(formData.issuingHub.name, formData.issuingHub.name),
      telephone: toTelephone(formData.issuingHub),
    },
  };
};

export const toTargetReceiptTeam = (formData) => {
  const group = formData?.recipients?.group || formData?.recipients?.groups?.[0] || null;
  if (!group && !ValidateRefData.validate(group, toValueAndLabel('id', 'displayname'))) {
    return null;
  }

  return {
    teamToReceiveTheTarget: {
      ...group,
      ...toValueAndLabel(
        group?.id,
        group?.displayname,
      ),
    },
  };
};

export const toTargetReceiptTeamSubmission = (formData) => {
  if (!formData?.teamToReceiveTheTarget && !ValidateRefData.validate(formData?.teamToReceiveTheTarget, toValueAndLabel('id', 'displayname'))) {
    return null;
  }

  return {
    ...formData.teamToReceiveTheTarget,
    ...toValueAndLabel(
      formData?.teamToReceiveTheTarget?.id,
      formData?.teamToReceiveTheTarget?.displayname,
    ),
  };
};

export const toOperation = (formData) => {
  if (!formData?.operation) {
    return undefined;
  }

  return {
    operation: replaceInvalidValues(formData.operation),
  };
};

export const toControlStrategies = (formData) => {
  return {
    controlStrategies: formData?.controlStrategies?.map((controlStrategy) => {
      if (!ValidateRefData.validate(controlStrategy, toValueAndLabel('id', 'strategy'))) {
        return undefined;
      }

      return {
        ...controlStrategy,
        ...toValueAndLabel(
          controlStrategy?.id,
          `${controlStrategy?.strategy} / Control Strategy ${controlStrategy?.priority}`,
        ),
      };
    }).filter((strategy) => !!strategy),
  };
};
