import { Checkboxes, FormGroup, Heading, TextInput } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const AbuseTypes = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.ABUSE_TYPES}
      label={<Heading id="suspected-abuse-type-heading" size="m">Suspected abuse type</Heading>}
      error={componentErrors?.[COMPONENT_IDS.ABUSE_TYPES]?.message}
    >
      <Checkboxes
        id={COMPONENT_IDS.ABUSE_TYPES}
        fieldId={COMPONENT_IDS.ABUSE_TYPES}
        options={[
          {
            value: 'CLASS_A_DRUG_TRAFFICKING',
            label: 'Class A drugs trafficking',
          },
          {
            value: 'CASH',
            label: 'Cash',
          },
          {
            value: 'OTHER',
            label: 'Other',
            children: (
              <FormGroup
                id={COMPONENT_IDS.OTHER_TYPES}
                required
                label="Enter the suspected abuse type"
                error={componentErrors?.[COMPONENT_IDS.OTHER_TYPES]?.message}
              >
                <TextInput
                  id={COMPONENT_IDS.OTHER_TYPES}
                  fieldId={COMPONENT_IDS.OTHER_TYPES}
                  rows="5"
                  onChange={onChange}
                  value={formData?.[COMPONENT_IDS.OTHER_TYPES]}
                />
              </FormGroup>
            ),
          },
        ]}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.ABUSE_TYPES]}
      />
    </FormGroup>
  );
};

AbuseTypes.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

AbuseTypes.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(AbuseTypes);
