// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const CreateAlertButton = ({ onClick }) => {
  return (
    <Button
      id="create-alert-button"
      classModifiers="blue"
      onClick={onClick}
      aria-label="create alert for task"
    >
      Create alert
    </Button>
  );
};

CreateAlertButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateAlertButton;
