import React, { createContext, useContext, useMemo, useState } from 'react';

const FormDataContext = createContext({});

const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const value = useMemo(() => ({
    formData,
    setFormData,
  }), [formData]);

  return <FormDataContext.Provider value={value}>{children}</FormDataContext.Provider>;
};

const useFormData = () => useContext(FormDataContext);

export { FormDataContext, FormDataProvider, useFormData };
