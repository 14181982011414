import { merge } from 'lodash';

// Config
import { COMPONENT_ERRORS, FRONTLINE_CHECK_OPTION_VALUES } from '../constants';

// Util
import { formatUpperToCamelCase } from '../../../../../../../../utils/String/stringUtil';

const hasErrors = (errors) => {
  return !!Object?.keys(errors)?.length;
};

const setErrorForComponent = (errors, componentId) => {
  merge(errors, {
    ...errors,
    [componentId]: COMPONENT_ERRORS[componentId],
  });
};

const checkArrayValue = (formData, componentId) => {
  return !!formData?.[componentId]?.length;
};

const checkFrontlineArrayValue = (formData, componentId) => {
  return !!formData?.frontlineChecks[componentId]?.length;
};

const checkValue = (formData, componentId) => {
  const value = formData?.[componentId];

  if (typeof value === 'string') {
    return !!value?.trim()?.length;
  }

  return !!value;
};

const checkFrontlineValue = (formData, componentId) => {
  const value = formData?.frontlineChecks[componentId];

  if (typeof value === 'string') {
    return !!value?.trim()?.length;
  }

  return !!value;
};

const getFrontlineCheckIds = () => {
  return Object.values(FRONTLINE_CHECK_OPTION_VALUES)
    .map((v) => v && formatUpperToCamelCase(v));
};

const setErrorsForFrontLineKeys = (keyValue, formData, errors, key, otherDetailKey) => {
  if (Array.isArray(keyValue)) {
    if (!checkFrontlineArrayValue(formData, key)) {
      setErrorForComponent(errors, key);
    }

    if (keyValue.includes('other') && !formData?.frontlineChecks[otherDetailKey]) {
      setErrorForComponent(errors, otherDetailKey);
    }
  }
};

export const validateSubmissionForGA = (formData, componentIds) => {
  const errors = {};
  const allFrontlineChecks = getFrontlineCheckIds();
  const frontlineChecks = formData?.frontlineChecks;
  const clickedCheckboxes = frontlineChecks?.frontlineChecks;
  let frontlineKeys = [];

  if (Array.isArray(clickedCheckboxes)) {
    frontlineKeys = clickedCheckboxes.map((box) => formatUpperToCamelCase(box));

    frontlineKeys.forEach((key) => {
      const keyVal = formData?.frontlineChecks?.[key];
      const otherDetailKey = `${key}OtherDetail`;

      setErrorsForFrontLineKeys(keyVal, formData, errors, key, otherDetailKey);

      if (!checkFrontlineValue(formData, key)) {
        setErrorForComponent(errors, key);
      }
    });
  }

  componentIds
    .filter((c) => !allFrontlineChecks.includes(c))
    .filter((c) => c !== 'frontlineChecks')
    .forEach((componentId) => {
      if (Array.isArray(formData?.[componentId]) && !checkArrayValue(formData, componentId)) {
        setErrorForComponent(errors, componentId);
        return;
      }

      if (!checkValue(formData, componentId)) {
        setErrorForComponent(errors, componentId);
      }
    });

  return { hasErrors: hasErrors(errors), errors };
};

const validateSubmission = (formData, componentIds) => {
  const errors = {};

  componentIds
    .filter((c) => c !== 'frontlineChecks')
    .forEach((componentId) => {
      if (Array.isArray(formData?.[componentId])) {
        if (!checkArrayValue(formData, componentId)) {
          setErrorForComponent(errors, componentId);
        }
        return;
      }

      if (!checkValue(formData, componentId)) {
        setErrorForComponent(errors, componentId);
      }
    });

  return { hasErrors: hasErrors(errors), errors };
};

export default validateSubmission;
