/**
 *
 * Validate the refData payload for the existence of data used to build auto-population data.
 *
 * @param {*} data The ref data node to validate
 * @param {*} itemStructure The fields which make up the value and label fields
 * e.g.
 * {
 *   value: field to source value from,
 *   label: field to source label from
 * }
 * @returns True if both fields are valid, else false.
 */
const validateRefData = (data, itemStructure) => {
  return !((!data?.[itemStructure.value] && data?.[itemStructure.value] !== 0)
    || !data?.[itemStructure.label]);
};

const ValidateRefData = {
  validate: validateRefData,
};

export default ValidateRefData;
