import { Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

import { scrollToMainContent } from './scrollToMainContent';

const SkipLink = ({ className, ...props }) => {
  const DEFAULT_CLASS = 'govuk-skip-link';

  const classes = Utils.classBuilder(DEFAULT_CLASS, className);

  return (
    <a {...props} href="#main-content" className={classes()} onClick={scrollToMainContent}>
      Skip to main content
    </a>
  );
};

SkipLink.propTypes = {
  className: PropTypes.string,
};

SkipLink.defaultProps = {
  className: null,
};

export default SkipLink;
