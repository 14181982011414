// Global imports
import { Tag } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../utils/constants';

// Component(s)
import PncCheck from '../../checks/PncCheck';
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import { TargetPersonUtil, TargetDocumentUtil, DateTimeUtil, StringUtil } from '../../../../../../../utils';

// Styling
import './PersonDetails.scss';

const PersonDetails = ({ person, personPosition, document, mode }) => {
  const adjustedPersonPosition = personPosition + 1;

  const toPersonOfInterest = () => {
    const ofInterest = TargetPersonUtil.ofInterest(person);

    if (!ofInterest) {
      return null;
    }
    return (
      <div className="person-of-interest">
        <Tag classModifiers="dark-blue">person of interest</Tag>
      </div>
    );
  };

  const panelData = (() => {
    return [
      {
        id: `person-${adjustedPersonPosition}-person-type`,
        label: 'Person type',
        value: StringUtil.capitalizeFirst(TargetPersonUtil.role(person)),
      },
      {
        id: `person-${adjustedPersonPosition}-date-of-birth`,
        label: 'Date of birth',
        value: DateTimeUtil.format(TargetPersonUtil.dob(person), TIME_FORMATS.DATE.DEFAULT),
      },
      {
        id: `person-${adjustedPersonPosition}-gender`,
        label: 'Gender',
        value: TargetPersonUtil.formattedGender(person),
      },
      {
        id: `person-${adjustedPersonPosition}-nationality`,
        label: 'Nationality',
        value: TargetPersonUtil.nationality(person),
      },
      {
        id: `person-${adjustedPersonPosition}-document-nationality`,
        label: 'Document nationality',
        value: TargetDocumentUtil.format.country(TargetDocumentUtil.docCountry(document)),
      },
      {
        id: `person-${adjustedPersonPosition}-document-number`,
        label: 'Document number',
        value: TargetDocumentUtil.docNumber(document),
      },
      {
        id: `person-${adjustedPersonPosition}-document-type`,
        label: 'Document type',
        value: TargetDocumentUtil.docTypeDescription(document),
      },
      {
        id: `person-${adjustedPersonPosition}-document-expiry`,
        label: 'Document expiry',
        value: DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), TIME_FORMATS.DATE.DEFAULT),
      },
    ];
  })();

  return (
    <div id="person-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <dl className="govuk-summary-list govuk-!-margin-bottom-3">
          <SummaryListRow
            id={`person-${adjustedPersonPosition}-name`}
            label={TargetPersonUtil.fullName(person)}
            value={toPersonOfInterest()}
          />
        </dl>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
        <PncCheck person={person} adjustedPersonPosition={adjustedPersonPosition} mode={mode} />
      </div>
    </div>
  );
};

PersonDetails.propTypes = {
  person: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
  document: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
};

export default memo(PersonDetails);
