import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Config(s)
import { PATHS } from '../../utils/constants';

// Component(s)
import NavigationItem from '../NavigationItem/NavigationItem';
import SkipLink from '../SkipLink/SkipLink';

// Styling
import './Header.scss';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = (e) => {
    e.preventDefault();
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toHomeURL = () => {
    if (location.pathname.startsWith(PATHS.AIRPAX)) {
      return PATHS.AIRPAX;
    }
    return PATHS.RORO;
  };

  return (
    <header className="govuk-header" role="banner" data-module="govuk-header">
      <SkipLink />
      <div className="govuk-header__container govuk-width-container">
        <div className="govuk-header__content">
          <Link
            to={toHomeURL()}
            className="govuk-header__link govuk-header__link--service-name"
          >
            Cerberus
            <span style={{ display: 'block', fontSize: '0.55em' }}>powered by the Central Operations Platform</span>
          </Link>
          <button
            className={
              mobileMenuOpen
                ? 'govuk-header__menu-button govuk-js-header-toggle govuk-header__menu-button--open'
                : 'govuk-header__menu-button govuk-js-header-toggle'
            }
            aria-controls="navigation"
            aria-label="Show or hide navigation menu"
            onClick={(e) => {
              toggleMenu(e);
            }}
          >
            Menu
          </button>
          <nav>
            <ul
              id="navigation"
              className={
                mobileMenuOpen
                  ? 'govuk-header__navigation-list govuk-header__navigation--open'
                  : 'govuk-header__navigation-list'
              }
              aria-label="Navigation menu"
            >
              <NavigationItem id="task-list-url" href={toHomeURL()}>Tasks</NavigationItem>
              <li className="govuk-header__navigation-item">
                <Link id="logout" className="govuk-header__link" to="/sign-out">
                  Sign out
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
