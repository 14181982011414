// Global import(s)
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTasks = (requestParams) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { setTasks, setIsLoadingTasks } = useTasks();
  const { view } = useView();

  const getTasks = async (params) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return [];
    }

    return AxiosRequests.getTasks(apiClient, params);
  };

  const { data: tasks, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.TASKS, requestParams],
    queryFn: () => getTasks(requestParams),
    refetchInterval: config.taskList.refreshInterval,
    initialData: [],
  });

  useEffect(() => {
    setTasks(tasks);
    setIsLoadingTasks(isFetching);
    return () => AxiosRequests.cancel(source);
  }, [tasks, isFetching]);
};

export default useFetchTasks;
