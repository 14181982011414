import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import airports from '@nitro-land/airport-codes';

// Config(s)
import { STRINGS } from '../constants';
import config from '../config';

// Util(s)
import validateDate from './Validation/validateDate';
import { getHiddenValue, shouldHideDate } from '../Hiding/hidingUtil';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.updateLocale('en', { relativeTime: config.dayjsConfig.relativeTime });

/**
 * Get the iata time zone
 *
 * @param {string} iataCode The location to extract the timezone from.
 * @returns {*|string|null} A timezone (or null if {@param iataCode} is not provided).
 */
const getIataTimezone = (iataCode) => {
  if (!iataCode) {
    return null;
  }

  const timeZone = airports.findWhere({ iata: iataCode })?.get('tz');
  if (!timeZone) {
    return null;
  }

  return timeZone;
};

/**
 * Get the user's timezone (this is guessed using the dayjs).
 * @returns {string} A guessed timezone.
 */
const getTimezone = () => {
  return dayjs.tz.guess();
};

/**
 * Return a formatted date according to the tokens provided.
 *
 * @param {string} datetime A UTC date.
 * @param {string} timeZone The target time zone.
 * @param {string} format The output format (if not provided, the default UTC format will be used.
 * Current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00')
 * @returns {null|string} A formatted date string. Returned date is local date.
 */
const toFormattedTimezoneDate = (datetime, timeZone, format = null) => {
  if (!datetime || !validateDate(datetime) || !timeZone) {
    return null;
  }

  if (!format) {
    return dayjs.utc(datetime).tz(timeZone).format();
  }

  return dayjs.utc(datetime).tz(timeZone).format(format);
};

/**
 * This will return either a formatted UTC datetime or a dayjs datetime object.
 * @param {*} asDayjsObj Flag to determine whether to return a formatted UTC datetime or a dayjs datetime object.
 * @returns A UTC formatted time or a dayjs datetime object.
 */
const getDate = (asDayjsObj = false) => {
  if (asDayjsObj) {
    return dayjs.utc();
  }
  return dayjs.utc().format();
};

const toRelativeTime = (date) => {
  if (!validateDate(date)) {
    return STRINGS.UNKNOWN;
  }
  const dateTimeStart = dayjs.utc(date);
  return dateTimeStart.fromNow();
};

const isInPast = (date) => {
  if (!validateDate(date)) {
    return STRINGS.UNKNOWN;
  }
  return toRelativeTime(date)?.endsWith(STRINGS.AGO_TEXT);
};

const getFormattedDate = (date, dateFormat) => {
  if (date === getHiddenValue() || shouldHideDate(date)) {
    return getHiddenValue();
  }

  if (!validateDate(date)) {
    return STRINGS.UNKNOWN;
  }

  return dayjs.utc(date).format(dateFormat);
};

const formatToUTCDate = (date, inputFormat, outputFormat) => {
  if (!date) {
    return STRINGS.UNKNOWN;
  }
  return dayjs.utc(date, inputFormat).format(outputFormat);
};

const toDateTimeList = (dateOne, dateTwo) => {
  return [dateOne, dateTwo];
};

const calculateDifference = (startDate, endDate, prefix = '', suffix = '') => {
  const formattedPrefix = prefix ? `${prefix} ` : '';
  const dateTimeStart = dayjs.utc(startDate);
  const dateTimeEnd = dayjs.utc(endDate);
  let dateTimeDifference = `${dateTimeEnd.from(dateTimeStart)}`;
  if (suffix) {
    dateTimeDifference = dateTimeDifference
      .replace(STRINGS.AFTER_TRAVEL_TEXT, suffix)
      .replace(STRINGS.BEFORE_TRAVEL_TEXT, suffix)
      .replace(STRINGS.AGO_TEXT, suffix);
  }
  return `${formattedPrefix}${dateTimeDifference}`;
};

/**
 * Calculates the relative time difference between two given date times.
 *
 * @param {*} dateTimeArray An array containing two data times.
 * @param {*} prefix Text to append to the start of output.
 * @param {*} suffix Text to perform string replacement on suffix.
 * @returns A relative time text.
 */
const calculateTimeDifference = (dateTimeArray, prefix = '', suffix = '') => {
  if (dateTimeArray.length <= 1
    || (!dateTimeArray[0] || !dateTimeArray[1])
    || (dateTimeArray[0] === STRINGS.UNKNOWN || dateTimeArray[1] === STRINGS.UNKNOWN)) {
    const formattedPrefix = prefix ? `${prefix} ` : '';
    return `${formattedPrefix}${STRINGS.UNKNOWN}`;
  }
  return calculateDifference(dateTimeArray[0], dateTimeArray[1], prefix, suffix);
};

const DateTimeUtil = {
  convertToUTC: formatToUTCDate,
  date: getDate,
  format: getFormattedDate,
  iataTimezone: getIataTimezone,
  isPast: isInPast,
  relativeTime: toRelativeTime,
  timeDiff: calculateTimeDifference,
  timezone: getTimezone,
  timezoneFormatted: toFormattedTimezoneDate,
  toList: toDateTimeList,
  validate: validateDate,
};

export default DateTimeUtil;

export {
  calculateTimeDifference,
  formatToUTCDate,
  getDate,
  getFormattedDate,
  getIataTimezone,
  getTimezone,
  isInPast,
  toDateTimeList,
  toFormattedTimezoneDate,
  toRelativeTime,
  validateDate,
};
