// Global import(s)
import { SmallHeading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { TIME_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import AdditionalContent from '../content/AdditionalContent';
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import TargetCommonUtil from '../../../../../../utils/Common/Target/targetCommonUtil';
import DateTimeUtil from '../../../../../../utils/Datetime/datetimeUtil';
import TargetDocumentUtil from '../../../../../../utils/Document/Target/targetDocumentUtil';

const DocumentDetails = ({ document, personPosition }) => {
  const adjustedPersonPosition = personPosition + 1;
  const additionalContents = TargetCommonUtil.additionalTextContents(document);

  const panelData = (() => {
    return [
      {
        id: `passenger-${adjustedPersonPosition}-document-nationality`,
        label: 'Document nationality',
        value: TargetDocumentUtil.format.country(TargetDocumentUtil.docCountry(document)),
      },
      {
        id: `passenger-${adjustedPersonPosition}-document-number`,
        label: 'Document number',
        value: TargetDocumentUtil.docNumber(document),
      },
      {
        id: `passenger-${adjustedPersonPosition}-document-type`,
        label: 'Document type',
        value: TargetDocumentUtil.docTypeDescription(document),
      },
      {
        id: `passenger-${adjustedPersonPosition}-document-expiry`,
        label: 'Document expiry',
        value: DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), TIME_FORMATS.DATE.DEFAULT),
      },
    ];
  })();

  return (
    <div id="document-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <SmallHeading size="m">Document details</SmallHeading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
        <AdditionalContent additions={additionalContents} heading="Document additions" />
      </div>
    </div>
  );
};

DocumentDetails.propTypes = {
  document: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
};

export default memo(DocumentDetails);
