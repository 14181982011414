// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

// Context(s)
import { useTask } from '../../../../../../../context/TaskContext';

// Component(s)
import AddTaskDetailButton from '../buttons/AddTaskDetailButton';
import CommoditiesMovement from './CommoditiesMovement';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import CtbpMovement from './CtbpMovement';
import GeneralAviationMovement from './GeneralAviationMovement';
import IdpMovement from './IdpMovement';

// Util(s)
import BaggageUtil from '../../../../../../../utils/Baggage/baggageUtil';
import BookingUtil from '../../../../../../../utils/Booking/bookingUtil';
import CommonUtil from '../../../../../../../utils/Common/commonUtil';
import FlightUtil from '../../../../../../../utils/Flight/Uplift/flightUtil';
import JourneyS4Util from '../../../../../../../utils/Journey/journeyS4Util';
import JourneyUtil from '../../../../../../../utils/Journey/Uplift/journeyUtil';
import MovementUtil from '../../../../../../../utils/Movement/Uplift/movementUtil';
import PersonS4Util from '../../../../../../../utils/Person/personS4Util';
import PersonUtil from '../../../../../../../utils/Person/Uplift/personUtil';
import ReferencesUtil from '../../../../../../../utils/References/referencesUtil';
import VehicleUtil from '../../../../../../../utils/Vehicle/vehicleUtil';
import { isCommoditiesSubMode, isGaSubMode, isIdpSubMode, isCtbpSubMode } from '../../../../../../../utils/Task/taskUtil';

// Styling
import './Movement.scss';

const Movement = forwardRef(({ canShowAddDetailButton, movementIds, onAddTaskDetail }, ref) => {
  const { subMode, task } = useTask();
  const baggage = BaggageUtil.get(task);
  const booking = BookingUtil.get(task);
  const flight = FlightUtil.get(task);
  const journey = JourneyUtil.get(task);
  const allPersons = PersonUtil.allPersons(task);
  const persons = CommonUtil.uniqueId(allPersons);
  const movementId = MovementUtil.movementId(task);
  const bookingVsIntent = MovementUtil.bookingVsIntent(task);
  const groupSize = MovementUtil.groupSize(task);
  const s4Person = PersonS4Util.get(task);
  const s4Journey = JourneyS4Util.get(task);
  const vehicle = VehicleUtil.get(task);
  const references = ReferencesUtil.get(task);
  const heading = isGaSubMode(subMode) ? 'Flight details' : 'Movement';

  return (
    <div id="movement-details" className="govuk-grid-row">
      <div id="header-container" className="govuk-grid-column-full">
        <Heading id="movement-section-header" size="l">
          {heading}
        </Heading>
        <div ref={ref}>
          <ComponentWrapper show={canShowAddDetailButton}>
            <AddTaskDetailButton
              onClick={onAddTaskDetail}
              label={!isGaSubMode(subMode) ? 'Add task detail' : 'Add/edit task detail'}
            />
          </ComponentWrapper>
        </div>
      </div>
      <div className="govuk-grid-column-full">
        <ComponentWrapper show={isCommoditiesSubMode(subMode)}>
          <CommoditiesMovement
            movementId={movementId}
            movementIds={movementIds}
            flight={flight}
            journey={journey}
            baggage={baggage}
            persons={persons}
            booking={booking}
            bookingVsIntent={bookingVsIntent}
            groupSize={groupSize}
            s4Person={s4Person}
            s4Journey={s4Journey}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isIdpSubMode(subMode)}>
          <IdpMovement
            movementId={movementId}
            movementIds={movementIds}
            flight={flight}
            journey={journey}
            baggage={baggage}
            persons={persons}
            booking={booking}
            bookingVsIntent={bookingVsIntent}
            groupSize={groupSize}
            s4Person={s4Person}
            s4Journey={s4Journey}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isCtbpSubMode(subMode)}>
          <CtbpMovement
            movementId={movementId}
            movementIds={movementIds}
            flight={flight}
            journey={journey}
            baggage={baggage}
            persons={persons}
            booking={booking}
            bookingVsIntent={bookingVsIntent}
            groupSize={groupSize}
            s4Person={s4Person}
            s4Journey={s4Journey}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isGaSubMode(subMode)}>
          <GeneralAviationMovement
            journey={journey}
            persons={persons}
            bookingVsIntent={bookingVsIntent}
            groupSize={groupSize}
            vehicle={vehicle}
            references={references}
          />
        </ComponentWrapper>
      </div>
    </div>
  );
});

Movement.propTypes = {
  task: PropTypes.shape({}).isRequired,
  canShowAddDetailButton: PropTypes.bool.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddTaskDetail: PropTypes.func.isRequired,
};

export default memo(Movement);
