const getHighestThreatLevel = (risks) => {
  return risks?.highestThreatLevel || null;
};

const getMatchedRulesCount = (task) => {
  return task?.risks?.matchedRules?.length || 0;
};

const getMatchedRules = (task) => {
  return task?.risks?.matchedRules || [];
};

const getS4RulesTotalScore = (task) => {
  return task?.risks?.s4RulesTotalScore || 0;
};

const getRisks = (task) => {
  return task?.risks || null;
};

const getRiskMatchedSelectorGroups = (risks) => {
  return risks?.matchedSelectorGroups || null;
};

const getRiskMatchedRules = (risks) => {
  return risks?.matchedRules || [];
};

const extractTopRisk = (risks, highestRisk) => {
  let riskType = null;
  if (highestRisk?.type?.toLowerCase() === 'selector') {
    const selectors = getRiskMatchedSelectorGroups(risks).groups;
    selectors?.forEach(({ category, name }) => {
      if (category?.toLowerCase() === highestRisk?.value?.toLowerCase()) {
        riskType = name;
      }
    });
  } else {
    const rules = getRiskMatchedRules(risks);
    rules?.forEach(({ priority, name }) => {
      if (priority?.toLowerCase() === highestRisk?.value?.toLowerCase()) {
        riskType = name;
      }
    });
  }
  return riskType;
};

const toFormattedTargetRisk = (risks, highestThreatLevel) => {
  const risksRules = getRiskMatchedRules(risks).length + getRiskMatchedSelectorGroups(risks)?.totalNumberOfSelectors;
  if (highestThreatLevel) {
    const topRisk = extractTopRisk(risks, highestThreatLevel);
    const count = risksRules > 1 && risksRules - 1;
    if (count > 0) {
      return `${topRisk} + ${count} more`;
    }
    return topRisk;
  }
  return null;
};

const formatHighestThreatLevel = (task) => {
  const risks = getRisks(task);
  const highestThreatLevel = getHighestThreatLevel(risks);
  return toFormattedTargetRisk(risks, highestThreatLevel);
};

const RisksUtil = {
  formatHighestThreat: formatHighestThreatLevel,
  get: getRisks,
  matchedRules: getMatchedRules,
  matchedRulesCount: getMatchedRulesCount,
  s4TotalScore: getS4RulesTotalScore,
};

export default RisksUtil;

export {
  formatHighestThreatLevel,
  getRisks,
  getMatchedRules,
  getS4RulesTotalScore,
  getMatchedRulesCount,
};
