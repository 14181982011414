import { Button, FormGroup } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../../constants';

// Context
import { useView } from '../../../../../../../../../context/ViewContext';

// Component(s)
import CommoditiesTargetRecipients from './CommoditiesTargetRecipients';

const TargetRecipients = ({ componentErrors, onChangeRecipient, onRemoveRecipient, onAddActionOption, formData }) => {
  const { view } = useView();
  return (
    <FormGroup
      id={COMPONENT_IDS.RECIPIENTS}
      label={<></>}
      error={componentErrors?.[COMPONENT_IDS.RECIPIENTS]?.message}
      className="govuk-!-margin-bottom-7"
    >
      <CommoditiesTargetRecipients
        id="target-recipients-table"
        formData={formData}
        onChangeRecipient={onChangeRecipient}
        onRemoveRecipient={onRemoveRecipient}
      />
      <p>All targets are sent to the common recipient list for {view}.</p>
      <Button
        className="govuk-!-margin-bottom-0"
        classModifiers="secondary"
        onClick={onAddActionOption}
      >
        Add recipient
      </Button>
    </FormGroup>
  );
};

TargetRecipients.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
  onAddActionOption: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

TargetRecipients.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(TargetRecipients);
