// Global Import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import BVI from '../components/BVI';
import DepartureArrivalCountDown from './DepartureArrivalCountDown';
import FlightNumber from '../components/FlightNumber';
import JourneyDetail from '../components/JourneyDetail';
import MultiMatchIndicator from '../../../../../TaskList/components/shared/tags/MultiMatchIndicator';

// Styling
import './SummaryLarge.scss';

const SummaryLarge = ({ task, journey, bookingVsIntent, flight, pnrLocator }) => {
  return (
    <div className="task-summary-large">
      <div className="task-summary-line task-summary-line-one">
        {/* Departs in ... */}
        <div className="departure-arrival-countdown-container">
          <DepartureArrivalCountDown
            journey={journey}
            numberClassName="ho-heading-m"
            unitClassName="ho-heading-m"
            labelClassName="ho-body-s label-whitespace"
          />
        </div>
        {/* MM badge and BKG MM badge */}
        <div className="multi-match-container">
          <MultiMatchIndicator task={task} />
        </div>
        {/* placeholder for API/PNR tags */}
        <div className="data-drop-indicator-container" />
        {/* PNR Locator */}
        <div className="pnr-locator-container">
          <span className="ho-heading-m">
            {pnrLocator}
          </span>
        </div>
      </div>

      <div className="task-summary-line task-summary-line-two">
        {/* Flight number */}
        <div className="flight-number-container">
          <FlightNumber flight={flight} className="ho-body-l" />
        </div>
        {/* placeholder for number of passengers of interest */}
        <div className="pax-ratio-container" />
        {/* Journey details */}
        <div className="journey-details-container">
          <JourneyDetail journey={journey} locationClassName="ho-heading-m" timeClassName="ho-body" />
        </div>
        {/* BVI */}
        <div className="bvi-container">
          <BVI bookingVsIntent={bookingVsIntent} className="ho-body" />
        </div>
      </div>
    </div>
  );
};

SummaryLarge.propTypes = {
  task: PropTypes.shape({}).isRequired,
  journey: PropTypes.shape({}).isRequired,
  bookingVsIntent: PropTypes.number.isRequired,
  flight: PropTypes.shape({}).isRequired,
  pnrLocator: PropTypes.string.isRequired,
};

export default SummaryLarge;
