import React from 'react';
import classNames from 'classnames';

import Aircraft from '../../__assets__/images/aircraft.svg';
import Arrow from '../../__assets__/images/arrow.svg';
import ArrowUp from '../../__assets__/images/arrow_up.svg';
import ArrowDown from '../../__assets__/images/arrow_down.svg';
import Car from '../../__assets__/images/car.svg';
import ExclamationCircle from '../../__assets__/images/exclamation_filled.svg';
import Group from '../../__assets__/images/group.svg';
import Hgv from '../../__assets__/images/hgv.svg';
import HOLogo from '../../__assets__/images/ho-logo.svg';
import Person from '../../__assets__/images/person.svg';
import Ship from '../../__assets__/images/ship.svg';
import Trailer from '../../__assets__/images/trailer.svg';
import Van from '../../__assets__/images/van.svg';

import './Icon.scss';

const DEFAULT_CLASS = 'hods_icon';

export const ICON_NAME = {
  AIRCRAFT: 'aircraft',
  ARROW: 'arrow',
  ARROW_DOWN: 'arrow_down',
  ARROW_UP: 'arrow_up',
  CAR: 'car',
  EXCLAMATION_CIRCLE_FILLED: 'exclamation-circle-filled',
  GROUP: 'group',
  HGV: 'hgv',
  HO_LOGO: 'ho-logo',
  PERSON: 'person',
  SHIP: 'ship',
  TRAILER: 'trailer',
  VAN: 'van',
};

const AircraftIcon = ({ classNames: _classNames }) => {
  return <Aircraft className={classNames(DEFAULT_CLASS, 'aircraft', _classNames)} />;
};
const ArrowIcon = ({ classNames: _classNames }) => {
  return <Arrow className={classNames('arrow', _classNames)} />;
};
const ArrowDownIcon = ({ classNames: _classNames }) => {
  return <ArrowDown className={classNames('arrow_down', _classNames)} />;
};
const ArrowUpIcon = ({ classNames: _classNames }) => {
  return <ArrowUp className={classNames('arrow_up', _classNames)} />;
};
const CarIcon = ({ classNames: _classNames }) => {
  return <Car className={classNames(DEFAULT_CLASS, 'car', _classNames)} />;
};
const ExclamationCircleFilled = ({ classNames: _classNames }) => {
  return <ExclamationCircle className={classNames(DEFAULT_CLASS, 'exclamation-circle-filled', _classNames)} />;
};
const GroupIcon = ({ classNames: _classNames }) => {
  return <Group className={classNames(DEFAULT_CLASS, 'group', _classNames)} />;
};
const HgvIcon = ({ classNames: _classNames }) => {
  return <Hgv className={classNames(DEFAULT_CLASS, 'hgv', _classNames)} />;
};
const HomeOfficeLogo = ({ classNames: _classNames }) => {
  return <HOLogo className={classNames(DEFAULT_CLASS, 'ho-logo', _classNames)} />;
};
const PersonIcon = ({ classNames: _classNames }) => {
  return <Person className={classNames(DEFAULT_CLASS, 'person', _classNames)} />;
};
const ShipIcon = ({ classNames: _classNames }) => {
  return <Ship className={classNames(DEFAULT_CLASS, 'ship', _classNames)} />;
};
const TrailerIcon = ({ classNames: _classNames }) => {
  return <Trailer className={classNames(DEFAULT_CLASS, 'trailer', _classNames)} />;
};
const VanIcon = ({ classNames: _classNames }) => {
  return <Van className={classNames(DEFAULT_CLASS, 'van', _classNames)} />;
};
const Icon = ({ name, classNames: _classNames }) => {
  if (!name) {
    return null;
  }
  switch (name) {
    case ICON_NAME.AIRCRAFT:
      return <AircraftIcon classNames={_classNames} />;
    case ICON_NAME.ARROW:
      return <ArrowIcon classNames={_classNames} />;
    case ICON_NAME.ARROW_UP:
      return <ArrowUpIcon classNames={_classNames} />;
    case ICON_NAME.ARROW_DOWN:
      return <ArrowDownIcon classNames={_classNames} />;
    case ICON_NAME.CAR:
      return <CarIcon classNames={_classNames} />;
    case ICON_NAME.EXCLAMATION_CIRCLE_FILLED:
      return <ExclamationCircleFilled classNames={_classNames} />;
    case ICON_NAME.GROUP:
      return <GroupIcon classNames={_classNames} />;
    case ICON_NAME.HGV:
      return <HgvIcon classNames={_classNames} />;
    case ICON_NAME.HO_LOGO:
      return <HomeOfficeLogo classNames={_classNames} />;
    case ICON_NAME.PERSON:
      return <PersonIcon classNames={_classNames} />;
    case ICON_NAME.SHIP:
      return <ShipIcon classNames={_classNames} />;
    case ICON_NAME.TRAILER:
      return <TrailerIcon classNames={_classNames} />;
    case ICON_NAME.VAN:
      return <VanIcon classNames={_classNames} />;
    default:
      return null;
  }
};
export default Icon;
export {
  AircraftIcon,
  CarIcon,
  ArrowIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ExclamationCircleFilled,
  GroupIcon,
  HgvIcon,
  HomeOfficeLogo,
  PersonIcon,
  ShipIcon,
  TrailerIcon,
  VanIcon,
};
