import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { VIEW } from '../../../../../utils/constants';

// Context(s)
import { useTabs } from '../../../../../context/TabContext';
import { useView } from '../../../../../context/ViewContext';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import Table from '../../../../../components/Table/Table';
import TargetListCardHeader from './TargetListCardHeader';
import TargetRow from './TargetRow';

// Util(s)
import { TargetMovementUtil } from '../../../../../utils';

// Styling
import './TargetListCard.scss';

const toRows = ({
  tableHeaders,
  targets,
  handleRadioButton,
  checkedRadioBtnName,
  selectedTab,
  handleOnRCCUReceipt,
  handleOnReceipt,
  handleOnAccept,
  handleViewDetails,
  handleRecordOutcome,
  handleAssign,
  handleReAssign,
  handleOnCapture,
  handleUndoCapture,
  selectedTarget,
}) => {
  const rows = [];
  targets.forEach((target) => {
    const { informationSheet } = target;
    const mode = TargetMovementUtil.mode(informationSheet);
    rows.push(
      <TargetRow
        key={`${target.informationSheet.id}-${TargetMovementUtil.targetId(informationSheet)}`}
        mode={mode}
        tableHeaders={tableHeaders}
        target={target}
        handleRadioButton={handleRadioButton}
        checkedRadioBtnName={checkedRadioBtnName}
        selectedTab={selectedTab}
        handleOnRCCUReceipt={handleOnRCCUReceipt}
        handleOnReceipt={handleOnReceipt}
        handleOnAccept={handleOnAccept}
        handleViewDetails={handleViewDetails}
        handleRecordOutcome={handleRecordOutcome}
        handleAssign={handleAssign}
        handleReAssign={handleReAssign}
        handleOnCapture={handleOnCapture}
        handleUndoCapture={handleUndoCapture}
        selectedTarget={selectedTarget}
      />,
    );
  });
  return rows;
};

const AirpaxTargetListCard = ({ headings, rows }) => {
  return (
    <div role="table" className="airpax-target-table">
      <div className="airpax-target-header">
        <div className="row">
          {headings.map((header) => (
            <div key={header} className="heading">
              <span className="ho-secondary-text-s">
                {header}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="airpax-target-body">
        {rows.map((row) => (
          <div key={row}>
            {row}
          </div>
        ))}
      </div>
    </div>
  );
};

const TargetListCard = ({
  tableHeaders,
  groupedTargets,
  handleOnRCCUReceipt,
  handleOnReceipt,
  handleOnAccept,
  handleRadioButton,
  handleViewDetails,
  handleRecordOutcome,
  handleAssign,
  handleReAssign,
  handleOnCapture,
  handleUndoCapture,
  checkedRadioBtnName,
  selectedTarget,
}) => {
  const { view } = useView();
  const { selectedTab } = useTabs();

  const groupedOverview = TargetMovementUtil.groupedMeta(groupedTargets);
  const rows = toRows({
    tableHeaders,
    targets: groupedTargets.targets,
    handleRadioButton,
    checkedRadioBtnName,
    selectedTab,
    handleOnRCCUReceipt,
    handleOnReceipt,
    handleOnAccept,
    handleViewDetails,
    handleRecordOutcome,
    handleAssign,
    handleReAssign,
    handleOnCapture,
    handleUndoCapture,
    selectedTarget,
  });

  return (
    <div id="grouped-targets" className="govuk-!-padding-bottom-4">
      <div id="movement-summary">
        <TargetListCardHeader groupedOverview={groupedOverview} view={view} />
      </div>
      <ComponentWrapper show={view === VIEW.RORO_IO}>
        <Table
          headings={tableHeaders}
          rows={rows}
        />
      </ComponentWrapper>
      <ComponentWrapper show={view !== VIEW.RORO_IO}>
        <AirpaxTargetListCard
          headings={tableHeaders}
          rows={rows}
        />
      </ComponentWrapper>
    </div>
  );
};

TargetListCard.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupedTargets: PropTypes.shape({
    arrival: PropTypes.shape({}),
    departure: PropTypes.shape({}),
    direction: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleOnReceipt: PropTypes.func.isRequired,
  handleOnRCCUReceipt: PropTypes.func.isRequired,
  handleOnAccept: PropTypes.func.isRequired,
  handleRadioButton: PropTypes.func.isRequired,
  handleViewDetails: PropTypes.func.isRequired,
  handleRecordOutcome: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  handleReAssign: PropTypes.func.isRequired,
  handleOnCapture: PropTypes.func.isRequired,
  handleUndoCapture: PropTypes.func.isRequired,
  checkedRadioBtnName: PropTypes.string,
  selectedTarget: PropTypes.shape({}),
};

TargetListCard.defaultProps = {
  checkedRadioBtnName: PropTypes.string,
  selectedTarget: PropTypes.shape({}),
};

export default TargetListCard;
