// Utils
import { MOVEMENT_MODES, VIEW } from '../../constants';

// JSON
import airpaxTisCerberus from '../../../forms/airpaxTisCerberus';
import roroTisCerberus from '../../../forms/roroTisCerberus';

const getRoroTargetingIndicatorUrlFilter = (taskData) => {
  switch (taskData?.movement?.mode) {
    case MOVEMENT_MODES.TOURIST:
      return '&filter=tourist=eq.true';
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT:
      return '&filter=freight=eq.true';
    default:
      return '';
  }
};

const getRoroTisForm = (taskData) => {
  const roroTis = roroTisCerberus();
  return {
    ...roroTis,
    components: roroTis.components.map((component) => {
      if (component.id !== 'targetingIndicators') {
        return component;
      }

      // Need to return a new object in order to not mutate the original roro tis form object
      return {
        ...component,
        data: {
          ...component.data,
          url: component.data.url + getRoroTargetingIndicatorUrlFilter(taskData),
        },
      };
    }),
  };
};

const getTisForm = (view, taskData) => {
  switch (view) {
    case VIEW.AIRPAX: {
      return airpaxTisCerberus;
    }
    case VIEW.RORO: {
      return getRoroTisForm(taskData);
    }
    default: {
      return null;
    }
  }
};

export default getTisForm;
