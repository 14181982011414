import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import InternalFilter from '../../../../../../components/Filter/InternalFilter';
import { useTasks } from '../../../../../../context/TasksContext';

import matchRules from '../../../../../../forms/uplift/matchRules';

const RuleToolsTab = forwardRef((props, ref) => {
  const { data, customOptions, onApply, onClear, filterControlStyles, showFilterControlsAtTop } = props;
  const { setRules } = useTasks();

  const onInternalApply = (payload) => {
    onApply({
      ...payload,
      rules: Array.isArray(payload?.rules) ? payload?.rules.filter((rule) => !!rule) : [payload?.rules].filter((rule) => !!rule),
    });

    setRules(payload?.rules);
  };

  return (
    <InternalFilter
      form={matchRules}
      data={data}
      ref={ref}
      customOptions={customOptions}
      filterControlStyles={filterControlStyles}
      showFilterControlsAtTop={showFilterControlsAtTop}
      onApply={onInternalApply}
      onClear={onClear}
      isToolsTab
    />
  );
});

RuleToolsTab.propTypes = {
  data: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterControlStyles: PropTypes.shape({}),
  showFilterControlsAtTop: PropTypes.bool,
};

RuleToolsTab.defaultProps = {
  data: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  filterControlStyles: {
    clearFiltersText: 'Clear filters',
  },
  showFilterControlsAtTop: true,
};

export default RuleToolsTab;
