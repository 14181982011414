/**
 * Creates an value and label object using provided tokens.
 * @param {string} value The value attribute.
 * @param {string} label The label attribute.
 * @returns {{label, value}} An object representing the standard value/ label required by an auto complete for checkboxes.
 */
const toValueAndLabel = (value, label) => {
  return {
    value,
    label,
  };
};

export default toValueAndLabel;
