// Global import(s)
import * as pluralise from 'pluralise';
import React from 'react';

// Config(s)
import { DATE_FORMATS, STRINGS } from '../../../../../utils/constants';

// Component(s)
import EnrichmentCount from './EnrichmentCount';
import PreviousTasksCount from '../../../../../components/PreviousTasks/PreviousTasksCount';

// Util(s)
import asUnknownIfRequired from '../../../../../utils/Transform/valueSanitizer';
import DatetimeUtil from '../../../../../utils/Datetime/datetimeUtil';
import TrailerUtil from '../../../../../utils/Trailer/trailerUtil';
import {
  AccountUtil,
  BookingUtil,
  CommonUtil,
  GoodsUtil,
  HaulierUtil,
  PersonUtil,
  VehicleUtil,
} from '../../../../../utils';

const AccompaniedMovementSection = ({ person,
  vehicle,
  trailer,
  haulier,
  account,
  booking,
  goods,
  bookingDepartureTime,
  othersCount,
  othersPreviousTaskCount }) => {
  const vehicleRegistration = asUnknownIfRequired(VehicleUtil.registration(vehicle));
  const vehicleColour = asUnknownIfRequired(VehicleUtil.colour(vehicle));
  const vehicleMake = asUnknownIfRequired(VehicleUtil.make(vehicle));
  const vehicleModel = asUnknownIfRequired(VehicleUtil.model(vehicle));

  return (
    <>
      <div className="govuk-grid-item vertical-dotted-line vertical-dotted-line--none">
        <>
          <div>
            <EnrichmentCount
              labelText="Driver details"
              movementStats={CommonUtil.movementStats(person)}
              classnames={['secondary-text']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{PersonUtil.fullname(person)}</li>
              <li>DOB: {PersonUtil.dob(person, DATE_FORMATS.SHORT)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(person)} />
            </ul>
          </div>
          <div>
            <p
              className="govuk-body-s govuk-!-margin-top-0 govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text"
            >
              Passenger details
            </p>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">
                {pluralise.withCount(othersCount, '% passenger', '% passengers', 'None')}
              </li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={othersPreviousTaskCount} />
            </ul>
          </div>
        </>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <>
          <div>
            <EnrichmentCount
              labelText="Vehicle details"
              movementStats={CommonUtil.movementStats(vehicle)}
              classnames={['secondary-text']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{vehicleRegistration}</li>
              <li>{vehicleColour}</li>
              <li>{vehicleMake}</li>
              <li>{vehicleModel}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(vehicle)} />
            </ul>
          </div>
          <div>
            <EnrichmentCount
              labelText="Trailer details"
              movementStats={CommonUtil.movementStats(trailer)}
              classnames={['secondary-text', 'govuk-!-margin-top-0']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{TrailerUtil.registration(trailer)}</li>
              <li>{TrailerUtil.type(trailer)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(trailer)} />
            </ul>
          </div>
        </>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <>
          <div>
            <EnrichmentCount
              labelText="Haulier details"
              movementStats={CommonUtil.movementStats(haulier)}
              classnames={['secondary-text']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{HaulierUtil.name(haulier)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(haulier)} />
            </ul>
          </div>
          <div>
            <EnrichmentCount
              labelText="Account details"
              movementStats={CommonUtil.movementStats(account)}
              classnames={['secondary-text', 'govuk-!-margin-top-0']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold list-item">{AccountUtil.name(account)}</li>
              <li className="list-item">Booked on {DatetimeUtil.format(BookingUtil.bookedAt(booking), DATE_FORMATS.SHORT)}</li>
              <li className="list-item">{DatetimeUtil.timeDiff(bookingDepartureTime, STRINGS.DEFAULT_BOOKING_STRING_PREFIX)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(account)} />
            </ul>
          </div>
        </>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Goods description
          </p>
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{GoodsUtil.description(goods)}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccompaniedMovementSection;
