import React from 'react';

import BorderEvent from '../BorderEvent';

const toBorderEventTab = (hasEab, borderEvent) => {
  if (!hasEab) {
    return null;
  }

  return {
    id: 'tab-eab',
    label: 'Border event',
    panel: <BorderEvent borderEvent={borderEvent} />,
  };
};

export default toBorderEventTab;
