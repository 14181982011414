import React from 'react';
import { DateTimeUtil } from '../../../../../utils';

import CaseCheckYourAnswers from './CaseCheckYourAnswers';

import { DATE_FORMATS } from '../../../../../utils/constants';

const SubmittedDetail = ({ submission }) => {
  return (
    <div>
      <div>
        <div className="container">
          <div className="date-completed">
            <b className="timeline-date">
              {DateTimeUtil.format(submission.timestamp, DATE_FORMATS.SHORT)}
            </b>
          </div>

          <div className="form-detail">
            <div>
              <details className="govuk-details" data-module="govuk-details" open>
                <summary className="govuk-details__summary">{submission.title}</summary>

                <div className="govuk-details__text--no-border detail-contents submitted-forms">
                  <dl className="govuk-summary-list govuk-summary-list--no-border">
                    <div className="govuk-summary-list__row">
                      <dt className="govuk-summary-list__key form-submission-summary">
                        Submitted by
                      </dt>
                      <dd className="govuk-summary-list__value form-submission-summary">
                        {submission.user}
                      </dd>
                    </div>
                    <div className="govuk-summary-list__row">
                      <dt className="govuk-summary-list__key form-submission-summary">
                        Submitted on
                      </dt>
                      <dd className="govuk-summary-list__value form-submission-summary">
                        {DateTimeUtil.format(submission.timestamp,
                          `${DATE_FORMATS.SHORT} ${DATE_FORMATS.CUSTOM_HOUR_MINUTE}`)}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div>
                  {submission && (
                    <div className="submitted-forms">
                      <CaseCheckYourAnswers submission={submission} />
                    </div>
                  )}
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedDetail;
