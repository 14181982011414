import { TAB_ID } from '../../constants';

const showAssigneeComponent = (tabId) => {
  return [TAB_ID.IN_PROGRESS, TAB_ID.ISSUED, TAB_ID.COMPLETE, TAB_ID.RECEIVED].includes(tabId);
};

const ComponentUtil = {
  showAssignee: showAssigneeComponent,
};

export default ComponentUtil;

export {
  showAssigneeComponent,
};
