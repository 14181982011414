import React from 'react';

import toUserAndTimestamp from './helper/toUserAndTimestamp';
import { HyperlinkifyUtil, StringUtil } from '../../utils';

const isDeprecatedBorderEventLog = (value) => {
  return value.includes('[Border Force Event Reference]');
};

const toContent = (value) => {
  if (isDeprecatedBorderEventLog(value)) {
    return HyperlinkifyUtil
      .hyperlinkify(StringUtil.unescape(value))
      .filter((item) => !!item);
  }

  const content = StringUtil.unescape(value).split(':');
  let [firstLine, ...otherLines] = content;
  firstLine = `${StringUtil.capitalizeFirst(firstLine)}${(otherLines.length !== 0 ? ':' : '')}\n`;

  let taskActivity = '';
  if (otherLines !== undefined) {
    if (otherLines.length > 0) {
      taskActivity = otherLines.join(':');
    } else {
      taskActivity = '';
    }
  } else {
    taskActivity = '';
  }
  const returnContent = StringUtil.unescape(firstLine.concat(taskActivity));

  return returnContent;
};

const Log = ({ activity }) => {
  const { content } = activity;

  if (!content?.value) {
    return null;
  }

  return (
    <div key={activity?.id}>
      <div className="activity-container">
        {toUserAndTimestamp(activity)}
        <p id="log-body" className="govuk-body govuk-!-padding-bottom-1">{toContent(content?.value)}</p>
      </div>
      <hr className="govuk-section-break govuk-section-break--m govuk-section-break--visible" />
    </div>
  );
};

export default Log;
