/* eslint-disable no-template-curly-in-string */
const getForm = () => {
  return {
    note: 'IMPORTANT: This form has been composed. Make changes to the relevant components, not this file, or your changes may get lost.',
    id: 'cerberus-general-aviation-task',
    version: '1.4.3',
    name: 'cerberus-general-aviation-task',
    title: 'General Aviation - Create a task',
    type: 'form',
    components: [
      {
        id: 'journey',
        fieldId: 'journey',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Flight details',
            useHodsTag: true,
          },
          {
            id: 'departure',
            fieldId: 'departure',
            type: 'container',
            components: [
              {
                id: 'port',
                fieldId: 'port',
                label: 'Departure airfield code',
                'aria-label': 'departure airfield code',
                type: 'radios',
                className: 'govuk-radios--small',
                data: {
                  options: [
                    {
                      value: 'ICAO',
                      label: 'ICAO code',
                      nested: [
                        {
                          id: 'icao',
                          fieldId: 'icao',
                          label: 'ICAO code of departure port',
                          'aria-label': 'icao code of the departure port',
                          type: 'text',
                          className: 'govuk-!-width-one-half',
                        },
                      ],
                    },
                    {
                      value: 'IATA',
                      label: 'IATA code',
                      nested: [
                        {
                          id: 'iata',
                          fieldId: 'iata',
                          label: 'IATA code of departure port',
                          'aria-label': 'iata code of the departure port',
                          type: 'text',
                          className: 'govuk-!-width-one-half',
                        },
                      ],
                    },
                  ],
                },
              },
              {
                id: 'date',
                fieldId: 'date',
                label: 'Departure date',
                'aria-label': 'departure date',
                hint: 'For example, 01 01 2024',
                type: 'date',
              },
              {
                id: 'time',
                fieldId: 'time',
                label: 'Departure time',
                'aria-label': 'departure time',
                hint: 'For example, use 24 hour',
                type: 'time',
              },
            ],
          },
          {
            id: 'arrival',
            fieldId: 'arrival',
            type: 'container',
            components: [
              {
                id: 'port',
                fieldId: 'port',
                label: 'Arrival airfield code',
                'aria-label': 'arrival airfield code',
                type: 'radios',
                className: 'govuk-radios--small',
                data: {
                  options: [
                    {
                      value: 'ICAO',
                      label: 'ICAO code',
                      nested: [
                        {
                          id: 'icao',
                          fieldId: 'icao',
                          label: 'ICAO code of arrival port',
                          'aria-label': 'icao code of the arrival port',
                          type: 'text',
                          className: 'govuk-!-width-one-half',
                        },
                      ],
                    },
                    {
                      value: 'IATA',
                      label: 'IATA code',
                      nested: [
                        {
                          id: 'iata',
                          fieldId: 'iata',
                          label: 'IATA code of arrival port',
                          'aria-label': 'iata code of the arrival port',
                          type: 'text',
                          className: 'govuk-!-width-one-half',
                        },
                      ],
                    },
                  ],
                },
              },
              {
                id: 'date',
                fieldId: 'date',
                label: 'Arrival date',
                'aria-label': 'arrival date',
                hint: 'For example, 01 01 2024',
                type: 'date',
              },
              {
                id: 'time',
                fieldId: 'time',
                label: 'Arrival time',
                'aria-label': 'arrival time',
                hint: 'For example, use 24 hour',
                type: 'time',
              },
            ],
          },
        ],
      },
      {
        id: 'occupants',
        fieldId: 'occupants',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Persons on board',
            useHodsTag: true,
          },
          {
            id: 'crewCount',
            fieldId: 'crewCount',
            label: 'Number of crew',
            'aria-label': 'number of crew',
            type: 'text',
            className: 'govuk-!-width-one-third',
            required: true,
            pattern: '^[0-9]+$',
            custom_errors: [
              {
                type: 'pattern',
                message: 'Number of crew must be a number',
              },
            ],
          },
          {
            id: 'passengerCount',
            fieldId: 'passengerCount',
            label: 'Number of passengers',
            'aria-label': 'number of passengers',
            type: 'text',
            className: 'govuk-!-width-one-third',
            required: true,
            pattern: '^[0-9]+$',
            custom_errors: [
              {
                type: 'pattern',
                message: 'Number of passengers must be a number',
              },
            ],
          },
        ],
      },
      {
        id: 'direction',
        fieldId: 'direction',
        label: 'Direction of flight',
        'aria-label': 'direction of flight',
        type: 'radios',
        className: 'govuk-radios--small',
        classModifiers: 'inline',
        data: {
          options: [
            {
              value: 'INBOUND',
              label: 'Inbound',
            },
            {
              value: 'OUTBOUND',
              label: 'Outbound',
            },
          ],
        },
      },
      {
        id: 'persons',
        fieldId: 'persons',
        type: 'collection',
        labels: {
          item: 'Person ${index} details',
          initial: 'Add person details',
          add: 'Add another person on board',
        },
        countOffset: 0,
        item: [
          {
            id: 'role',
            fieldId: 'role',
            label: 'Person type',
            'aria-label': 'person type',
            type: 'radios',
            className: 'govuk-radios--small',
            data: {
              options: [
                {
                  value: 'CREW',
                  label: 'Crew',
                },
                {
                  value: 'PILOT',
                  label: 'Pilot',
                },
                {
                  value: 'PASSENGER',
                  label: 'Passenger',
                },
              ],
            },
            required: true,
          },
          {
            id: 'name',
            fieldId: 'name',
            type: 'container',
            components: [
              {
                id: 'first',
                fieldId: 'first',
                label: 'Forename/s',
                required: true,
                'arial-label': 'forename/s',
                type: 'text',
                className: 'govuk-!-width-one-half',
              },
              {
                id: 'last',
                fieldId: 'last',
                label: 'Surname',
                required: true,
                'arial-label': 'surname',
                type: 'text',
                className: 'govuk-!-width-one-half',
              },
            ],
          },
          {
            id: 'gender',
            fieldId: 'gender',
            label: 'Gender',
            'aria-label': 'gender',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
            className: 'width-one-quarter hods-multi-select-autocomplete',
          },
          {
            id: 'dateOfBirth',
            fieldId: 'dateOfBirth',
            label: 'Date of Birth',
            'aria-label': 'date of birth',
            hint: 'For example, 31 3 1980',
            type: 'date',
            className: 'govuk-!-width-one-half',
            required: true,
          },
          {
            id: 'document',
            fieldId: 'document',
            type: 'container',
            required: true,
            components: [
              {
                id: 'type',
                fieldId: 'type',
                label: 'Travel document type',
                'aria-label': 'travel document type',
                type: 'radios',
                className: 'govuk-radios--small',
                hideOptionalSuffix: true,
                data: {
                  options: [
                    {
                      value: 'PASSPORT',
                      label: 'Passport',
                      nested: [
                        {
                          id: 'passportDetails',
                          fieldId: 'passportDetails',
                          type: 'container',
                          components: [
                            {
                              id: 'number',
                              fieldId: 'number',
                              label: 'Travel document number',
                              'aria-label': 'travel document number',
                              type: 'text',
                              className: 'govuk-!-width-one-half',
                              required: true,
                            },
                            {
                              id: 'expiry',
                              fieldId: 'expiry',
                              label: 'Travel document expiry',
                              'aria-label': 'travel document expiry',
                              type: 'date',
                              className: 'govuk-!-width-one-half',
                              required: true,
                            },
                            {
                              id: 'nationality',
                              fieldId: 'nationality',
                              label: 'Travel document issuing country',
                              'aria-label': 'travel document issuing country',
                              type: 'autocomplete',
                              clearable: true,
                              openOnClick: true,
                              placeholder: '',
                              item: {
                                value: 'id',
                                label: 'name',
                              },
                              data: {
                                url: '${environmentContext.referenceDataUrl}/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true',
                              },
                              className: 'width-one-quarter hods-multi-select-autocomplete',
                              required: true,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      value: 'OTHER',
                      label: 'Other',
                      nested: [
                        {
                          id: 'otherTypeDetail',
                          fieldId: 'otherTypeDetail',
                          label: 'Nature of identity document',
                          'arial-label': 'nature of identity document',
                          type: 'text',
                          className: 'govuk-!-width-one-half',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            id: 'nationality',
            fieldId: 'nationality',
            label: 'Nationality',
            'aria-label': 'nationality',
            type: 'autocomplete',
            required: true,
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'nationality',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
            },
            className: 'width-one-quarter hods-multi-select-autocomplete',
          },
          {
            id: 'ofInterest',
            fieldId: 'ofInterest',
            label: '',
            'aria-label': 'this is a person of interest',
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'true',
                  label: 'This is a person of interest',
                },
              ],
            },
          },
        ],
      },
      {
        id: 'aircraft',
        fieldId: 'aircraft',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Aircraft details',
            useHodsTag: true,
          },
          {
            id: 'registration',
            fieldId: 'registration',
            label: 'Aircraft registration',
            'aria-label': 'aircraft registration',
            type: 'text',
            className: 'govuk-!-width-one-half',
            required: true,
          },
          {
            id: 'type',
            fieldId: 'type',
            label: 'Aircraft type',
            'aria-label': 'aircraft type',
            type: 'text',
            className: 'govuk-!-width-one-half',
          },
        ],
      },
      {
        id: 'references',
        fieldId: 'references',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Reference details',
            useHodsTag: true,
          },
          {
            id: 'sgarId',
            fieldId: 'sgarId',
            label: 'sGAR ID',
            'aria-label': 'sgar id',
            type: 'text',
            className: 'govuk-!-width-one-half',
          },
          {
            id: 'caseFileReference',
            fieldId: 'caseFileReference',
            label: 'Casefile reference',
            'aria-label': 'casefile reference',
            type: 'text',
            className: 'govuk-!-width-one-half',
          },
        ],
      },
    ],
    pages: [
      {
        id: 'info',
        name: 'info',
        title: 'General Aviation - Create a task',
        components: [
          {
            use: 'journey',
          },
          {
            type: 'heading',
            size: 'm',
            content: 'Flight direction',
            useHodsTag: true,
          },
          {
            use: 'direction',
          },
          {
            use: 'aircraft',
          },
          {
            use: 'references',
          },
          {
            use: 'occupants',
          },
          {
            use: 'persons',
          },
        ],
        actions: [
          {
            type: 'submit',
            validate: true,
            label: 'Create task',
          },
          {
            type: 'cancel',
            validate: false,
            label: 'Cancel',
            classModifiers: 'secondary',
          },
        ],
      },
    ],
  };
};

const generalAviationTask = (update = false) => {
  const form = getForm();
  if (!update) {
    return form;
  }

  form.title = 'Add/edit task detail';
  form.pages[0].title = 'Add/edit task detail';
  form.pages[0].actions[0].label = 'Save';

  return form;
};

export default generalAviationTask;
