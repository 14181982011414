import { shouldHideString } from '../../Hiding/hidingUtil';
import { getMaskedValue, shouldMaskString } from '../../Masking/maskingUtil';

const getSeatNumber = (flight) => {
  if (!flight?.seatNumber) {
    return null;
  }

  if (shouldHideString(flight.seatNumber)) {
    return null;
  }

  if (shouldMaskString(flight.seatNumber)) {
    return getMaskedValue();
  }

  return flight.seatNumber;
};

/**
 * Returns an airline operator name.
 *
 * @param flight A flight entity
 * @returns {*|null} The operator name if found otherwise null.
 */
const getOperatorName = (flight) => {
  if (!flight || !flight?.carrier || !flight?.carrier?.name) {
    return null;
  }

  if (shouldHideString(flight.carrier.name)) {
    return null;
  }

  return flight.carrier.name;
};

const getAirlineOperator = (flight) => {
  if (!flight?.operator) {
    return null;
  }

  if (shouldHideString(flight.operator)) {
    return null;
  }

  if (shouldMaskString(flight.operator)) {
    return getMaskedValue();
  }

  return flight.operator;
};

const getFlightNumber = (flight) => {
  if (!flight?.number) {
    return null;
  }

  if (shouldHideString(flight.number)) {
    return null;
  }

  if (shouldMaskString(flight.number)) {
    return getMaskedValue();
  }

  return flight.number;
};

const getDepartureStatus = (flight) => {
  if (!flight?.departureStatus) {
    return null;
  }

  if (shouldHideString(flight.departureStatus)) {
    return null;
  }

  if (shouldMaskString(flight.departureStatus)) {
    return getMaskedValue();
  }

  return flight.departureStatus;
};

const getFlight = (task) => {
  return task?.movement?.flight || null;
};

const FlightUtil = {
  airlineOperator: getAirlineOperator,
  departureStatus: getDepartureStatus,
  get: getFlight,
  flightNumber: getFlightNumber,
  operatorName: getOperatorName,
  seatNumber: getSeatNumber,
};

export default FlightUtil;

export {
  getAirlineOperator,
  getFlight,
  getDepartureStatus,
  getFlightNumber,
  getOperatorName,
  getSeatNumber,
};
