import { STRINGS } from '../../constants';

const formatAddress = (address) => {
  if (!address) {
    return null;
  }
  return (address.line1 ? `${address.line1}\n` : '')
    + (address.line2 ? `${address.line2}\n` : '')
    + (address.line3 ? `${address.line3}\n` : '')
    + (address.city ? `${address.city}\n` : '')
    + (address.postCode ? `${address.postCode}\n` : '')
    + (address.country ? `${address.country}\n` : '');
};

const formatCategory = (category) => {
  if (!category) {
    return category;
  }

  return category.slice(-1);
};

const INVALID_VALUES = [STRINGS.UNKNOWN, null, undefined, 'Invalid Date', 'NaN'];

const stringifyValues = (arr) => {
  return arr.map((v) => String(v).toUpperCase());
};

const replaceInvalidValues = (value) => {
  const stringValues = stringifyValues(INVALID_VALUES);
  if (stringValues.includes(String(value).toUpperCase())) {
    return '';
  }
  return value;
};

const TargetStringUtil = {
  formatAddress,
  formatCategory,
  replaceInvalid: replaceInvalidValues,
};

export default TargetStringUtil;

export {
  formatAddress,
  formatCategory,
  replaceInvalidValues,
};
