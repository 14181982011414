import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { COMMODITIES_SYSTEM_CHECKS_LABEL, IDP_SYSTEM_CHECKS_LABEL, GA_SYSTEM_CHECKS_LABEL } from './constants';

// Context(s)
import { useTask } from '../../../../../../../context/TaskContext';

// Component(s)
import Table from '../../../../../../../components/Table/Table';

// Util(s)
import { CredibilityChecksUtil } from '../../../../../../../utils';
import { isCommoditiesSubMode, isGaSubMode, isIdpSubMode } from '../../../../../../../utils/Task/taskUtil';

// Styling
import './OtherChecksSummary.scss';

const OtherChecksSummary = ({ passenger, passengerPositionedAt }) => {
  const { subMode } = useTask();
  const credibilityChecks = CredibilityChecksUtil.getChecksWithoutPnc(passenger);
  const checksCompleted = credibilityChecks.filter((credibilityCheck) => !!credibilityCheck.result);

  const getChecksByRbac = () => {
    if (isCommoditiesSubMode(subMode)) {
      return Object.keys(COMMODITIES_SYSTEM_CHECKS_LABEL);
    }

    if (isIdpSubMode(subMode)) {
      return Object.keys(IDP_SYSTEM_CHECKS_LABEL);
    }

    if (isGaSubMode(subMode)) {
      return Object.keys(GA_SYSTEM_CHECKS_LABEL);
    }

    return [];
  };

  const getChecksNotCompleted = () => {
    const systemsByRbac = getChecksByRbac();
    const systems = checksCompleted.map((check) => check.system);
    return systemsByRbac.filter((check) => !systems.includes(check));
  };

  const formatTrace = (trace) => {
    if (trace) {
      return 'Trace';
    }
    return 'No trace';
  };

  const toFormattedSystemByRbac = (system) => {
    if (isCommoditiesSubMode(subMode)) {
      return COMMODITIES_SYSTEM_CHECKS_LABEL[system];
    }

    if (isIdpSubMode(subMode)) {
      return IDP_SYSTEM_CHECKS_LABEL[system];
    }

    if (isGaSubMode(subMode)) {
      return GA_SYSTEM_CHECKS_LABEL[system];
    }

    return null;
  };

  const toChecksCompletedRows = () => {
    return checksCompleted.map((checkCompleted) => {
      if (checkCompleted.type === 'TRACE') {
        return [
          toFormattedSystemByRbac(checkCompleted.system),
          formatTrace(checkCompleted.result.trace),
          checkCompleted.result.comment,
        ];
      }

      if (checkCompleted.type === 'OTHER') {
        return [
          checkCompleted.result.systemChecked,
          formatTrace(checkCompleted.result.trace),
          checkCompleted.result.comment,
        ];
      }

      return null;
    });
  };

  const toChecksCompleted = () => {
    if (!checksCompleted?.length) {
      return null;
    }

    return (
      <div id={`passenger-${passengerPositionedAt}-checks-completed`}>
        <Heading size="s">Other checks completed</Heading>
        <Table
          className="checks-summary-completed"
          headings={['Check', 'Result', 'Comment']}
          rows={[
            ...toChecksCompletedRows(),
          ]}
        />
      </div>
    );
  };

  const toChecksNotCompleted = () => {
    const checksNotCompleted = getChecksNotCompleted();

    if (!checksNotCompleted?.length) {
      return null;
    }

    return (
      <div id={`passenger-${passengerPositionedAt}-checks-not-completed`}>
        <Heading size="s">Checks not completed</Heading>
        {checksNotCompleted.map((checkNotCompleted) => (
          <p
            key={`check-not-completed-passenger-${passengerPositionedAt}-check-${checkNotCompleted}`}
            id={`passenger-${passengerPositionedAt}-check-not-completed`}
          >
            {toFormattedSystemByRbac(checkNotCompleted)}
          </p>
        ))}
      </div>
    );
  };

  const toChecksSummary = () => {
    if (CredibilityChecksUtil.notRequired(passenger?.credibilityChecks)) {
      return (
        <div id={`passenger-${passengerPositionedAt}-checks-not-required`}>
          <Heading size="s">Credibility checks not necessary</Heading>
          <p id={`passenger-${passengerPositionedAt}-checks-not-required-reason`}>
            {CredibilityChecksUtil.notRequiredReason(passenger?.credibilityChecks)}
          </p>
        </div>
      );
    }

    return (
      <>
        {toChecksCompleted()}
        {toChecksNotCompleted()}
      </>
    );
  };

  return (
    <div id={`passenger-${passengerPositionedAt}-checks-summary`}>
      {toChecksSummary()}
    </div>
  );
};

OtherChecksSummary.propTypes = {
  passenger: PropTypes.shape({}).isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
};

export default OtherChecksSummary;
