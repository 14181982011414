import React from 'react';

import getLogByType from './helper/getLogByType';

import './ActivityLog.scss';

const ActivityLog = ({ activityLog }) => {
  return (
    <>
      <h3 className="govuk-heading-m activity-heading">Task activity</h3>
      { activityLog.map((activity) => {
        return getLogByType(activity);
      })}
    </>
  );
};

export default ActivityLog;
