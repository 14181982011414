import { FORM_ACTIONS } from '../utils/constants';

export default {
  id: 'relistTask',
  version: '0.0.1',
  name: 'relistTask',
  title: 'Relist task',
  type: 'wizard',
  components: [],
  pages: [
    {
      id: 'reason-for-relisting',
      name: 'reason-for-relisting',
      components: [
        {
          type: 'heading',
          size: 'm',
          content: 'Provide a reason for relisting the task',
        },
        {
          id: 'reasonForRelisting',
          fieldId: 'reasonForRelisting',
          className: 'govuk-!-margin-bottom-3',
          label: 'Provide a reason for relisting the task',
          'aria-label': 'provide a reason for relisting the task',
          type: 'textarea',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Enter a reason for relisting the task',
            },
          ],
        },
        {
          type: 'heading',
          size: 's',
          content: 'What happens next',
        },
        'The task will return to \'in progress\' where you will be able to action it.',
      ],
      actions: [
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
        {
          type: 'submit',
          label: 'Relist task',
          validate: true,
        },
      ],
    },
  ],
};
