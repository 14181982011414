export const scrollToMainContent = (e) => {
  e.preventDefault();
  const div = document.getElementById('main-content');
  if (div) {
    div.setAttribute('tabIndex', '-1');
    div.focus();
    div.removeAttribute('tabIndex');
  }
};

export default scrollToMainContent;
