import { FormGroup, Heading, TextInput } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

// Styling
import './OperationName.scss';

const OperationName = ({ onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.OPERATION}
      required
      label={(
        <div id="operation-name-heading">
          <Heading size="m">Operation name</Heading>
          <p>&nbsp;&nbsp;(optional)</p>
        </div>
        )}
      className="govuk-!-margin-bottom-7"
    >
      <TextInput
        id={COMPONENT_IDS.OPERATION}
        fieldId={COMPONENT_IDS.OPERATION}
        onChange={onChange}
        rows="5"
        value={formData?.[COMPONENT_IDS.OPERATION]}
      />
    </FormGroup>
  );
};

OperationName.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

OperationName.defaultProps = {
  formData: null,
};

export default memo(OperationName);
