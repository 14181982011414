import { formatAddress } from '../../String/Target/targetStringUtil';

const getConsigneeAddress = (consignee) => {
  if (!consignee) {
    return undefined;
  }
  return formatAddress(consignee?.address) || undefined;
};

const getConsigneeName = (consignee) => {
  return consignee?.name || undefined;
};

const getConsignee = (informationSheet) => {
  return informationSheet?.movement?.consignee || undefined;
};

const TargetConsigneeUtil = {
  get: getConsignee,
  name: getConsigneeName,
  address: getConsigneeAddress,
};

export default TargetConsigneeUtil;
