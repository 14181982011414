import { Button, Panel } from '@ukhomeoffice/cop-react-components';
import React from 'react';

const OutcomeMessage = ({ message, onFinish, description }) => {
  return (
    <div id="outcome-message">
      <Panel title={message} />
      {description}
      <Button
        className="govuk-button"
        onClick={onFinish}
      >
        Finish
      </Button>
    </div>
  );
};

export default OutcomeMessage;
