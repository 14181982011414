import React from 'react';

const TargetIndicatorsSection = ({ risks }) => {
  return (
    <section id="previous-task-list-target-indicators-section" className="previous-task-target-indicators-section">
      <div className="section-header">
        Rules / Selectors hit
      </div>
      {risks && risks?.length && (
        <div className="section-body">
          <ul>
            {risks.map((risk, index) => {
              return (
                <li id={`risk-${index}`} key={risk}>
                  {risk}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </section>
  );
};

export default TargetIndicatorsSection;
