import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import fileDownload from 'js-file-download';
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../../../../context/Keycloak';
import AxiosRequests from '../../../../../api/axiosRequests';

import config from '../../../../../utils/config';
import { DATE_FORMATS } from '../../../../../utils/constants';

import Table from '../../../../../components/Table/Table';

import { DateTimeUtil } from '../../../../../utils';

const DEFAULT_HEADERS = ['Name', 'Uploaded by', 'Uploaded on'];

const BorderEventAttachments = ({ attachments }) => {
  const keycloak = useKeycloak();
  const fileApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);

  const handleDownload = (e, { url, filename }) => {
    e.preventDefault();
    AxiosRequests.blobData(fileApiClient, url)
      .then((data) => fileDownload(data, filename));
  };

  const toRow = (attachment) => {
    return [
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        key={attachment.filename}
        href="#"
        onClick={(e) => handleDownload(e, attachment)}
      >
        {attachment.filename}
      </Link>,
      <p key={attachment.filename}>{attachment.user}</p>,
      <p key={attachment.filename}>{DateTimeUtil.format(attachment.timestamp,
        `${DATE_FORMATS.SHORT} ${DATE_FORMATS.CUSTOM_HOUR_MINUTE}`)}
      </p>,
    ];
  };

  return (
    <Table
      headings={DEFAULT_HEADERS}
      rows={attachments.map((attachment) => toRow(attachment))}
    />
  );
};

export default BorderEventAttachments;
