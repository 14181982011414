import { MOVEMENT_MODES } from '../constants';

export const DIRECTION = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
};

export const PNC_OUTCOMES = {
  NO_RECORDS_FOUND: 'NO_RECORDS_FOUND',
  INSUFFICIENT_IDENTITY_INFORMATION: 'INSUFFICIENT_IDENTITY_INFORMATION',
  INSUFFICIENT_TIME: 'INSUFFICIENT_TIME',
  CHECK_NOT_RELEVANT_FOR_PERSON: 'CHECK_NOT_RELEVANT_FOR_PERSON',
  IRRELEVANT_INFORMATION_FOUND: 'IRRELEVANT_INFORMATION_FOUND',
  POTENTIALLY_RELEVANT_INFORMATION_FOUND: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
};

export const PNC_FIELDS = {
  DRUGS: 'DRUGS',
  SUPPLY: 'SUPPLY',
  TRAFFICKING: 'TRAFFICKING',
  POSSESSION: 'POSSESSION',
  GOODS_SMUGGLING: 'GOODS_SMUGGLING',
  PEOPLE_SMUGGLING: 'PEOPLE_SMUGGLING',
  STAFF_CONCERN: 'STAFF_CONCERN',
  POI_CONCERN: 'POI_CONCERN',
};

export const RORO_MODES = [
  MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
  MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
  MOVEMENT_MODES.TOURIST];
