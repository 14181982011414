// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import config from '../../../../../../utils/config';
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Component(s)
import AirpaxTargetInformation from './AirpaxTargetInformation';
import AirpaxTargetSheet from './AirpaxTargetSheet';
import GeneralAviationTargetSheet from './GeneralAviationTargetSheet';
import RoRoTargetInformation from './RoRoTargetInformation';

// Util(s)
import TargetMovementUtil from '../../../../../../utils/Movement/Target/targetMovementUtil';
import TargetPersonUtil from '../../../../../../utils/Person/Target/targetPersonUtil';

const TargetInformation = ({ informationSheet, preview }) => {
  const getComponent = () => {
    const mode = TargetMovementUtil.mode(informationSheet);
    const persons = TargetPersonUtil.getAll(informationSheet);

    switch (mode) {
      case MOVEMENT_MODES.GENERAL_AVIATION: {
        return <GeneralAviationTargetSheet informationSheet={informationSheet} persons={persons} mode={mode} />;
      }
      case MOVEMENT_MODES.AIR_PASSENGER: {
        if (config.enableUpliftTargetSheet) {
          return <AirpaxTargetSheet informationSheet={informationSheet} mode={mode} persons={persons} preview={preview} />;
        }
        return <AirpaxTargetInformation persons={persons} mode={mode} />;
      }

      case MOVEMENT_MODES.TOURIST:
      case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
      case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
        return <RoRoTargetInformation informationSheet={informationSheet} mode={mode} />;
      }
      default: {
        return null;
      }
    }
  };

  return getComponent();
};

TargetInformation.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  preview: PropTypes.bool,
};

TargetInformation.defaultProps = {
  preview: false,
};

export default memo(TargetInformation);
