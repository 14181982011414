import { FORM_ACTIONS } from '../utils/constants';

export default {
  id: 'deleteTask',
  version: '0.0.1',
  name: 'deleteTask',
  title: 'Delete a task (Cerberus)',
  type: 'form',
  components: [],
  pages: [
    {
      id: 'deleteTask',
      name: 'deleteTask',
      components: [
        {
          id: 'taskId',
          fieldId: 'taskId',
          className: 'govuk-!-margin-bottom-3 govuk-!-width-two-thirds',
          label: 'Enter a task id',
          'aria-label': 'enter a task id',
          type: 'text',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'You must enter a task id',
            },
          ],
        },
      ],
      actions: [
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Delete',
          classModifiers: 'warning',
        },
      ],
    },
  ],
};
