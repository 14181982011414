// Global import(s)
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Config(s)
import config from '../../../../../../../utils/config';
import { FORM_MESSAGES } from '../../../../../../../utils/constants';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../context/TaskContext';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Component(s)
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import UpliftedForm from '../forms/UpliftedForm';

// Hook(s)
import useRefDataFetch from '../../../../../../../utils/Hooks/useRefDataFetch';
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';

// Util(s)
import toAutoPopulationPayload from './helper/toAutoPopulationPayload';
import toSubmissionPayload from '../../../../../GATask/helper/toSubmissionPayload';

// Form
import generalAviationTask from '../../../../../../../forms/generalAviationTask';

const UpdateGeneralAviationTask = ({ onClose }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { task, setTask } = useTask();
  const { data: nationalities, isLoading: isLoadingNationalities } = useRefDataFetch('/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true');
  const { data: genders, isLoading: isLoadingGenders } = useRefDataFetch('/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true');

  useEffect(() => {
    if (!isLoadingNationalities && !isLoadingGenders) {
      setData(toAutoPopulationPayload(task, nationalities, genders));
      setIsLoading(false);
    }
  }, [isLoadingNationalities, isLoadingGenders]);

  if (isLoading || isLoadingNationalities || isLoadingGenders) {
    return <LoadingSpinner />;
  }

  const onCancelConfirmation = (onCancel) => {
    // eslint-disable-next-line no-alert
    if (confirm(FORM_MESSAGES.ON_CANCELLATION)) {
      onCancel();
    }
  };

  const onSubmit = async ({ data: formData }) => {
    setIsLoading(true);
    await AxiosRequests.updateGaTask(apiClient, task.id, toSubmissionPayload(formData))
      .then((updatedTask) => {
        setTask(updatedTask);
        setIsLoading(false);
        onClose();
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <UpliftedForm
          form={generalAviationTask(true)}
          preFillData={data}
          fullScreenOnly
          onSubmit={onSubmit}
          onCancel={() => onCancelConfirmation(onClose)}
        />
      </div>
    </div>
  );
};

UpdateGeneralAviationTask.propTypes = {
  onClose: PropTypes.shape({}).isRequired,
};

export default UpdateGeneralAviationTask;
