// Global import(s)
import React from 'react';

// Context(s)
import { useView } from '../../context/ViewContext';

// Components
import DefaultLayout from './DefaultLayout';
import UpliftedLayout from '../Uplift/Layout/Layout';

const Layout = ({ beforeMain, children }) => {
  const { view, useUpliftLayout } = useView();

  if (!view) {
    return null;
  }

  if (useUpliftLayout) {
    return (
      <UpliftedLayout beforeMain={beforeMain}>
        {children}
      </UpliftedLayout>
    );
  }

  return (
    <DefaultLayout beforeMain={beforeMain}>
      {children}
    </DefaultLayout>
  );
};

export default Layout;
