import React from 'react';

import { FRONTLINE_OUTCOME_NAME, STRINGS } from '../../utils/constants';

import { StringUtil } from '../../utils/index';
import toUserAndTimestamp from './helper/toUserAndTimestamp';

const toLabel = (content) => {
  return <div className="font-bold">{content}</div>;
};

const toValue = (content) => {
  return (
    <ul className="govuk-!-padding-left-5 govuk-!-margin-top-0 govuk-!-margin-bottom-1">
      <li>{content}</li>
    </ul>
  );
};

const OutcomeLog = ({ activity }) => {
  if (!activity?.content?.outcome) {
    return null;
  }

  const { content } = activity;

  return (
    <div key={activity?.id}>
      <div className="activity-container activity-container--outcome">
        {toUserAndTimestamp(activity)}
        <div className="govuk-body govuk-!-padding-bottom-1">
          {toLabel('Action taken')}
          <br />
          {toLabel('What was the outcome of the target?')}
          {toValue(FRONTLINE_OUTCOME_NAME[content?.outcome] ?? STRINGS.UNKNOWN)}
          {toLabel('Was anyone arrested?')}
          {toValue(StringUtil.boolToString(content?.anyoneArrested))}
          {content?.otherInformation && (
          <>
            {toLabel('Reason for Travel and Other Information.')}
            {toValue(StringUtil.unescape(content?.otherInformation))}
          </>
          )}
          {toLabel('Are there any return tickets?')}
          {toValue(StringUtil.boolToString(content?.returnTickets))}
          {content?.updatedInformation && (
          <>
            {toLabel('Details that update the information we hold for this target.')}
            {toValue(StringUtil.unescape(content?.updatedInformation))}
          </>
          )}
        </div>
      </div>
      <hr className="govuk-section-break govuk-section-break--m govuk-section-break--visible" />
    </div>
  );
};

export default OutcomeLog;
