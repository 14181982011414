import { formatAddress } from '../../String/Target/targetStringUtil';

const getHaulierAddress = (haulier) => {
  if (!haulier) {
    return undefined;
  }
  return formatAddress(haulier?.address) || undefined;
};

const getHaulierName = (haulier) => {
  return haulier?.name || undefined;
};

const getHaulier = (informationSheet) => {
  return informationSheet?.movement?.haulier || undefined;
};

const TargetHaulierUtil = {
  get: getHaulier,
  name: getHaulierName,
  address: getHaulierAddress,
};

export default TargetHaulierUtil;
