import { FormGroup, VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';

// Config
import { OTHER_CHECKS_FORM_PREFIX } from '../../constants';

const Comment = ({ id, uniqueId, onChange, label, containerId, formData, required, error, sourceValuePath: _sourceValuePath }) => {
  const [cursor, setCursor] = useState(null);
  const inputRef = useRef(null);
  const componentUniqueId = [id, uniqueId].filter((item) => !!item || item === 0).join('');
  const sourceValuePath = _sourceValuePath || `${containerId}.${OTHER_CHECKS_FORM_PREFIX}.${id}`;
  const value = useMemo(() => get(formData, sourceValuePath) ?? '', [formData]);

  const onInternalChange = (event) => {
    setCursor(event.target.selectionStart);
    onChange(event);
  };

  useLayoutEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [inputRef, cursor, value]);

  return (
    <FormGroup
      id={componentUniqueId}
      label={label}
      required={required}
      error={error}
    >
      <VisuallyHidden id={componentUniqueId} aria-hidden />
      <textarea
        ref={inputRef}
        id={componentUniqueId}
        className="govuk-textarea"
        name={componentUniqueId}
        rows="1"
        onChange={onInternalChange}
        value={value}
      />
    </FormGroup>
  );
};

Comment.propTypes = {
  id: PropTypes.string.isRequired,
  uniqueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  label: PropTypes.string,
  formData: PropTypes.shape({}),
  required: PropTypes.bool,
  error: PropTypes.string,
  sourceValuePath: PropTypes.string,
};

Comment.defaultProps = {
  uniqueId: null,
  formData: null,
  label: 'Comment',
  error: null,
  required: false,
  sourceValuePath: null,
};

export default memo(Comment);
