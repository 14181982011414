// Global imports
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { TIME_FORMATS, EUROPE_LONDON } from '../../../../../../../utils/constants';

// Component(s)
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import { TargetJourneyUtil, VehicleUtil } from '../../../../../../../utils';
import DateTimeUtil from '../../../../../../../utils/Datetime/Uplift/datetimeUtil';

const FlightDetails = ({ informationSheet }) => {
  const journey = TargetJourneyUtil.get(informationSheet);
  const departureLocation = TargetJourneyUtil.departureLocation(journey);
  const departureDate = TargetJourneyUtil.departureDate(journey);
  const departureTimezone = DateTimeUtil.timezone(departureLocation);
  const arrivalLocation = TargetJourneyUtil.arrivalLocation(journey);
  const arrivalDate = TargetJourneyUtil.arrivalDate(journey);
  const arrivalTimezone = DateTimeUtil.timezone(arrivalLocation);
  const vehicle = VehicleUtil.get(informationSheet);

  const panelData = (() => {
    return [
      {
        id: 'aircraft-registration',
        label: 'Aircraft registration',
        value: VehicleUtil.registration(vehicle),
      },
      {
        id: 'aircraft-type',
        label: 'Aircraft type',
        value: VehicleUtil.type(vehicle),
      },
      {
        id: 'arrival-airfield',
        label: 'Arrival airfield',
        value: `${arrivalLocation} (${TargetJourneyUtil.format.airportName(arrivalLocation)})`,
      },
      {
        id: 'estimated-date-of-arrival',
        label: 'Estimated date of arrival',
        value: DateTimeUtil.format.fromOriginToDestinationTimezone(arrivalDate, EUROPE_LONDON, arrivalTimezone, TIME_FORMATS.DATE.DEFAULT),
      },
      {
        id: 'estimated-time-of-arrival',
        label: 'Estimated time of arrival',
        value: DateTimeUtil.format.fromOriginToDestinationTimezone(arrivalDate, EUROPE_LONDON, arrivalTimezone, TIME_FORMATS.DATE.TIME),
      },
      {
        id: 'departure-airfield',
        label: 'Departure airfield',
        value: `${departureLocation} (${TargetJourneyUtil.format.airportName(departureLocation)})`,
      },
      {
        id: 'estimated-date-of-departure',
        label: 'Estimated date of departure',
        value: DateTimeUtil.format.fromOriginToDestinationTimezone(departureDate, EUROPE_LONDON, departureTimezone, TIME_FORMATS.DATE.DEFAULT),
      },
      {
        id: 'estimated-time-of-departure',
        label: 'Estimated time of departure',
        value: DateTimeUtil.format.fromOriginToDestinationTimezone(departureDate, EUROPE_LONDON, departureTimezone, TIME_FORMATS.DATE.TIME),
      },
    ];
  })();

  return (
    <div id="flight-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Flight details</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
      </div>
    </div>
  );
};

FlightDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(FlightDetails);
