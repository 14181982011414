// Util(s)
import { formatDataFromArray } from '../../Common/commonUtil';

const getRefDataMode = (informationSheet) => {
  return informationSheet?.movement?.refDataMode?.mode || null;
};

const getGroupTasksMetaData = (groupedAirPaxTasks) => {
  return {
    name: groupedAirPaxTasks?.name || null,
    direction: groupedAirPaxTasks?.direction || null,
    departure: groupedAirPaxTasks?.departure || null,
    arrival: groupedAirPaxTasks?.arrival || null,
  };
};

const getVesselName = (informationSheet) => {
  return informationSheet?.movement?.vessel?.name || null;
};

const getRoute = (informationSheet) => {
  return informationSheet?.movement?.journey?.route || null;
};

const getPort = (informationSheet) => {
  return informationSheet?.eventPort?.name || null;
};

const toFormattedStrategies = (informationSheet) => {
  const controlStrategies = informationSheet?.controlStrategies;
  if (!controlStrategies) {
    return '';
  }

  if (Array.isArray(controlStrategies) && !controlStrategies?.length) {
    return '';
  }

  return controlStrategies
    .reduce((prev, next) => {
      return [...prev, `${next?.strategy} / Control Strategy ${next?.priority}`];
    }, [])
    .filter((strategy) => !!strategy)
    .join(', ');
};

const getNominalChecks = (informationSheet) => {
  return (
    informationSheet?.nominalChecks?.map((nominalCheck, i) => {
      return {
        item: `check${i}`,
        type: nominalCheck?.type,
        comments: nominalCheck?.comments,
        checks: formatDataFromArray(nominalCheck?.checks, 'name'),
      };
    }) || []
  );
};

const getOperation = (informationSheet) => {
  return informationSheet?.operation || null;
};

const getSelectionReasoning = (informationSheet) => {
  return informationSheet?.selectionReasoning || null;
};

const getMode = (informationSheet) => {
  return informationSheet?.movement?.mode || null;
};

const getTargetId = (informationSheet) => {
  return informationSheet?.id || null;
};

const TargetMovementUtil = {
  groupedMeta: getGroupTasksMetaData,
  refDataMode: getRefDataMode,
  mode: getMode,
  port: getPort,
  route: getRoute,
  vesselName: getVesselName,
  formattedStrategies: toFormattedStrategies,
  nominalChecks: getNominalChecks,
  operation: getOperation,
  targetId: getTargetId,
};

export default TargetMovementUtil;

export {
  getMode,
  getNominalChecks,
  getOperation,
  getGroupTasksMetaData,
  getSelectionReasoning,
  toFormattedStrategies,
  getPort,
  getRoute,
  getTargetId,
  getRefDataMode,
  getVesselName,
};
