// Config(s)
import { STRINGS } from '../constants';

// Util(s)
import NumberUtil from '../Number/numberUtil';

export const HAZARDOUS_MAPPING = {
  true: STRINGS.YES,
  false: STRINGS.NO,
  null: '',
  undefined: '',
  '': '',
};

const toHazardousFormatted = (hazardous) => {
  return HAZARDOUS_MAPPING[hazardous];
};

const getGoodsWeight = (goods) => {
  if (!goods) {
    return STRINGS.UNKNOWN;
  }
  return goods?.weight || STRINGS.UNKNOWN;
};

const getGrossWeight = (goods) => {
  return goods?.grossWeight || null;
};

const getHazardous = (goods) => {
  return goods?.hazardous;
};

const getNetWeight = (goods) => {
  return goods?.netWeight || null;
};

const getDescription = (goods) => {
  if (!goods) {
    return STRINGS.UNKNOWN;
  }
  return goods?.description || STRINGS.UNKNOWN;
};

const getGoods = (targetTask) => {
  return targetTask?.movement?.goods || undefined;
};

const hasWeight = (good) => {
  return good?.grossWeight && good?.netWeight;
};

const getTotalWeight = (items, attr) => {
  return items.map((item) => item?.[attr])
    .filter((item) => !!item)
    .reduce((prev, curr) => (Number.parseInt(prev, 10) || 0) + (Number.parseInt(curr, 10) || 0), 0);
};

const formatWeight = (weight) => {
  if (!weight || weight === STRINGS.UNKNOWN) {
    return null;
  }

  if (weight.endsWith('kg')) {
    return weight;
  }

  return `${NumberUtil.withComma(weight)}kg`;
};

const toGrossWeightFormatted = (goods) => {
  const weight = getGrossWeight(goods);
  return formatWeight(weight);
};

const toNetWeightFormatted = (goods) => {
  const weight = getNetWeight(goods);
  return formatWeight(weight);
};

const toWeightFormatted = (goods) => {
  const weight = getGoodsWeight(goods);
  return formatWeight(weight);
};

const GoodsUtil = {
  get: getGoods,
  description: getDescription,
  getTotalWeight,
  grossWeight: getGrossWeight,
  hasWeight,
  hazardous: getHazardous,
  netWeight: getNetWeight,
  weight: getGoodsWeight,
  format: {
    hazardous: toHazardousFormatted,
    grossWeight: toGrossWeightFormatted,
    netWeight: toNetWeightFormatted,
    weight: toWeightFormatted,
  },
};

export default GoodsUtil;
