// Global import(s)
import React from 'react';

// Component(s)
import PreviousTasksCount from '../../../../../components/PreviousTasks/PreviousTasksCount';
import EnrichmentCount from './EnrichmentCount';

// Util(s)
import asUnknownIfRequired from '../../../../../utils/Transform/valueSanitizer';
import { CommonUtil, PersonUtil, VehicleUtil } from '../../../../../utils';
import { toBookingBlock, toCoTravellersBlock } from '../../helper/common';

const TouristMovementVehicle = ({ person, vehicle, booking, datetimeList, otherPersons }) => {
  const vehicleRegistration = asUnknownIfRequired(VehicleUtil.registration(vehicle));

  return (
    <>
      <div className="govuk-grid-item">
        <div>
          <EnrichmentCount
            labelText="Driver"
            movementStats={CommonUtil.movementStats(person)}
            classnames={['secondary-text']}
          />
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{PersonUtil.fullname(person)}</li>
            <li>{PersonUtil.gender(person)}</li>
          </ul>
          <ul className="govuk-body-s govuk-list">
            <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(person)} />
          </ul>
        </div>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <EnrichmentCount
            labelText="VRN"
            movementStats={CommonUtil.movementStats(vehicle)}
            classnames={['secondary-text']}
          />
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{vehicleRegistration}</li>
          </ul>
        </div>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        {toBookingBlock(booking, datetimeList)}
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        {toCoTravellersBlock(otherPersons)}
      </div>
    </>
  );
};

export default TouristMovementVehicle;
