const MaskingConstants = {
  stringValue: '*****',
  dateValue: '-1000000000-01-01T00:00:00Z',
  numberValue: -2147483648,
};

const shouldMaskStringArray = (arr) => {
  return arr.every((item) => {
    return item === MaskingConstants.stringValue;
  });
};

const shouldMaskObjectArrayByAttr = (arr, attr) => {
  return arr.every((item) => item[attr] === MaskingConstants.stringValue);
};

const shouldMaskNumber = (number) => {
  return number === MaskingConstants.numberValue;
};

const shouldMaskString = (value) => {
  return value === MaskingConstants.stringValue;
};

const shouldMaskDate = (date) => {
  return date === MaskingConstants.dateValue;
};

const getMaskedValue = () => {
  return MaskingConstants.stringValue;
};

export {
  shouldMaskNumber,
  shouldMaskDate,
  shouldMaskString,
  getMaskedValue,
  shouldMaskObjectArrayByAttr,
  shouldMaskStringArray,
  MaskingConstants,
};
