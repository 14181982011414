// utils/additionalContent.js
import { v4 as uuidv4 } from 'uuid';

import EntityUtil from '../../../../../utils/Entity/entityUtil';

const addAdditionalContentToTask = (task, additionalContent, user, dateTimeStamp) => {
  const addTaskActivity = (activities) => {
    const activity = {
      id: uuidv4(),
      content: 'additional details added',
      user,
      timestamp: dateTimeStamp,
    };

    activities.unshift(activity);
  };

  additionalContent.forEach((content) => {
    if (content.type === 'BOOKING') {
      task.movement.booking.additionalContent.unshift({
        id: uuidv4(),
        type: content.content.type,
        content: content.content.content,
        user,
        timestamp: dateTimeStamp,
      });
    } else if (content.type === 'BAGGAGE') {
      task.movement.baggage.additionalContent.unshift({
        id: uuidv4(),
        type: content.content.type,
        content: content.content.content,
        user,
        timestamp: dateTimeStamp,
      });
    } else if (content.type === 'VOYAGE') {
      task.movement.journey.additionalContent.unshift({
        id: uuidv4(),
        type: content.content.type,
        content: content.content.content,
        user,
        timestamp: dateTimeStamp,
      });
    } else if (content.type === 'PASSENGER') {
      const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];

      if (content.content.type === 'PHOTO') {
        personsToUpdate.forEach((person) => {
          if (EntityUtil.isMatchingEntity(person.entityId, content.entityId)) {
            person.additionalContent.unshift({
              id: uuidv4(),
              type: content.content.type,
              url: content.content.url,
              filename: content.content.filename,
              user,
              timestamp: dateTimeStamp,
            });
          }
        });
      } else {
        personsToUpdate.forEach((person) => {
          if (EntityUtil.isMatchingEntity(person.entityId, content.entityId)) {
            person.additionalContent.unshift({
              id: uuidv4(),
              type: content.content.type,
              content: content.content.content,
              user,
              timestamp: dateTimeStamp,
            });
          }
        });
      }
      task.movement.person = personsToUpdate[0];
      task.movement.otherPersons = personsToUpdate.slice(1);
    } else if (content.type === 'DOCUMENT') {
      const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];

      personsToUpdate.forEach((person) => {
        if (person.document) {
          if (EntityUtil.isMatchingEntity(person.document.entityId, content.entityId)) {
            person.document.additionalContent.unshift({
              id: uuidv4(),
              type: content.content.type,
              content: content.content.content,
              user,
              timestamp: dateTimeStamp,
            });
          }
        }
      });
      task.movement.person = personsToUpdate[0];
      task.movement.otherPersons = personsToUpdate.slice(1);
    }
  });

  addTaskActivity(task.activities);
  return task;
};

const addAdditionalContent = (task, submissionPayload, user, dateTime) => {
  const updatedTask = addAdditionalContentToTask(task, submissionPayload, user, dateTime);
  return updatedTask;
};

export default addAdditionalContent;
