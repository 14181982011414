// Global import(s)
import React from 'react';

// Styling
import './TabNotificationIcon.scss';

const TabNotificationIcon = () => {
  return <span className="notification-icon" />;
};

export default TabNotificationIcon;
