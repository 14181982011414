import React from 'react';

import LinkButton from './LinkButton';

import cleanAttributes from '../Utils/Component/cleanAttributes';

import './CommonButton.scss';

const generateAriaLabel = ({ taskId, children }) => {
  if (!children || !taskId) {
    return null;
  }

  if (children === 'Dismiss') {
    return `select ${taskId} for dismissal`;
  }

  // In this instance taskId will be an array of ids
  if (children === 'Dismiss all') {
    return taskId && Array.isArray(taskId) && taskId.length > 1 ? `dismiss tasks ${taskId.join(', ')}` : `dismiss task ${taskId}`;
  }

  if (children === "Return to 'New' tab") {
    return `return ${taskId} to new tab`;
  }

  if (children === 'Claim') {
    return `claim task ${taskId}`;
  }

  if (children === 'Unclaim task') {
    return `unclaim task ${taskId}`;
  }

  return null;
};

const CommonButton = ({ inProgress, commonProps }) => {
  const ariaLabel = generateAriaLabel(commonProps);
  if (inProgress) {
    return <span id="in-progress-dialog" className="govuk-body">Please wait...</span>;
  }
  return <LinkButton type="button" {...cleanAttributes(commonProps, 'taskId')} {...ariaLabel && { 'aria-label': ariaLabel }} />;
};

export default CommonButton;
