import { Heading, Utils } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './PageHeaderActions.scss';

const DEFAULT_CLASS = 'hods-page-header-actions';

const PageHeaderActions = ({ heading, classBlock, classModifiers, className, children, ...props }) => {
  const classes = Utils.classBuilder(classBlock, classModifiers, className);

  return (
    <div {...props} className={classes()}>
      <div className={`${DEFAULT_CLASS}__title`}>
        <Heading size="xl">{heading}</Heading>
      </div>
      <div className={`${DEFAULT_CLASS}__actions`}>
        <div className={`${DEFAULT_CLASS}__menu`}>
          <div className={`${DEFAULT_CLASS}__menu_wrapper`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

PageHeaderActions.propTypes = {
  heading: PropTypes.string.isRequired,
  classBlock: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  className: PropTypes.string,
};

PageHeaderActions.defaultProps = {
  classBlock: DEFAULT_CLASS,
  classModifiers: [],
  className: '',
};

export default PageHeaderActions;
