const QUERY_KEYS = {
  BORDER_EVENT: 'fetch-border-event',
  CURRENT_GROUP: 'fetch-current-group',
  FILTER_COUNT: 'fetch-filter-count',
  FORM_JSON: 'fetch-form-json',
  FRONTLINE_OFFICERS: 'fetch-frontline-officers',
  IMAGE: 'fetch-image',
  INFORMATION_SHEET: 'fetch-information-sheet',
  JUSTIFICATIONS: 'fetch-justifications',
  MOVEMENT_IDS: 'fetch-movement-ids',
  PREVIOUS_TASK: 'fetch-previous-task',
  RULES_MATCHED: 'fetch-rules-matched',
  SINGLE_TASK: 'fetch-single-task',
  TARGETING_TEAMS: 'fetch-targeting-teams',
  TARGETS: 'fetch-targets',
  TARGETS_GROUP_COUNTS: 'fetch-target-group-counts',
  TARGETS_TAB_COUNTS: 'fetch-targets-tab-counts',
  TARGET_RECIPIENTS: 'fetch-target-recipients',
  TARGET_SHEET: 'fetch-target-sheet',
  TASK: 'fetch-task',
  TASKS: 'fetch-tasks',
  TASKS_COUNT: 'fetch-tasks-count',
  TASK_IDS: 'fetch-task-ids',
  TEAM: 'fetch-team',
  TEAM_GROUP: 'fetch-team-group',
  USER: 'fetch-user',
};

export default QUERY_KEYS;
