// Global import(s)
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const TeamContext = createContext({
  team: null,
  setTeam: () => {},
  teamGroup: null,
  setTeamGroup: () => {},
});

const TeamProvider = ({ children }) => {
  const [team, setTeam] = useState(null);
  const [teamGroup, setTeamGroup] = useState(null);

  const value = useMemo(() => ({
    team,
    setTeam,
    teamGroup,
    setTeamGroup,
  }), [team, setTeam, teamGroup, setTeamGroup]);

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};

const useTeam = () => useContext(TeamContext);

TeamProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { TeamContext, TeamProvider, useTeam };
