import React, { useEffect } from 'react';
import { useKeycloak } from '../../context/Keycloak';

// Config(s)
import { SCRIM_TYPE } from '../../utils/constants';

// Context
import { useScrim } from '../../context/ScrimContext';

// Component
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

// Util(s)
import clearLocalStorageWithExceptions from '../Utils/clearLocalStorageWithExceptions';
import lastKnownLocationCheck from '../Utils/lastKnownLocationCheck';

import './Signout.scss';

const Signout = () => {
  const keycloak = useKeycloak();
  const { openScrim, closeScrim } = useScrim();

  const onLogout = async () => {
    clearLocalStorageWithExceptions();
    sessionStorage.clear();

    await keycloak.logout({
      redirectUri: window.location.origin.toString(),
    });
  };

  useEffect(() => {
    onLogout();
  });

  useEffect(() => {
    openScrim(SCRIM_TYPE.FULL);

    lastKnownLocationCheck();

    return () => closeScrim();
  }, []);

  return (
    <div className="white-screen">
      <LoadingSpinner />
    </div>
  );
};

export default Signout;
