import { useLayoutEffect, useState, useRef } from 'react';

// Contexts
import { useView } from '../../../context/ViewContext';

// Util(s)
import { addEventListener, removeEventListener } from '../../../utils/Hooks/helper/events';

export const STICKY_CONTAINER_PROPS = {
  TOP_PADDING: 40,
  OFF_TOP_PADDING: 150,
};

const useStickyContainer = ({
  elRef,
  topPositionElementBecomesStickyFromTop = STICKY_CONTAINER_PROPS.TOP_PADDING,
}) => {
  const [top, setTop] = useState(0);
  const [position, setPosition] = useState('relative');
  const elementRef = useRef(null);
  const { disableStickyContainer } = useView();

  useLayoutEffect(() => {
    const element = elRef.current;
    let prevScrollTop = window.scrollY;

    if (!element) {
      return;
    }

    elementRef.current = element;

    const offset = Math.ceil(element.offsetTop);

    const handleScroll = () => {
      const elementBoundingRect = element.getBoundingClientRect();
      const elementBoundingRectTop = Math.ceil(elementBoundingRect.top);
      const elementBoundingRectBottom = Math.ceil(elementBoundingRect.bottom);
      const topDistanceOfElementRelativeToPageTop = Math.ceil(
        element.offsetTop,
      );
      const topDistanceAtWhichElementBecomesSticky = elementBoundingRectTop;

      const scrollYOffset = window.scrollY;
      const isScrollingUp = scrollYOffset < prevScrollTop;
      const isScrollingDown = scrollYOffset > prevScrollTop;
      const windowHeight = document.documentElement.clientHeight;

      const topEndPosition = elementBoundingRectTop - topPositionElementBecomesStickyFromTop;
      const bottomEndPosition = elementBoundingRectBottom;

      const isTopEndAboveViewport = topEndPosition < 0;
      const isTopEndBelowViewport = topEndPosition > windowHeight;
      const isBottomEndBelowViewport = bottomEndPosition > windowHeight;
      const isBottomEndAboveViewport = bottomEndPosition < 0;

      const isTopEndBetweenViewport = !isTopEndAboveViewport && !isTopEndBelowViewport;
      const isBottomEndBetweenViewport = !isBottomEndAboveViewport && !isBottomEndBelowViewport;

      const areBothTopAndBottomEndsOnOppositeEnds = isTopEndAboveViewport && isBottomEndBelowViewport;
      const areBothTopAndBottomBetweenViewport = isTopEndBetweenViewport && isBottomEndBetweenViewport;

      if (areBothTopAndBottomBetweenViewport || isBottomEndAboveViewport) {
        setPosition('sticky');
        setTop(topPositionElementBecomesStickyFromTop);
        prevScrollTop = scrollYOffset;
        return;
      }

      if (isTopEndBelowViewport || isBottomEndAboveViewport) {
        setPosition('relative');
        setTop((prevTop) => {
          const newTop = scrollYOffset > STICKY_CONTAINER_PROPS.TOP_PADDING
            ? scrollYOffset - STICKY_CONTAINER_PROPS.OFF_TOP_PADDING
            : scrollYOffset;
          return isScrollingUp ? Math.min(prevTop, newTop) : newTop;
        });
        prevScrollTop = scrollYOffset;
        return;
      }

      if (areBothTopAndBottomEndsOnOppositeEnds) {
        setPosition('relative');
        setTop(topDistanceOfElementRelativeToPageTop - offset);
        prevScrollTop = scrollYOffset;
        return;
      }

      if (isScrollingUp || isScrollingDown) {
        if (isTopEndBetweenViewport) {
          setPosition(isScrollingUp ? 'sticky' : 'relative');
          setTop(isScrollingUp ? topPositionElementBecomesStickyFromTop : topDistanceOfElementRelativeToPageTop - offset);
        } else if (isBottomEndBetweenViewport) {
          setPosition(isScrollingUp ? 'relative' : 'sticky');
          setTop(isScrollingUp ? topDistanceOfElementRelativeToPageTop - offset : topDistanceAtWhichElementBecomesSticky);
        }
      }
      prevScrollTop = scrollYOffset;
    };

    addEventListener([
      { type: 'scroll', handler: handleScroll, options: { passive: true } },
    ]);
    handleScroll();

    return () => {
      removeEventListener([{ type: 'scroll', handler: handleScroll }]);
    };
  }, [elRef, topPositionElementBecomesStickyFromTop]);

  if (disableStickyContainer) {
    return {
      elRef,
      topPositionElementBecomesStickyFromTop,
    };
  }

  return { top, position };
};

export default useStickyContainer;
