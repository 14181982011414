import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../context/Keycloak';

// Config
import config from '../../utils/config';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../components/RenderForm/RenderForm';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

// Utils
import { Renderers } from '../../utils/Form/ReactForm';

// Form
import movementRecord from '../../forms/movementRecord';

const MovementRecord = ({ isSubmitted, setSubmitted, onCancel, onFinish, children, attrs }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isLoading, setIsLoading] = useState(false);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.DOWNLOAD_MOVEMENT_RECORD);

  const onDownload = async (data) => {
    setIsLoading(true);
    await AxiosRequests.movementRecordByVersion(apiClient, data.taskId, data.version)
      .then((record) => {
        setSubmitted(true);
        setIsLoading(false);
        fileDownload(JSON.stringify(record, null, 2), `${data.taskId}-version-${data.version}-movement-record.json`);
      })
      .catch(() => setIsLoading(false));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      {...attrs}
      preFillData={attrs?.data}
      form={movementRecord}
      viewOnly={false}
      onSubmit={async ({ data }) => onDownload(data)}
      renderer={Renderers.REACT}
      onFinish={onFinish}
      onCancel={onCancel}
    />
  );
};

export default MovementRecord;
