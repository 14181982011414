// Global import(s)
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useQueryClient } from '@tanstack/react-query';
import { Heading, Tag } from '@ukhomeoffice/cop-react-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Config(s)
import QUERY_KEYS from '../../../utils/Hooks/constants';
import { LOCAL_STORAGE_KEYS, TASK_STATUS } from '../../../utils/constants';

// Context(s)
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useTask } from '../../../context/TaskContext';
import { useView } from '../../../context/ViewContext';

// Component(s)
import ActivityLog from '../../../components/ActivityLog/ActivityLog';
import AddJustification from '../TaskDetails/components/airpax/justification/AddJustification';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Tabs from '../../../components/Tabs/Tabs';

// Hook(s)
import useFetchBorderEvent from '../../../utils/Hooks/useFetchBorderEvent';
import useFetchJustifications from '../../../utils/Hooks/useFetchJustifications';
import useFetchPreviousTask from '../../../utils/Hooks/useFetchPreviousTask';
import useFetchTargetSheet from '../../../utils/Hooks/useFetchTargetSheet';

// Util(s)
import toBorderEventTab from '../TaskDetails/components/shared/helper/toBorderEventTab';
import toTargetSheetTab from '../TaskDetails/components/shared/helper/toTargetSheetTab';
import toTaskVersionsTab from '../TaskDetails/components/shared/helper/toTaskVersionsTab';
import { CommonUtil, JustificationUtil, MovementUtil, StorageUtil, StringUtil } from '../../../utils';

const InternalPreviousTaskDetailsPage = () => {
  const queryClient = useQueryClient();
  const { taskId } = useParams();
  const { trackPageView } = useMatomo();
  const [justificationId, setJustificationId] = useState(null);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { task, target } = useTask();
  const { view, isSettingView } = useView();

  // Hook(s)
  const { isLoading: isLoadingTask } = useFetchPreviousTask(taskId, justificationId);
  const { justifications, isLoading: isLoadingJustifications } = useFetchJustifications(task?.maskingEnabled);
  const { targetSheet } = useFetchTargetSheet(
    taskId,
    [
      [TASK_STATUS.ISSUED, TASK_STATUS.COMPLETE].includes(task?.status) && CommonUtil.requiresTargetSheet(task?.targeterAction),
      task?.status === TASK_STATUS.RECEIVED,
      task?.status === TASK_STATUS.COMPLETE && task?.withdrawn,
    ].some((condition) => !!condition),
    target,
  );
  const { borderEvent } = useFetchBorderEvent(taskId, CommonUtil.eabRaised(task));

  // Setup the `beforeunload` event listener for when tab is closed
  window.onbeforeunload = (e) => {
    if (e.isTrusted) {
      e.preventDefault();
      CommonUtil.removePreviousFlag();
      CommonUtil.removeMovementFlag();
    }
  };

  const goToJustificationUI = () => {
    window.location.replace(JustificationUtil.createJustificationURL(window.location.href));
  };

  useEffect(() => {
    const invalidateQueries = async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASK, justificationId] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.JUSTIFICATIONS] }),
      ]);
    };

    if (!justificationId) {
      return;
    }

    if (justificationId === 'CREATE_JUSTIFICATION') {
      goToJustificationUI();
      return;
    }

    invalidateQueries();
  }, [justificationId]);

  useEffect(() => {
    trackPageView();
    StorageUtil.add(LOCAL_STORAGE_KEYS.PREVIOUS_TASK_ENABLED, true);
  }, []);

  if (isLoadingTask || isLoadingJustifications || isSettingView) {
    return <LoadingSpinner />;
  }

  if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
    return <p>You are not authorised to view this task.</p>;
  }

  if (!task) {
    return null;
  }

  return (
    <>
      <div className="govuk-grid-row govuk-task-detail-header govuk-!-padding-bottom-9">
        <div className="govuk-grid-column-one-half">
          <Heading id="previous-tasks-page-header" caption={taskId} size="xl">Previous task details</Heading>
          <div>
            <Tag className="tag tag--task-status" text={StringUtil.format.word(task?.status)} />
            {MovementUtil.outcomeStatusTag(MovementUtil.outcome(task))}
          </div>
        </div>
      </div>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <AddJustification
            isMaskingEnabled={!task?.hidingEnabled && task?.maskingEnabled}
            justifications={justifications}
            getTaskWithJustification={setJustificationId}
            toCreateJustification={goToJustificationUI}
          />
          <Tabs
            id="task-versions-data"
            items={[
              toTaskVersionsTab(task.versions, taskId),
              toTargetSheetTab(targetSheet),
              toBorderEventTab(CommonUtil.eabRaised(task), borderEvent),
            ].filter((tab) => !!tab)}
          />
        </div>
        <div className="govuk-grid-column-one-third">
          <ActivityLog
            activityLog={task?.notes}
          />
        </div>
      </div>
    </>
  );
};

export default InternalPreviousTaskDetailsPage;
