import React from 'react';
import CommonButton from './CommonButton';

/**
 * Dismiss button component.
 *
 * @param label An alternate label for the button.
 * @param onClick A function to invoke.
 * @param props Component props.
 * @returns {JSX.Element} A JSX element.
 */
const DismissButton = ({ label, onClick, ...props }) => {
  return (
    <CommonButton
      commonProps={{
        id: 'dismiss-button',
        className: 'govuk-button govuk-!-font-weight-bold',
        onClick,
        children: label || 'Dismiss',
        ...props,
      }}
    />
  );
};

export default DismissButton;
