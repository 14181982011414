import React, { useState, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// Config
import config from '../../../../../utils/config';
import { TASK_STATUS } from '../../../../../utils/constants';
import QUERY_KEYS from '../../../../../utils/Hooks/constants';

// Context
import { PermissionContext } from '../../../../../context/PermissionContext';
import { NotificationContext } from '../../../../../context/NotificationContext';
import { TasksContext } from '../../../../../context/TasksContext';

import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../../../../context/Keycloak';

import AxiosRequests from '../../../../../api/axiosRequests';

// Components
import CommonButton from '../../../../../components/Buttons/CommonButton';

const DismissUnDismissTask = ({ taskId, taskStatus }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const queryClient = useQueryClient();
  const [inProgress, setInProgress] = useState(false);
  const { canUpdateTask } = useContext(PermissionContext);
  const { setNotification } = useContext(NotificationContext);
  const { removeTasksByIds, removeSelection } = useContext(TasksContext);

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_COUNT] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FILTER_COUNT] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RULES_MATCHED] }),
    ]);
  };

  const shouldShowDismissButton = () => {
    return taskStatus === TASK_STATUS.NEW && canUpdateTask;
  };

  const shouldShowUnDismissButton = () => {
    return taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL && canUpdateTask;
  };

  const handleUnStageDismissal = async () => {
    setInProgress(true);
    await AxiosRequests.unStageDismissal(apiClient, {
      taskIds: [taskId],
    })
      .then(() => {
        setInProgress(false);
        removeSelection(taskId);
        invalidateQueries();
        setNotification('Restored to task list');
        removeTasksByIds(taskId);
      }).catch(() => {
        setInProgress(false);
      });
  };

  const handleStageDismissal = async () => {
    setInProgress(true);
    await AxiosRequests.stageDismissal(apiClient, {
      taskIds: [taskId],
    })
      .then(() => {
        setInProgress(false);
        invalidateQueries();
        setNotification("Task moved to 'Dismiss' tab");
        removeTasksByIds(taskId);
      }).catch(() => {
        setInProgress(false);
      });
  };

  if (shouldShowDismissButton()) {
    return (
      <CommonButton
        inProgress={inProgress}
        commonProps={{
          id: `dismiss-task-${taskId}`,
          className: 'govuk-button govuk-!-font-weight-bold',
          onClick: handleStageDismissal,
          children: 'Remove',
          taskId,
          'aria-label': `move task ${taskId} to the to dismiss tab`,
        }}
      />
    );
  }

  if (shouldShowUnDismissButton()) {
    return (
      <>
        <CommonButton
          inProgress={inProgress}
          commonProps={{
            id: `return-to-new-${taskId}`,
            className: 'govuk-button govuk-!-font-weight-bold',
            onClick: handleUnStageDismissal,
            children: 'Restore',
            taskId,
            'aria-label': `return task ${taskId} back to the new tab`,
          }}
        />
      </>
    );
  }

  return null;
};

export default DismissUnDismissTask;
