import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Config
import { TARGET_OPERATION } from '../../../../../../../../../utils/constants';

// Context
import { useView } from '../../../../../../../../../context/ViewContext';

// Component(s)
import LoadingSpinner from '../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../../../../components/RenderForm/RenderForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import addPreviousSelection from '../../helper/addPreviousSelection';
import containsRecipient from '../../validate/containsRecipient';
import { Renderers } from '../../../../../../../../../utils/Form/ReactForm';

// Form(s)
import targetRecipientIdpChange, { TARGET_RECIPIENT_ID } from '../../../../../../../../../forms/uplift/targetRecipientIdpChange';

const CommoditiesChangeRecipient = ({ actionType, previouslySelectedRecipient, onSave, formData, setFormData, onCancel, targetRecipientOptions }) => {
  const [form, setForm] = useState(null);
  const { view } = useView();

  setTabTitle(TAB_TITLES.CHANGE_TARGET_SHEET_RECIPIENT);

  const onInternalSaveAndClose = (data) => {
    //
    // This is a stub for a recipient;
    // REMOVE the FALSE data later on when the BE
    // has caught up;
    //
    const createNewRecipient = () => {
      return {
        email: data?.email,
        telephone: '01234-567-8901',
        customName: 'Winston Churchill',
        recipientName: 'Winston Churchill',
        displayname: 'Winston Churchill',
      };
    };

    let newRecipient = data?.email ? createNewRecipient() : {};

    if (containsRecipient(formData, data, view)) {
      // Do not save the data, instead call the onSave callback.
      if (typeof onSave === 'function') {
        onSave();
        return;
      }
    }

    // const newSelection = data.targetRecipient;
    const newSelection = newRecipient;
    const previousSelectionIndex = formData.targetRecipients.findIndex((recipient) => recipient.email === previouslySelectedRecipient);
    const formDataCopy = { ...formData };
    formDataCopy.targetRecipients[previousSelectionIndex] = newSelection;
    setFormData((prev) => ({
      ...prev,
      ...formDataCopy,
    }));

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    setForm(addPreviousSelection(targetRecipientIdpChange, formData.targetRecipients, TARGET_RECIPIENT_ID, targetRecipientOptions, previouslySelectedRecipient));
  }, []);

  useEffect(() => {
    return () => setTabTitle(actionType === TARGET_OPERATION.CREATE ? TAB_TITLES.CREATE_TARGET : TAB_TITLES.EDIT_TARGET);
  }, []);

  if (!form) {
    return <LoadingSpinner />;
  }

  let prefillData = {};

  if (previouslySelectedRecipient !== null) {
    prefillData = {
      email: previouslySelectedRecipient,
      targetRecipient: ['email'],
    };
  }

  return (
    <div id="change-recipient" className={classNames('govuk-grid-row', previouslySelectedRecipient && 'option-divider')}>
      <div className="govuk-grid-column-two-thirds">
        <RenderForm
          form={form}
          preFillData={prefillData}
          viewOnly={false}
          onSubmit={async ({ data }) => onInternalSaveAndClose(data)}
          renderer={Renderers.REACT}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

CommoditiesChangeRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  previouslySelectedRecipient: PropTypes.string,
  formData: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

CommoditiesChangeRecipient.defaultProps = {
  previouslySelectedRecipient: null,
};

export default CommoditiesChangeRecipient;
