// Config(s)
import { DOCUMENT_TYPES, MOVEMENT_MODES, STRINGS, TIME_FORMATS } from '../../../../utils/constants';

// Util(s)
import DateTimeUtil from '../../../../utils/Datetime/Uplift/datetimeUtil';
import PersonUtil from '../../../../utils/Person/Uplift/personUtil';
import StringUtil from '../../../../utils/String/stringUtil';
import { isTruthy } from '../../../../utils/config';

const FORM_DATE_INPUT_FORMAT = 'DD-MM-YYYY HH:mm';

const toFormattedDate = (date, time, inputFormat) => {
  if (!date || !time) {
    return null;
  }

  const splitDate = date.split('-');
  const paddedDate = splitDate.map((part) => {
    if (part.length >= 2) {
      return part;
    }
    return StringUtil.padStart(part, 2, '0');
  }).join('-');

  return DateTimeUtil.format.asUTC(`${paddedDate} ${time}`, inputFormat, TIME_FORMATS.UTC);
};

const toJuncture = (juncture) => {
  return {
    country: null,
    location: null,
    unlo: null,
    time: toFormattedDate(juncture?.date, juncture?.time, FORM_DATE_INPUT_FORMAT),
    iata: juncture?.port === 'IATA' ? juncture?.iata : null,
    icao: juncture?.port === 'ICAO' ? juncture?.icao : null,
  };
};

const toDocument = (document) => {
  if (!document) {
    return null;
  }

  const toDocumentNumber = () => {
    return document?.passportDetails?.number || null;
  };

  const toDocumentExpiry = () => {
    const date = document?.passportDetails?.expiry || null;
    return toFormattedDate(date, '00:00', FORM_DATE_INPUT_FORMAT);
  };

  const toDocumentCountry = () => {
    return document?.passportDetails?.nationality?.iso31661alpha2 || null;
  };

  if (document?.type === DOCUMENT_TYPES.PASSPORT) {
    return {
      type: document?.type || null,
      number: toDocumentNumber(),
      validFrom: null,
      expiry: toDocumentExpiry(),
      countryOfIssue: toDocumentCountry(),
      otherTypeDetail: null,
    };
  }
  return {
    type: document?.type || null,
    otherTypeDetail: document?.otherTypeDetail || null,
  };
};

const toPerson = (person) => {
  return {
    name: {
      first: person?.name?.first || null,
      last: person?.name?.last || null,
      full: PersonUtil.formattedName(person?.name?.first)(person?.name?.last) || null,
    },
    role: person?.role || null,
    dateOfBirth: toFormattedDate(person?.dateOfBirth, '00:00', FORM_DATE_INPUT_FORMAT),
    gender: person?.gender?.value || null,
    nationality: person?.nationality?.iso31661alpha2 || null,
    document: toDocument(person?.document),
    ofInterest: person?.ofInterest?.some(isTruthy) || false,
  };
};

const toVehicle = (vehicle) => {
  if (!vehicle) {
    return null;
  }

  return {
    type: vehicle?.type || null,
    registration: vehicle?.registration || null,
  };
};

const toSubmissionPayload = (formData) => {
  return {
    status: 'PRE_ARRIVAL',
    mode: MOVEMENT_MODES.GENERAL_AVIATION,
    journey: {
      direction: formData?.direction || null,
      arrival: toJuncture(formData?.journey?.arrival),
      departure: toJuncture(formData?.journey?.departure),
    },
    person: null,
    otherPersons: formData?.persons?.map(toPerson) || [],
    flight: {
      departureStatus: STRINGS.UNKNOWN.toUpperCase(),
      number: null,
    },
    vehicle: toVehicle(formData?.aircraft),
    references: {
      sgarId: formData?.references?.sgarId || null,
      caseFileReference: formData?.references?.caseFileReference || null,
    },
    occupants: {
      numberOfCrew: Number(formData?.occupants?.crewCount) || 0,
      numberOfPassengers: Number(formData?.occupants?.passengerCount) || 0,
    },
  };
};

export default toSubmissionPayload;
