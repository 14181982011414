import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';

import config from '../../utils/config';

import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

const AccessibilityStatement = () => {
  const { trackPageView } = useMatomo();

  useSetTabTitle(TAB_TITLES.ACCESSIBILITY_STATEMENT);

  useEffect(() => {
    trackPageView();
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(1, 1);
    };
  }, []);

  return (
    <div className="govuk-width-container">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <h1 className="govuk-heading-l">
            Accessibility statement for Central Operations Platform (COP) Cerberus
          </h1>
          <p className="govuk-body">
            We want everyone to be able to get and do what they need with this service,
            regardless of access needs, due to a disability or condition.
          </p>
          <p className="govuk-body">
            This accessibility statement contains information about the Central Operations Platform - Cerberus,
            available at{' '}
            <a href="https://www.cerberus.cop.homeoffice.gov.uk/" target="_blank" rel="noopener noreferrer">
              https://www.cerberus.cop.homeoffice.gov.uk/ (opens in new tab)
            </a>
            .
          </p>
          <p className="govuk-body">
            This website is run by Border Force. We want as many people as possible to be able to
            use this website. For example, that means you should be able to:
          </p>
          <ul className="govuk-list govuk-list--bullet">
            <li>Zoom in up to 300%, without the text spilling off the screen.</li>
            <li>Navigate most of the website using just a keyboard.</li>
            <li>Reach the main dashboard from every form on COP Cerberus.</li>
            <li>Read and navigate the order on COP Cerberus as it is logical and intuitive/clear.</li>
            <li>Tab through questions in the form whilst always having the focus visible.</li>
          </ul>
          <p className="govuk-body">
            We’ve also made the website text as simple as possible to understand.
          </p>
          <p className="govuk-body">
            <a href="https://mcmw.abilitynet.org.uk/" target="_blank" rel="noopener noreferrer">
              Abilitynet (opens in new tab)
            </a>{' '}
            has advice on making your device easier to use if you have a disability.
          </p>

          <h2 className="govuk-heading-m">How accessible this website is</h2>
          <p className="govuk-body">
            We aim to meet international accessibility guidelines. However, this may not always be
            possible, or we may have missed a problem.
          </p>
          <p className="govuk-body">
            Some people may find parts of this service difficult to use because:
          </p>

          <ul className="govuk-list govuk-list--bullet">
            <li>
              Drop down lists may require users to “type to search” for their options as not all
              options will be displayed initially.
            </li>
            <li>
              Adding additional instances of a response, may make the form tab to the top of
              the page for keyboard only users.
            </li>
            <li>
              The JAWS screen reader does not read options in drop down lists and users are unable
              to use their keyboard to navigate through drop down lists with JAWS running in the
              background.
            </li>
            <li>
              Questions on the form and some labels in names, such as the forms page, are unable
              to be identified by the NVDA screen reader. NVDA is able to read text fields, tabs,
              links and radio buttons.
            </li>
            <li>
              Headings and titles on COP Cerberus forms and pages do not get read out by the
              NVDA screen reader.
            </li>
            <li>Skip to content does not highlight the body of text that it skips to.</li>
            <li>The main headings on each page vary from H2 to H4 instead of H1.</li>
            <li>There are some images of text.</li>
          </ul>

          <p className="govuk-body">
            We know some parts of this website are not fully accessible. You can see a full list
            of any issues we currently know about in the Non-accessible content section of this
            statement.
          </p>

          <h2 className="govuk-heading-m">Feedback and contact information</h2>
          <p className="govuk-body">If you have difficulty using this service, contact us by:</p>
          <ul className="govuk-list govuk-list--bullet">
            <li>
              Selecting{' '}
              <a href={config.supportUrl} target="_blank" rel="noopener noreferrer">
                Help (opens in new tab)
              </a>
              .
            </li>
            <li>Using the chat service on ITNow.</li>
            <li>Reporting an issue on ITNow.</li>
            <li>Calling the service desk on 0845 000 0050.</li>
          </ul>

          <h2 className="govuk-heading-m">Reporting accessibility problems with this website</h2>
          <p className="govuk-body">
            As part of providing this service, we may need to send you messages or documents. Tell
            us how you want us to send messages or documents to you. Tell us if you need them in a
            different format, for example large print, audio recording or braille.
          </p>
          <p className="govuk-body">
            We’re always looking to improve the accessibility of our websites and services. If you find
            any problems or think we’re not meeting accessibility requirements, use the contact details
            above (in feedback and contact information section) to tell us.
          </p>

          <h2 className="govuk-heading-m">Enforcement procedure</h2>
          <p className="govuk-body">
            The Equality and Human Rights Commission (EHRC) is responsible for enforcing the
            Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
            Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we
            respond to your complaint,{' '}
            <a
              href="https://www.equalityadvisoryservice.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact the Equality Advisory and Support Service (EASS) (opens in new tab)
            </a>
            .
          </p>
          <p className="govuk-body">
            If you are in Northern Ireland and are not happy with how we respond to your complaint
            you can contact the{' '}
            <a href="https://www.equalityni.org/home" target="_blank" rel="noopener noreferrer">
              Equality Commission for Northern Ireland (opens in new tab)
            </a>{' '}
            who are responsible for enforcing the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’)
            in Northern Ireland.
          </p>

          <h2 className="govuk-heading-m">
            Technical information about this website’s accessibility
          </h2>
          <p className="govuk-body">
            The Home Office is committed to making its website accessible, in accordance with the
            Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
            Regulations 2018.
          </p>

          <h2 className="govuk-heading-m">Compliance status</h2>
          <p className="govuk-body">
            This website is not compliant with the{' '}
            <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noopener noreferrer">
              Web Content Accessibility Guidelines version 2.1 AA standard (opens in new tab)
            </a>{' '}
            AA standard. The non-compliances are listed below.
          </p>

          <h2 className="govuk-heading-m">Non-accessible content</h2>
          <p className="govuk-body">
            The content listed below is non-accessible for the following reasons.
          </p>

          <h3 className="govuk-heading-s">Non-compliance with the accessibility regulations</h3>
          <ul className="govuk-list govuk-list--bullet">
            <li>
              Info and relationships (1.3.1) The first heading on issuing targets is H3 and then
              goes to legend throughout. It should start with H1 and move through H2 and so forth.
            </li>
            <li>
              Contrast (minimum) (1.4.3) – Fails on Issuing Targets on the control strategy selection
              and targeting indicators – FFFFFF & 00BCD4.
            </li>
            <li>
              Reflow (1.4.10) – Horizonal scrolling is not avoided when the page is zoomed to 400%
              and some of the text visualised is out of the correct formatting.
            </li>
            <li>
              Reflow (1.4.10) – Horizontal scroll has not been avoided, the main issue is the name
              of the task.
            </li>
            <li>
              Text Spacing (1.4.12) – The issue target, assessment complete and dismiss buttons obstruct
              the name of the task.
            </li>
            <li>
              Bypass block (2.4.1) – the skip to content doesn’t highlight the body of text. Frames must
              have title attribute for screen readers.
            </li>
            <li>
              Headings and labels (2.4.6)  - The labels and headings range from different sizes such as H3
              at the top of the page, H2 for rules matches and H4 after H2
            </li>
          </ul>

          <h3 className="govuk-heading-s">What we’re doing to improve accessibility</h3>
          <p className="govuk-body">
            Our plan describes how and when we plan to improve the accessibility of this service.
            We will:
          </p>
          <ul className="govuk-list govuk-list--bullet">
            <li>train our staff to create accessible services</li>
            <li>
              implement automated and manual accessibility testing in our development process
            </li>
            <li>conduct user research and testing with users who have access needs</li>
            <li>
              put in place alternative arrangements for those who need them and be willing to make
              additional adjustments if these are not enough
            </li>
          </ul>

          <p className="govuk-body">
            We are rebuilding the underlying technology in order to make COP more accessible.
          </p>

          <p className="govuk-body">
            From December 2022, parts of COP Cerberus start to move onto this new accessible technology.
            Over time, we plan for all the parts of COP Cerberus to be compliant.
          </p>

          <h3 className="govuk-heading-s">
            Content that’s not within the scope of the accessibility regulations
          </h3>
          <p className="govuk-body">
            At this time, we have not identified any content that is not within scope of the
            accessibility regulations.
          </p>

          <h2 className="govuk-heading-m">Preparation of this accessibility statement</h2>
          <p className="govuk-body">
            This statement was prepared on 01 December 2022. It was last reviewed on 06 January
            2023.
          </p>
          <p className="govuk-body">
            This website was last tested on 06 January 2023. The test was carried out by the Home
            Office’s Quality Assurance and Testing team.
          </p>
          <p className="govuk-body">
            We tested the service based on a user’s ability to complete key journeys. All parts of
            the chosen journeys were tested.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityStatement;
