const getCaseFileReference = (references) => {
  return references?.caseFileReference || null;
};

const getSgarId = (references) => {
  return references?.sgarId || null;
};

const getReference = (task) => {
  return task?.movement?.references || null;
};

const ReferencesUtil = {
  get: getReference,
  caseFileReference: getCaseFileReference,
  sgarId: getSgarId,
};

export default ReferencesUtil;

export { getCaseFileReference, getReference, getSgarId };
