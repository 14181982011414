/**
 * Orders components to be rendered using the specified ids.
 *
 * @param {Array} components A collection of components.
 * @param {Array} componentOrder A string collection specifying the order of components to be rendered by their ids.
 * @returns An array of components in the specified order
 */
const orderComponents = (components, componentOrder) => {
  if (!components || !componentOrder || !Array.isArray(components) || !Array.isArray(componentOrder)) {
    return [];
  }

  return componentOrder
    .map((order) => components.find((component) => component.id === order))
    .filter((component) => !!component);
};

export default orderComponents;
