// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Util(s)
import RisksUtil from '../../../../../../../utils/Risks/Uplift/risksUtil';

const MultiMatchIndicator = ({ task }) => {
  const matchCount = RisksUtil.matchedRulesCount(task);

  if (matchCount < 2) {
    return null;
  }

  return <Tag id="multi-match-indicator" className="tag tag--multi-match" text="MM" />;
};

MultiMatchIndicator.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default MultiMatchIndicator;
