// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { EUROPE_LONDON, DATE_FORMATS } from '../../../../../../utils/constants';

// Util(s)
import PersonUtil from '../../../../../../utils/Person/Uplift/personUtil';
import { TargetMovementUtil, TargetInformationUtil } from '../../../../../../utils';
import DateTimeUtil from '../../../../../../utils/Datetime/Uplift/datetimeUtil';

// Styling
import './TargetingDetails.scss';

const TargetingDetails = ({ informationSheet }) => {
  const panelData = [
    {
      id: 'targeting-hub',
      label: 'Targeting hub',
      value: TargetInformationUtil.informationSheet.targetDetails.issuingHub(informationSheet),
    },
    {
      id: 'targeter-name',
      label: 'Targeter',
      value: (() => {
        const user = TargetInformationUtil.informationSheet.targetDetails.submittingUser(informationSheet);
        return PersonUtil.formattedName(user?.firstName)(user?.lastName);
      })(),
    },
    {
      id: 'targeting-hub-telephone',
      label: 'Phone',
      value: TargetInformationUtil.informationSheet.targetDetails.issuingHubTel(informationSheet),
    },
    {
      id: 'target-issue-date',
      label: 'Target issued',
      value: DateTimeUtil.local.format(TargetInformationUtil.informationSheet.targetDetails.issueDate(informationSheet), EUROPE_LONDON, DATE_FORMATS.LONG_DAY_DATE_FORMAT),
    },
    {
      id: 'target-reference',
      label: 'Reference',
      value: TargetMovementUtil.targetId(informationSheet),
    },
  ];

  return (
    <div id="targeting-details">
      {panelData.map((item) => {
        return (
          <div key={item.label}>
            <Heading size="s">{item.label}</Heading>
            <p className="govuk-body force-wrap-text">{item.value}</p>
          </div>
        );
      })}
    </div>
  );
};

TargetingDetails.propTypes = {
  informationSheet: PropTypes.shape({}),
};

TargetingDetails.defaultProps = {
  informationSheet: null,
};

export default memo(TargetingDetails);
