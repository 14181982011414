import React from 'react';
import { Panel } from '@ukhomeoffice/cop-react-components';
import LinkButton from '../../../../../components/Buttons/LinkButton';

// Styling
import './OutcomeMessage.scss';

const OutcomeMessage = ({ message, caption, description, onFinish, ...props }) => {
  return (
    <div id="outcome-message" {...props}>
      <Panel title={message}>
        {caption}
      </Panel>
      {description}
      <LinkButton
        id="back-to-task-list"
        className="govuk-link"
        onClick={onFinish}
      >
        Back to task list
      </LinkButton>
    </div>
  );
};

export default OutcomeMessage;
