// Util(s)
import { shouldHideDate, shouldHideString } from '../../Hiding/hidingUtil';
import { getMaskedValue, shouldMaskDate, shouldMaskString } from '../../Masking/maskingUtil';

const getIcao = (journey) => {
  return journey?.icao || null;
};

const getIata = (journey) => {
  return journey?.iata || null;
};

const getAdditionalContent = (journey) => {
  if (!journey?.additionalContent || !journey?.additionalContent?.length) {
    return [];
  }

  return journey.additionalContent;
};

const getItinerary = (journey) => {
  if (!journey?.itinerary) {
    return [];
  }

  return journey.itinerary;
};

const getDepartureTime = (journey) => {
  if (!journey?.departure?.time) {
    return null;
  }

  if (shouldHideDate(journey.departure.time)) {
    return null;
  }

  if (shouldMaskDate(journey.departure.time)) {
    return getMaskedValue();
  }

  return journey.departure.time;
};

const getArrivalTime = (journey) => {
  if (!journey?.arrival?.time) {
    return null;
  }

  if (shouldHideDate(journey.arrival.time)) {
    return null;
  }

  if (shouldMaskDate(journey.arrival.time)) {
    return getMaskedValue();
  }

  return journey.arrival.time;
};

const getDepartureLocation = (journey) => {
  if (!journey?.departure?.location) {
    return null;
  }

  if (shouldHideString(journey.departure.location)) {
    return null;
  }

  if (shouldMaskString(journey.departure.location)) {
    return getMaskedValue();
  }

  return journey.departure.location;
};

const getArrivalLocation = (journey) => {
  if (!journey?.arrival?.location) {
    return null;
  }

  if (shouldHideString(journey.arrival.location)) {
    return null;
  }

  if (shouldMaskString(journey.arrival.location)) {
    return getMaskedValue();
  }

  return journey.arrival.location;
};

const getMovementDirection = (journey) => {
  if (!journey?.direction) {
    return null;
  }

  if (shouldHideString(journey.direction)) {
    return null;
  }

  if (shouldMaskString(journey.direction)) {
    return getMaskedValue();
  }

  return journey.direction;
};

const hasJourney = (task) => {
  return !!task?.movement?.journey;
};

const getDeparture = (journey) => {
  return journey?.departure || null;
};

const getArrival = (journey) => {
  return journey?.arrival || null;
};

const getJourney = (task) => {
  if (!hasJourney(task)) {
    return null;
  }

  return task.movement.journey;
};

const JourneyUtil = {
  additionalContent: getAdditionalContent,
  arrival: getArrival,
  arrivalLoc: getArrivalLocation,
  arrivalTime: getArrivalTime,
  departure: getDeparture,
  departureLoc: getDepartureLocation,
  departureTime: getDepartureTime,
  direction: getMovementDirection,
  get: getJourney,
  iata: getIata,
  icao: getIcao,
  itinerary: getItinerary,
};

export default JourneyUtil;

export {
  getAdditionalContent,
  getArrivalLocation,
  getArrivalTime,
  getDepartureLocation,
  getDepartureTime,
  getIata,
  getIcao,
  getJourney,
  getMovementDirection,
};
