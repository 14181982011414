// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../../generics/Details';
import EntitySearchModal from '../../../prompts/EntitySearchModal';
import LabelValuePair from '../../../generics/LabelValuePair';
import SubHeaderModule from '../../../generics/SubHeaderModule';
import ValueField from '../../../generics/ValueField';

// Util(s)
import CommonUtil from '../../../../../../../../../utils/Common/Uplift/commonUtil';
import DocumentUtil from '../../../../../../../../../utils/Document/Uplift/documentUtil';
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import { isDocumentNumberMasked, toValidTo, toValidToDifference } from '../helper/common';

const Document = ({ document, journey, positionedAt }) => {
  const docNumber = DocumentUtil.number(document);
  const docType = DocumentUtil.type(document);
  const formattedDocType = DocumentUtil.format.type(docType);
  const iso2CountryOfIssueCode = DocumentUtil.countryOfIssue(document);
  const formattedCountryOfIssue = DocumentUtil.format.countryOfIssue(iso2CountryOfIssueCode);
  const iso3CountryOfIssueCode = CommonUtil.iso3Code(iso2CountryOfIssueCode);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const dateOfExpiry = DocumentUtil.expiry(document);
  const sipURL = CommonUtil.sipEntitySearchURL(document);
  const centaurURL = CommonUtil.centaurEntitySearchURL(document);

  if (!document) {
    return null;
  }

  return (
    <div id={`document-${positionedAt}-module`}>
      <SubHeaderModule
        id={`document-${positionedAt}-details`}
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id={`document-${positionedAt}-header`} size="s">Document ID</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={docNumber}>
                <div className="grid-item">
                  <ComponentWrapper show={!sipURL && !centaurURL}>
                    <ValueField id={`document-${positionedAt}-number`} value={docNumber} />
                  </ComponentWrapper>
                  <ComponentWrapper show={sipURL || centaurURL}>
                    {!isDocumentNumberMasked(docNumber) ? (
                      <EntitySearchModal
                        id={`document-${positionedAt}-number`}
                        modalHeading={`Search document ID ${docNumber} on:`}
                        label={docNumber}
                        sipURL={sipURL}
                        centaurURL={centaurURL}
                      />
                    ) : <ValueField id={`document-${positionedAt}-number`} value={docNumber} />}
                  </ComponentWrapper>
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={docType && formattedDocType}>
                <div className="grid-item">
                  <ValueField id={`document-${positionedAt}-type`} value={formattedDocType} />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={iso2CountryOfIssueCode && formattedCountryOfIssue}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`document-${positionedAt}-country-of-issue`}
                    label="Issued by"
                    value={`${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={dateOfExpiry}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`document-${positionedAt}-validity`}
                    label="Valid to"
                    value={toValidTo(dateOfExpiry)}
                    secondaryValue={toValidToDifference(dateOfExpiry, departureLocation, departureTime)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
    </div>
  );
};

Document.propTypes = {
  document: PropTypes.shape({}),
  journey: PropTypes.shape({}).isRequired,
  positionedAt: PropTypes.number.isRequired,
};

Document.defaultProps = {
  document: null,
};

export default Document;
