import React, { useState } from 'react';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../context/Keycloak';

// Config
import config from '../../utils/config';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../components/RenderForm/RenderForm';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

// Utils
import { Renderers } from '../../utils/Form/ReactForm';

// Form
import deleteTask from '../../forms/deleteTask';

const DeleteTask = ({ isSubmitted, setSubmitted, onCancel, onFinish, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isLoading, setIsLoading] = useState(false);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.DELETE_TASK);

  const onDelete = async (data) => {
    setIsLoading(true);
    await AxiosRequests.deleteById(apiClient, data.taskId.toUpperCase())
      .then(() => {
        setSubmitted(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={deleteTask}
      viewOnly={false}
      onSubmit={async ({ data }) => onDelete(data)}
      renderer={Renderers.REACT}
      onFinish={onFinish}
      onCancel={onCancel}
    />
  );
};

export default DeleteTask;
