// Global Import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const WithdrawAlertButton = ({ onClick }) => {
  return (
    <Button
      id="withdraw-alert-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="withdraw the issued alert"
    >
      Withdraw alert
    </Button>
  );
};

WithdrawAlertButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default WithdrawAlertButton;
