import { FORM_ACTIONS } from '../../utils/constants';

export const TARGET_RECIPIENT_ID = 'targetRecipientIdp';

export default {
  id: 'targetRecipientIdpChange',
  version: '1.0.0',
  name: 'targetRecipientIdpChange',
  title: 'Target recipient',
  type: 'form',
  components: [],
  pages: [
    {
      id: 'targetRecipient',
      name: 'targetRecipient',
      label: 'Select a type of recipient to add',
      'aria-label': 'select a type of recipient to add',
      components: [
        {
          id: 'targetRecipient',
          fieldId: 'targetRecipient',
          type: 'checkboxes',
          data: {
            options: [
              {
                value: 'email',
                label: 'Email address',
                'aria-label': 'email address',
                nested: [
                  {
                    id: 'email',
                    fieldId: 'email',
                    label: 'Enter an email address ending @fcdo.gov.uk or @homeoffice.gov.uk',
                    'aria-label': 'enter an email address',
                    type: 'text',
                    required: true,
                    clearable: true,
                    pattern: '^[_a-zA-Z0-9.-]+@(?:fcdo|homeoffice).gov.uk',
                    custom_errors: [
                      {
                        type: 'required',
                        message: 'Add an email address to send the target to',
                      },
                      {
                        type: 'pattern',
                        message: 'Enter a valid email address ending @fdco.gov.uk or @homeoffice.gov.uk',
                      },
                    ],
                  },
                ],
                required: true,
              },
            ],
          },
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'A selection type must be provided to add a recipient',
            },
          ],
        },
      ],
      required: true,
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Save and close',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    },
  ],
};
