export default {
  id: 'matchRules',
  version: '1.0.0',
  name: 'match rules',
  type: 'form',
  components: [],
  pages: [
    {
      id: 'tools',
      name: 'tools',
      components: [
        {
          id: 'rules-title',
          fieldId: 'rules-title',
          type: 'heading',
          size: 'l',
          content: 'Delete matches',
        },
        {
          id: 'rules',
          fieldId: 'rules',
          label: 'Select a rule to delete matches for',
          'aria-label': 'Select a rule to delete matches for',
          type: 'autocomplete',
          openOnClick: true,
          required: true,
          placeholder: 'Select a rule...',
          useCustomOptions: true,
          hideOptionalSuffix: true,
          item: { value: 'ids', label: 'name' },
          sort: {
            direction: 'ASC',
            sortBy: 'name',
          },
        },
      ],
    },
  ],
};
