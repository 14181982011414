import { FORM_ACTIONS } from '../../utils/constants';

export default {
  id: 'completeAssessment',
  version: '1.0.0',
  name: 'completeAssessment',
  title: 'Assessment result',
  type: 'form',
  components: [],
  pages: [{
    id: 'completeAssessment',
    name: 'completeAssessment',
    components: [
      {
        type: 'heading',
        size: 'l',
        content: 'Assessment result',
      },
      {
        type: 'html',
        tagName: 'p',
        content: 'Select the result of your assessment for this task and add a note as necessary.',
      },
      {
        id: 'completeAssessment',
        fieldId: 'completeAssessment',
        label: '',
        type: 'radios',
        required: true,
        custom_errors: [
          {
            type: 'required',
            message: 'You must indicate at least one reason for completing your assessment',
          },
        ],
        data: {
          options: [
            {
              value: 'NO_TARGET_REQUIRED',
              label: 'No target required',
              nested: [
                {
                  id: 'noTargetRequiredNote',
                  fieldId: 'noTargetRequiredNote',
                  label: 'Add note',
                  'aria-label': 'add a note for reason for selection',
                  type: 'text',
                },
              ],
            },
            {
              value: 'FALSE_MATCH',
              label: 'False match',
              nested: [
                {
                  id: 'falseMatchNote',
                  fieldId: 'falseMatchNote',
                  label: 'Add note',
                  'aria-label': 'add a note for false match',
                  type: 'text',
                },
              ],
            },
            {
              value: 'OTHER',
              label: 'Other',
              nested: [
                {
                  id: 'otherNote',
                  fieldId: 'otherNote',
                  label: 'Please specify why no further action should be taken',
                  'aria-label': 'please specify why no further action should be taken',
                  type: 'text',
                  required: true,
                },
              ],
            },
          ],
        },
        description: 'Is there an error message to be displayed when a save action is performed without notes entered?',
      },
    ],
    actions: [
      {
        type: FORM_ACTIONS.SUBMIT,
        validate: true,
        label: 'Finish',
      },
      {
        type: FORM_ACTIONS.CANCEL,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  }],
};
