const getTotalOccupants = (occupants) => {
  return occupants?.numberOfOccupants ?? 0;
};

const getTotalCrew = (occupants) => {
  return occupants?.numberOfCrew ?? 0;
};

const getTotalPassengers = (occupants) => {
  return occupants?.numberOfPassengers ?? 0;
};

const getOccupants = (task) => {
  return task?.movement?.occupants || null;
};

const OccupantsUtil = {
  crewTotal: getTotalCrew,
  get: getOccupants,
  occupantsTotal: getTotalOccupants,
  passengersTotal: getTotalPassengers,
};

export default OccupantsUtil;

export { getOccupants, getTotalCrew, getTotalOccupants, getTotalPassengers };
