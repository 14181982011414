// Global import(s)
import { VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Blank element is required for screen reader software for empty table cells.
 * This is a workaround as JAWS Screen Reader should automatically recognize empty cells but doesn't.
 */
const BlankElement = () => <VisuallyHidden>blank</VisuallyHidden>;

export const CombinedRowCell = ({ value1, value2 }) => {
  if (!value1 && !value2) {
    return null;
  }

  return (
    <>
      {value1 && <div>{value1}</div>}
      {value2 && <div>{value2}</div>}
    </>
  );
};

const RowCell = ({ value }) => {
  return <>{value || <BlankElement />}</>;
};

RowCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

RowCell.defaultProps = {
  value: null,
};

CombinedRowCell.propTypes = {
  value1: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CombinedRowCell.defaultProps = {
  value1: null,
  value2: null,
};

export default RowCell;
