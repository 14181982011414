import React from 'react';
import { formatMovementMode } from '../helper/titleSection';

const TitleSection = ({ movementMode, createdAt }) => {
  return (
    <section className="previous-task-list-title-section">
      <div className="govuk-grid-row task-list-voyage-summary">
        <div className="govuk-grid-column-one-quarter govuk-!-padding-left-0">
          <div className="previous-task-movement-mode">
            {formatMovementMode(movementMode)}
          </div>
        </div>
        <div className="govuk-grid-column-one-quarter">
          <span className="previous-task-created-at govuk-!-margin-left-4">{createdAt}</span>
        </div>
      </div>
    </section>
  );
};

export default TitleSection;
