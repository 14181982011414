import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

// Config(s)
import { TASKS_TABS } from '../../../routes/Task/TaskList/constants';
import { VIEW } from '../../../utils/constants';

// Context(s)
import { useTabs } from '../../../context/TabContext';

// Util(s)
import { CommonUtil } from '../../../utils';
import setupFilterGroupCounts from '../helper/setupFilterGroupCounts';
import setupFilterTaskCounts from '../helper/setupFilterTaskCounts';
import setupComponentErrors from '../helper/setupComponentErrors';

const useFilters = (appliedFilters, filterCounts) => {
  const [filtersData, setFiltersData] = useState(appliedFilters);
  const { tabIndex } = useTabs();

  const updateTargetingTeamsCount = () => {
    if (!filterCounts?.length) {
      return appliedFilters;
    }

    const containsOnly = (collection, value) => {
      return Array.isArray(collection) && collection.length === 1 && collection[0] === value;
    };

    const findFilterAndSelectorCount = (targetingTeam) => {
      return filterCounts?.find((filterAndSelectorCount) => filterAndSelectorCount.filterParams.taskStatuses.includes(TASKS_TABS[tabIndex].taskStatus)
        && containsOnly(filterAndSelectorCount.filterParams.targetingTeams, targetingTeam.value));
    };

    return {
      ...appliedFilters,
      targetingTeams: appliedFilters?.targetingTeams?.map((targetingTeam) => {
        const foundFilterAndSelectorCount = findFilterAndSelectorCount(targetingTeam);
        const selectedTabId = CommonUtil.findTabByStatus(TASKS_TABS, TASKS_TABS[tabIndex].taskStatus).id;

        if (!foundFilterAndSelectorCount) {
          return targetingTeam;
        }

        return {
          ...targetingTeam,
          count: foundFilterAndSelectorCount.statusCounts[selectedTabId],
          label: `${targetingTeam.originalLabel} (${foundFilterAndSelectorCount.statusCounts[selectedTabId]})`,
        };
      }),
    };
  };

  useDeepCompareEffect(() => {
    setFiltersData(appliedFilters);
  }, [appliedFilters]);

  useDeepCompareEffect(() => {
    setFiltersData(updateTargetingTeamsCount());
  }, [filterCounts]);

  return { filtersData };
};

export const useSetupFilterCounts = (form, selectedTab, filterCounts, groupCounts, view) => {
  if ([VIEW.AIRPAX, VIEW.RORO, VIEW.AIRPAX_V2, VIEW.GENERAL_AVIATION, VIEW.COMMODITIES, VIEW.CTBP, VIEW.IDP, VIEW.OSDT].includes(view)) {
    return setupFilterTaskCounts(form, selectedTab, filterCounts);
  }

  if ([VIEW.AIRPAX_RCCU, VIEW.AIRPAX_HO, VIEW.AIRPAX_IO, VIEW.RORO_IO].includes(view)) {
    return setupFilterGroupCounts(form, groupCounts);
  }

  return form;
};

export const useSetupComponentErrors = (form, errorList) => {
  const [internalForm, setInternalForm] = useState(form);

  useEffect(() => {
    if (errorList?.length) {
      setInternalForm((prev) => {
        prev.pages.forEach((page) => page.components.forEach((component) => setupComponentErrors(component, errorList)));
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              components: page.components,
            };
          }),
        };
      });
    }
  }, [errorList]);

  useEffect(() => setInternalForm(form), [form]);
  return internalForm;
};

export default useFilters;
