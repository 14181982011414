import { FORM_ACTIONS } from '../utils/constants';

export default {
  id: 'movementRecord',
  version: '0.0.1',
  name: 'movementRecord',
  title: 'Download a movement record (Cerberus)',
  type: 'form',
  components: [],
  pages: [
    {
      id: 'movementRecord',
      name: 'movementRecord',
      components: [
        {
          id: 'taskId',
          fieldId: 'taskId',
          className: 'govuk-!-margin-bottom-3 govuk-!-width-two-thirds',
          label: 'Enter a task id',
          'aria-label': 'enter a task id',
          type: 'text',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'You must enter a task id',
            },
          ],
        },
        {
          id: 'version',
          fieldId: 'version',
          className: 'govuk-!-margin-bottom-3 govuk-!-width-one-quarter',
          label: 'Enter a version number',
          'aria-label': 'enter a version number',
          type: 'text',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'You must enter a version number',
            },
          ],
        },
      ],
      actions: [
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Download',
        },
      ],
    },
  ],
};
