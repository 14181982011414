// Global import(s)
import { Label, Select } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { SORT_ORDER_DROPDOWN_OPTIONS } from '../../constants';

const SortOrderSelector = ({ sortParamsOrder, onChange }) => {
  return (
    <div id="sort-order-selector">
      <Label id="sortOrder" aria-label="Sort order" />
      <Select
        id="sortOrder"
        fieldId="sortOrder"
        defaultValue={sortParamsOrder}
        className="govuk-!-margin-top-0 govuk-!-margin-bottom-4"
        options={SORT_ORDER_DROPDOWN_OPTIONS}
        onChange={onChange}
      />
    </div>
  );
};

SortOrderSelector.propTypes = {
  sortParamsOrder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SortOrderSelector;
