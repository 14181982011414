import { Checkboxes, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../../constants';

const AbuseTypes = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.ABUSE_TYPES}
      label={<Heading id="suspected-abuse-type-heading" size="m">Suspected abuse type</Heading>}
      error={componentErrors?.[COMPONENT_IDS.ABUSE_TYPES]?.message}
    >
      <Checkboxes
        id={COMPONENT_IDS.ABUSE_TYPES}
        fieldId={COMPONENT_IDS.ABUSE_TYPES}
        options={[
          {
            value: 'FORGED_OR_COUNTERFEIT_DOCUMENT_IMPERSONATION',
            label: 'Forged or counterfeit document / impersonation',
          },
          {
            value: 'FORGED_OR_COUNTERFEIT_VISA',
            label: 'Forged or counterfeit visa',
          },
          {
            value: 'SUSPECTED_UK_TRANSIT_ABUSE',
            label: 'Suspected UK transit abuse',
          },
          {
            value: 'SUSPECTED_FACILITATOR',
            label: 'Suspected facilitator',
          },
        ]}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.ABUSE_TYPES]}
      />
    </FormGroup>
  );
};

AbuseTypes.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

AbuseTypes.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(AbuseTypes);
