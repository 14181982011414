// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTasksCount = (requestParams, selectedTab) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const mergeResults = (result) => {
    if (!Array.isArray(result)) {
      return null;
    }

    return result.reduce((acc, current) => {
      if (current?.statusCounts && typeof current?.statusCounts === 'object') {
        return {
          ...acc,
          ...Object.fromEntries(
            Object.entries(current.statusCounts).map(([key, value]) => [key, (acc[key] || 0) + (value || 0)]),
          ),
        };
      }

      return acc;
    }, {});
  };

  const getTasksCount = async (params) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return null;
    }

    return AxiosRequests.taskCount(apiClient, params);
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.TASKS_COUNT, requestParams, selectedTab],
    queryFn: () => getTasksCount(requestParams),
    refetchInterval: config.taskList.refreshInterval,
    initialData: null,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return mergeResults(data);
};

export default useFetchTasksCount;
