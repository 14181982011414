// Config(s)
import { STRINGS } from '../constants';

export const asUnknownAllowingZeroIfRequired = (value) => {
  if (!value && value !== 0) {
    return STRINGS.UNKNOWN;
  }

  return value;
};

const asUnknownIfRequired = (value) => {
  if (!value) {
    return STRINGS.UNKNOWN;
  }

  return value;
};

export default asUnknownIfRequired;
