// Global import(s)
import { SmallHeading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { TIME_FORMATS, EUROPE_LONDON } from '../../../../../../utils/constants';

// Styling
import './AdditionalContent.scss';

// Utils
import DateTimeUtil from '../../../../../../utils/Datetime/Uplift/datetimeUtil';

const AdditionalContent = ({ additions, heading, ...props }) => {
  if (!additions?.length) {
    return null;
  }

  const toTimestamp = (entity, format = TIME_FORMATS.NOTES) => {
    if (!entity?.timestamp || !DateTimeUtil.validate.date(entity.timestamp)) {
      return null;
    }
    return DateTimeUtil.local.format(entity.timestamp, EUROPE_LONDON, format);
  };

  return (
    additions.map((addition) => {
      const timestamp = toTimestamp({ timestamp: addition?.timestamp }, TIME_FORMATS.CONTENT);
      const [time, ...dateParts] = timestamp ? timestamp.split(' ') : ['', ''];
      const date = dateParts.join(' ');

      return (
        <div {...props} key={addition.id} className="additional-content-container">
          <div className="grid-item">
            <SmallHeading size="m">{heading}</SmallHeading>
            <p id={`content-timestamp-${addition.id}`} className="timestamp">
              <span className="time">{time}</span>
              <span className="date">{date}</span>
            </p>
          </div>
          <div className="additional-content-row">
            <p id={`content-${addition.id}`}>{addition.content}</p>
          </div>
        </div>
      );
    })
  );
};

AdditionalContent.propTypes = {
  additions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
  heading: PropTypes.string.isRequired,
};

AdditionalContent.defaultProps = {
  additions: [],
};

export default memo(AdditionalContent);
