const uploadDocument = async (client, taskId, file) => {
  if (!client || !taskId || !file) {
    return Promise.resolve(null);
  }

  const formData = new FormData();
  formData.append('file', file);
  const { data } = await client.post(taskId, formData);
  return data?.url;
};

const DocumentUploader = {
  upload: uploadDocument,
};

export default DocumentUploader;

export {
  uploadDocument,
};
