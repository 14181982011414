import React from 'react';

import { DATE_FORMATS, EUROPE_LONDON } from '../../../utils/constants';

import { DateTimeUtil } from '../../../utils';

const toUserAndTimestamp = (activity) => {
  return (
    <p className="govuk-body-s govuk-!-padding-top-1 govuk-!-margin-bottom-2">
      <span className="govuk-!-font-weight-bold">
        {DateTimeUtil.timezoneFormatted(activity?.timestamp, EUROPE_LONDON, DATE_FORMATS.CUSTOM_LONG)}
      </span>
      {activity?.user?.email && (
        <>
          &nbsp;by &thinsp;
          <a
            href={`mailto:${activity?.user?.email}`}
            title="Clicking this link opens the default email client to let the user send a new email to this email address"
          >
            {activity?.user?.email}
          </a>
        </>
      )}
    </p>
  );
};

export default toUserAndTimestamp;
