import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './Highlight.scss';

const Highlight = ({ children }) => {
  return (
    <mark className="hods-highlight">
      <strong>{children}</strong>
    </mark>
  );
};

Highlight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default Highlight;
