import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const ChecksValidationContext = createContext({});

const ChecksValidationProvider = ({ children }) => {
  const [errors, setErrors] = useState(null);

  const addError = (containerId, error) => {
    setErrors((prev) => {
      if (!error) {
        return;
      }

      return {
        ...prev,
        [containerId]: error,
      };
    });
  };

  const clearErrors = () => {
    setErrors(null);
  };

  const value = useMemo(() => ({
    errors,
    addError,
    clearErrors,
  }), [errors]);

  return (
    <ChecksValidationContext.Provider value={value}>
      {children}
    </ChecksValidationContext.Provider>
  );
};

const useChecksValidation = () => useContext(ChecksValidationContext);

export { ChecksValidationContext, ChecksValidationProvider, useChecksValidation };

ChecksValidationProvider.propTypes = {
  children: PropTypes.node,
};

ChecksValidationProvider.defaultProps = {
  children: null,
};
