import { ErrorSummary } from '@ukhomeoffice/cop-react-components';
import React, { useContext } from 'react';

import { ErrorAlertContext } from '../../context/ErrorAlertContext';

const ErrorBoundary = ({ children }) => {
  const { errors } = useContext(ErrorAlertContext);

  return (
    <>
      {errors && errors.length ? (
        <ErrorSummary
          title="There is a problem"
          errors={errors}
        />
      ) : null}
      {children}
    </>
  );
};

export default ErrorBoundary;
