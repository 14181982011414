const toAdditionalContentAndCredibilityChecksPayload = (additionalContent, checks) => {
  return {
    additionalContent: {
      add: additionalContent ? [...additionalContent] : null,
    },
    credibilityChecks: checks,
  };
};

export default toAdditionalContentAndCredibilityChecksPayload;
