import React from 'react';
import ReactDOM from 'react-dom';

// Config(s)
import config from '../config';

const reportAccessibilityIfEnabled = async () => {
  if (config.reportAccessibility) {
    // eslint-disable-next-line import/no-extraneous-dependencies
    await import('@axe-core/react').then((axe) => {
      axe.default(React, ReactDOM, 1000);
    });
  }
};

export default reportAccessibilityIfEnabled;
