// Global imports
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Utils
import { TargetInformationUtil } from '../../../../../../../utils';

const AlertReason = ({ informationSheet }) => {
  const reason = TargetInformationUtil.selectionReason(informationSheet);

  if (!reason) {
    return null;
  }

  return (
    <div id="reason-for-alert" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Reason for alert</Heading>
        <p className="alert-reason-text">{reason}</p>
      </div>
    </div>
  );
};

AlertReason.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default AlertReason;
