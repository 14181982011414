import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const CancelButton = ({ onClick }) => {
  return (
    <Button
      id="cancel-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="cancel"
    >
      Cancel
    </Button>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;
