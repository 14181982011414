import { Button } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../context/Keycloak';

// Config
import config from '../../utils/config';

// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

const Migration = ({ isSubmitted, setSubmitted, onCancel, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isLoading, setIsLoading] = useState(false);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.MIGRATIONS);

  const onMigrateTasks = async () => {
    setIsLoading(true);
    await AxiosRequests.migrateTasks(apiClient)
      .then(() => {
        setSubmitted(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const onMigrateTargets = async () => {
    setIsLoading(true);
    await AxiosRequests.migrateTargets(apiClient)
      .then(() => {
        setSubmitted(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  return (
    <div className="admin-action-buttons">
      <Button id="cancel" classModifiers="secondary" onClick={onCancel}>Cancel</Button>
      <Button id="migrate-tasks" onClick={onMigrateTasks}>Migrate tasks</Button>
      <Button id="migrate-targets" onClick={onMigrateTargets}>Migrate targets</Button>
    </div>
  );
};

export default Migration;
