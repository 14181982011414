import { Pagination } from '@ukhomeoffice/cop-react-components';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Config(s)
import config from '../../../utils/config';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import QUERY_KEYS from '../../../utils/Hooks/constants';

// Context(s)
import { useKeycloak } from '../../../context/Keycloak';
import { useNotification } from '../../../context/NotificationContext';
import { useTabs } from '../../../context/TabContext';
import { useView } from '../../../context/ViewContext';

// Services
import AxiosRequests from '../../../api/axiosRequests';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import TargetListCard from './components/cards/TargetListCard';

// Hook(s)
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';
import useTrackTargetsList from '../../../utils/Hooks/useTrackTargetsList';

// Util(s)
import { StorageUtil, TargetMovementUtil } from '../../../utils';
import toTableHeaders from './helper/headers/toTableHeaders';

const TargetsTab = ({
  isLoading,
  targets,
  totalTargets = 0,
  itemsPerPage,
  activePage,
  setActivePage,
}) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const queryClient = useQueryClient();
  const source = axios.CancelToken.source();
  const navigate = useNavigate();
  const { setupTabNotificationIfRequired } = useNotification();
  const { DEFAULTS, selectedTab } = useTabs();
  const { view } = useView();
  const { tabKeyPosition: position, setTabKeyPosition } = useTrackTargetsList();
  const [checkedRadioBtnName, setCheckedRadioBtnName] = useState('');
  const [selectedTarget, setSelectedTarget] = useState({});

  /**
   * Store the tab before navigating away from page. On manual reload of the new page,
   * the UI does not lose the tab to return back to when a back action is triggered.
   */
  const storeTab = () => {
    StorageUtil.add(LOCAL_STORAGE_KEYS.CACHED_TAB, selectedTab);
  };

  const resetSelectedTargetOption = async (checkedRadioButton, targetSelected, refreshTargets = null) => {
    setCheckedRadioBtnName(checkedRadioButton);
    setSelectedTarget(targetSelected);

    if (refreshTargets || refreshTargets === false) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TARGETS] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TARGETS_TAB_COUNTS] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TARGETS_GROUP_COUNTS] }),
      ]);
    }
  };

  const handleRadioButton = (e, target) => {
    const { id, name } = e.target; // Radio button id & name
    setTabKeyPosition(id);
    resetSelectedTargetOption(name, target);
  };

  const handleRecordOutcome = async () => {
    const { informationSheet } = selectedTarget;
    storeTab();
    const path = `${DEFAULTS[view].redirectPath}/${TargetMovementUtil.targetId(informationSheet)}/record-outcome`;
    StorageUtil.add(LOCAL_STORAGE_KEYS.TARGET_INFO_PATH, path);
    navigate(path);
  };

  const handleReAssign = async () => {
    storeTab();
    const { informationSheet } = selectedTarget;
    const path = `${DEFAULTS[view].redirectPath}/${TargetMovementUtil.targetId(informationSheet)}/reassign`;
    navigate(path);
  };

  const handleAssign = async () => {
    storeTab();
    const { informationSheet } = selectedTarget;
    const path = `${DEFAULTS[view].redirectPath}/${TargetMovementUtil.targetId(informationSheet)}/assign`;
    navigate(path);
  };

  const handleOnRCCUReceipt = async () => {
    const { informationSheet } = selectedTarget;
    await AxiosRequests.receiptRccuTarget(apiClient, TargetMovementUtil.targetId(informationSheet))
      .then((data) => {
        setupTabNotificationIfRequired(DEFAULTS[view].tabs, selectedTab, data.status);
        resetSelectedTargetOption(null, null, true);
      });
  };

  const handleOnReceipt = async () => {
    const { informationSheet } = selectedTarget;
    await AxiosRequests.receiptTarget(apiClient, TargetMovementUtil.targetId(informationSheet))
      .then((data) => {
        setupTabNotificationIfRequired(DEFAULTS[view].tabs, selectedTab, data.status);
        resetSelectedTargetOption(null, null, true);
      });
  };

  const handleOnAccept = async () => {
    const { informationSheet } = selectedTarget;
    await AxiosRequests.acceptTarget(apiClient, TargetMovementUtil.targetId(informationSheet))
      .then((data) => {
        setupTabNotificationIfRequired(DEFAULTS[view].tabs, selectedTab, data.status);
        resetSelectedTargetOption(null, null, true);
      });
  };

  const handleOnCapture = async () => {
    const { informationSheet } = selectedTarget;
    await AxiosRequests.captureTarget(apiClient, TargetMovementUtil.targetId(informationSheet))
      .then(() => resetSelectedTargetOption(null, null, true));
  };

  const handleUndoCapture = async () => {
    const { informationSheet } = selectedTarget;
    await AxiosRequests.unCaptureTarget(apiClient, TargetMovementUtil.targetId(informationSheet))
      .then(() => resetSelectedTargetOption(null, null, true));
  };

  const handleViewDetails = async () => {
    storeTab();
    const { informationSheet } = selectedTarget;
    const path = `${DEFAULTS[view].redirectPath}/${TargetMovementUtil.targetId(informationSheet)}`;
    StorageUtil.add(LOCAL_STORAGE_KEYS.TARGET_INFO_PATH, path);
    navigate(path);
  };

  useLayoutEffect(() => {
    const element = document.getElementById(position);
    if (element) {
      element.focus();
    }
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  if (isLoading) {
    return <ApplicationSpinner />;
  }

  if (!targets?.length) {
    return <p className="govuk-body-l">No more targets available</p>;
  }

  return (
    <>
      {targets.map((groupedTargets, index) => (
        <TargetListCard
          key={`grouped-target-${groupedTargets.id}-${index}`}
          tableHeaders={toTableHeaders(DEFAULTS, view, selectedTab)}
          groupedTargets={groupedTargets}
          handleOnRCCUReceipt={handleOnRCCUReceipt}
          handleOnReceipt={handleOnReceipt}
          handleOnAccept={handleOnAccept}
          handleRadioButton={handleRadioButton}
          handleViewDetails={handleViewDetails}
          handleRecordOutcome={handleRecordOutcome}
          handleAssign={handleAssign}
          handleReAssign={handleReAssign}
          handleOnCapture={handleOnCapture}
          handleUndoCapture={handleUndoCapture}
          checkedRadioBtnName={checkedRadioBtnName}
          selectedTarget={selectedTarget}
        />
      ))}
      <Pagination
        totalItems={totalTargets}
        resultsPerPage={itemsPerPage}
        activePage={activePage}
        updatePageNumber={setActivePage}
      />
    </>
  );
};

TargetsTab.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  targets: PropTypes.instanceOf(Array),
  totalTargets: PropTypes.number,
  itemsPerPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

TargetsTab.defaultProps = {
  targets: PropTypes.instanceOf(Array),
  totalTargets: PropTypes.number,
};

export default TargetsTab;
