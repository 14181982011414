import PropTypes from 'prop-types';
import React from 'react';

// Util(s)
import { CommonUtil, RisksUtil } from '../../../../../utils';

import './MatchedRules.scss';

const MatchedRules = ({ risks }) => {
  if (!risks) {
    return null;
  }

  const matchedRules = RisksUtil.matchedRules(risks);
  if (!matchedRules || !matchedRules?.length) {
    return null;
  }

  return (
    <ul id="task-list-table" className="rules">
      {matchedRules?.map((rule) => {
        return CommonUtil.uniqueId(rule?.indicatorMatches)
          .map((match) => (
            <li className="matched-rule-row" key={`rule-${match?.id}-${match?.descriptor}`}>
              <span className="col">{rule?.threatType}</span>
              <span className="col">{rule?.name}</span>
              <span className="col">{match?.entity} {match?.descriptor} {match?.operator}</span>
              <span className="col">{match?.value}</span>
            </li>
          ));
      })}
    </ul>
  );
};

MatchedRules.propTypes = {
  risks: PropTypes.shape({}),
};

MatchedRules.defaultProps = {
  risks: null,
};

export default MatchedRules;
