import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Config(s)
import { SCRIM_TYPE } from '../../../../../../../utils/constants';

// Context(s)
import { useScrim } from '../../../../../../../context/ScrimContext';

// Component(s)
import Modal from '../prompts/Modal';

// Styling
import './AdditionalChecksModal.scss';

const AdditionalChecksModal = ({ onProceedAndDiscard, onCancel }) => {
  const { openScrim, closeScrim } = useScrim();

  useEffect(() => {
    openScrim(SCRIM_TYPE.FULL);

    return () => closeScrim();
  }, []);

  return (
    <Modal
      id="additional-checks-modal"
      className="additional-checks-modal"
      onCancel={onCancel}
      onProceed={onProceedAndDiscard}
      modalProps={{
        heading: 'Additional credibility check results for this passenger will be discarded',
        actions: {
          proceed: 'Proceed and discard',
          cancel: 'Cancel',
        },
      }}
    >
      <p>
        Input made to Additional credibility checks for this passenger will not be saved if you proceed.
      </p>
    </Modal>
  );
};

AdditionalChecksModal.propTypes = {
  onProceedAndDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AdditionalChecksModal;
