// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import FrontLineActionSection from './FrontLineActionSection';
import SeizuresSection from './SeizuresSection';
import TargetIndicatorsSection from './TargetIndicatorsSection';
import TargeterActionSection from './TargeterActionSection';

// Util(s)
import { getRisks, getTargeterAction, getFrontLineAction, getSeizures } from '../../../../utils/PreviousTask/previousTaskUtil';

const PreviousTaskDetailsSection = ({ task }) => {
  return (
    <section id="previous-task-list-details-section" className="previous-task-list-details-section">
      <div className="govuk-grid-row">
        <div className="govuk-grid-item">
          <TargetIndicatorsSection risks={getRisks(task)} />
        </div>
        <div className="govuk-grid-item">
          <TargeterActionSection action={getTargeterAction(task)} />
        </div>
        <div className="govuk-grid-item">
          <FrontLineActionSection action={getFrontLineAction(task)} />
        </div>
        <div className="govuk-grid-item">
          <SeizuresSection seizures={getSeizures(task)} />
        </div>
      </div>
    </section>
  );
};

PreviousTaskDetailsSection.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default PreviousTaskDetailsSection;
