const toSortFunction = (sortParams) => {
  const sortBy = sortParams.sortBy;
  const sortDirection = sortParams?.direction;

  const sortAsc = (a, b) => {
    return String(a[sortBy]).toUpperCase().localeCompare(String(b[sortBy]).toUpperCase());
  };

  const sortDesc = (a, b) => {
    return -1 * String(a[sortBy]).toUpperCase().localeCompare(String(b[sortBy]).toUpperCase());
  };

  return sortDirection === 'ASC' ? sortAsc : sortDesc;
};

/**
 * Within the customOptions node, the component id has to be linked with the options for the component.
 * e.g.
 * const customOptions = {
 *   component_id: [
 *   {value: 'alpha', label: 'Alpha'},
 *   {value: 'bravo', label: 'Bravo'}
 *   ]
 * };
 *
 */
const getCustomOptions = (component, customOptions) => {
  let _options = customOptions[component.id];
  if (typeof component?.sort === 'object') {
    _options = _options?.sort(toSortFunction(component.sort));
  }
  return _options;
};

const setUpCustomOptions = (components, customOptions) => {
  components.forEach((component) => {
    if (component && component?.useCustomOptions) {
      if (!component?.data) {
        component.data = {};
      }

      component.data.options = getCustomOptions(component, customOptions);
    }
  });
};

export default setUpCustomOptions;
