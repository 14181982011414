// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LinkButton from '../../../../../../../components/Buttons/LinkButton';

// Hook(s)
import useScreenSize from '../../../../../../../utils/Hooks/useScreenSize';

const AddTaskDetailLinkButton = ({ id, label, onClick, ...props }) => {
  return (
    <LinkButton
      id={id}
      className="govuk-link ho-link-body"
      onClick={onClick}
      {...props}
    >
      {label}
    </LinkButton>
  );
};

const AddTaskDetailButton = ({ onClick, label }) => {
  const id = 'add-task-detail-button';
  const ariaLabel = `${label} to task`;

  const { isNineteenTwentyOrMorePixels } = useScreenSize();

  if (!isNineteenTwentyOrMorePixels) {
    return <AddTaskDetailLinkButton id={id} label={label} onClick={onClick} aria-label={ariaLabel} />;
  }

  return (
    <Button
      id={id}
      classModifiers="secondary"
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {label}
    </Button>
  );
};

AddTaskDetailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.func.isRequired,
};

export default AddTaskDetailButton;
