// Config(s)
import { TASK_STATUS, TAB_ID } from '../../../../utils/constants';
import DEFAULTS, { TASKS_TABS } from '../constants';

// Util(s)
import CommonUtil from '../../../../utils/Common/commonUtil';
import StorageUtil from '../../../../utils/Storage/storageUtil';
import { formatInTwoDigits } from '../../../../components/Filter/Custom/Validate/utils';

export const DATE_OPTIONS = {
  IS: 'IS',
  IS_IN_DATE_RANGE: 'IS_IN_DATE_RANGE',
  IS_IN_DATE_AND_TIME_RANGE: 'IS_IN_DATE_AND_TIME_RANGE',
};

const ARRIVAL_DATE_DEPENDENCIES = {
  IS: ['arrivalIsStartDate'],
  IS_IN_DATE_RANGE: ['arrivalIsFromStartDate', 'arrivalIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['arrivalRangeIsStartDate', 'arrivalRangeIsEndDate'],
};

const DEPARTURE_DATE_DEPENDENCIES = {
  IS: ['departureIsStartDate'],
  IS_IN_DATE_RANGE: ['departureIsFromStartDate', 'departureIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['departureRangeIsStartDate', 'departureRangeIsEndDate'],
};

const CREATED_DATE_DEPENDENCIES = {
  IS: ['taskCreatedDateIsStartDate'],
  IS_IN_DATE_RANGE: ['taskCreatedDateIsFromStartDate', 'taskCreatedDateIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['taskCreatedDateRangeIsStartDate', 'taskCreatedDateRangeIsEndDate'],
};

const constructDate = (date, isEndDate) => {
  // For normal dates, without time;
  if (date.length === 3) {
    const suffix = !isEndDate ? '00:00:00Z' : '23:59:59Z';
    return `${date[2]}-${formatInTwoDigits(date[1])}-${formatInTwoDigits(date[0])}T${suffix}`;
  }

  // Default is date with time;
  const suffix = isEndDate ? ':59Z' : ':00Z';
  return `${date[2]}-${formatInTwoDigits(date[1])}-${formatInTwoDigits(date[0])}T${formatInTwoDigits(date[3])}:${formatInTwoDigits(date[4])}${suffix}`;
};

const toDate = (selection, payload, isEndDate = false) => {
  const splitDate = payload[selection]?.split('-');
  if (!splitDate || !splitDate.length) {
    return null;
  }
  const date = splitDate.filter((item) => !!item);
  if (date.length < 3) {
    return null;
  }
  return constructDate(date, isEndDate);
};

const toEndDate = (selectedOption, dateDependencies, payload) => {
  switch (selectedOption?.value) {
    case DATE_OPTIONS.IS: {
      const selection = dateDependencies.IS[0];
      return toDate(selection, payload, true);
    }
    case DATE_OPTIONS.IS_IN_DATE_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_RANGE[1];
      return toDate(selection, payload, true);
    }
    case DATE_OPTIONS.IS_IN_DATE_AND_TIME_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_AND_TIME_RANGE[1];
      return toDate(selection, payload, true);
    }
    default:
      return null;
  }
};

const toStartDate = (selectedOption, dateDependencies, payload) => {
  switch (selectedOption?.value) {
    case DATE_OPTIONS.IS: {
      const selection = dateDependencies.IS[0];
      return toDate(selection, payload);
    }
    case DATE_OPTIONS.IS_IN_DATE_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_RANGE[0];
      return toDate(selection, payload);
    }
    case DATE_OPTIONS.IS_IN_DATE_AND_TIME_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_AND_TIME_RANGE[0];
      return toDate(selection, payload);
    }
    default:
      return null;
  }
};

const shouldIncludeIssuedByUsingStatus = (taskStatus) => {
  return [TASK_STATUS.ISSUED, TASK_STATUS.COMPLETE, TASK_STATUS.RECEIVED].includes(taskStatus);
};

export const shouldIncludeIssuedByUsingId = (tabId) => {
  return [TAB_ID.ISSUED, TAB_ID.COMPLETE, TAB_ID.RECEIVED].includes(tabId);
};

export const adaptEvent = (payload) => {
  if (!payload?.arrivalDate && !payload?.departureDate) {
    return {};
  }

  const { arrivalDate, departureDate } = payload;

  return {
    inbound: {
      startDate: toStartDate(arrivalDate, ARRIVAL_DATE_DEPENDENCIES, payload),
      endDate: toEndDate(arrivalDate, ARRIVAL_DATE_DEPENDENCIES, payload),
    },
    outbound: {
      startDate: toStartDate(departureDate, DEPARTURE_DATE_DEPENDENCIES, payload),
      endDate: toEndDate(departureDate, DEPARTURE_DATE_DEPENDENCIES, payload),
    },
  };
};

export const adaptCreatedDate = (payload) => {
  if (!payload?.taskCreatedDate) {
    return {};
  }

  const { taskCreatedDate } = payload;

  return {
    startDate: toStartDate(taskCreatedDate, CREATED_DATE_DEPENDENCIES, payload),
    endDate: toEndDate(taskCreatedDate, CREATED_DATE_DEPENDENCIES, payload),
  };
};

export const toGetFilters = (movementModes, view) => {
  if (!movementModes || !Array.isArray(movementModes) || !movementModes?.length) {
    return {
      movementModes: DEFAULTS[view]?.filters.default.movementModes || [],
    };
  }

  return {
    movementModes,
  };
};

export const adaptJourneyDirection = (payload) => {
  if (!payload?.journeyDirections?.length) {
    return [];
  }
  return payload?.journeyDirections;
};

export const adaptMovementModes = (payload, defaultFilter) => {
  if (typeof payload?.mode === 'string') {
    return [payload.mode];
  }
  if (Array.isArray(payload?.mode) && payload?.mode?.length) {
    return payload?.mode;
  }
  // Return the default
  return defaultFilter.movementModes;
};

export const adaptPassengerStatuses = (payload) => {
  if (!payload?.passengerStatuses?.length) {
    return [];
  }
  return payload?.passengerStatuses;
};

export const adaptTargetingTeams = (payload) => {
  if (!payload?.targetingTeams?.length) {
    return [];
  }
  return payload?.targetingTeams;
};

export const toTargetingTeams = (taskStatus, storedData, defaultFilter, view, currentUser, targetingTeams) => {
  return targetingTeams?.map((targetingTeam) => ({
    taskStatuses: [taskStatus],
    movementModes: adaptMovementModes(storedData, defaultFilter),
    selectors: storedData.selectors,
    ruleNames: storedData.ruleNames,
    searchText: storedData.searchText,
    assignees: ((taskStatus === TASK_STATUS.IN_PROGRESS)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    journeyDirections: adaptJourneyDirection(storedData),
    passengerStatuses: adaptPassengerStatuses(storedData),
    targetingTeams: [targetingTeam],
    event: storedData.event,
    createdDate: storedData.createdDate,
    journeyId: storedData.journeyId,
    selectedForDismissalBy: taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
    issuedBy: (shouldIncludeIssuedByUsingStatus(taskStatus)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
  })) ?? [];
};

export const toPassengerStatus = (taskStatus, storedData, defaultFilter, view, currentUser) => {
  return DEFAULTS[view]?.filters?.passengerStatuses?.map((status) => ({
    taskStatuses: [taskStatus],
    movementModes: adaptMovementModes(storedData, defaultFilter),
    selectors: storedData.selectors,
    ruleNames: storedData.ruleNames,
    searchText: storedData.searchText,
    assignees: ((taskStatus === TASK_STATUS.IN_PROGRESS)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    journeyDirections: adaptJourneyDirection(storedData),
    passengerStatuses: status.passengerStatuses,
    targetingTeams: adaptTargetingTeams(storedData).map((targetingTeam) => targetingTeam.value),
    event: storedData.event,
    createdDate: storedData.createdDate,
    journeyId: storedData.journeyId,
    selectedForDismissalBy: taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
    issuedBy: (shouldIncludeIssuedByUsingStatus(taskStatus)
    && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
  })) ?? [];
};

export const toDirections = (taskStatus, storedData, defaultFilter, view, currentUser) => {
  return DEFAULTS[view]?.filters?.directions?.map((direction) => ({
    taskStatuses: [taskStatus],
    movementModes: adaptMovementModes(storedData, defaultFilter),
    selectors: storedData.selectors,
    ruleNames: storedData.ruleNames,
    searchText: storedData.searchText,
    assignees: ((taskStatus === TASK_STATUS.IN_PROGRESS)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    journeyDirections: direction.journeyDirections,
    passengerStatuses: adaptPassengerStatuses(storedData),
    targetingTeams: adaptTargetingTeams(storedData).map((targetingTeam) => targetingTeam.value),
    event: storedData.event,
    createdDate: storedData.createdDate,
    journeyId: storedData.journeyId,
    selectedForDismissalBy: taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
    issuedBy: (shouldIncludeIssuedByUsingStatus(taskStatus)
    && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
  })) ?? [];
};

export const toSelectors = (taskStatus, storedData, defaultFilter, view, currentUser) => {
  return DEFAULTS[view]?.filters?.selectors?.map((selector) => ({
    taskStatuses: [taskStatus],
    movementModes: adaptMovementModes(storedData, defaultFilter),
    selectors: selector.selectors,
    ruleNames: storedData.ruleNames,
    searchText: storedData.searchText,
    assignees: ((taskStatus === TASK_STATUS.IN_PROGRESS)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    journeyDirections: adaptJourneyDirection(storedData),
    passengerStatuses: adaptPassengerStatuses(storedData),
    targetingTeams: adaptTargetingTeams(storedData).map((targetingTeam) => targetingTeam.value),
    event: storedData.event,
    createdDate: storedData.createdDate,
    journeyId: storedData.journeyId,
    selectedForDismissalBy: taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
    issuedBy: (shouldIncludeIssuedByUsingStatus(taskStatus)
    && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
  })) ?? [];
};

export const toMovementModes = (taskStatus, storedData, view, currentUser) => {
  return DEFAULTS[view]?.filters?.movementModes?.map((mode) => ({
    taskStatuses: [taskStatus],
    movementModes: mode.movementModes,
    selectors: storedData.selectors,
    ruleNames: storedData.ruleNames,
    searchText: storedData.searchText,
    assignees: ((taskStatus === TASK_STATUS.IN_PROGRESS)
      && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    journeyDirections: adaptJourneyDirection(storedData),
    passengerStatuses: adaptPassengerStatuses(storedData),
    targetingTeams: adaptTargetingTeams(storedData).map((targetingTeam) => targetingTeam.value),
    event: storedData.event,
    createdDate: storedData.createdDate,
    journeyId: storedData.journeyId,
    selectedForDismissalBy: taskStatus === TASK_STATUS.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
    issuedBy: (shouldIncludeIssuedByUsingStatus(taskStatus)
    && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
  })) ?? [];
};

export const toFilterCountsPayload = (currentUser, view, taskStatus = TASK_STATUS.NEW, targetingTeams = []) => {
  const storedData = StorageUtil.get(DEFAULTS[view].filters.key);
  const defaultFilter = DEFAULTS[view].filters.default;
  if (storedData) {
    return [
      toMovementModes(taskStatus, storedData, view, currentUser),
      toSelectors(taskStatus, storedData, defaultFilter, view, currentUser),
      toDirections(taskStatus, storedData, defaultFilter, view, currentUser),
      toPassengerStatus(taskStatus, storedData, defaultFilter, view, currentUser),
      toTargetingTeams(taskStatus, storedData, defaultFilter, view, currentUser, targetingTeams),
    ].filter((item) => !!item.length);
  }
  return [
    (DEFAULTS?.[view]?.filters?.movementModes?.map((mode) => ({
      ...mode,
      taskStatuses: [taskStatus],
    })) || []),
    (DEFAULTS?.[view]?.filters?.selectors?.map((selector) => ({
      ...selector,
      taskStatuses: [taskStatus],
    })) || []),
    (DEFAULTS?.[view]?.filters?.directions?.map((direction) => ({
      ...direction,
      taskStatuses: [taskStatus],
    })) || []),
    (DEFAULTS?.[view]?.filters?.passengerStatuses?.map((status) => ({
      ...status,
      taskStatuses: [taskStatus],
    })) || []),
    (targetingTeams?.map((targetingTeam) => ({
      ...DEFAULTS?.[view]?.filters?.default,
      targetingTeams: [targetingTeam],
      taskStatuses: [taskStatus],
    })) || []),
  ].filter((item) => !!item.length);
};

export const toFilterPayload = (payload, defaultFilter, selectedTab) => {
  return {
    ...payload,
    createdDate: adaptCreatedDate(payload),
    event: adaptEvent(payload),
    movementModes: adaptMovementModes(payload, defaultFilter),
    ruleNames: payload?.rules?.map((rule) => rule.name, []) || [],
    searchText: payload?.searchText ? payload.searchText.toUpperCase().trim() : null,
    assignees: selectedTab === TAB_ID.IN_PROGRESS ? payload?.claimedByMe : [],
    journeyDirections: adaptJourneyDirection(payload),
    passengerStatuses: adaptPassengerStatuses(payload),
    targetingTeams: adaptTargetingTeams(payload),
    journeyId: payload?.journeyId ? payload.journeyId.toUpperCase().trim() : null,
    issuedBy: shouldIncludeIssuedByUsingId(selectedTab) ? payload?.claimedByMe : [],
  };
};

export const toTasksPayload = ({
  appliedFilters,
  tabIndex,
  view,
  itemsPerPage,
  offset,
  currentUser,
  taskModes,
}) => {
  return {
    filterParams: {
      ...appliedFilters,
      taskStatuses: [TASKS_TABS[tabIndex].taskStatus],
      assignees: ((TASKS_TABS[tabIndex].id === TAB_ID.IN_PROGRESS)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? appliedFilters?.claimedByMe : [],
      targetingTeams: appliedFilters?.targetingTeams?.map((targetingTeam) => targetingTeam.value),
      selectedForDismissalBy: TASKS_TABS[tabIndex].id === TAB_ID.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
      issuedBy: (shouldIncludeIssuedByUsingId(TASKS_TABS[tabIndex].id)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? appliedFilters?.claimedByMe : [],
      taskModes,
    },
    sortParams: [
      ...DEFAULTS[view].sortParams,
    ],
    pageParams: {
      limit: itemsPerPage,
      offset,
    },
  };
};

export const toTaskCountsPayload = (payload, view, currentUser, selectedTab, taskModes = []) => {
  const commonProperties = {
    ...payload,
    targetingTeams: payload?.targetingTeams?.map((targetingTeam) => targetingTeam.value),
    selectedForDismissalBy: [currentUser],
    taskModes,
  };

  if ([TAB_ID.NEW, TAB_ID.SELECTED_FOR_DISMISSAL].includes(selectedTab)) {
    return [
      {
        ...commonProperties,
        taskStatuses: [],
        assignees: [],
        issuedBy: [],
      },
    ];
  }

  return [
    {
      ...commonProperties,
      taskStatuses: [TASK_STATUS.NEW, TASK_STATUS.SELECTED_FOR_DISMISSAL],
      assignees: [],
      issuedBy: [],
    },
    {
      ...commonProperties,
      taskStatuses: [TASK_STATUS.IN_PROGRESS],
      assignees: CommonUtil.hasAssignee(DEFAULTS[view].filters.key) ? [currentUser] : [],
      issuedBy: [],
    },
    {
      ...commonProperties,
      taskStatuses: [TASK_STATUS.ISSUED, TASK_STATUS.RECEIVED, TASK_STATUS.COMPLETE],
      issuedBy: CommonUtil.hasAssignee(DEFAULTS[view].filters.key) ? [currentUser] : [],
      assignees: [],
    },
  ];
};
