import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const UpdateTaskButton = ({ onClick }) => {
  return (
    <Button
      id="update-task-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="update task to add task detail"
    >
      Update task details
    </Button>
  );
};

UpdateTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UpdateTaskButton;
