import { formatAddress } from '../../String/Target/targetStringUtil';

const getConsignorAddress = (consignor) => {
  if (!consignor) {
    return undefined;
  }

  return formatAddress(consignor?.address) || undefined;
};

const getConsignorName = (consignor) => {
  return consignor?.name || undefined;
};

const getConsignor = (informationSheet) => {
  return informationSheet?.movement?.consignor || undefined;
};

const TargetConsignorUtil = {
  get: getConsignor,
  name: getConsignorName,
  address: getConsignorAddress,
};

export default TargetConsignorUtil;
