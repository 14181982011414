import React from 'react';
import { AccountUtil,
  BookingUtil,
  CommonUtil,
  GoodsUtil,
  HaulierUtil } from '../../../../../utils';
import DatetimeUtil from '../../../../../utils/Datetime/datetimeUtil';
import TrailerUtil from '../../../../../utils/Trailer/trailerUtil';

import { DATE_FORMATS, STRINGS } from '../../../../../utils/constants';

import EnrichmentCount from './EnrichmentCount';
import PreviousTasksCount from '../../../../../components/PreviousTasks/PreviousTasksCount';

const UnaccompaniedMovementSection = ({ trailer,
  haulier,
  account,
  booking,
  goods,
  bookingDepartureTime }) => {
  return (
    <>
      <div className="govuk-grid-item vertical-dotted-line vertical-dotted-line--none">
        <>
          <div>
            <EnrichmentCount
              labelText="Trailer details"
              movementStats={CommonUtil.movementStats(trailer)}
              classnames={['secondary-text']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{TrailerUtil.registration(trailer)}</li>
              <li>{TrailerUtil.type(trailer)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(trailer)} />
            </ul>
          </div>
        </>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <>
          <div>
            <EnrichmentCount
              labelText="Haulier details"
              movementStats={CommonUtil.movementStats(haulier)}
              classnames={['secondary-text']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{HaulierUtil.name(haulier)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(haulier)} />
            </ul>
          </div>
          <div>
            <EnrichmentCount
              labelText="Account details"
              movementStats={CommonUtil.movementStats(account)}
              classnames={['secondary-text', 'govuk-!-margin-top-0']}
            />
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold list-item">{AccountUtil.name(account)}</li>
              <li className="list-item">Booked on {DatetimeUtil.format(BookingUtil.bookedAt(booking), DATE_FORMATS.SHORT)}</li>
              <li className="list-item">{DatetimeUtil.timeDiff(bookingDepartureTime, STRINGS.DEFAULT_BOOKING_STRING_PREFIX)}</li>
            </ul>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(account)} />
            </ul>
          </div>
        </>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Goods description
          </p>
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{GoodsUtil.description(goods)}</li>
          </ul>
        </div>
      </div>
      <div className="govuk-grid-item vertical-dotted-line" />
    </>
  );
};

export default UnaccompaniedMovementSection;
