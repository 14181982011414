// Global import(s)
import { Button, ButtonGroup } from '@ukhomeoffice/cop-react-components';
import React, { useState, useEffect } from 'react';

// Config(s)
import config from '../../utils/config';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';

const Versions = ({ onCancel }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [apiInfo, setApiInfo] = useState(null);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.VERSIONS);

  useEffect(() => {
    const fetchInfo = async () => {
      const info = await AxiosRequests.apiInfo(apiClient);
      setApiInfo(info);
    };
    fetchInfo();
  }, []);

  return (
    <>
      <div>
        <p>UI Version: {`${config.gitInfo.version}`}</p>
        <p>UI Commit Hash: {`${config.gitInfo.commitHash}`}</p>
        {apiInfo && (
          <>
            <p>API Version: {apiInfo.build.version}</p>
            <p>API Commit Hash: {apiInfo.git.commit.id}</p>
          </>
        )}
      </div>
      <ButtonGroup>
        <Button id="cancel" classModifiers="secondary" onClick={onCancel}>Cancel</Button>
      </ButtonGroup>
    </>
  );
};

export default Versions;
