import { Checkboxes, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const PublicInterestImmunity = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY}
      label={<Heading id="public-interest-immunity-heading" size="m">Public interest immunity</Heading>}
      error={componentErrors?.[COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY]?.message}
      className="govuk-!-margin-bottom-7"
    >
      <p>Uncheck the checkbox if not required.</p>
      <Checkboxes
        id={COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY}
        fieldId={COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY}
        options={[
          {
            value: 'true',
            label: 'Public interest immunity',
          },
        ]}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY]}
      />
    </FormGroup>
  );
};

PublicInterestImmunity.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

PublicInterestImmunity.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(PublicInterestImmunity);
