// Global imports
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Component(s)
import AirpaxPersonDetails from './airpax/PersonDetails';
import BaggageDetails from '../baggage/BaggageDetails';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import DocumentDetails from '../document/DocumentDetails';
import GeneralAviationPersonDetails from './general_aviation/PersonDetails';

// Util(s)
import { TargetBaggageUtil, TargetPersonUtil } from '../../../../../../utils';

const Header = ({ mode }) => {
  const heading = mode === MOVEMENT_MODES.GENERAL_AVIATION ? 'Persons on board' : 'Passenger details';

  return <Heading size="m">{heading}</Heading>;
};

const Persons = ({ persons, mode }) => {
  return (
    <div id="persons-details" className="target-info-summary-list">
      <Header mode={mode} />
      {persons?.map((person, index) => {
        const document = TargetPersonUtil.document(person);
        const baggage = TargetBaggageUtil.get(person);

        return (
          <>
            <ComponentWrapper show={mode === MOVEMENT_MODES.AIR_PASSENGER}>
              <AirpaxPersonDetails person={person} personPosition={index} mode={mode} />
              <DocumentDetails document={document} personPosition={index} mode={mode} />
              <ComponentWrapper show={index === 0}>
                <BaggageDetails baggage={baggage} personPosition={index} />
              </ComponentWrapper>
            </ComponentWrapper>
            <ComponentWrapper show={mode === MOVEMENT_MODES.GENERAL_AVIATION}>
              <GeneralAviationPersonDetails
                person={person}
                document={document}
                personPosition={index}
                mode={mode}
              />
            </ComponentWrapper>
          </>
        );
      })}
    </div>
  );
};

Persons.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mode: PropTypes.string.isRequired,
};

Header.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default memo(Persons);
