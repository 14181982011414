// Config(s)
import { MOVEMENT_MODES, VIEW } from '../utils/constants';

const MODE_OPTIONS = {
  [VIEW.RORO_IO]: {
    type: 'checkboxes',
    options: [
      {
        value: MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
        label: 'Freight unaccompanied',
      },
      {
        value: MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
        label: 'Freight accompanied',
      },
      {
        value: MOVEMENT_MODES.TOURIST,
        label: 'Tourist',
      },
    ],
  },
};

const filter = (groupNames, view) => {
  const components = [
    {
      id: 'categories',
      fieldId: 'categories',
      label: 'Category',
      type: 'checkboxes',
      required: true,
      data: {
        options: [
          {
            value: 'A',
            label: 'Category A',
          },
          {
            value: 'B',
            label: 'Category B',
          },
          {
            value: 'C',
            label: 'Category C',
          },
        ],
      },
      showFor: [VIEW.AIRPAX_RCCU, VIEW.RORO_IO],
    },
    {
      id: 'movementModes',
      fieldId: 'movementModes',
      label: 'Mode',
      type: MODE_OPTIONS?.[view]?.type,
      required: true,
      data: {
        options: MODE_OPTIONS?.[view]?.options,
      },
      showFor: [VIEW.RORO_IO],
    },
    {
      id: 'journeyDirections',
      fieldId: 'journeyDirections',
      label: 'Direction of travel',
      type: 'checkboxes',
      required: true,
      data: {
        options: [
          {
            value: 'INBOUND',
            label: 'Inbound',
          },
          {
            value: 'OUTBOUND',
            label: 'Outbound',
          },
        ],
      },
      showFor: [VIEW.RORO_IO],
    },
    {
      id: 'journeyDirections',
      fieldId: 'journeyDirections',
      label: 'Direction of travel',
      type: 'checkboxes',
      required: true,
      data: {
        options: [
          {
            value: 'INBOUND',
            label: 'Inbound',
          },
          {
            value: 'OUTBOUND',
            label: 'Outbound',
          },
          {
            value: 'UNKNOWN',
            label: 'Unknown',
          },
        ],
      },
      showFor: [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU],
    },
    {
      id: 'groupCodes',
      fieldId: 'groupCodes',
      label: 'Groups',
      type: 'checkboxes',
      dynamicOptions: true,
      required: true,
      data: {
        options: groupNames.map((group) => {
          return {
            value: group.code,
            label: group.name,
            hint: '',
          };
        }),
      },
      showFor: [VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU, VIEW.RORO_IO],
    },
    {
      id: 'targetsBy',
      fieldId: 'targetsBy',
      label: 'View targets by',
      type: 'radios',
      required: true,
      data: {
        options: [
          {
            value: 'UPCOMING',
            label: 'All upcoming',
          },
          {
            value: 'DATE_RANGE',
            label: 'Custom date range',
            nested: [
              {
                id: 'rangeFrom',
                fieldId: 'rangeFrom',
                label: 'From',
                type: 'date',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must enter a date to filter from',
                  },
                ],
              },
              {
                id: 'rangeTo',
                fieldId: 'rangeTo',
                label: 'To',
                type: 'date',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must enter a date to filter to',
                  },
                ],
              },
            ],
          },
        ],
      },
      showFor: [VIEW.AIRPAX_RCCU, VIEW.RORO_IO],
    },
  ];

  return {
    id: 'cop-filter',
    version: '1.0.0',
    name: 'cop-filter',
    type: 'form',
    components: [],
    pages: [
      {
        id: 'filter',
        name: 'filter',
        components: components.filter((component) => component.showFor.includes(view)),
        actions: [
          {
            type: 'submit',
            validate: true,
            label: 'Apply',
          },
        ],
      },
    ],
  };
};

export default filter;
