import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const SaveAndCloseButton = ({ onClick }) => {
  return (
    <Button
      id="save-and-close-button"
      onClick={onClick}
      aria-label="save and close task"
    >
      Save and close
    </Button>
  );
};

SaveAndCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SaveAndCloseButton;
