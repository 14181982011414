import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';

import './ExternalLink.scss';

const ExternalLink = ({ className, href, content, withIcon = false }) => {
  return (
    <span className="govuk-!-margin-right-0">
      <Link className={className} href={href} target="_blank" rel="noreferrer noopener">
        {content}
        {withIcon && <i className="icon-external-arrow c-icon-north-east-arrow" />}
      </Link>
    </span>
  );
};

export default ExternalLink;
