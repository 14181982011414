import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ErrorAlertContext = createContext({});

const ErrorAlertProvider = ({ children }) => {
  const location = useLocation();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (location?.pathname) {
      setErrors([]);
    }
  }, [location.pathname]);

  const value = useMemo(() => ({
    errors,
    setErrors,
  }), [errors]);

  return (
    <ErrorAlertContext.Provider value={value}>
      {children}
    </ErrorAlertContext.Provider>
  );
};

const useErrorAlert = () => useContext(ErrorAlertContext);

export { ErrorAlertProvider, ErrorAlertContext, useErrorAlert };
