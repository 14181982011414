import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Component(s)
import GeneralAviationFlightDetails from './general_aviation/FlightDetails';
import AirpaxFlightDetails from './airpax/FlightDetails';

const FlightDetails = ({ informationSheet, mode }) => {
  if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
    return <GeneralAviationFlightDetails informationSheet={informationSheet} />;
  }

  return <AirpaxFlightDetails informationSheet={informationSheet} />;
};

FlightDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
};

export default memo(FlightDetails);
