import { useState, useEffect } from 'react';
/**
 * This hook stores the keyboard tab key position to track the target list inputs before the page reload
 */
const useTrackTargetsList = () => {
  const [tabKeyPosition, setTabKeyPosition] = useState(null);

  useEffect(() => {
    window.onkeyup = (e) => {
      if (e.key === 'Tab' && e.target.ariaLabel) {
        setTabKeyPosition(e.target.id);
      }
    };
  }, []);

  return { tabKeyPosition, setTabKeyPosition };
};

export default useTrackTargetsList;
