import PersonUtil from '../personUtil';

const getPassengerStatus = (person) => {
  const status = person?.passengerStatus ?? undefined;
  switch (status) {
    case 'CHECKED_IN': {
      return 'Checked in';
    }
    case 'DEPARTURE_CONFIRMED': {
      return 'Departure confirmed';
    }
    default:
      return 'Status not available';
  }
};

const getAdditionalPhotoContent = (entity) => {
  return entity?.additionalContents?.find((addition) => addition.type === 'PHOTO') || null;
};

const getImageUrl = (additionalContent) => {
  return additionalContent?.url || null;
};

const getGenderNode = (person) => {
  return person?.gender || undefined;
};

const getFormattedGender = (person) => {
  if (!person) {
    return undefined;
  }
  return getGenderNode(person)?.name || undefined;
};

const getNationalityNode = (person) => {
  return person?.nationality || undefined;
};

const getNationality = (person) => {
  return person?.nationality?.nationality || undefined;
};

const getDocumentNumber = (document) => {
  return document?.number || undefined;
};

const getDob = (person) => {
  return person?.dateOfBirth || undefined;
};

const getDocument = (person) => {
  return person?.document || undefined;
};

const getFirstName = (person) => {
  return person?.name?.first || undefined;
};

const getLastName = (person) => {
  return person?.name?.last || undefined;
};

const getFullName = (person) => {
  return person?.name?.full || undefined;
};

const getOthers = (informationSheet) => {
  return informationSheet?.movement?.otherPersons || [];
};

const getPerson = (informationSheet) => {
  return informationSheet?.movement?.person || undefined;
};

const getAllPersons = (informationSheet) => {
  return [getPerson(informationSheet), ...getOthers(informationSheet)].filter((val) => !!val);
};

const getWatchListAdded = (person) => {
  return person?.watchList?.added ? 'Yes' : 'No';
};

const getWatchListReferenceNumber = (person) => {
  return person?.watchList?.referenceNumber || 'Not provided';
};

const getSeatNumber = (person) => {
  return person?.seatNumber || undefined;
};

const getPersonsOfInterest = (person) => {
  return person?.ofInterest || null;
};

const getPersonRole = (person) => {
  return person?.role || null;
};

const getAllPersonsOnWhitelist = (informationSheet) => {
  const allPersons = getAllPersons(informationSheet);
  const allWatchedPersons = allPersons.map((a) => (a.watchList?.added ? 'Yes' : 'No')).filter((a) => a === 'Yes');
  return allPersons.length === allWatchedPersons.length;
};

const getAllPersonsOfInterest = (informationSheet) => {
  const allInterestingPersons = getOthers(informationSheet)
    .map((a) => getPersonsOfInterest(a))
    .filter(Boolean)
    .concat(getPerson(informationSheet));
  return allInterestingPersons;
};

const getCoTravellers = (informationSheet) => {
  const allInterestingPersons = getAllPersonsOfInterest(informationSheet);
  const coTravellers = getOthers(informationSheet)
    .filter((o) => !allInterestingPersons.includes(o));
  return coTravellers;
};

const getUnder18s = (informationSheet) => {
  const allPersons = getAllPersons(informationSheet);
  const ages = allPersons.reduce((acc, curr) => {
    if (PersonUtil.age(curr) < 18) {
      /* eslint-disable-next-line no-plusplus */
      acc++;
    }
    return acc;
  }, 0);
  return ages;
};

const TargetPersonUtil = {
  additionalPhotoContent: getAdditionalPhotoContent,
  allOnWhitelist: getAllPersonsOnWhitelist,
  allOfInterest: getAllPersonsOfInterest,
  coTravellers: getCoTravellers,
  document: getDocument,
  docNumber: getDocumentNumber,
  dob: getDob,
  firstName: getFirstName,
  formattedGender: getFormattedGender,
  fullName: getFullName,
  genderNode: getGenderNode,
  getAll: getAllPersons,
  imageUrl: getImageUrl,
  lastName: getLastName,
  nationality: getNationality,
  nationalityNode: getNationalityNode,
  ofInterest: getPersonsOfInterest,
  others: getOthers,
  person: getPerson,
  passengerStatus: getPassengerStatus,
  role: getPersonRole,
  watchList: {
    added: getWatchListAdded,
    referenceNumber: getWatchListReferenceNumber,
  },
  seatNumber: getSeatNumber,
  under18s: getUnder18s,
};

export default TargetPersonUtil;

export {
  getAdditionalPhotoContent,
  getAllPersonsOnWhitelist,
  getAllPersonsOfInterest,
  getCoTravellers,
  getDocument,
  getDocumentNumber,
  getDob,
  getFirstName,
  getFormattedGender,
  getFullName,
  getGenderNode,
  getAllPersons,
  getImageUrl,
  getLastName,
  getNationality,
  getNationalityNode,
  getOthers,
  getPerson,
  getPersonsOfInterest,
  getPersonRole,
  getWatchListAdded,
  getWatchListReferenceNumber,
  getSeatNumber,
  getUnder18s,
};
