// Global import(s)
import { SmallHeading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import AdditionalContent from '../content/AdditionalContent';
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import TargetBaggageUtil from '../../../../../../utils/Baggage/Target/targetBaggageUtil';
import TargetCommonUtil from '../../../../../../utils/Common/Target/targetCommonUtil';

const BaggageDetails = ({ baggage, personPosition }) => {
  const adjustedPersonPosition = personPosition + 1;
  const additionalContents = TargetCommonUtil.additionalTextContents(baggage);

  const panelData = (() => {
    return [
      {
        id: `person-${adjustedPersonPosition}-checked-bags`,
        label: 'Checked bags',
        value: TargetBaggageUtil.totalCheckedBags(baggage),
      },
      {
        id: `person-${adjustedPersonPosition}-total-baggage-weight`,
        label: 'Total weight',
        value: TargetBaggageUtil.totalWeight(baggage),
      },
      {
        id: `person-${adjustedPersonPosition}-baggage-tag-numbers`,
        label: 'Tag numbers',
        value: TargetBaggageUtil.tags(baggage),
      },
    ];
  })();

  return (
    <div id="baggage-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <SmallHeading size="m">Baggage Details</SmallHeading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
        <AdditionalContent additions={additionalContents} heading="Baggage additions" />
      </div>
    </div>
  );
};

BaggageDetails.propTypes = {
  baggage: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
};

export default memo(BaggageDetails);
