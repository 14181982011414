// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import { TargetInformationUtil } from '../../../../../../utils';

const ReferralReason = ({ informationSheet }) => {
  const panelData = (() => {
    return [
      {
        id: 'suspected-abuse-type',
        label: 'Suspected abuse type',
        value: TargetInformationUtil.format.abuseTypes(
          TargetInformationUtil.suspectedAbuseTypes(informationSheet),
        ),
      },
      {
        id: 'comment',
        label: 'Comments',
        value: TargetInformationUtil.selectionReason(informationSheet),
      },
      {
        id: 'additional-info',
        label: 'Additional information',
        value: TargetInformationUtil.informationSheet.additionalInformation(informationSheet),
      },
    ];
  })();

  return (
    <div id="referral-reason" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Reason for referral</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
      </div>
    </div>
  );
};

ReferralReason.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(ReferralReason);
