// Config(s)
import { PNC_OUTCOMES } from '../../../../../utils/TargetInformation/constants';
import { STRINGS } from '../../../../../utils/constants';
import { COMMODITIES_CHECKS, IDP_CHECKS, GA_CHECKS, SYSTEM_CHECKS } from '../components/shared/checks/constants';

// Util(s)
import { isCommoditiesSubMode, isGaSubMode, isIdpSubMode, isRoRoSubMode } from '../../../../../utils/Task/taskUtil';

const toPncResult = (pnc) => {
  if (!pnc || !pnc?.pncOutcome) {
    return null;
  }

  const { pncOutcome, pncDetails, pncFound, pncCategories } = pnc;

  return {
    outcome: pncOutcome,
    ...(pncOutcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND && {
      found: {
        pncId: pncFound?.pncId,
        safetyInformation: pncFound?.safetyInformation === STRINGS.YES,
      },
      ...(pncFound.safetyInformation === STRINGS.YES ? {
        details: {
          types: pncDetails?.types || [],
          staffConcerns: pncDetails?.types.includes('STAFF_CONCERN') ? pncDetails?.staffConcerns : null,
          poiConcerns: pncDetails?.types.includes('POI_CONCERN') ? pncDetails?.poiConcerns : null,
        },
      } : null),
      categories: {
        drugsPossession: pncCategories?.drugsPossession?.length ? pncCategories?.possessionClass : [],
        drugsSupply: pncCategories?.drugsSupply?.length ? pncCategories?.supplyClass : [],
        drugsTrafficking: pncCategories?.drugsTrafficking?.length ? pncCategories?.traffickingClass : [],
        weaponsPossession: pncCategories?.weaponsPossession?.length ? pncCategories?.weaponsPossessionOpts : [],
        weaponsSupply: pncCategories?.weaponsSupply?.length ? pncCategories?.weaponsSupplyOptions : [],
        goodsSmuggling: pncCategories?.goodsSmuggling?.length ? pncCategories?.goodsSmugglingOpts : [],
        peopleSmuggling: pncCategories?.peopleSmuggling?.length ? pncCategories?.peopleSmugglingOpts : [],
      },
    }),
  };
};

const toPncCredibilityCheck = ({ pnc }) => {
  return {
    id: pnc?.id || null,
    system: 'PNC',
    type: 'PNC',
    result: toPncResult(pnc),
  };
};

const isTrace = (trace) => {
  return trace === 'TRACE';
};

const toBooleanTrace = (trace) => {
  return trace === 'TRACE';
};

const toResult = (trace, comment, isSelectedCheck) => {
  if (!trace || !isSelectedCheck) {
    return null;
  }

  const toComment = () => {
    if (!comment) {
      return null;
    }
    return comment;
  };

  return {
    trace: toBooleanTrace(trace),
    comment: isTrace(trace) ? toComment() : null,
  };
};

const toSystemResult = (otherSystem, isSelectedCheck) => {
  delete otherSystem.dataId;
  if (!otherSystem || !isSelectedCheck || !Object.keys(otherSystem).length) {
    return null;
  }

  return {
    systemChecked: otherSystem?.systemChecked || null,
    ...toResult(otherSystem?.trace, otherSystem?.comment, isSelectedCheck),
  };
};

const toOtherSystems = (otherSystems, isSelectedCheck) => {
  return otherSystems
    .map((otherSystem) => ({
      id: otherSystem?.id || null,
      system: SYSTEM_CHECKS.OTHER,
      type: SYSTEM_CHECKS.OTHER,
      result: toSystemResult(otherSystem, isSelectedCheck),
    }));
};

const toTrace = (system, result, isSelectedCheck) => {
  const { id, trace, comment } = result;
  return {
    id,
    system,
    type: 'TRACE',
    result: toResult(trace, comment, isSelectedCheck),
  };
};

const toCheck = (check, otherChecks, isSelectedCheck) => {
  switch (check) {
    case SYSTEM_CHECKS.PEGA:
    case SYSTEM_CHECKS.SIP:
    case SYSTEM_CHECKS.OPEN_SOURCE:
    case SYSTEM_CHECKS.INTERPOL:
    case SYSTEM_CHECKS.HMRC:
    case SYSTEM_CHECKS.ENTITY_SEARCH:
    case SYSTEM_CHECKS.DVA:
    case SYSTEM_CHECKS.CRS:
    case SYSTEM_CHECKS.CENTAUR:
    case SYSTEM_CHECKS.ATLAS: {
      return toTrace(
        check,
        {
          id: otherChecks?.[check.toLowerCase()]?.id || null,
          trace: otherChecks?.[check.toLowerCase()]?.trace,
          comment: otherChecks?.[check.toLowerCase()]?.comment,
        },
        isSelectedCheck);
    }
    case SYSTEM_CHECKS.OTHER: {
      return toOtherSystems(otherChecks?.otherSystems, isSelectedCheck);
    }
    default: {
      return null;
    }
  }
};

const getChecksForRbac = (subMode) => {
  if (isCommoditiesSubMode(subMode)) {
    return COMMODITIES_CHECKS;
  }

  if (isIdpSubMode(subMode)) {
    return IDP_CHECKS;
  }

  if (isGaSubMode(subMode)) {
    return GA_CHECKS;
  }

  return [];
};

const toOtherChecks = ({ otherChecks }, subMode) => {
  const { checks } = otherChecks;
  const rbacChecks = getChecksForRbac(subMode);

  if (!rbacChecks || !rbacChecks?.length) {
    return [];
  }

  return rbacChecks
    .map((rbacCheck) => {
      const isSelectedCheck = checks?.includes(rbacCheck);
      return toCheck(rbacCheck, otherChecks, isSelectedCheck);
    })
    .flat()
    .filter((item) => !!item);
};

const toCredibilityChecksSubmission = (entityData, subMode) => {
  return {
    entityId: entityData.entityId,
    notNecessary: !!entityData?.otherChecks?.checks?.includes(STRINGS.FORM.NOT_REQUIRED),
    notNecessaryReason: entityData?.otherChecks?.checks?.includes(STRINGS.FORM.NOT_REQUIRED) ? entityData?.otherChecks?.notRequired : null,
    credibilityChecks: [
      ((isRoRoSubMode(subMode) || isCommoditiesSubMode(subMode) || isGaSubMode(subMode)) && toPncCredibilityCheck(entityData)),
      ...(entityData?.otherChecks ? toOtherChecks(entityData, subMode) : []),
    ].filter((item) => !!item),
  };
};

export default toCredibilityChecksSubmission;
