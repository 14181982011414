import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import { AircraftIcon, PersonIcon } from '../../../../../components/Icon/Icon';

// Util(s)
import { CommonUtil, FlightUtil, JourneyUtil, MovementUtil } from '../../../../../utils';

// Styling
import '../__assets__/VoyageSection.scss';

const VoyageSection = ({ journey,
  flight,
  arrivalTime,
  description,
  movementType,
  movementStatus }) => {
  const airline = MovementUtil.airlineName(flight);
  const flightNumber = FlightUtil.flightNumber(flight);
  const formattedVoyageDescription = MovementUtil.voyageText(arrivalTime);
  const formattedDepartureTime = JourneyUtil.formatDepartureTime(journey);
  const formattedArrivalTime = JourneyUtil.formatArrivalTime(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);

  return (
    <div id="task-list-voyage-summary">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-one-third voyage-description-container">
          <div id="voyage-mode-type-icon">
            <PersonIcon />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s content-line-one govuk-!-margin-bottom-0">
              {description}
            </p>
            <span className="govuk-body-s govuk-!-font-weight-bold">
              {movementType} {movementStatus}
            </span>
          </div>
        </div>
        <div className="govuk-grid-column-two-thirds voyage-details-container">
          <div id="voyage-movement-details">
            <ComponentWrapper show={airline || flightNumber || formattedVoyageDescription}>
              <p className="content-line-one">
                {CommonUtil.commaSeparated(
                  airline || null,
                  flightNumber ? `flight ${flightNumber}` : null,
                  arrivalTime ? formattedVoyageDescription : null,
                )}
              </p>
            </ComponentWrapper>
            <p className="govuk-body-s content-line-two">
              <ComponentWrapper show={flightNumber}>
                <span className="govuk-!-font-weight-bold">{flightNumber}</span>
              </ComponentWrapper>

              <ComponentWrapper show={flightNumber || formattedDepartureTime}>
                <span className="dot" />
              </ComponentWrapper>

              <ComponentWrapper show={formattedDepartureTime}>
                {formattedDepartureTime}
              </ComponentWrapper>

              <ComponentWrapper show={formattedDepartureTime || departureLocation}>
                <span className="dot" />
              </ComponentWrapper>

              <ComponentWrapper show={departureLocation}>
                <span className="govuk-!-font-weight-bold">{departureLocation} </span>
              </ComponentWrapper>
              <ComponentWrapper show={departureLocation || arrivalLocation}>
                <span className="right-arrow"> &#8594; </span>
              </ComponentWrapper>

              <ComponentWrapper show={arrivalLocation}>
                <span className="govuk-!-font-weight-bold"> {arrivalLocation}</span>
              </ComponentWrapper>

              <ComponentWrapper show={formattedArrivalTime || arrivalLocation}>
                <span className="dot" />
              </ComponentWrapper>

              <ComponentWrapper show={formattedArrivalTime}>
                {formattedArrivalTime}
              </ComponentWrapper>
            </p>
          </div>
          <div id="voyage-movement-icon">
            <AircraftIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoyageSection;
