// Global import(s)
import { withCount } from 'pluralise';
import PropTypes from 'prop-types';
import React from 'react';

const CrewPassengerCount = ({ totalCount, crewCount, passengerCount }) => {
  if (!totalCount) {
    return null;
  }

  return (
    <p id="summary-movement-crew-passenger">
      {`${totalCount} onboard : ${crewCount} crew ${withCount(passengerCount, '% passenger', '% passengers', '')}`}
    </p>
  );
};

CrewPassengerCount.propTypes = {
  totalCount: PropTypes.number.isRequired,
  crewCount: PropTypes.number.isRequired,
  passengerCount: PropTypes.number.isRequired,
};

export default CrewPassengerCount;
