const getVehicleColour = (vehicle) => {
  return vehicle?.colour || undefined;
};

const getVehicleNationality = (vehicle) => {
  return vehicle?.nationality?.nationality || undefined;
};

const getVehicleModel = (vehicle) => {
  return vehicle?.model || undefined;
};

const getVehicleRegistration = (vehicle) => {
  return vehicle?.registration || undefined;
};

const getVehicleMake = (vehicle) => {
  return vehicle?.make || undefined;
};

const getVehicle = (informationSheet) => {
  return informationSheet?.movement?.vehicle || undefined;
};

const TargetVehicleUtil = {
  colour: getVehicleColour,
  get: getVehicle,
  make: getVehicleMake,
  model: getVehicleModel,
  nationality: getVehicleNationality,
  registration: getVehicleRegistration,
};

export default TargetVehicleUtil;
