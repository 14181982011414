import { STRINGS } from '../constants';

const getAccountNumber = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  return account?.number || STRINGS.UNKNOWN;
};

/*
This is currently not mapped as we do not know where to extract the data from.
This method until further notice will always return Unknown.
 */
// eslint-disable-next-line no-unused-vars
const getAccountEmail = (account) => {
  return STRINGS.UNKNOWN;
};

const getTelephoneContact = (account) => {
  if (!account) {
    return undefined;
  }
  return account?.contacts?.phone;
};

const getMobileContact = (account) => {
  if (!account) {
    return undefined;
  }
  return account?.contacts?.mobile;
};

const getAccountMobile = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  const contact = getMobileContact(account);
  if (!contact) {
    return STRINGS.UNKNOWN;
  }
  return contact?.value || STRINGS.UNKNOWN;
};

const getAccountTelephone = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  const contact = getTelephoneContact(account);
  if (!contact) {
    return STRINGS.UNKNOWN;
  }
  return contact?.value || STRINGS.UNKNOWN;
};

const getAddress = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  return account?.address || undefined;
};

const getAccountReference = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  return account?.reference || STRINGS.UNKNOWN;
};

const getShortName = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  return account?.shortName || STRINGS.UNKNOWN;
};

const getAccountName = (account) => {
  if (!account) {
    return STRINGS.UNKNOWN;
  }
  return account?.name || STRINGS.UNKNOWN;
};

const getAccount = (targetTask) => {
  return targetTask?.movement?.account || undefined;
};

const AccountUtil = {
  address: getAddress,
  email: getAccountEmail,
  get: getAccount,
  mobile: getAccountMobile,
  mobileContact: getMobileContact,
  name: getAccountName,
  number: getAccountNumber,
  shortName: getShortName,
  reference: getAccountReference,
  telephone: getAccountTelephone,
  telephoneContact: getTelephoneContact,
};

export default AccountUtil;

export {
  getAccount,
  getAccountName,
  getAddress,
  getAccountMobile,
  getAccountReference,
  getAccountTelephone,
  getMobileContact,
  getShortName,
  getTelephoneContact,
};
