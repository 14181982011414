import { useState } from 'react';
import { useInterval } from 'react-use';

// Utils
import { NumberUtil } from '../index';

const toTimeValues = (countDown) => {
  // Calculate time left
  const days = NumberUtil.zeroIfRequired(Math.floor(countDown / (1000 * 60 * 60 * 24)));
  const hours = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60)) / 1000));
  return [days, hours, minutes, seconds];
};

const useTimeCountDown = (countDownDate, delay) => {
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useInterval(() => {
    setCountDown(countDownDate - new Date().getTime());
  }, delay);

  return toTimeValues(countDown);
};

export default useTimeCountDown;
