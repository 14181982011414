import React from 'react';
import { formatOutcome } from '../helper/frontLineActionSection';

const FrontLineActionSection = ({ action }) => {
  return (
    <section id="previous-task-list-frontline-action">
      <div className="section-header">
        Front Line Action
      </div>
      <div className="section-body">
        <div className="govuk-!-margin-bottom-2 previous-task-targeter-outcome">
          {action?.outcome && formatOutcome(action?.outcome)}
        </div>
        <div className="govuk-!-margin-bottom-1 previous-task-targeter-reason">
          {action?.reason}
        </div>
      </div>
    </section>
  );
};

export default FrontLineActionSection;
