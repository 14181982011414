// Global import(s)
import { Link as ExternalLink } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { Link as InternalLink } from 'react-router-dom';

// Config(s)
import { MOVEMENT_MODES, PATHS, SYSTEMS } from '../../../../utils/constants';

const toPagePath = (movementMode) => {
  switch (movementMode) {
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.TOURIST:
      return PATHS.PREVIOUS_TASKS.RORO;
    case MOVEMENT_MODES.AIR_PASSENGER:
      return PATHS.PREVIOUS_TASKS.AIRPAX;
    default:
      return null;
  }
};

const getCopSystemLink = (source, movementMode) => {
  return (
    <InternalLink
      to={[toPagePath(movementMode), source?.taskId].filter((item) => !!item).join('/')}
      target="_self"
      className="govuk-link"
    >
      View details
    </InternalLink>
  );
};

const getCerberusSystemLink = (source) => {
  return (
    <ExternalLink
      href={source?.link}
      target="_blank"
    >
      View details
    </ExternalLink>
  );
};

const getSourceLink = (source, movementMode) => {
  switch (source.system) {
    case SYSTEMS.COP:
      return getCopSystemLink(source, movementMode);
    case SYSTEMS.CERBERUS:
      return getCerberusSystemLink(source);
    default:
      return null;
  }
};

export default getSourceLink;
