import { STRINGS } from '../constants';

import { getHiddenValue, shouldHideString } from '../Hiding/hidingUtil';

const getSeatNumber = (flight) => {
  if (!flight?.seatNumber) {
    return null;
  }
  if (shouldHideString(flight.seatNumber)) {
    return null;
  }
  return flight.seatNumber;
};

const getFlightNumber = (flight) => {
  if (!flight?.number) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(flight.number)) {
    return null;
  }
  return flight.number;
};

const getDepartureStatus = (flight) => {
  if (!flight) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(flight?.departureStatus)) {
    return getHiddenValue();
  }

  return flight?.departureStatus || STRINGS.UNKNOWN;
};

const getFlight = (targetTask) => {
  return targetTask?.movement?.flight || null;
};

const FlightUtil = {
  get: getFlight,
  departureStatus: getDepartureStatus,
  flightNumber: getFlightNumber,
  seatNumber: getSeatNumber,
};

export default FlightUtil;

export {
  getFlight,
  getDepartureStatus,
  getFlightNumber,
  getSeatNumber,
};
