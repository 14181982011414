// Global import(s)
import { FormGroup, Heading, Select } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const TargetCategory = ({ componentErrors, onChange, formData, title, placeHolderTitle }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.TARGET_CATEGORY}
      label={<Heading id="target-category-heading" size="m">{title}</Heading>}
      error={componentErrors?.[COMPONENT_IDS.TARGET_CATEGORY]?.message}
      className="govuk-!-margin-bottom-7"
    >
      <Select
        id={COMPONENT_IDS.TARGET_CATEGORY}
        fieldId={COMPONENT_IDS.TARGET_CATEGORY}
        options={[
          {
            value: 'A',
            label: 'Category A',
          },
          {
            value: 'B',
            label: 'Category B',
          },
          {
            value: 'C',
            label: 'Category C',
          },
        ]}
        onChange={onChange}
        required
        value={formData?.[COMPONENT_IDS.TARGET_CATEGORY] || null}
        placeholder={placeHolderTitle}
      />
    </FormGroup>
  );
};

TargetCategory.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
  title: PropTypes.string,
  placeHolderTitle: PropTypes.string,
};

TargetCategory.defaultProps = {
  componentErrors: null,
  formData: null,
  title: 'Target category',
  placeHolderTitle: 'Select a target category',
};

export default memo(TargetCategory);
