import gds from '@ukhomeoffice/formio-gds-template/lib';
import React from 'react';

import { Formio, Form } from 'react-formio';

Formio.use(gds);

const FormIO = ({ form,
  formattedPreFillData,
  setLoaderVisibility,
  onSubmit,
  onNextPage,
  onPrevPage,
  options,
  setSubmitted,
  setError,
  onCancel }) => {
  const handleNextPage = () => {
    if (typeof onNextPage === 'function') {
      return onNextPage;
    }
    return () => window.scrollTo(0, 0);
  };

  const handlePreviousPage = () => {
    if (typeof onPrevPage === 'function') {
      return onPrevPage;
    }
    return () => {
      window.scrollTo(0, 0);
      setError(null);
    };
  };

  return (
    <Form
      form={form}
      submission={formattedPreFillData}
      onSubmit={async (data) => {
        setLoaderVisibility(true);
        try {
          await onSubmit(data, form);
          setSubmitted(true);
        } catch (e) {
          setError(e.message);
        } finally {
          setLoaderVisibility(false);
        }
      }}
      onNextPage={handleNextPage}
      onPrevPage={handlePreviousPage}
      options={options || {
        noAlerts: true,
        hooks: {
          beforeCancel: async () => {
            if (onCancel) {
              await onCancel();
            } else {
              history.go(0);
            }
          },
        },
      }}
    />
  );
};

export default FormIO;
