// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Util(s)
import { CommonUtil, TargetMovementUtil, TargetRisksUtil, TargetStringUtil, TargetInformationUtil } from '../../../../../../utils';

const PreArrival = ({ informationSheet }) => {
  const selector = TargetRisksUtil.selector(informationSheet);
  const mode = TargetMovementUtil.mode(informationSheet);

  const toInterestImmunity = () => {
    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      return {
        id: 'interest-immunity',
        label: 'Public interest immunity',
        value: TargetInformationUtil.informationSheet.interestImmunity(informationSheet),
      };
    }
    return null;
  };

  const toReasoning = () => {
    return {
      id: mode === MOVEMENT_MODES.AIR_PASSENGER ? 'comments' : 'selection-reasoning',
      label: mode === MOVEMENT_MODES.AIR_PASSENGER ? 'Comments' : 'Selection comments',
      value: TargetInformationUtil.selectionReason(informationSheet),
    };
  };

  const panelData = [
    {
      id: 'category',
      label: 'Category',
      value: TargetStringUtil.formatCategory(TargetRisksUtil.category(selector)),
    },
    {
      id: 'threat-type',
      label: 'Threat type / National Control Strategy',
      value: TargetMovementUtil.formattedStrategies(informationSheet),
    },
    {
      id: 'threat-indicators',
      label: 'Threat indicators',
      value: CommonUtil.informationSheet.formatArrayData(TargetRisksUtil.indicators(informationSheet), 'userfacingtext'),
    },
    toReasoning(),
    {
      id: 'operation',
      label: 'Operation',
      value: TargetMovementUtil.operation(informationSheet),
    },
    toInterestImmunity(),
  ];

  return (
    <div id="pre-arrival-details" className="govuk-!-padding-bottom-4">
      <Heading size="m">Pre-arrival details</Heading>
      <dl className="govuk-summary-list">
        {panelData.map((item) => {
          if (item) {
            return (
              <div className="govuk-summary-list__row" key={item.label}>
                <dt id={`${item.id}-label`} className="govuk-summary-list__key">{item.label}</dt>
                <dd id={`${item.id}-value`} className="govuk-summary-list__value">{item.value}</dd>
              </div>
            );
          }
          return null;
        }).filter((item) => !!item)}
      </dl>
    </div>
  );
};

PreArrival.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(PreArrival);
