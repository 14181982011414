import { FORM_ACTIONS } from '../../utils/constants';

const getForm = (isIdpSubMode) => {
  return {
    id: 'withdrawTarget',
    version: '1.0.0',
    name: 'withdrawTarget',
    title: 'Withdraw target',
    type: 'form',
    components: [],
    pages: [{
      id: 'withdrawTarget',
      name: 'withdrawTarget',
      components: [
        {
          type: 'heading',
          size: 'l',
          content: 'Withdraw target',
        },
        {
          type: 'html',
          tagName: 'p',
          content: 'Provide a reason for withdrawing this target',
        },
        {
          id: 'withdrawalReason',
          fieldId: 'withdrawalReason',
          label: 'Reason for target withdrawal',
          'aria-label': 'reason for target withdrawal',
          type: 'textarea',
          rows: 3,
          required: true,
        },
        (isIdpSubMode && {
          type: 'inset-text',
          content: 'Reason for withdrawal will included in the withdrawal notice for HOIO recipients of this target',
        }
        ),
        {
          type: 'heading',
          size: 'm',
          content: 'New assessment result',
        },
        {
          type: 'html',
          tagName: 'p',
          content: 'Indicate the new assessment result for this task',
        },
        {
          id: 'newAssessmentResult',
          fieldId: 'newAssessmentResult',
          label: 'New assessment result',
          'aria-label': 'new assessment result',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'NO_TARGET_REQUIRED',
                label: 'No target required',
              },
              {
                value: 'FALSE_MATCH',
                label: 'False match',
              },
              {
                value: 'OTHER',
                label: 'Other',
                nested: [
                  {
                    id: 'otherReasonDetail',
                    fieldId: 'otherReasonDetail',
                    label: 'Please specify why no further action should be taken',
                    'aria-label': 'please specify why no further action should be taken',
                    type: 'text',
                    required: true,
                  },
                ],
              },
            ],
          },
        },
      ].filter((component) => !!component),
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Withdraw target',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    }],
  };
};
const form = (isIdpSubMode) => {
  return getForm(isIdpSubMode);
};

export default form;
