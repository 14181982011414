import * as pluralise from 'pluralise';

// Util(s)
import { shouldMaskNumber, getMaskedValue, shouldMaskStringArray, shouldMaskString } from '../Masking/maskingUtil';
import { shouldHideNumber, shouldHideString, shouldHideStringArray } from '../Hiding/hidingUtil';

const getAdditionalContent = (baggage) => {
  if (!baggage?.additionalContent || !baggage?.additionalContent?.length) {
    return [];
  }

  return baggage.additionalContent;
};

const sanitizeWeightIfRequired = (weight) => {
  if (!weight) {
    return null;
  }

  if (weight.endsWith('kg')) {
    return weight.split('kg')[0];
  }

  return weight;
};

const toFormattedBaggageCount = (bagCount) => {
  if (!bagCount) {
    return null;
  }

  if (bagCount === getMaskedValue()) {
    return getMaskedValue();
  }

  return pluralise.withCount(bagCount, '% bag', '% bags');
};

const toFormattedBaggageWeight = (weight) => {
  if (!weight) {
    return null;
  }

  if (weight === getMaskedValue()) {
    return getMaskedValue();
  }

  return `${weight} kg total`;
};

const toFormattedBaggageTags = (tags) => {
  if (!Array.isArray(tags) || !tags?.length) {
    return null;
  }

  if (shouldHideStringArray(tags)) {
    return getMaskedValue();
  }

  return tags.join(', ');
};

const getBaggage = (task) => {
  if (!task?.movement?.baggage) {
    return null;
  }

  return task.movement.baggage;
};

const getTags = (baggage) => {
  if (!baggage?.tags?.length) {
    return null;
  }

  if (shouldHideStringArray(baggage.tags)) {
    return null;
  }

  if (shouldMaskStringArray(baggage.tags)) {
    return [getMaskedValue()];
  }

  return baggage.tags;
};

const getBagCount = (baggage) => {
  if ((!baggage || !baggage?.numberOfCheckedBags || baggage?.numberOfCheckedBags === '0')) {
    return null;
  }

  if (shouldHideNumber(baggage.numberOfCheckedBags)) {
    return null;
  }

  if (shouldMaskNumber(baggage.numberOfCheckedBags)) {
    return getMaskedValue();
  }

  return baggage.numberOfCheckedBags;
};

const getBaggageWeight = (baggage) => {
  if (!baggage?.weight) {
    return null;
  }

  if (shouldHideString(baggage.weight)) {
    return null;
  }

  if (shouldMaskString(baggage.weight)) {
    return getMaskedValue();
  }

  const weight = sanitizeWeightIfRequired(baggage?.weight);
  if (!weight || [0, '0'].includes(weight)) {
    return null;
  }

  return weight;
};

const BaggageUtil = {
  bagCount: getBagCount,
  additionalContent: getAdditionalContent,
  get: getBaggage,
  tags: getTags,
  weight: getBaggageWeight,
  format: {
    bagCount: toFormattedBaggageCount,
    tags: toFormattedBaggageTags,
    weight: toFormattedBaggageWeight,
  },
};

export default BaggageUtil;

export {
  getBagCount,
  getBaggage,
  getBaggageWeight,
  getAdditionalContent,
  getTags,
  toFormattedBaggageCount,
  toFormattedBaggageTags,
  toFormattedBaggageWeight,
};
