// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Styling
import './SBTInfo.scss';

const SBTInfo = ({ selector }) => {
  const panelData = [
    {
      label: 'Inputting team',
      value: selector?.targetingTeam,
    },
    {
      label: 'Intelligence reference',
      value: selector?.intelligenceSource,
    },
    {
      label: 'Local reference',
      value: selector.localReference,
    },
  ];

  return (
    <div id={`sbt-${selector.localReference}-info`}>
      {panelData.map((item) => {
        return (
          <div key={item.label}>
            <p className="govuk-body-m govuk-!-font-weight-bold label">{item.label}</p>
            <p className="govuk-body force-wrap-text value">{item.value}</p>
          </div>
        );
      })}
    </div>
  );
};

SBTInfo.propTypes = {
  selector: PropTypes.shape({}).isRequired,
};

export default memo(SBTInfo);
