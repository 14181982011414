// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config(s)
import { DEPARTURE_STATUS } from '../../utils/constants';

// Component(s)
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const DepartureStatus = ({ id, departureStatus }) => {
  const config = DEPARTURE_STATUS[departureStatus];
  return (
    <ComponentWrapper show={config}>
      <Tag
        id={id}
        className={`tag tag--${config?.classname}`}
        text={config?.code}
      />
    </ComponentWrapper>
  );
};

export default DepartureStatus;
