// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../generics/Details';
import HeaderModule from '../../generics/HeaderModule';
import LabelValuePair from '../../generics/LabelValuePair';

// Util(s)
import ReferencesUtil from '../../../../../../../../utils/References/referencesUtil';

const References = ({ references }) => {
  const sgarId = ReferencesUtil.sgarId(references);
  const caseFileReference = ReferencesUtil.caseFileReference(references);

  if (!sgarId && !caseFileReference) {
    return null;
  }

  return (
    <div id="references-module" className="govuk-grid-column-full">
      <HeaderModule
        id="references-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="references-header" size="m">References</Heading>
              </div>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={sgarId}>
                <div className="grid-item">
                  <LabelValuePair
                    id="references-sgar-id"
                    label="sGAR ID"
                    value={sgarId}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={caseFileReference}>
                <div className="grid-item">
                  <LabelValuePair
                    id="references-case-file-reference"
                    label="Casefile reference"
                    value={caseFileReference}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
    </div>
  );
};

References.propTypes = {
  references: PropTypes.shape({}).isRequired,
};

export default References;
