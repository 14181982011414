import { Details } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Component(s)
import TaskActivityItem from './TaskActivityItem';

// Styling
import './TaskActivity.scss';

// TODO: Tests to be written once api payload has been defined
const TaskActivity = ({ activities }) => {
  if (!activities?.length) {
    return null;
  }

  return (
    <Details id="task-activity" summary="Task activity">
      {activities.map((activity) => <TaskActivityItem key={activity.id} id={`activity-${activity.id}`} activity={activity} />)}
    </Details>
  );
};

TaskActivity.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({})),
};

TaskActivity.defaultProps = {
  activities: [],
};

export default TaskActivity;
