// Global import(s)
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withCount } from 'pluralise';

// Config
import config from '../../../utils/config';

// Hook(s)
import useTimeCountDown from '../../../utils/Hooks/useTimeCountDown';

import './TimeCountDown.scss';

const TimeCountDown = ({ dateTime, summary = false, numberClassName, unitClassName }) => {
  const [days, hours, minutes] = useTimeCountDown(dateTime, config.countdownInterval);

  const getCountDownComponent = () => {
    const numberClassNames = classNames(numberClassName, `count-${summary}`);
    const unitClassNames = classNames(unitClassName);

    if (days >= 1) {
      return (
        <>
          <span {...numberClassNames && { className: numberClassNames }}>{days}</span>
          <span {...unitClassNames && { className: unitClassNames }}> {withCount(days, 'day', 'days')}</span>
        </>
      );
    }

    return (
      <>
        <span {...numberClassNames && { className: numberClassNames }}>{hours} </span>
        <span {...unitClassNames && { className: unitClassNames }}>{`${withCount(hours, 'hr', 'hrs')} `}</span>
        <span {...numberClassNames && { className: numberClassNames }}>{minutes} </span>
        <span {...unitClassNames && { className: unitClassNames }}>{`${withCount(minutes, 'min', 'mins')}`}</span>
      </>
    );
  };

  const countDownComponent = getCountDownComponent();

  return (
    <span className="time-count-down">
      {countDownComponent}
    </span>
  );
};

TimeCountDown.propTypes = {
  dateTime: PropTypes.instanceOf(Date),
  numberClassName: PropTypes.string,
  unitClassName: PropTypes.string,
};

TimeCountDown.defaultProps = {
  dateTime: null,
  numberClassName: null,
  unitClassName: null,
};

export default TimeCountDown;
