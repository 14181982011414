import pluralise from 'pluralise';

const getForm = () => {
  return {
    id: 'dismissTask',
    version: '0.0.1',
    name: 'dismissTask',
    title: 'Dismiss task',
    type: 'wizard',
    components: [],
    pages: [
      {
        id: 'reason-for-dismissing',
        name: 'reason-for-dismissing',
        title: 'Provide a reason for dismissing these X tasks',
        components: [
          {
            id: 'reasonForDismissing',
            fieldId: 'reason',
            type: 'radios',
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must indicate at least one reason for dismissing the task',
              },
            ],
            data: {
              options: [
                {
                  value: 'ARRIVED',
                  label: 'Arrived at port',
                  nested: [
                    {
                      id: 'arrivedReason',
                      fieldId: 'note',
                      label: 'Add note',
                      type: 'text',
                      className: 'govuk-!-width-two-thirds',
                    },
                  ],
                },
                {
                  value: 'FALSE_MATCH',
                  label: 'False match',
                  nested: [
                    {
                      id: 'falseMatchReason',
                      fieldId: 'note',
                      label: 'Add note',
                      type: 'text',
                      className: 'govuk-!-width-two-thirds',
                    },
                  ],
                },
                {
                  value: 'RESOURCE_REDIRECTED',
                  label: 'Resource redirected',
                  nested: [
                    {
                      id: 'resourceRedirectedReason',
                      fieldId: 'note',
                      label: 'Add note',
                      type: 'text',
                      className: 'govuk-!-width-two-thirds',
                    },
                  ],
                },
                {
                  value: 'OTHER',
                  label: 'Other',
                },
              ],
            },
          },
          {
            id: 'otherReason',
            fieldId: 'otherReason',
            label: 'Enter the reason for dismissal',
            type: 'text',
            required: true,
            className: 'govuk-!-width-two-thirds',
            show_when: [
              {
                field: './reason',
                op: 'eq',
                value: 'OTHER',
              },
            ],
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide the reason for dismissal',
              },
            ],
          },
        ],
        actions: [
          {
            type: 'submit',
            label: 'Finish',
            validate: true,
          },
          {
            type: 'cancel',
            label: 'Cancel',
            classModifiers: 'secondary',
          },
        ],
      },
    ],
  };
};

export default (numberOfTasks) => {
  const form = getForm();

  form.pages[0].title = pluralise.withCount(
    numberOfTasks,
    'Provide a reason for dismissing this task',
    'Provide a reason for dismissing these % tasks',
  );

  return form;
};
