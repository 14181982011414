import formHooks from './formHooks';
import getRenderer, { Renderers } from './getRenderer';
import getTisForm from './getTisForm';
import cleanSubmission from './cleanSubmission';
import { showAssigneeComponent } from './componentUtil';
import uploadDocuments from './uploadDocuments';

const FormUtils = {
  formHooks,
  getRenderer,
  getTisForm,
  Renderers,
  cleanSubmission,
  showAssigneeComponent,
  uploadDocuments,
};

export {
  Renderers,
};

export default FormUtils;
