// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { useAxiosInstance } from '../Axios/axiosInstance';
import { usePermission } from '../../context/PermissionContext';
import { useView } from '../../context/ViewContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Utils
import { toGetFilters } from '../../routes/Task/TaskList/helper/adaptFilters';
import { useTabs } from '../../context/TabContext';

const useFetchTargetingTeams = (movementModes, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const getTargetingTeams = async (modes) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return [];
    }

    return AxiosRequests.getTargetingTeams(apiClient, toGetFilters(modes, view));
  };

  const { data: targetingTeams } = useQuery({
    queryKey: [QUERY_KEYS.TARGETING_TEAMS],
    queryFn: () => getTargetingTeams(movementModes),
    refetchInterval: config.taskList.refreshInterval,
    initialData: [],
    enabled,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { targetingTeams };
};

export default useFetchTargetingTeams;
