import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';

import classNames from 'classnames';

// Components
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import Table from '../../../../../../components/Table/Table';

// Utils
import { GoodsUtil, TaskVersionUtil } from '../../../../../../utils';
import renderBlock from '../../../helper/common';

import FIELDS from '../../../helper/Fields';
import { STRINGS } from '../../../../../../utils/constants';

const toRows = (data) => {
  const rows = [];
  data.map((item) => {
    rows.push(item);
  });
  return rows;
};

const Goods = ({ version, versionDiff, classModifiers }) => {
  const goods = GoodsUtil.get(version);
  const goodsDiff = GoodsUtil.get(versionDiff);

  return (
    <div className={classNames('task-details-container', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Goods</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Description',
          {
            content: GoodsUtil.description(goods),
          },
          TaskVersionUtil.hasAny(goodsDiff, [FIELDS.description]),
        )}
        {renderBlock(
          'Hazardous',
          {
            content: GoodsUtil.format.hazardous(GoodsUtil.hazardous(goods)) || STRINGS.UNKNOWN,
          },
          TaskVersionUtil.hasAny(goodsDiff, [FIELDS.hazardous]),
        )}
      </div>
      <div className="govuk-task-details-grid-full-column govuk-!-margin-bottom-2">
        <ComponentWrapper show={GoodsUtil.hasWeight(goods)}>
          <Table
            className="weights-table"
            headings={['Weights', '']}
            rows={toRows(
              [
                ['Gross', GoodsUtil.format.grossWeight(goods)],
                ['Net', GoodsUtil.format.netWeight(goods)],
              ])}
          />
        </ComponentWrapper>
      </div>
    </div>
  );
};

export default Goods;
