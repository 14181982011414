import _ from 'lodash';
import { diff, patch } from 'jsondiffpatch';
import { diff as changesDiff } from 'diff-json';

const compareTaskVersions = (versionA, versionB, differencesCounts, differencesIdx) => {
  /*
   * versionA is the "latest" version in whatever iteration compareTaskVersions is getting called in
   * versionB is the "latest" version - 1 in whatever iteration compareTaskVersions is getting called in
   * differencesCounts is the array where the number of differences between each version is recorded
   */

  /*
   * Check if versionA is an object containing childSets, loops through the contents of a fieldSet then
   * feeds it's respective childSets down recursively (which will then be picked up by the final else if)
   */
  if (!Array.isArray(versionA) && typeof versionA === 'object' && versionA?.childSets && versionA?.contents) {
    versionA.contents.forEach((e, i) => {
      // Avoid HIDDEN types as they are not pertinent changes for the user, thus irrelevant for versioning
      if (e.content !== versionB?.contents[i]?.content && e.type !== 'HIDDEN') {
        e.type = `${e.type}-CHANGED`;
        differencesCounts[differencesIdx] += 1;
      }
    });
    compareTaskVersions(versionA.childSets, versionB.childSets, differencesCounts, differencesIdx);
  /*
   * Below condition is for when a fieldSet ONLY has a contents prop, no recursion required as there are no nested
   * arrays here and a direct comparison between versionA and versionB arrays is all that is needed
   */
  } else if (!Array.isArray(versionA) && typeof versionA === 'object' && versionA?.contents) {
    versionA.contents.forEach((e, i) => {
      if (e.content !== versionB?.contents[i]?.content && e.type !== 'HIDDEN') {
        e.type = `${e.type}-CHANGED`;
        differencesCounts[differencesIdx] += 1;
      }
    });
  /*
   * As metioned above, the below condition is for when childSets (which are arrays) get passed down into a recursive
   * call.
   */
  } else if (Array.isArray(versionA)) {
    versionA.forEach((e, i) => compareTaskVersions(e, versionB[i], differencesCounts, differencesIdx));
  }
};

const findAndUpdateTaskVersionDifferences = (taskVersions) => {
  /*
   * differencesCounts will always match the length of the taskVersions array - this allows a mapping between index positions
   * of the taskVersions and the differencesCounts
   */
  const differencesCounts = [0];
  let wasUpdated = false;
  if (taskVersions.length >= 2) {
    wasUpdated = true;
    for (let i = 0; i < taskVersions.length - 1; i += 1) {
      const taskVersionA = taskVersions[i].filter((fieldSet) => {
        return !['targetingIndicators', 'selectors', 'rules'].includes(fieldSet.propName);
      });
      const taskVersionB = taskVersions[i + 1].filter((fieldSet) => {
        return !['targetingIndicators', 'selectors', 'rules'].includes(fieldSet.propName);
      });
      differencesCounts.push(0);
      compareTaskVersions(taskVersionA, taskVersionB, differencesCounts, i);
    }
  }
  return {
    wasUpdated,
    differencesCounts,
  };
};

// Change data is generated using diff-json dependency.
const getChangesCount = (changeData) => {
  let count = 0;

  const getCount = (changes) => {
    changes?.forEach((change) => {
      if (Object.keys(change).includes('changes')) {
        getCount(change.changes);
      } else {
        // This node is the change
        count += 1;
      }
    });
  };

  getCount(changeData);
  return count;
};

const keyExists = (_haystack, dependency) => {
  const keys = [];
  const getKeys = (haystack) => {
    if (Array.isArray(haystack)) {
      haystack.forEach((item) => getKeys(item));
    } else if (typeof haystack === 'object' && !!haystack) {
      Object.keys(haystack).forEach((k) => {
        if (typeof haystack[k] === 'object' && haystack[k]) {
          keys.push(k);
          getKeys(haystack[k]);
        } else {
          keys.push(k);
        }
      });
    }
  };

  getKeys(_haystack);
  return keys.some((k) => k === dependency);
};

/**
 * Searches a haystack and returns true if any of the dependencies are found, otherwise false.
 *
 * @param haystack An object
 * @param dependencies The nodes which are being searched for within the haystack
 * @returns {boolean} true if any is found, false if none is found.
 */
const containsAtLeastOne = (haystack, dependencies) => {
  const result = [];
  dependencies.forEach((dependency) => result.push(keyExists(haystack, dependency)));
  return result.some((v) => v);
};

const patchData = (data, delta) => {
  if (!data || !delta) {
    return data;
  }
  const _data = _.cloneDeep(data);
  return patch(_data, delta);
};

const diffData = (left, right) => {
  return diff(left, right);
};

const getDiffForChangesCount = (left, right) => {
  return changesDiff(left, right);
};

const patchPreviousVersion = (currentVersion, previousVersionDiff) => {
  return {
    ...currentVersion,
    movement: {
      ...patchData(currentVersion?.movement, previousVersionDiff?.movement),
    },
  };
};

const isTheSame = (left, right) => {
  return _.isEqual(left, right);
};

const deleteNode = (data, remove) => {
  if (!data || !remove) {
    return data;
  }

  const _data = _.cloneDeep(data);
  if (!Array.isArray(remove)) {
    delete _data[remove];
    return _data;
  }

  remove.forEach((r) => {
    delete _data[r];
  });
  return _data;
};

const TaskVersionUtil = {
  countChanges: getChangesCount,
  delete: deleteNode,
  diff: diffData,
  diffForChanges: getDiffForChangesCount,
  hasAny: containsAtLeastOne,
  isSame: isTheSame,
  patch: patchData,
  patchPrevious: patchPreviousVersion,
  roro: {
    versionDifferences: findAndUpdateTaskVersionDifferences,
  },
};

export default TaskVersionUtil;

export {
  containsAtLeastOne,
  deleteNode,
  diffData,
  findAndUpdateTaskVersionDifferences,
  getChangesCount,
  getDiffForChangesCount,
  isTheSame,
  patchData,
  patchPreviousVersion,
};
