// Global import(s)
import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext({
  userGroupMember: null,
  setUserGroupMember: () => {},
  userRole: null,
  setUserRole: () => {},
  userGroups: {},
  setUserGroups: () => {},
  isRCCUOfficer: false,
  setIsRCCUOfficer: () => {},
  isHigherOfficer: false,
  setIsHigherOfficer: () => {},
  isInterceptingOfficer: false,
  setIsInterceptingOfficer: () => {},
});

const UserProvider = ({ children }) => {
  const [userGroupMember, setUserGroupMember] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userGroups, setUserGroups] = useState({});
  const [isRCCUOfficer, setIsRCCUOfficer] = useState(false);
  const [isHigherOfficer, setIsHigherOfficer] = useState(false);
  const [isInterceptingOfficer, setIsInterceptingOfficer] = useState(false);

  const value = useMemo(
    () => ({
      userGroupMember,
      setUserGroupMember,
      userRole,
      setUserRole,
      userGroups,
      setUserGroups,
      isRCCUOfficer,
      setIsRCCUOfficer,
      isHigherOfficer,
      setIsHigherOfficer,
      isInterceptingOfficer,
      setIsInterceptingOfficer,
    }),
    [userGroupMember, userRole, userGroups, isRCCUOfficer, isHigherOfficer, isInterceptingOfficer],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const useUser = () => useContext(UserContext);

export { UserProvider, UserContext, useUser };
