// Global import(s)
/**
 * React implementation of GOV.UK Design System Tabs
 * Demo: https://design-system.service.gov.uk/components/tabs/
 * Code: https://github.com/alphagov/govuk-frontend/blob/master/package/govuk/components/tabs/README.md
 */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

// Context(s)
import { useTabs } from '../../context/TabContext';
import { useNotification } from '../../context/NotificationContext';
import { useView } from '../../context/ViewContext';

// Config(s)
import { FILTER_TAB_ID } from '../../utils/constants';

// Component(s)
import TabNotificationIcon from '../TabNotification/TabNotificationIcon';

// Styling
import './Tabs.scss';

const Tabs = ({ id, idPrefix, className, title, items, onTabClick, hasFilters = false, ...props }) => {
  const { tabIndex, setSelectedFilterTab } = useTabs();
  const { notificationOnTab } = useNotification();
  const { isTaskListPage, isTargetsListPage } = useView();
  const indexToUse = (isTaskListPage || isTargetsListPage) && !hasFilters ? tabIndex : 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(indexToUse);
  const currentTab = items[currentTabIndex];
  const currentTabId = currentTab?.id || `${idPrefix}-${currentTabIndex}`;
  const panelIsReactElement = typeof currentTab?.panel === 'string' || Array.isArray(currentTab?.panel) || React.isValidElement(currentTab?.panel);
  const panelAttributes = panelIsReactElement ? {} : currentTab?.panel.attributes;
  const panelContents = panelIsReactElement ? currentTab?.panel : currentTab?.panel.children;

  useEffect(() => {
    if (currentTabId === FILTER_TAB_ID.TOOLS || currentTabId === FILTER_TAB_ID.FILTERS) {
      setSelectedFilterTab(currentTabId);
    }
  }, [currentTabId]);

  return (
    <div
      id={id}
      className={classNames('govuk-tabs', className)}
      {...props}
    >
      {title && <h2 className="govuk-tabs__title">{title}</h2>}
      {items.length > 0 && (
        <>
          <ul className="govuk-tabs__list">
            {items.map((item, index) => {
              const itemId = item.id || `${idPrefix}-${index}`;
              return (
                <li
                  key={`${item.id}-${index}`}
                  className={classNames('govuk-tabs__list-item', { 'govuk-tabs__list-item--selected': index === currentTabIndex })}
                >
                  <a
                    id={item.id}
                    className="govuk-tabs__tab"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTabIndex(index);
                      if (typeof onTabClick === 'function') {
                        onTabClick(item, index);
                      }
                    }}
                    href={`#${itemId}`}
                    {...item.attributes}
                    name={item.label}
                  >
                    {item.label}
                    {notificationOnTab?.[item.id] ? <TabNotificationIcon /> : null}
                  </a>
                </li>
              );
            })}
          </ul>
          <div
            key={currentTabId}
            className="govuk-tabs__panel"
            id={`${currentTabId}__panel`}
            {...panelAttributes}
          >
            {panelContents}
          </div>
        </>
      )}
    </div>
  );
};

export default Tabs;
