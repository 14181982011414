import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Config(s)
import { MODULES } from '../../../../../../../../utils/constants';

// Context(s)
import { useModule } from '../../../../../../../../context/ModuleContext';

// Components(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Contents from '../../content/Contents';
import HeaderModule from '../../generics/HeaderModule';
import ValueField from '../../generics/ValueField';

// Util(s)
import BaggageUtil from '../../../../../../../../utils/Baggage/baggageUtil';

const Baggage = ({ baggage }) => {
  const { registerModule, removeModule } = useModule();
  const weight = BaggageUtil.weight(baggage);
  const bagCount = BaggageUtil.bagCount(baggage);
  const tags = BaggageUtil.tags(baggage);
  const additionalContent = BaggageUtil.additionalContent(baggage);

  useEffect(() => {
    registerModule(MODULES.BAGGAGE);

    return () => removeModule(MODULES.BAGGAGE);
  }, []);

  return (
    <div id="baggage-module" className="govuk-grid-column-full">
      <HeaderModule
        id="baggage-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="baggage-header" size="m">Baggage</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={bagCount}>
                <div className="grid-item">
                  <ValueField id="baggage-total" value={BaggageUtil.format.bagCount(bagCount)} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={weight}>
                <div className="grid-item">
                  <ValueField id="baggage-weight" value={BaggageUtil.format.weight(weight)} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={tags?.length}>
                <div className="grid-item grid-item-span-2">
                  <ValueField id="baggage-tags" value={BaggageUtil.format.tags(tags)} />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Contents id="baggage-contents" contents={additionalContent} />
    </div>
  );
};

Baggage.propTypes = {
  baggage: PropTypes.shape({}),
};

Baggage.defaultProps = {
  baggage: null,
};

export default Baggage;
