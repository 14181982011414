// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import Aircraft from './aircraft/Aircraft';
import Persons from './passenger/general_aviation/Persons';
import References from './references/References';
import Route from './voyage/general_aviation/Route';

const GeneralAviationMovement = ({
  journey,
  persons,
  vehicle,
  references,
}) => {
  return (
    <>
      <Route journey={journey} />
      <Aircraft journey={journey} vehicle={vehicle} />
      <Persons persons={persons} journey={journey} />
      <References references={references} />
    </>
  );
};

GeneralAviationMovement.propTypes = {
  journey: PropTypes.shape({}),
  persons: PropTypes.arrayOf(PropTypes.shape({})),
  vehicle: PropTypes.shape({}),
  references: PropTypes.shape({}),
};

GeneralAviationMovement.defaultProps = {
  journey: null,
  persons: [],
  references: null,
  vehicle: null,
};

export default memo(GeneralAviationMovement);
