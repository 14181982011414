// Global import(s)
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTargets = (requestParams, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTarget } = usePermission();

  const getTargets = async (params) => {
    if (!canReadTarget) {
      return { data: [], totalTargets: 0 };
    }
    return AxiosRequests.getTargets(apiClient, params);
  };

  const { data, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TARGETS, requestParams],
    queryFn: () => getTargets(requestParams),
    initialData: { data: [], totalTargets: 0 },
    refetchInterval: config.targetsList.refreshInterval,
    enabled,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { targets: data.data, totalTargets: data.totalTargets, isLoading };
};

export default useFetchTargets;
