import React from 'react';
import { MOVEMENT_MODES, MOVEMENT_MODE_NAMES, STRINGS } from '../../../../utils/constants';

import { AircraftIcon, ShipIcon } from '../../../../components/Icon/Icon';

const getIcon = (movementMode) => {
  switch (movementMode) {
    case MOVEMENT_MODES.TOURIST:
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT:
      return <ShipIcon />;
    case MOVEMENT_MODES.AIR_PASSENGER:
      return <AircraftIcon />;
    default:
      // Add icons for fast parcel and air freight when available
      return null;
  }
};

const getText = (movementMode) => {
  return MOVEMENT_MODE_NAMES[movementMode] || STRINGS.UNKNOWN;
};

export const formatMovementMode = (movementMode) => {
  return (
    <>
      <div id="movement-mode-icon">{getIcon(movementMode)}</div>
      <div id="movement-mode">{getText(movementMode)}</div>
    </>
  );
};

export default formatMovementMode;
