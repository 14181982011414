import React from 'react';

import TargetSheet from '../TargetSheet';

const toTargetSheetTab = (targetSheet, isWithdrawnByTargeter = false) => {
  if (!targetSheet) {
    return null;
  }

  return {
    id: 'tab-target-sheet',
    label: isWithdrawnByTargeter ? 'Withdrawn target sheet' : 'Target sheet',
    panel: <TargetSheet targetSheet={targetSheet} />,
  };
};

export default toTargetSheetTab;
