import { CONTENT_TYPE, FORM_ACTIONS } from '../../utils/constants';

const toTextComponent = (type) => {
  if (type !== CONTENT_TYPE.TEXT) {
    return [];
  }

  return [
    {
      type: 'heading',
      size: 'l',
      content: 'Edit task detail addition',
    },
    {
      type: 'html',
      tagName: 'p',
      className: 'font--grey',
      content: 'Additions are editable until you either unclaim the task, issue a target or complete your assessment of this task. Photos are editable until a task is completed.',
    },
    {
      type: 'html',
      tagName: 'p',
      className: 'font--grey',
      content: 'Task detail additions are included on target sheets.',
    },
    {
      id: 'content',
      fieldId: 'content',
      label: 'Additions are editable until you either unclaim the task, issue a target or complete your assessment of this task. Photos are editable until a task is completed.',
      'aria-label': 'enter details or note',
      type: 'textarea',
      rows: 3,
      required: false,
    },
  ];
};

const toPassengerComponent = (type) => {
  if (type !== CONTENT_TYPE.PHOTO) {
    return [];
  }

  return [
    {
      type: 'heading',
      size: 'm',
      content: 'Edit photo',
    },
    {
      type: 'html',
      tagName: 'p',
      content: 'Photo will be included on any target sheet.',
    },
    {
      id: 'photograph',
      fieldId: 'photograph',
      type: 'container',
      components: [
        {
          'aria-label': 'choose file',
          id: 'photo',
          fieldId: 'photo',
          hideOptionalSuffix: true,
          type: 'file',
          fetchFile: true,
        },
        {
          id: 'source',
          fieldId: 'source',
          label: 'Enter the source of the photo',
          type: 'text',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Enter the source of the photo',
            },
          ],
          show_when: {
            field: './photo.type',
            op: 'contains',
            value: 'image',
          },
        },
        {
          id: 'approximateDateTaken',
          fieldId: 'approximateDateTaken',
          label: 'Enter an approximate date of the photo, if available',
          type: 'text',
          hideOptionalSuffix: true,
          show_when: {
            field: './photo.type',
            op: 'contains',
            value: 'image',
          },
        },
      ],
    },
  ];
};

const editDetail = (type) => {
  return {
    id: 'editDetail',
    version: '1.0.1',
    name: 'editDetail',
    title: 'Edit detail',
    type: 'form',
    components: [],
    pages: [{
      id: 'editDetail',
      name: 'editDetail',
      components: [
        ...toTextComponent(type),
        ...toPassengerComponent(type),
      ].filter((component) => !!component),
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Continue',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    }],
  };
};

export default editDetail;
