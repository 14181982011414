import { FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config
import { OPERATION } from '../../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../../context/TaskContext';

// Component(s)
import CommoditiesAddRecipient from './airpax/CommoditiesAddRecipient';
import CommoditiesChangeRecipient from './airpax/CommoditiesChangeRecipient';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LinkButton from '../../../../../../../components/Buttons/LinkButton';

// Util(s)
import { isCommoditiesSubMode, isGaSubMode } from '../../../../../../../utils/Task/taskUtil';

// Styling
import './ModifyRecipient.scss';

const ModifyRecipient = ({ actionType, formData, setFormData, backToCreateTarget, backTargetLabel, previouslySelectedRecipient, type, targetRecipientOptions }) => {
  const { subMode } = useTask();

  const toCommoditiesLabelByAction = () => {
    if (type === OPERATION.ADD) {
      return 'Add target sheet recipient';
    }

    if (type === OPERATION.CHANGE) {
      return 'Change target sheet recipient';
    }

    return null;
  };

  return (
    <div id="add-recipient-container">
      <LinkButton
        id="back-to-create-target"
        className="govuk-back-link"
        onClick={() => backToCreateTarget()}
      >
        {`Back to ${backTargetLabel}`}
      </LinkButton>
      <FormGroup
        id="add-recipient"
        label={(
          <>
            <ComponentWrapper show={isCommoditiesSubMode(subMode) || isGaSubMode(subMode)}>
              <Heading size="l">{toCommoditiesLabelByAction()}</Heading>
            </ComponentWrapper>
          </>
        )}
      >
        <ComponentWrapper show={isCommoditiesSubMode(subMode) || isGaSubMode(subMode)}>
          <ComponentWrapper show={type === OPERATION.ADD}>
            <CommoditiesAddRecipient
              previouslySelectedRecipient={previouslySelectedRecipient}
              onSave={backToCreateTarget}
              onCancel={backToCreateTarget}
              formData={formData}
              setFormData={setFormData}
              targetRecipientOptions={targetRecipientOptions}
              actionType={actionType}
            />
          </ComponentWrapper>
          <ComponentWrapper show={type === OPERATION.CHANGE}>
            <CommoditiesChangeRecipient
              previouslySelectedRecipient={previouslySelectedRecipient}
              onSave={backToCreateTarget}
              onCancel={backToCreateTarget}
              formData={formData}
              setFormData={setFormData}
              targetRecipientOptions={targetRecipientOptions}
              actionType={actionType}
            />
          </ComponentWrapper>
        </ComponentWrapper>
      </FormGroup>
    </div>
  );
};

ModifyRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  formData: PropTypes.shape({}).isRequired,
  backToCreateTarget: PropTypes.func.isRequired,
  backTargetLabel: PropTypes.string,
  previouslySelectedRecipient: PropTypes.string,
  type: PropTypes.string.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ModifyRecipient.defaultProps = {
  backTargetLabel: 'Create target',
  previouslySelectedRecipient: null,
  targetRecipientOptions: [],
};

export default memo(ModifyRecipient);
