import { Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import './ValueField.scss';

const ValueField = ({ value, classNames: _classNames, ...props }) => {
  const DEFAULT_CLASS = 'value-field';
  const classes = Utils.classBuilder(DEFAULT_CLASS, null, _classNames);

  if (!value) {
    return null;
  }

  return <p {...props} className={classes()}>{value}</p>;
};

ValueField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

ValueField.defaultProps = {
  value: null,
  classNames: null,
};

export default ValueField;
