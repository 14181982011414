// Global import(s)
import React from 'react';

// Component(s)
import TaskVersions from '../TaskVersions';

const toTaskVersionsTab = (versions, taskId) => {
  return {
    id: 'tab-task-details',
    label: 'Task details',
    panel: (
      <TaskVersions
        taskVersions={versions}
        taskId={taskId}
      />
    ),
  };
};

export default toTaskVersionsTab;
