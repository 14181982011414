/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import { Link } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

import './Accordion.scss';

const Accordion = ({ id, classnames, items }) => {
  const [expandAll, setExpandAll] = useState(false);

  return (
    <div id={id} className={classNames('custom-accordion', 'govuk-!-margin-bottom-4', classnames)}>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full govuk-!-margin-bottom-2">
          <Link
            id="custom-accordion--control-all"
            className="custom-accordion--control-all govuk-!-font-size-16"
            onClick={() => setExpandAll(!expandAll)}
          >
            <span className={expandAll ? 'custom-accordion-nav__chevron' : 'custom-accordion-nav__chevron--down'} />
            <span
              className="custom-accordion-control-label govuk-!-margin-left-1"
            >
              {expandAll ? 'Hide all sections' : 'Show all sections'}
            </span>
          </Link>
        </div>
      </div>
      {items.map((item, index) => (
        <AccordionItem
          key={item.id || index}
          id={item.id}
          item={item}
          expanded={expandAll}
        />
      ))}
    </div>
  );
};

export default Accordion;
