import React from 'react';
import PropTypes from 'prop-types';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LabelField from './LabelField';
import ValueField from './ValueField';

// Styling
import './LabelValuePair.scss';

const LabelValuePair = ({ id, label, value, secondaryValue }) => {
  return (
    <div className="label-value-pair">
      <LabelField id={`${id}-label`} classNames="govuk-body-xs font--grey" value={label} />
      <ValueField id={`${id}-value`} classNames="govuk-body" value={value} />
      <ComponentWrapper show={secondaryValue}>
        <ValueField id={`${id}-value-secondary`} classNames="govuk-body-xs font--grey value-field-secondary" value={secondaryValue} />
      </ComponentWrapper>
    </div>
  );
};

LabelValuePair.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondaryValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

LabelValuePair.defaultProps = {
  label: null,
  value: null,
  secondaryValue: null,
};

export default LabelValuePair;
