import React from 'react';

import { LOG_TYPE } from '../../../utils/constants';

import Log from '../Log';
import OutcomeLog from '../OutcomeLog';
import LinkLog from '../LinkLog';

const getLogByType = (activity) => {
  switch (activity?.content?.type) {
    case LOG_TYPE.TARGET_OUTCOME:
      return <OutcomeLog key={activity?.id} activity={activity} />;
    case LOG_TYPE.LINK:
      return <LinkLog key={activity.id} activity={activity} />;
    default:
      return <Log key={activity?.id} activity={activity} />;
  }
};

export default getLogByType;
