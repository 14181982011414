import React from 'react';
import classNames from 'classnames';

import { CommonUtil, DocumentUtil, EnrichmentUtil, PersonUtil, TaskVersionUtil } from '../../../../../../utils';
import { toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams, toEntitySearchLinkContent } from '../../../helper/common';

import FIELDS from '../../../helper/Fields';

import EnrichmentCount from './EnrichmentCount';

const Occupant = ({ person,
  personDiff,
  personPosition = 1,
  document,
  documentDiff,
  departureTime,
  journeyDiff,
  labelText,
  classModifiers,
  version }) => {
  const toClassNames = (className, changed) => {
    return classNames('govuk-!-margin-bottom-0', className, changed && 'task-versions--highlight');
  };

  const toLightClassNames = (changed) => {
    return toClassNames('font__light', changed);
  };

  const toBoldClassNames = (changed) => {
    return toClassNames('font__bold', changed);
  };

  const toPersonDetails = () => {
    const personChanged = TaskVersionUtil.hasAny(personDiff, [FIELDS.dateOfBirth, FIELDS.gender, FIELDS.full, FIELDS.nationality]);
    const name = PersonUtil.fullname(person);
    const nameChanged = TaskVersionUtil.hasAny(personDiff, [FIELDS.full]);
    const entitySearchUrl = CommonUtil.entitySearchUrl(person);
    const description = `${PersonUtil.gender(person)}, born ${PersonUtil.dob(person)}, ${PersonUtil.nationality(person)}`;
    const descriptionChanged = TaskVersionUtil.hasAny(personDiff, [FIELDS.gender, FIELDS.dateOfBirth, FIELDS.nationality]);
    return (
      <div className="govuk-!-margin-bottom-2">
        <EnrichmentCount
          id={`occupant-${personPosition}`}
          movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(person))}
          examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(person))}
          seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(person))}
        />
        <p className={toLightClassNames(personChanged)}>
          {labelText}
        </p>
        <div className="flex-content-container">
          <span className={`${toBoldClassNames(nameChanged)} govuk-!-margin-right-1`}>{toEntitySearchLinkContent({ content: name, url: entitySearchUrl })}</span>
        </div>
        <p className={classNames('govuk-!-margin-bottom-0', 'previous-task-link')}>
          {toPreviousTaskLink(toPreviousTaskLinkParams(version, person))}
        </p>
        <p className={classNames('govuk-!-margin-bottom-0', 'previous-movement-link')}>
          {toPreviousMovementLink(person)}
        </p>
        <p className={toLightClassNames(descriptionChanged)}>
          {description}
        </p>
      </div>
    );
  };

  const toDocumentDetails = () => {
    const documentType = DocumentUtil.docTypeFormatted(document);
    const documentChanged = TaskVersionUtil.hasAny(documentDiff, [FIELDS.number, FIELDS.countryOfIssue]);
    const documentNumber = DocumentUtil.docNumber(document);
    const documentNumberChanged = TaskVersionUtil.hasAny(documentDiff, [FIELDS.number]);
    const entitySearchUrl = CommonUtil.entitySearchUrl(document);
    const countryOfIssue = DocumentUtil.docCountry(document, true);
    const countryOfIssueChanged = TaskVersionUtil.hasAny(documentDiff, [FIELDS.countryOfIssue]);
    return (
      <div className="govuk-!-margin-bottom-2">
        <p className={toLightClassNames(documentChanged)}>
          {documentType}
        </p>
        <div className="flex-content-container">
          <span className={`${toBoldClassNames(documentNumberChanged)} govuk-!-margin-right-1`}>{toEntitySearchLinkContent({ content: documentNumber, url: entitySearchUrl })}</span>
        </div>
        <p className="govuk-!-margin-bottom-0 previous-task-link">
          {toPreviousTaskLink(toPreviousTaskLinkParams(version, document))}
        </p>
        <p className={toLightClassNames(countryOfIssueChanged)}>
          {countryOfIssue}
        </p>
      </div>
    );
  };

  const toValidityDetails = () => {
    const documentJourneyDiff = { ...documentDiff, ...journeyDiff?.departure };
    const validityChanged = TaskVersionUtil.hasAny(documentJourneyDiff, [FIELDS.expiry, FIELDS.time]);
    const expiry = DocumentUtil.docExpiry(document, false);
    const expiryChanged = TaskVersionUtil.hasAny(documentJourneyDiff, [FIELDS.expiry]);
    const validityDuration = DocumentUtil.calculateExpiry(DocumentUtil.docExpiryDate(document), departureTime);
    return (
      <div className="govuk-!-margin-bottom-2">
        <p className={toLightClassNames(validityChanged)}>
          Validity
        </p>
        <p className={toBoldClassNames(expiryChanged)}>
          {expiry}
        </p>
        <p className={toLightClassNames(validityChanged)}>
          {validityDuration}
        </p>
      </div>
    );
  };

  return (
    <div className={classNames(classModifiers)}>
      {toPersonDetails()}
      <div className="govuk-task-details-grid-column">
        {toDocumentDetails()}
        {toValidityDetails()}
      </div>
    </div>
  );
};

export default Occupant;
