import React from 'react';

import {
  BookingUtil,
  DocumentUtil,
  JourneyUtil,
  MovementUtil,
  PersonUtil,
  VehicleUtil,
} from '../../../../../utils';
import DatetimeUtil from '../../../../../utils/Datetime/datetimeUtil';

import { ICON, ICON_MAPPING, MOVEMENT_MODES } from '../../../../../utils/constants';

import TouristMovementVehicle from './TouristMovementVehicle';
import TouristMovementFootPassengers from './TouristMovementFootPassengers';

const TouristMovementSection = ({ targetTask }) => {
  const person = PersonUtil.get(targetTask);
  const otherPersons = PersonUtil.getOthers(targetTask);
  const document = DocumentUtil.get(person);
  const vehicle = VehicleUtil.get(targetTask);
  const booking = BookingUtil.get(targetTask);
  const journey = JourneyUtil.get(targetTask);
  const mode = MovementUtil.movementMode(targetTask);
  const description = MovementUtil.iconDescription(targetTask);
  const iconFromDescription = ICON_MAPPING[mode]?.[description];
  const datetimeList = DatetimeUtil.toList(BookingUtil.bookedAt(booking), JourneyUtil.departureTime(journey));

  return (
    <>
      {mode === MOVEMENT_MODES.TOURIST && iconFromDescription === ICON.CAR && (
        <TouristMovementVehicle
          person={person}
          vehicle={vehicle}
          booking={booking}
          datetimeList={datetimeList}
          otherPersons={otherPersons}
        />
      )}
      {mode === MOVEMENT_MODES.TOURIST
        && (iconFromDescription === ICON.INDIVIDUAL || iconFromDescription === ICON.GROUP) && (
        <TouristMovementFootPassengers
          person={person}
          vehicle={vehicle}
          booking={booking}
          document={document}
          datetimeList={datetimeList}
          otherPersons={otherPersons}
        />
      )}
    </>
  );
};

export default TouristMovementSection;
