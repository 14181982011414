/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from '@ukhomeoffice/cop-react-components';

const AccordionItem = ({ id, className, item, headingLevel = 2, expanded: _expanded, ...attrs }) => {
  const [expanded, setExpanded] = useState(item.expanded || false);
  const Heading = `h${headingLevel}`;

  useEffect(() => {
    setExpanded(_expanded);
  }, [_expanded]);

  return (
    <div className="custom-accordion--container">
      <div
        {...attrs}
        className="custom-accordion--section govuk-!-padding-bottom-6"
        aria-hidden="true"
        role="button"
        tabIndex={0}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <button className="custom-accordion--control">
              <Heading
                id="custom-accordion--header"
                className="custom-accordion--section-heading govuk-!-margin-top-2 govuk-!-margin-bottom-0"
              >
                {item.heading}
              </Heading>
            </button>
          </div>
          <div className="govuk-grid-column-one-third">
            {item.summary && (
              <div className="custom-accordion--section-summary govuk-body govuk-!-margin-bottom-1">
                {item.summary}
              </div>
            )}
          </div>
        </div>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <Link
              id="custom-accordion--control"
              className="custom-accordion--control govuk-!-font-size-16 govuk-!-margin-bottom-1"
              onClick={() => setExpanded(!expanded)}
            >
              <span className={expanded ? 'custom-accordion-nav__chevron' : 'custom-accordion-nav__chevron--down'} />
              <span className="custom-accordion-control-label govuk-!-margin-left-1">{expanded ? 'Hide' : 'Show'}</span>
            </Link>
          </div>
        </div>
        {expanded && (
          <div
            className="custom-accordion--section-content govuk-!-margin-top-5 govuk-!-padding-bottom-0"
            role="complementary"
          >
            {item.children}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionItem;
