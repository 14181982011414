// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { DATE_FORMATS } from '../../../../../../../utils/constants';

// Component(s)
import PncCheck from '../../checks/PncCheck';
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import DateTimeUtil from '../../../../../../../utils/Datetime/datetimeUtil';
import TargetDocumentUtil from '../../../../../../../utils/Document/Target/targetDocumentUtil';
import TargetPersonUtil from '../../../../../../../utils/Person/Target/targetPersonUtil';

const PersonTemplate = ({ person, document, adjustedPersonPosition }) => {
  return (
    <>
      <dl className="govuk-summary-list">
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-added-to-watchlist`}
          label="Has this person been added to the WI?"
          value={TargetPersonUtil.watchList.added(person)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-watchlist-reference-number`}
          label="WI (IRN) Individual Reference Number"
          value={TargetPersonUtil.watchList.referenceNumber(person)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-nationality`}
          label="Nationality"
          value={TargetPersonUtil.nationality(person)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-date-of-birth`}
          label="Date of birth"
          value={DateTimeUtil.format(TargetPersonUtil.dob(person), DATE_FORMATS.SHORT)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-sex`}
          label="Sex"
          value={TargetPersonUtil.formattedGender(person)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-document-type`}
          label="Travel document type"
          value={TargetDocumentUtil.docTypeDescription(document)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-document-number`}
          label="Travel document number"
          value={TargetDocumentUtil.docNumber(document)}
        />
        <SummaryListRow
          id={`passenger-${adjustedPersonPosition}-document-expiry`}
          label="Travel document expiry"
          value={DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), DATE_FORMATS.SHORT)}
        />
      </dl>
      <PncCheck person={person} adjustedPersonPosition={adjustedPersonPosition} />
    </>
  );
};

PersonTemplate.propTypes = {
  person: PropTypes.shape({}),
  document: PropTypes.shape({}),
  adjustedPersonPosition: PropTypes.number,
};

PersonTemplate.defaultProps = {
  person: null,
  document: null,
  adjustedPersonPosition: 1,
};
export default memo(PersonTemplate);
