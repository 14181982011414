// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config(s)
import FIELDS from '../../helper/Fields';

// Utils(s)
import renderBlock, { renderRow } from '../../helper/common';
import { BaggageUtil, TaskVersionUtil } from '../../../../../utils';

const Baggage = ({ version, versionDiff }) => {
  const baggage = BaggageUtil.get(version);
  const baggageDiff = BaggageUtil.get(versionDiff);
  const patchedBaggage = TaskVersionUtil.patch(baggage, baggageDiff);
  const numberOfCheckedBags = BaggageUtil.bagCount(baggage);
  const formattedNumberOfCheckedBags = BaggageUtil.format.bagCount(numberOfCheckedBags);
  const weight = BaggageUtil.weight(baggage);
  const formattedWeight = BaggageUtil.format.weight(weight);
  const tags = BaggageUtil.tags(baggage);
  const patchedTags = BaggageUtil.tags(patchedBaggage);
  const formattedTags = BaggageUtil.format.tags(tags);

  return (
    <div className="task-details-container">
      <Heading className="govuk-!-margin-top-0" size="m">Baggage</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Checked bags',
          {
            content: formattedNumberOfCheckedBags,
          },
          TaskVersionUtil.hasAny(baggageDiff, [FIELDS.numberOfCheckedBags]),
        )}
        {renderBlock(
          'Total weight',
          {
            content: formattedWeight,
          },
          TaskVersionUtil.hasAny(baggageDiff, [FIELDS.weight]),
        )}
      </div>
      <div>
        {renderRow(
          'Tag number',
          {
            content: formattedTags,
          },
          !TaskVersionUtil.isSame(tags, patchedTags),
        )}
      </div>
    </div>
  );
};

export default Baggage;
