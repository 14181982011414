// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const AccordionRow = ({ id, label, value, ...props }) => {
  return (
    <div className="govuk-summary-list__row" key={label} {...props}>
      <dt id={`${id}-label`} className="govuk-summary-list__key">{label}</dt>
      <dd id={`${id}-value`} className="govuk-summary-list__value accordion_item">{value}</dd>
      <dd id={`${id}-actions`} className="govuk-summary-list__actions" />
    </div>
  );
};

AccordionRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AccordionRow.defaultProps = {
  value: null,
};

export default memo(AccordionRow);
