// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { DATE_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import Accordion from '../../../../../../components/Accordion/Accordion';
import PassportPhoto from '../../../../../Task/TaskDetails/components/shared/PassportPhoto';
import PncCheck from '../checks/PncCheck';
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import {
  DateTimeUtil,
  TargetBaggageUtil,
  TargetCommonUtil,
  TargetDocumentUtil,
  TargetPersonUtil,
} from '../../../../../../utils';
import { toPncWarningTag } from '../../../../../Task/TaskDetails/components/shared/helper/adaptPncContent';

const AirpaxTargetInformation = ({ persons, mode }) => {
  const toPerson = (index, person) => {
    const adjustedPersonPosition = index + 1;
    const document = TargetDocumentUtil.doc(person);
    const baggage = TargetBaggageUtil.get(person);
    const photoContent = TargetCommonUtil.additionalPhotoContent(person);

    return {
      id: `person-${adjustedPersonPosition}`,
      heading: `${TargetPersonUtil.firstName(person)} ${TargetPersonUtil.lastName(person)}${index !== 0 ? ' (Associated passenger)' : ''}`,
      summary: (
        <div className="section-right">
          {toPncWarningTag(person)}
        </div>
      ),
      children: (
        <div>
          <dl className="govuk-summary-list">
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-photograph`}
              label="Passport photo"
              value={<PassportPhoto photoContent={photoContent} />}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-added-to-watchlist`}
              label="Has this person been added to the WI?"
              value={TargetPersonUtil.watchList.added(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-watchlist-reference-number`}
              label="WI (IRN) Individual Reference Number"
              value={TargetPersonUtil.watchList.referenceNumber(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-nationality`}
              label="Nationality"
              value={TargetPersonUtil.nationality(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-date-of-birth`}
              label="Date of birth"
              value={DateTimeUtil.format(TargetPersonUtil.dob(person), DATE_FORMATS.SHORT)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-sex`}
              label="Sex"
              value={TargetPersonUtil.formattedGender(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-document-type`}
              label="Travel document type"
              value={TargetDocumentUtil.docTypeDescription(document)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-document-number`}
              label="Travel document number"
              value={TargetDocumentUtil.docNumber(document)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-document-expiry`}
              label="Travel document expiry"
              value={DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), DATE_FORMATS.SHORT)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-passenger-status`}
              label="Passenger status"
              value={TargetPersonUtil.passengerStatus(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-seat-number`}
              label="Seat number"
              value={TargetPersonUtil.seatNumber(person)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-checked-bags`}
              label="Checked bags"
              value={TargetBaggageUtil.totalCheckedBags(baggage)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-total-baggage-weight`}
              label="Total weight"
              value={TargetBaggageUtil.totalWeight(baggage)}
            />
            <SummaryListRow
              id={`passenger-${adjustedPersonPosition}-baggage-tag-numbers`}
              label="Tag numbers"
              value={TargetBaggageUtil.tags(baggage)}
            />
          </dl>
          <PncCheck person={person} personPosition={index} mode={mode} />
        </div>
      ),
      expanded: true,
    };
  };

  if (!persons || !persons?.length) {
    return null;
  }

  return (
    <div id="passengers-details" className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <Heading size="l">People</Heading>
        <Accordion
          className="target-info-accordion"
          id="airpax-target-info-accordion"
          items={[
            ...persons.map((person, index) => toPerson(index, person)),
          ]}
        />
      </div>
    </div>
  );
};

AirpaxTargetInformation.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})),
  mode: PropTypes.string.isRequired,
};

AirpaxTargetInformation.defaultProps = {
  persons: null,
};

export default memo(AirpaxTargetInformation);
