const POSITIVE_WARNING_STATUS = 'Yes';
const NEGATIVE_WARNING_STATUS = 'No';
export const WARNING_TYPES = {
  CONTAGION: 'CONTAGION', // TODO: Deprecated?
  FIREARMS: 'FIREARMS',
  SELF_HARM: 'SELF_HARM',
  VIOLENCE: 'VIOLENCE',
  WEAPONS: 'WEAPONS',
  OTHER: 'OTHER',
  DRUG_USE: 'DRUG_USE',
  MISSING: 'MISSING',
  WANTED: 'WANTED',
  MENTAL: 'MENTAL',
};

const getIndicatorMatches = (selector) => {
  if (!selector?.indicatorMatches || !selector?.indicatorMatches?.length) {
    return [];
  }

  return selector.indicatorMatches;
};

const getThreatIndicators = (informationSheet) => {
  return informationSheet?.risks?.targetingIndicators || [];
};

const getSelectors = (informationSheet) => {
  return informationSheet?.risks?.selectors || null;
};

const getSelector = (informationSheet) => {
  return informationSheet?.risks?.selector || null;
};

const getSelectorCategory = (selector) => {
  return selector?.category || null;
};

const getWarningStatus = (selector) => {
  return selector?.warning?.status || NEGATIVE_WARNING_STATUS;
};

const getRisks = (informationSheet) => {
  return informationSheet?.risks || null;
};

const formatWarnings = (type) => {
  switch (type) {
    case WARNING_TYPES.CONTAGION: // TODO: Deprecated?
      return 'contagion';
    case WARNING_TYPES.FIREARMS:
      return 'firearms';
    case WARNING_TYPES.SELF_HARM:
      return 'self harm';
    case WARNING_TYPES.VIOLENCE:
      return 'violence';
    case WARNING_TYPES.WEAPONS:
      return 'weapons';
    case WARNING_TYPES.DRUG_USE:
      return 'drug use';
    case WARNING_TYPES.MISSING:
      return 'missing';
    case WARNING_TYPES.WANTED:
      return 'wanted by police';
    case WARNING_TYPES.MENTAL:
      return 'mental health issues';
    case WARNING_TYPES.OTHER:
      return 'other risk';
    default:
      return `unknown warning (${type})`;
  }
};

const getWarning = (selector) => {
  return selector?.warning || undefined;
};

const getPncWarning = (informationSheet) => {
  const risks = getRisks(informationSheet);
  return risks.hasPncWarnings ? POSITIVE_WARNING_STATUS : NEGATIVE_WARNING_STATUS;
};

const TargetRisksUtil = {
  category: getSelectorCategory,
  selector: getSelector,
  selectors: getSelectors,
  indicators: getThreatIndicators,
  indicatorMatches: getIndicatorMatches,
  pncWarning: getPncWarning,
  risks: getRisks,
  warning: {
    get: getWarning,
    status: getWarningStatus,
    format: formatWarnings,
  },
};

export default TargetRisksUtil;

export {
  formatWarnings,
  getIndicatorMatches,
  getPncWarning,
  getRisks,
  getSelector,
  getSelectorCategory,
  getSelectors,
  getThreatIndicators,
  getWarning,
  getWarningStatus,
};
