import { capitalizeFirstLetter } from '../../String/stringUtil';

// The nominal types are a hard coded portion of the form

export const NOMINAL_VALUE = {
  AIR_WAYBILL: {
    value: 'airWaybill',
    label: 'Air Waybill',
  },
  BANK: {
    value: 'bank',
    label: 'Bank Account',
  },
  IDENTITY: {
    value: 'identity',
    label: 'Identity Document',
  },
  POSTAL: {
    value: 'postal',
    label: 'Postal Address',
  },
};

const adaptTypeToLabel = (value) => {
  switch (value) {
    case NOMINAL_VALUE.AIR_WAYBILL.value: {
      return NOMINAL_VALUE.AIR_WAYBILL.label;
    }
    case NOMINAL_VALUE.BANK.value: {
      return NOMINAL_VALUE.BANK.label;
    }
    case NOMINAL_VALUE.IDENTITY.value: {
      return NOMINAL_VALUE.IDENTITY.label;
    }
    case NOMINAL_VALUE.POSTAL.value: {
      return NOMINAL_VALUE.POSTAL.label;
    }
    default: {
      return capitalizeFirstLetter(value);
    }
  }
};

export default adaptTypeToLabel;
