import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import airports from '@nitro-land/airport-codes';

// Config(s)
import config from '../../config';
import { UNKNOWN_TIME_DATA } from '../../constants';

// Util(s)
import { getHiddenValue, shouldHideDate } from '../../Hiding/hidingUtil';
import validateDate from '../Validation/validateDate';
import { isNotNumber } from '../../Number/numberUtil';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.updateLocale('en', { relativeTime: config.dayjsConfig.relativeTime });

const getDayJsDate = (date, inputFormat) => {
  return dayjs.utc(date, inputFormat);
};

const toEndOfDay = (date, inputFormat, outputFormat) => {
  if (!date) {
    return null;
  }

  return getDayJsDate(date, inputFormat).endOf('day').format(outputFormat);
};

/**
 * Converts a duration (in seconds) to time components e.g. hours, minutes, seconds.
 * @param duration Time in seconds
 * @returns {{hours: number, seconds: number, minutes: number}|{hours: null, seconds: null, minutes: null}} A time object made up of hours, minutes and seconds.
 */
const toHoursMinutesAndSeconds = (duration) => {
  if ((!duration && duration !== 0) || isNotNumber(duration)) {
    return UNKNOWN_TIME_DATA;
  }

  const totalMinutes = Math.floor(duration / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor(duration % 60);

  return { hours, minutes, seconds };
};

/**
 * Converts a UTC datetime to a local Date object.
 *
 * @param {string} datetime The UTC date.
 * @param {string} timeZone The timezone to convert the datetime to.
 * @returns {dayjs.Dayjs|null} A Date object or null if {@param timeZone} is invalid.
 */
const toLocalDate = (datetime, timeZone) => {
  if (!datetime || !validateDate(datetime) || !timeZone) {
    return null;
  }

  if (datetime === getHiddenValue() || shouldHideDate(datetime)) {
    return null;
  }

  return dayjs.utc(datetime).tz(timeZone);
};

/**
 * Parses a given datetime to a Date object.
 *
 * @param {string} datetime The UTC date.
 * @returns {dayjs.Dayjs|null} A Date object.
 */
const parseDate = (datetime) => {
  if (!datetime || !validateDate(datetime)) {
    return null;
  }

  if (datetime === getHiddenValue() || shouldHideDate(datetime)) {
    return null;
  }

  return dayjs(datetime);
};

/**
 * Get a formatted local date string.
 *
 * @param {string} datetime The UTC date.
 * @param {string} timeZone The timezone to convert the datetime to.
 * @param {string | null} format The output date format.
 * @returns {string|null} A formatted date string or null when any of the provided params are invalid.
 */
const toFormattedLocalDate = (datetime, timeZone, format = null) => {
  if (!datetime || !validateDate(datetime) || !format || !timeZone) {
    return null;
  }

  if (shouldHideDate(datetime)) {
    return null;
  }

  if (datetime === getHiddenValue()) {
    return null;
  }
  return toLocalDate(datetime, timeZone).format(format);
};

/**
 * Get the iata time zone
 *
 * @param {string} iata The location to extract the timezone from.
 * @returns {*|string|null} A timezone (or null if {@param iata} is not provided).
 */
const getTimezoneWithIata = (iata) => {
  return airports.findWhere({ iata })?.get('tz') || null;
};

/**
 * Get the icao time zone
 *
 * @param {string} icao The location to extract the timezone from.
 * @returns {*|string|null} A timezone (or null if {@param icao} is not provided).
 */
const getTimezoneWithIcao = (icao) => {
  return airports.findWhere({ icao })?.get('tz') || null;
};

/**
 * Get the time zone from either icao or iata code.
 *
 * @param {string | null} locationCode The location code.
 * @returns {string | null} A timezone based on provided tokens.
 */
const getTimezone = (locationCode) => {
  if (!locationCode) {
    return null;
  }

  return getTimezoneWithIata(locationCode) || getTimezoneWithIcao(locationCode) || null;
};

/**
 * Return a formatted date according to the tokens provided.
 *
 * @param {string} datetime A UTC date.
 * @param {string} timeZone The target time zone.
 * @param {string || null} format The output format (if not provided, the default UTC format will be used.
 * Current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00')
 * @returns {null|string} A formatted date string.
 */
const toFormattedTimezoneDate = (datetime, timeZone, format = null) => {
  if (!datetime || !validateDate(datetime) || !timeZone) {
    return null;
  }

  if (shouldHideDate(datetime)) {
    return null;
  }

  if (datetime === getHiddenValue()) {
    return null;
  }

  return dayjs.utc(datetime).tz(timeZone).format(format);
};

/**
 * Return a formatted date according to the tokens provided.
 *
 * @param {string} datetime A UTC date.
 * @param {string} fromTimeZone The origin time zone.
 * @param {string} toTimeZone The destination time zone.
 * @param {string || null} format The output format (if not provided, the default UTC format will be used.
 * Current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00')
 * @returns {null|string} A formatted date string.
 */
const fromOriginToDestinationTimezoneDate = (datetime, fromTimeZone, toTimeZone, format = null) => {
  if (!datetime || !validateDate(datetime) || !fromTimeZone || !toTimeZone) {
    return null;
  }

  if (shouldHideDate(datetime)) {
    return null;
  }

  if (datetime === getHiddenValue()) {
    return null;
  }

  return dayjs.tz(datetime, fromTimeZone).tz(toTimeZone).format(format);
};

const parseAsUTCDate = (datetime, inputFormat = null) => {
  if (!datetime) {
    return null;
  }

  if (datetime === getHiddenValue() || shouldHideDate(datetime)) {
    return null;
  }

  if (!inputFormat) {
    return dayjs.utc(datetime);
  }

  return dayjs.utc(datetime, inputFormat);
};

const toUTCFormattedDate = (datetime, inputFormat, outputFormat) => {
  if (!datetime || !outputFormat) {
    return null;
  }

  if (!validateDate(datetime)) {
    return null;
  }

  if (datetime === getHiddenValue() || shouldHideDate(datetime)) {
    return null;
  }

  return dayjs.utc(datetime, inputFormat).format(outputFormat);
};

const DateTimeUtil = {
  timezone: getTimezone,
  endOfDay: toEndOfDay,
  local: {
    date: toLocalDate,
    format: toFormattedLocalDate,
  },
  asDate: parseDate,
  parse: {
    asUTC: parseAsUTCDate,
  },
  format: {
    asUTC: toUTCFormattedDate,
    toTimezone: toFormattedTimezoneDate,
    fromOriginToDestinationTimezone: fromOriginToDestinationTimezoneDate,
  },
  validate: {
    date: validateDate,
  },
  convert: {
    hoursMinutesSeconds: toHoursMinutesAndSeconds,
  },
};

export default DateTimeUtil;

export {
  getTimezone,
  toFormattedTimezoneDate,
  parseDate,
  toUTCFormattedDate,
  parseAsUTCDate,
  toEndOfDay,
  toHoursMinutesAndSeconds,
  toLocalDate,
  validateDate,
};
