// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Context(s)
import { useNotification } from '../../../../../../../context/NotificationContext';
import { useTasks } from '../../../../../../../context/TasksContext';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ModeTaskDetail from './ModeTaskDetail';
import ModeTaskHeader from './ModeTaskHeader';
import TaskNotification from '../../../../../TaskList/components/shared/TaskNotification';

// Styling
import './TaskCard.scss';

const TaskCard = ({ task, ...props }) => {
  const { notification } = useNotification();
  const { taskIdsToRemove } = useTasks();

  return (
    <div {...props} className="task-card">
      <ComponentWrapper show={notification && taskIdsToRemove.includes(task.id)}>
        <TaskNotification />
      </ComponentWrapper>
      <ModeTaskHeader task={task} />
      <ModeTaskDetail task={task} />
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default memo(TaskCard);
