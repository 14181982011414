// Config(s)
import { FORM_ACTIONS } from '../../../../../../../../utils/constants';
import { ASSESSMENT_COMPLETE_PNC_ERROR, CREATE_TARGET_PNC_ERROR } from './constants';

// Util(s)
import { toPncChangeElementId } from '../PncCheckSummary';

const toErrorMessageByTriggerType = (triggerType) => {
  if (triggerType === FORM_ACTIONS.CREATE_TARGET) {
    return CREATE_TARGET_PNC_ERROR;
  }

  // Default to complete assessment
  return ASSESSMENT_COMPLETE_PNC_ERROR;
};

const validatePncCheck = (entityData, entityIndex, triggerType) => {
  if (!entityData?.pnc?.pncOutcome && entityData?.otherChecks?.checks?.length) {
    return {
      componentId: toPncChangeElementId(entityIndex),
      message: toErrorMessageByTriggerType(triggerType),
    };
  }

  return null;
};

export default validatePncCheck;
