const getBaggage = (person) => {
  return person?.baggage || undefined;
};

const getCheckedBags = (baggage) => {
  return baggage?.numberOfCheckedBags || undefined;
};

const getTotalWeight = (baggage) => {
  return baggage?.weight || undefined;
};

const getTags = (baggage) => {
  return baggage?.tags || undefined;
};

const BaggageUtil = {
  get: getBaggage,
  tags: getTags,
  totalCheckedBags: getCheckedBags,
  totalWeight: getTotalWeight,
};

export default BaggageUtil;

export { getBaggage, getTags, getCheckedBags, getTotalWeight };
