// Global import(s)
import { useQuery } from '@tanstack/react-query';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchInformationSheet = (targetId) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const getInformationSheet = async () => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return null;
    }

    return AxiosRequests.informationSheet(apiClient, targetId);
  };

  const { data: informationSheet, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.INFORMATION_SHEET, targetId],
    queryFn: () => getInformationSheet(),
    initialData: null,
  });

  return { informationSheet, isLoading };
};

export default useFetchInformationSheet;
