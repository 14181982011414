import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './Details.scss';

const Details = ({ items }) => {
  if (!items || !items?.length) {
    return null;
  }

  return (
    <div className="govuk-grid-row details-row">
      {items.map((item) => item.content)}
    </div>
  );
};

Details.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node,
  })),
};

Details.defaultProps = {
  items: [],
};

export default Details;
