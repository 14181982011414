import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import config from '../../../utils/config';
import { useKeycloak } from '../../../context/Keycloak';
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';
import AxiosRequests from '../../../api/axiosRequests';

import { PATHS } from '../../../utils/constants';

// Components / Pages
import Layout from '../../../components/Layout/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import LoginJustification from './login/LoginJustification';

// Styling
import './PnrAccessRequest.scss';

const STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  SESSION_REGISTERED: 'SESSION_REGISTERED',
  REGISTERED: 'REGISTERED',
};

const toPNRJustificationURL = (redirectUrl) => {
  return `${config.justificationUIUrl}access?redirect=*cop-url*&step=1`
    .replace('*cop-url*', encodeURIComponent(redirectUrl));
};

const PnrAccessRequest = ({ children }) => {
  const { COMPLETE, PENDING, REGISTERED, SESSION_REGISTERED } = STATUS;
  const keycloak = useKeycloak();
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();
  const justificationUIUrl = toPNRJustificationURL(window.location.href);
  const [status, setStatus] = useState(PENDING);
  const [displayForm, setDisplayForm] = useState(false);

  const PNR_QUESTION_EXCEPTION_PATHS = [
    PATHS.ACCESSIBILITY_STATEMENT,
    PATHS.ADMIN,
    PATHS.TARGETS.AIRPAX,
    PATHS.TARGETS.RORO,
  ];

  const isException = () => {
    return PNR_QUESTION_EXCEPTION_PATHS.some((path) => location?.pathname?.startsWith(path));
  };

  const getUserAccess = async () => {
    return AxiosRequests.pnrAccessPrivilege(apiClient)
      .then((response) => response.data)
      .catch(() => {
        // If the api service is down, do not block the UI. User will have to sign out and sign back in.
        setDisplayForm(false);
        setStatus(COMPLETE);
      });
  };

  const registerSession = async () => {
    await AxiosRequests.registerSession(apiClient);
  };

  const registerIfNotRegistered = async () => {
    if (!config.justificationUIEnabled) {
      setStatus(SESSION_REGISTERED);
      return;
    }

    const { submitted } = await getUserAccess();

    if (!submitted) {
      await registerSession()
        .then(() => setStatus(SESSION_REGISTERED));
    } else {
      setStatus(REGISTERED);
    }
  };

  const setupRedirectCondition = async () => {
    const { submitted } = await getUserAccess();
    setDisplayForm(!submitted);
    setStatus(COMPLETE);
  };

  useEffect(() => {
    if ([SESSION_REGISTERED, REGISTERED].includes(status)) {
      setupRedirectCondition();
    }
  }, [status]);

  useEffect(() => {
    trackPageView();
    registerIfNotRegistered();

    return () => {
      AxiosRequests.cancel(source);
    };
  }, []);

  if ([PENDING, REGISTERED, SESSION_REGISTERED].includes(status)) {
    return (
      <Layout>
        <LoadingSpinner />
      </Layout>
    );
  }

  if ((!displayForm && status === COMPLETE) || isException()) {
    return children;
  }

  return (
    <>
      {displayForm && status === COMPLETE && (
        <>
          {config.justificationUIEnabled && window.location.replace(justificationUIUrl)}
          {!config.justificationUIEnabled && <LoginJustification setDisplayForm={setDisplayForm} />}
        </>
      )}
    </>
  );
};

export default PnrAccessRequest;
