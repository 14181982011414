import React from 'react';
import { Link as InternalLink, useNavigate } from 'react-router-dom';

// Components
import Layout from '../../../../components/Layout/Layout';

// Utils
import { CommonUtil } from '../../../../utils';

const InternalLayout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Layout beforeMain={(
      <InternalLink
        className="govuk-back-link"
        onClick={() => {
          CommonUtil.removePreviousFlag();
          CommonUtil.removeMovementFlag();
          navigate(-1);
        }}
      >
        Back to previous tasks list
      </InternalLink>
    )}
    >
      {children}
    </Layout>
  );
};

export default InternalLayout;
