/* eslint-disable no-template-curly-in-string */
const getForm = () => {
  return {
    note: 'IMPORTANT: This form has been composed. Make changes to the relevant components, not this file, or your changes may get lost.',
    id: 'cerberus-airpax-pnc-check',
    version: '1.0.2',
    name: 'cerberus-airpax-pnc-check',
    title: 'Add PNC check results',
    type: 'form',
    components: [
      {
        id: 'pncOutcome',
        fieldId: 'pncOutcome',
        label: 'Police National Computer (PNC) check',
        type: 'radios',
        data: {
          options: [
            {
              value: 'NO_RECORDS_FOUND',
              label: 'No PNC records were found based on the identity information available',
            },
            {
              value: 'INSUFFICIENT_IDENTITY_INFORMATION',
              label: 'There was not enough identity information available to perform a PNC check',
            },
            {
              value: 'INSUFFICIENT_TIME',
              label: 'There was not enough time to complete a PNC search',
            },
            {
              value: 'CHECK_NOT_RELEVANT_FOR_PERSON',
              label: 'A PNC check is not relevant for this person',
            },
            {
              value: 'IRRELEVANT_INFORMATION_FOUND',
              label: 'A PNC record was found but it does not contain any relevant information',
            },
            {
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              label: 'PNC information was found which is potentially relevant to the target',
            },
          ],
        },
        required: true,
        custom_errors: [
          {
            type: 'required',
            message: 'You must select one option to reflect your actions to confirm a PNC check',
          },
        ],
      },
      {
        id: 'pncFound',
        fieldId: 'pncFound',
        type: 'container',
        className: 'indent',
        components: [
          {
            id: 'pncId',
            fieldId: 'pncId',
            label: 'Enter the PNC ID of the record you found',
            type: 'text',
            hint: 'For example, 12/34567890A, 12/34A, or 76/6518286013A',
            pattern: '^[0-9]{2}/[0-9]{1,}[A-Za-z]{1}$',
            required: true,
            custom_errors: [
              {
                type: 'pattern',
                message: 'Enter a PNC ID in the correct format',
              },
              {
                type: 'required',
                message: 'Enter a PNC ID in the correct format',
              },
            ],
          },
          {
            id: 'safetyInformation',
            fieldId: 'safetyInformation',
            className: 'margin-bottom-0 padding-top-1',
            label: 'Did you find any information which was relevant for officer or passenger safety?',
            type: 'radios',
            data: {
              options: [
                {
                  value: 'No',
                  label: 'No',
                },
                {
                  value: 'Yes',
                  label: 'Yes',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must confirm if you found information relevant to officer or passenger safety',
              },
            ],
          },
        ],
        required: true,
        show_when: [
          {
            field: './pncOutcome',
            op: 'eq',
            value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
          },
        ],
      },
      {
        id: 'pncDetails',
        fieldId: 'pncDetails',
        className: 'no-label indent padding-top-2',
        type: 'container',
        components: [
          {
            id: 'types',
            fieldId: 'types',
            label: 'PNC information types relevant to officer or passenger safety',
            className: 'no-label types indent margin-bottom-0',
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'VIOLENCE',
                  label: 'Violence',
                },
                {
                  value: 'FIREARMS',
                  label: 'Firearms',
                },
                {
                  value: 'DRUG_USE',
                  label: 'Drug use',
                },
                {
                  value: 'SELF_HARM',
                  label: 'Self harm',
                },
                {
                  value: 'MISSING',
                  label: 'Missing',
                },
                {
                  value: 'WANTED',
                  label: 'Wanted',
                },
                {
                  value: 'MENTAL',
                  label: 'Mental health issues',
                },
                {
                  value: 'STAFF_CONCERN',
                  label: 'Other concerns for the safety of frontline staff',
                },
                {
                  value: 'POI_CONCERN',
                  label: 'Other concerns for the person of interest',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide details about PNC information relevant for officer or passenger safety',
              },
            ],
          },
          {
            id: 'staffConcerns',
            fieldId: 'staffConcerns',
            className: 'border-left error-spacing margin-bottom-0 padding-left-1 padding-top-2',
            label: 'Explain the concerns for safety of frontline staff',
            type: 'textarea',
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide details about PNC information relevant to officer safety',
              },
            ],
            showCharacterCount: true,
            show_when: {
              field: './types',
              op: 'contains',
              value: 'staff_concern',
            },
          },
          {
            id: 'poiConcerns',
            fieldId: 'poiConcerns',
            className: 'border-left no-bottom-spacing margin-bottom-0 padding-left-1 padding-top-2',
            label: 'Explain the concerns for person of interest',
            type: 'textarea',
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide details about PNC information relevant to passenger safety',
              },
            ],
            showCharacterCount: true,
            show_when: {
              field: './types',
              op: 'contains',
              value: 'poi_concern',
            },
          },
        ],
        show_when: [
          {
            field: './pncFound/safetyInformation',
            op: 'eq',
            value: 'Yes',
          },
          {
            field: './pncOutcome',
            op: 'eq',
            value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
          },
        ],
      },
      {
        id: 'pncCategories',
        fieldId: 'pncCategories',
        className: 'indent margin-bottom-2',
        type: 'container',
        components: [
          {
            type: 'html',
            tagName: 'p',
            content: 'Select all categories in which PNC information indicated potential involvement',
            className: 'margin-top-0 padding-top-3',
          },
          {
            id: 'drugsPossession',
            fieldId: 'drugsPossession',
            className: 'no-label margin-bottom-1',
            label: '',
            show_on_cya: false,
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'DRUGS',
                  label: 'Possession of drugs',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'possessionClass',
            fieldId: 'possessionClass',
            label: 'Possession of drugs',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'CLASS_A',
                  label: 'Class A',
                },
                {
                  value: 'CLASS_B',
                  label: 'Class B',
                },
                {
                  value: 'CLASS_C',
                  label: 'Class C',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'Please provide additional details regarding potential involvement with possession of drugs',
              },
            ],
            show_when: {
              field: './drugsPossession',
              op: 'contains',
              value: 'drugs',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'drugsSupply',
            fieldId: 'drugsSupply',
            className: 'no-label margin-bottom-1',
            label: 'Possession of drugs',
            show_on_cya: false,
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'SUPPLY',
                  label: 'Supply of drugs',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'supplyClass',
            fieldId: 'supplyClass',
            label: 'Supply of drugs',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'CLASS_A',
                  label: 'Class A',
                },
                {
                  value: 'CLASS_B',
                  label: 'Class B',
                },
                {
                  value: 'CLASS_C',
                  label: 'Class C',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'Please provide additional details regarding potential involvement with supply of drugs',
              },
            ],
            show_when: {
              field: './drugsSupply',
              op: 'contains',
              value: 'supply',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'drugsTrafficking',
            fieldId: 'drugsTrafficking',
            label: 'Trafficking of drugs',
            className: 'no-label margin-bottom-1',
            show_on_cya: false,
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'TRAFFICKING',
                  label: 'Trafficking of drugs',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'traffickingClass',
            fieldId: 'traffickingClass',
            label: 'Trafficking of drugs',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'CLASS_A',
                  label: 'Class A',
                },
                {
                  value: 'CLASS_B',
                  label: 'Class B',
                },
                {
                  value: 'CLASS_C',
                  label: 'Class C',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'Please provide additional details regarding potential involvement with trafficking of drugs',
              },
            ],
            show_when: {
              field: './drugsTrafficking',
              op: 'contains',
              value: 'trafficking',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'weaponsPossession',
            fieldId: 'weaponsPossession',
            label: 'Possession of firearms, weapons, or harmful materials',
            className: 'no-label margin-bottom-1',
            type: 'checkboxes',
            show_on_cya: false,
            data: {
              options: [
                {
                  value: 'POSSESSION',
                  label: 'Possession of firearms, weapons, or harmful materials',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'weaponsPossessionOpts',
            fieldId: 'weaponsPossessionOpts',
            label: 'Possession of firearms, weapons, or harmful materials',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'FIREARMS',
                  label: 'Firearms',
                },
                {
                  value: 'OFFENSIVE_WEAPONS',
                  label: 'Offensive weapons',
                  hint: '(including non-lethal firearms)',
                },
                {
                  value: 'EXPLOSIVE_MATERIALS',
                  label: 'Explosive materials',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'Please provide additional details regarding potential involvement with possession of firearms, weapons, or harmful materials',
              },
            ],
            show_when: {
              field: './weaponsPossession',
              op: 'contains',
              value: 'possession',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'weaponsSupply',
            fieldId: 'weaponsSupply',
            label: 'Supply of firearms, weapons, or harmful materials',
            className: 'no-label margin-bottom-1',
            type: 'checkboxes',
            show_on_cya: false,
            data: {
              options: [
                {
                  value: 'SUPPLY',
                  label: 'Supply of firearms, weapons, or harmful materials',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'weaponsSupplyOptions',
            fieldId: 'weaponsSupplyOptions',
            type: 'checkboxes',
            label: 'Supply of firearms, weapons, or harmful materials',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'FIREARMS',
                  label: 'Firearms',
                },
                {
                  value: 'OFFENSIVE_WEAPONS',
                  label: 'Offensive weapons',
                  hint: '(including non-lethal firearms)',
                },
                {
                  value: 'EXPLOSIVE_MATERIALS',
                  label: 'Explosive materials',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'Please provide additional details regarding potential involvement with supply of firearms, weapons, or harmful materials',
              },
            ],
            show_when: {
              field: './weaponsSupply',
              op: 'contains',
              value: 'supply',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'goodsSmuggling',
            fieldId: 'goodsSmuggling',
            label: 'Smuggling of goods',
            className: 'no-label margin-bottom-1',
            type: 'checkboxes',
            show_on_cya: false,
            data: {
              options: [
                {
                  value: 'GOODS_SMUGGLING',
                  label: 'Smuggling of goods',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'goodsSmugglingOpts',
            fieldId: 'goodsSmugglingOpts',
            label: 'Smuggling of goods',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'EXCISE',
                  label: 'Actively relating to excise goods',
                },
                {
                  value: 'FALSE_IMPORT',
                  label: 'False import licences',
                },
                {
                  value: 'MONEY_LAUNDERING',
                  label: 'Money laundering',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide details about PNC information indicating potential involvement with smuggling of goods',
              },
            ],
            show_when: {
              field: './goodsSmuggling',
              op: 'contains',
              value: 'goods_smuggling',
            },
            description: 'Label added for cya page',
          },
          {
            id: 'peopleSmuggling',
            fieldId: 'peopleSmuggling',
            label: 'Smuggling of people',
            className: 'no-label margin-bottom-1',
            type: 'checkboxes',
            show_on_cya: false,
            data: {
              options: [
                {
                  value: 'PEOPLE_SMUGGLING',
                  label: 'Smuggling of people',
                },
              ],
            },
            description: 'Label added for cya page',
          },
          {
            id: 'peopleSmugglingOpts',
            fieldId: 'peopleSmugglingOpts',
            label: 'Smuggling of people',
            type: 'checkboxes',
            className: 'indent no-label',
            data: {
              options: [
                {
                  value: 'HUMAN_TRAFFICKING',
                  label: 'Human trafficking',
                },
                {
                  value: 'FALSE_DOCUMENTS',
                  label: 'False identity documents',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must provide details about PNC information indicating potential smuggling of people',
              },
            ],
            show_when: {
              field: './peopleSmuggling',
              op: 'contains',
              value: 'people_smuggling',
            },
            description: 'Label added for cya page',
          },
        ],
        show_when: [
          {
            field: './pncOutcome',
            op: 'eq',
            value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
          },
        ],
      },
    ],
    pages: [
      {
        id: 'pncCheck',
        name: 'pncCheck',
        components: [
          {
            type: 'heading',
            size: 'l',
            content: 'Add PNC check results',
          },
          {
            type: 'html',
            content: "<div><p id='custom-header-label'><span id='person-role'>{personRole}</span>&nbsp;-&nbsp;<span id='person-name'>{personName}</span></p></div>",
            customHeader: true,
          },
          {
            use: 'pncOutcome',
          },
          {
            use: 'pncFound',
          },
          {
            use: 'pncDetails',
          },
          {
            use: 'pncCategories',
          },
        ],
        actions: [
          {
            type: 'submit',
            validate: true,
            label: 'Add and close',
          },
          {
            type: 'cancel',
            label: 'Cancel',
            classModifiers: 'secondary',
          },
        ],
      },
    ],
  };
};

const addPncCheck = (labels) => {
  let form = getForm();
  form.pages = form.pages.map((page) => {
    return {
      ...page,
      components: page.components.map((component) => {
        if (component?.customHeader) {
          return {
            ...component,
            content: (() => {
              return component.content.replace('{personRole}', labels.role).replace('{personName}', labels.name);
            })(),
          };
        }
        return component;
      }),
    };
  });
  return form;
};

export default addPncCheck;
