// Config(s)
import { SUB_MODES } from '../constants';

const isRoRoSubMode = (subMode) => {
  return subMode === SUB_MODES.RORO;
};

const isCommoditiesSubMode = (subMode) => {
  return subMode === SUB_MODES.COMMODITIES;
};

const isIdpSubMode = (subMode) => {
  return subMode === SUB_MODES.IDP;
};

const isGaSubMode = (subMode) => {
  return subMode === SUB_MODES.GENERAL_AVIATION;
};

const isCtbpSubMode = (subMode) => {
  return subMode === SUB_MODES.CTBP;
};

const getTaskId = (task) => {
  return task?.id || null;
};

const TaskUtil = {
  taskId: getTaskId,
};

export default TaskUtil;

export {
  getTaskId,
  isCommoditiesSubMode,
  isCtbpSubMode,
  isGaSubMode,
  isIdpSubMode,
  isRoRoSubMode,
};
