import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink, toPreviousMovementLink } from '../../helper/common';
import { JourneyUtil, MovementUtil, PersonUtil, TaskVersionUtil, CommonUtil } from '../../../../../utils';
import FIELDS from '../../helper/Fields';

const Passenger = ({ version, versionDiff }) => {
  const person = PersonUtil.get(version);
  const personDiff = PersonUtil.get(versionDiff);
  const patchedPerson = TaskVersionUtil.patch(person, personDiff);
  const flightDiff = MovementUtil.movementFlight(versionDiff);
  const journey = JourneyUtil.get(version);
  const journeyDiff = JourneyUtil.get(versionDiff);
  const departureDate = JourneyUtil.departureTime(journey);
  const personJourneyDiff = { ...personDiff, ...journeyDiff?.departure };

  return (
    <div className="task-details-container bottom-border-thin govuk-!-margin-bottom-2">
      <Heading className="govuk-!-margin-top-0" size="m">{MovementUtil.movementType(version)}</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Name',
          {
            content: `${PersonUtil.lastname(person)}, ${PersonUtil.firstname(person)}`,
            url: CommonUtil.entitySearchUrl(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.last, FIELDS.first]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, person)),
          toPreviousMovementLink(person),
        )}
        {renderBlock(undefined, undefined)}
        {renderBlock(
          'Date of birth',
          {
            content: PersonUtil.dob(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.dateOfBirth]),
        )}
        {renderBlock(
          'Age at travel',
          {
            content: PersonUtil.travelAge(person, departureDate),
          },
          TaskVersionUtil.hasAny(personJourneyDiff, [FIELDS.dateOfBirth, FIELDS.time]),
        )}
        {renderBlock(
          'Gender',
          {
            content: PersonUtil.gender(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.gender]),
        )}
        {renderBlock(
          'Nationality',
          {
            content: `${PersonUtil.countryName(person)} (${PersonUtil.nationality(person)})`,
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.nationality]),
        )}
        {renderBlock(
          'Departure status',
          {
            content: MovementUtil.status(version, true),
          },
          TaskVersionUtil.hasAny(flightDiff, [FIELDS.departureStatus]),
        )}
        {renderBlock(undefined, undefined)}
        {renderBlock(
          'Frequent flyer number',
          {
            content: PersonUtil.frequentFlyerNumber(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.frequentFlyerNumber]),
        )}
        {renderBlock(undefined, undefined)}
        {renderBlock(
          'SSR codes',
          {
            content: PersonUtil.ssrCodes(person),
          },
          !TaskVersionUtil.isSame(PersonUtil.ssrCodes(person), PersonUtil.ssrCodes(patchedPerson)),
        )}
      </div>
    </div>
  );
};

export default Passenger;
