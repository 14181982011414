// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { TargetInformationUtil } from '../../../../../utils';

const InterceptionQuestions = ({ informationSheet }) => {
  const immigrationChecks = TargetInformationUtil.interception.immigrationChecks(informationSheet);
  const customsChecks = TargetInformationUtil.interception.customsChecks(informationSheet);
  const intelligenceGatheringChecks = TargetInformationUtil.interception.intelligenceGatheringChecks(informationSheet);
  const crewChecks = TargetInformationUtil.interception.crewChecks(informationSheet);
  const additionalChecks = TargetInformationUtil.interception.additionalChecks(informationSheet);

  const toInterceptionList = (checks) => {
    if (!checks?.length) {
      return null;
    }

    return (
      <ul className="govuk-list govuk-list--bullet">
        {checks.map((item) => {
          return <li key={item}>{item}</li>;
        })}
      </ul>
    );
  };

  if (
    [
      immigrationChecks,
      customsChecks,
      intelligenceGatheringChecks,
      crewChecks,
      additionalChecks,
    ].every((arr) => !arr?.length)
  ) {
    return null;
  }

  return (
    <div id="interception-questions" className="govuk-grid-row">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Interception questions</Heading>
        <ComponentWrapper show={immigrationChecks?.length}>
          <Heading size="s">Immigration checks</Heading>
          {toInterceptionList(immigrationChecks)}
        </ComponentWrapper>
        <ComponentWrapper show={customsChecks?.length}>
          <Heading size="s">Customs checks</Heading>
          {toInterceptionList(customsChecks)}
        </ComponentWrapper>
        <ComponentWrapper show={intelligenceGatheringChecks?.length}>
          <Heading size="s">Intelligence gathering checks</Heading>
          {toInterceptionList(intelligenceGatheringChecks)}
        </ComponentWrapper>
        <ComponentWrapper show={crewChecks?.length}>
          <Heading size="s">Crew checks</Heading>
          {toInterceptionList(crewChecks)}
        </ComponentWrapper>
        <ComponentWrapper show={additionalChecks?.length}>
          <Heading size="s">Additional checks</Heading>
          {toInterceptionList(additionalChecks)}
        </ComponentWrapper>
      </div>
    </div>
  );
};

InterceptionQuestions.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(InterceptionQuestions);
