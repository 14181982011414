import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const NavigationItem = ({ href, children }) => {
  const location = useLocation();
  const className = location.pathname === href
    ? 'govuk-header__navigation-item govuk-header__navigation-item--active'
    : 'govuk-header__navigation-item';

  return (
    <li className={className}>
      {/^https?:\/\//.test(href)
        ? <Link href={href} className="govuk-header__link">{children}</Link>
        : <RouterLink to={href} className="govuk-header__link">{children}</RouterLink>}
    </li>
  );
};

export default NavigationItem;
