/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, Suspense } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS } from '../../utils/constants';

// Components/ Pages
import Layout from '../../components/Layout/Layout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import TargetsListPage from './TargetsListPage/TargetsListPage';

// Hook(s)
import useFetchTeam from '../../utils/Hooks/useFetchTeam';

// HOC(s)
import withWebSocketSession from '../../components/HOC/withWebSocketSession';

// Lazy Component(s)
const AssignTarget = lazy(() => import('./TargetAssign/AssignTarget'));
const ReAssignTarget = lazy(() => import('./TargetAssign/ReAssignTarget'));
const RecordOutcome = lazy(() => import('./TargetOutcome/RecordOutcome'));
const TargetSheetPage = lazy(() => import('./TargetSheetPage/TargetSheetPage'));

const TargetRouter = () => {
  const navigate = useNavigate();

  useFetchTeam();

  const goBack = async () => {
    const path = localStorage.getItem(LOCAL_STORAGE_KEYS.TARGET_DETAILS_BACK_PATH);
    if (path && path.length > 0) {
      try {
        setTimeout(() => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TARGET_DETAILS_BACK_PATH);
        }, 500);

        navigate(`/${path}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Navigation error', error);
      }
    }
    navigate(-1);
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {[
          {
            path: `${PATHS.TARGETS.RORO}/`,
            enabled: true,
          },
          {
            path: `${PATHS.TARGETS.AIRPAX}/`,
            enabled: true,
          },
          {
            path: PATHS.TARGETS.RORO,
            enabled: true,
          },
          {
            path: PATHS.TARGETS.AIRPAX,
            enabled: true,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={(
                <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                  <TargetsListPage />
                </Layout>
              )}
              replace
            />
          ),
          )}
        {[
          {
            path: `${PATHS.TARGETS.RORO}/:targetId`,
            enabled: true,
          },
          {
            path: `${PATHS.TARGETS.AIRPAX}/:targetId`,
            enabled: true,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={(
                <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                  <TargetSheetPage />
                </Layout>
              )}
              replace
            />
          ),
          )}
        {[
          {
            path: `${PATHS.TARGETS.RORO}/:targetId/assign`,
            enabled: true,
          },
          {
            path: `${PATHS.TARGETS.AIRPAX}/:targetId/assign`,
            enabled: true,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={(
                <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                  <AssignTarget />
                </Layout>
              )}
              replace
            />
          ),
          )}
        {[
          {
            path: `${PATHS.TARGETS.RORO}/:targetId/reassign`,
            enabled: true,
          },
          {
            path: `${PATHS.TARGETS.AIRPAX}/:targetId/reassign`,
            enabled: true,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={(
                <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                  <ReAssignTarget />
                </Layout>
              )}
              replace
            />
          ),
          )}
        {[
          {
            path: `${PATHS.TARGETS.RORO}/:targetId/record-outcome`,
            enabled: true,
          },
          {
            path: `${PATHS.TARGETS.AIRPAX}/:targetId/record-outcome`,
            enabled: true,
          },
        ].filter((item) => !!item.enabled)
          .map((item) => (
            <Route
              key={item.path}
              path={item.path}
              element={(
                <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back</Link>}>
                  <RecordOutcome />
                </Layout>
              )}
              replace
            />
          ),
          )}
      </Routes>
    </Suspense>
  );
};

export default withWebSocketSession(TargetRouter);
