// Global import(s)
import { BackToTop } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import LinkButton from '../../../components/Buttons/LinkButton';
import OfficialWarning from './components/header/OfficialWarning';
// import SBTChecks from '../../../components/RenderForm/Forms/Components/SBTChecks';
import ScanAppOutcome from './components/outcomes/ScanAppOutcome';
import TargetDetails from './components/target/TargetDetails';
import TargetHeader from './components/header/TargetHeader';
import TargetInformation from './components/movement/information/TargetInformation';
import Warnings from './components/banners/Warnings';

// Hook(s)
import useRedirectIfRequired from '../../../utils/Hooks/useRedirectIfRequired';

// Styling
import './TargetSheetPage.scss';

const PreviewTargetSheetPage = ({
  backToCreateTarget,
  formData,
  mode,
  targetId,
  isAllowedToViewTargetSheet,
  isSettingView,
  isSubmittedForXrayAnalysis,
  isSubmittingForXrayAnalysis,
  informationSheet,
}) => {
  const isTrueSet = (myValue) => {
    return myValue === 'true';
  };

  const usingFormData = () => {
    const keyData = {
      operation: 'operation',
      selectionReasoning: 'referralReason',
      suspectedAbuseTypes: 'suspectedAbuseType',
      targetCategory: 'targetCategory',
      targetRecipients: 'targetRecipients',
    };

    const updateSheet = () => {
      // First we find what key/vals are in the formData
      // but not in the informationSheet;
      const ourForm = Object.entries(keyData)
        .reduce((acc, [key, val]) => {
          acc[key] = formData[val];
          return acc;
        }, {});

      // Now we go through the persons/otherPersons to change
      // the watchList (if changed);
      const personForm = Object.entries(informationSheet)
        .reduce((acc, [key, val]) => {
          if (key === 'movement') {
            const ourVal = val;
            ourVal.person.watchList.added = isTrueSet(formData.addedToWatchList);
            ourVal.otherPersons.forEach((p) => {
              p.watchList.added = isTrueSet(formData.addedToWatchList);
            });
            acc[key] = ourVal;
          }
          return acc;
        }, {});
      return { ...informationSheet, ...ourForm, ...personForm };
    };

    const ourFormData = updateSheet();
    return ourFormData;
  };

  useRedirectIfRequired(mode, targetId);

  if (isSubmittingForXrayAnalysis || isSettingView) {
    return <ApplicationSpinner />;
  }

  if (!isAllowedToViewTargetSheet) {
    return <p>You are not authorised to view this target.</p>;
  }

  if (isSubmittedForXrayAnalysis) {
    return (<ScanAppOutcome movement={informationSheet?.movement} targetId={targetId} />);
  }

  const previewInformationSheet = usingFormData();

  return (
    <div id="preview-target-sheet-page" className="target-sheet-page">
      <LinkButton
        id="back-to-create-target"
        className="govuk-back-link"
        onClick={() => backToCreateTarget()}
      >
        Back to Create target
      </LinkButton>
      <OfficialWarning position="top" preview />
      <TargetHeader
        canRecordOutcome={false}
        onRecordOutcome={() => {}}
        mode={mode}
        informationSheet={previewInformationSheet}
      />
      <Warnings informationSheet={previewInformationSheet} />
      <TargetDetails mode={mode} informationSheet={previewInformationSheet} />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetInformation informationSheet={previewInformationSheet} preview />
        </div>
      </div>
      <BackToTop />
      <OfficialWarning position="bottom" preview />
    </div>
  );
};

export default PreviewTargetSheetPage;
