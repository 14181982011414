import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink, toPreviousMovementLink } from '../../../../helper/common';

import { CommonUtil, EnrichmentUtil, PersonUtil, TaskVersionUtil } from '../../../../../../../utils';
import FIELDS from '../../../../helper/Fields';
import EnrichmentCount from '../../shared/EnrichmentCount';

const PrimaryTraveller = ({ version, versionDiff, classModifiers }) => {
  const person = PersonUtil.get(version);
  const personDiff = PersonUtil.get(versionDiff);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Primary traveller</Heading>
      <EnrichmentCount
        id="primary-traveller"
        movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(person))}
        examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(person))}
        seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(person))}
      />
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Name',
          {
            content: PersonUtil.fullname(person),
            url: CommonUtil.entitySearchUrl(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.full]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, person)),
          toPreviousMovementLink(person),
        )}
        {renderBlock(
          'Date of birth',
          {
            content: PersonUtil.dob(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.dateOfBirth]),
        )}
        {renderBlock(
          'Gender',
          {
            content: PersonUtil.gender(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.gender]),
        )}
        {renderBlock(
          'Nationality',
          {
            content: PersonUtil.nationality(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.nationality]),
        )}
      </div>
    </div>
  );
};

export default PrimaryTraveller;
