// Config(s)
import { STRINGS, TIME_FORMATS } from '../../../../../../../../../utils/constants';

// Util(s)
import DateTimeUtil from '../../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import { getMaskedValue } from '../../../../../../../../../utils/Masking/maskingUtil';

export const toValidTo = (dateOfExpiry) => {
  if (!dateOfExpiry) {
    return null;
  }

  if (dateOfExpiry === getMaskedValue()) {
    return dateOfExpiry;
  }

  return DateTimeUtil.format.asUTC(dateOfExpiry, TIME_FORMATS.UTC, TIME_FORMATS.DOB);
};

export const toValidToDifference = (dateOfExpiry, departureLocation, departureTime) => {
  const timezone = DateTimeUtil.timezone(departureLocation);
  if (!departureLocation || !timezone || !departureTime || !dateOfExpiry) {
    return null;
  }

  if ([departureLocation, departureTime, dateOfExpiry].includes(getMaskedValue())) {
    return getMaskedValue();
  }

  const departureLocalDate = DateTimeUtil.local.date(departureTime, timezone);
  const expiryLocalDate = DateTimeUtil.local.date(dateOfExpiry, timezone);
  return departureLocalDate.from(expiryLocalDate).replace(STRINGS.DAYJS_PAST, STRINGS.AFTER_TRAVEL_TEXT);
};

export const isDocumentNumberMasked = (docNumber) => {
  return docNumber === getMaskedValue();
};

export const isPersonFullNameMasked = (fullName) => {
  return fullName === getMaskedValue();
};

export const toDateOfBirth = (dateOfBirth) => {
  if (!dateOfBirth) {
    return null;
  }

  if (dateOfBirth === getMaskedValue()) {
    return dateOfBirth;
  }

  return DateTimeUtil.format.asUTC(dateOfBirth, TIME_FORMATS.UTC, TIME_FORMATS.DOB);
};

export const toAgeAtTravel = (dateOfBirth, departureTime) => {
  if (!departureTime || !dateOfBirth) {
    return null;
  }

  if (departureTime === getMaskedValue() || dateOfBirth === getMaskedValue()) {
    return getMaskedValue();
  }

  // Steer is not to do any transformations with the dates here, 24hr margin of error is acceptable.
  const departureDate = DateTimeUtil.parse.asUTC(departureTime);
  const dateOfBirthDate = DateTimeUtil.parse.asUTC(dateOfBirth);
  return departureDate.diff(dateOfBirthDate, 'year');
};
