import { Button } from '@ukhomeoffice/cop-react-components';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Context(s)
import { useTasks } from '../../../../../../../context/TasksContext';
import { useTask } from '../../../../../../../context/TaskContext';

// Components
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ValueField from '../generics/ValueField';

// Utils
import { toContent, toTimestamp, toCreator } from '../../../helper/toContent';

// Styling
import './TaskNoteItem.scss';

const TaskNoteItem = ({ note, ...props }) => {
  const { setReturnToElementId, setReturnToElement } = useTasks();
  const { setIsEditNoteFormOpen, setEditorContentProps } = useTask();
  const noteItemRef = useRef();

  const openEditor = () => {
    setReturnToElementId(noteItemRef.current.id);
    setEditorContentProps({
      data: note,
      onClose: () => {
        setIsEditNoteFormOpen(false);
        setReturnToElement(true);
        setEditorContentProps(null);
      },
    });
    setIsEditNoteFormOpen(true);
  };

  return (
    <div {...props} ref={noteItemRef}>
      <div className="grid-item grid-item-span-3">
        <ValueField id={`note-content-${note.id}`} value={toContent(note)} />
      </div>
      <div className="grid-item">
        <ValueField id={`note-timestamp-${note.id}`} value={toTimestamp(note)} />
      </div>
      <div className="grid-item note-creator-edit-control">
        <ValueField id={`note-creator-${note.id}`} value={toCreator(note)} />
        <ComponentWrapper show={note?.editable}>
          <Button
            id={`note-edit-${note.id}`}
            classModifiers="secondary"
            onClick={openEditor}
          >
            Edit
          </Button>
        </ComponentWrapper>
      </div>
    </div>
  );
};

TaskNoteItem.propTypes = {
  note: PropTypes.shape({
    content: PropTypes.shape({}).isRequired,
    timestamp: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TaskNoteItem;
