import { Panel } from '@ukhomeoffice/cop-react-components';
import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Config
import config from '../../../../../utils/config';

// Context(s)
import { NotificationContext } from '../../../../../context/NotificationContext';

// Styling
import './TaskNotification.scss';

const TaskNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);
  let timeoutId = null;

  const notificationAnimations = {
    initial: {
      opacity: 0,
      y: -10,
    },
    exit: {
      opacity: 0,
      y: -10,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.25,
        ease: 'easeIn',
      },
    },
  };

  useEffect(() => {
    if (notification) {
      setIsVisible(true);
    }
  }, [notification]);

  useEffect(() => {
    if (isVisible) {
      timeoutId = setTimeout(() => {
        setNotification(null);
        setIsVisible(!isVisible);
      }, config.taskList.notificationDuration);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div {...notificationAnimations}>
        <Panel id="task-notification" title={notification} />
      </motion.div>
    </AnimatePresence>
  );
};

export default TaskNotification;
