import React from 'react';
import { Tag } from '@ukhomeoffice/cop-react-components';
import { STRINGS, TARGETER_OUTCOME, TARGETER_OUTCOME_NAME } from '../../../../utils/constants';

const getColourClass = (outcome) => {
  switch (outcome) {
    case TARGETER_OUTCOME.TARGET_ISSUED:
      return 'outcome--red';
    case TARGETER_OUTCOME.DISMISSED:
    case TARGETER_OUTCOME.ASSESSMENT_COMPLETED:
    default:
      return 'outcome--dark-grey';
  }
};

const getText = (outcome) => {
  return TARGETER_OUTCOME_NAME[outcome] || STRINGS.UNKNOWN_TEXT;
};

export const formatOutcome = (outcome) => {
  return (
    <Tag
      className={getColourClass(outcome)}
      text={getText(outcome)}
    />
  );
};
export default formatOutcome;
