import validateDate from './validateDate';

import { COMPONENT_TYPES } from '../../../../utils/constants';

const validateComponent = (component, formData) => {
  let error;
  switch (component.type) {
    case COMPONENT_TYPES.DATETIME:
    case COMPONENT_TYPES.DATE: {
      error = validateDate(component, formData[component.fieldId], component.custom_errors);
      break;
    }
    // If validation is to be enabled on all filter components, a default validation can be added here.
    default: {
      error = undefined;
      break;
    }
  }
  return {
    message: error,
  };
};

export default validateComponent;
