import React from 'react';

import EnrichmentCount from './EnrichmentCount';
import PreviousTasksCount from '../../../../../components/PreviousTasks/PreviousTasksCount';

import { CommonUtil, DocumentUtil, PersonUtil } from '../../../../../utils';
import { toBookingBlock, toCoTravellersBlock } from '../../helper/common';

const TouristMovementFootPassengers = ({ person, booking, document, datetimeList, otherPersons }) => {
  return (
    <>
      <div className="govuk-grid-item">
        <div>
          <EnrichmentCount
            labelText="Primary traveller"
            movementStats={CommonUtil.movementStats(person)}
            classnames={['secondary-text']}
          />
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{PersonUtil.fullname(person)}</li>
          </ul>
          <ul className="govuk-body-s govuk-list">
            <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(person)} />
          </ul>
        </div>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Document
          </p>
          <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
            <li className="govuk-!-font-weight-bold">{DocumentUtil.docNumber(document)}</li>
          </ul>
        </div>
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        {toBookingBlock(booking, datetimeList)}
      </div>
      <div className="govuk-grid-item vertical-dotted-line">
        {toCoTravellersBlock(otherPersons)}
      </div>
    </>
  );
};

export default TouristMovementFootPassengers;
