const FIELDS = {
  // Has been ordered in an address format
  address: {
    line1: 'line1',
    line2: 'line2',
    line3: 'line3',
    city: 'city',
    postcode: 'postcode',
    country: 'country',
  },
  bookedAt: 'bookedAt',
  checkInAt: 'checkInAt',
  colour: 'colour',
  contactText: 'contactText',
  country: 'country',
  countryOfIssue: 'countryOfIssue',
  departureStatus: 'departureStatus',
  description: 'description',
  dateOfBirth: 'dateOfBirth',
  duration: 'duration',
  email: 'email',
  expiry: 'expiry',
  first: 'first',
  frequentFlyerNumber: 'frequentFlyerNumber',
  full: 'full',
  gender: 'gender',
  groupSize: 'groupSize',
  hazardous: 'hazardous',
  height: 'height',
  iata: 'iata',
  itinerary: 'itinerary',
  last: 'last',
  length: 'length',
  loadStatus: 'loadStatus',
  location: 'location',
  make: 'make',
  model: 'model',
  name: 'name',
  numberOfCheckedBags: 'numberOfCheckedBags',
  nationality: 'nationality',
  number: 'number',
  operator: 'operator',
  paymentMethod: 'paymentMethod',
  reference: 'reference',
  registration: 'registration',
  shortName: 'shortName',
  time: 'time',
  type: 'type',
  validFrom: 'validFrom',
  value: 'value',
  weight: 'weight',
};

export default FIELDS;
