import { shouldHideString } from '../../Hiding/hidingUtil';
import { getMaskedValue, shouldMaskString } from '../../Masking/maskingUtil';

const getFlightNumber = (itinerary) => {
  if (!itinerary?.id) {
    return null;
  }

  if (shouldHideString(itinerary.id)) {
    return null;
  }

  if (shouldMaskString(itinerary.id)) {
    return getMaskedValue();
  }

  return itinerary.id;
};

const ItineraryUtil = {
  flightNumber: getFlightNumber,
};

export default ItineraryUtil;
