/* eslint-disable jsx-a11y/anchor-is-valid */
import * as pluralise from 'pluralise';
import { ButtonGroup, Button, FormGroup, Link, Radios } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';

import { DATE_FORMATS } from '../../../../../../utils/constants';

import { DateTimeUtil, JustificationUtil } from '../../../../../../utils';

const ApprovedJustifications = ({
  justifications,
  pendingApprovals,
  onOpenAndCloseAction,
  getTaskWithJustification,
}) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const ID = 'justificationId';
  const FIELD_ID = ID;
  const ERROR = 'You must choose an approved justification or create a new justification';

  const onChange = ({ target }) => {
    setData((prev) => {
      return { ...prev, [target.name]: target.value };
    });
  };

  const validate = () => {
    return !!data[FIELD_ID];
  };

  const internalOnSubmit = () => {
    if (!validate()) {
      setError(ERROR);
      return;
    }

    getTaskWithJustification(data[FIELD_ID]);
  };

  const toHintRow = (question, answer) => {
    return (
      <p className="govuk-body govuk-!-font-size-14 govuk-!-margin-bottom-1">
        <span className="govuk-!-font-weight-bold govuk-!-margin-right-1 hint-question">
          {question}:
        </span>
        <span className="hint-answer">{answer}</span>
      </p>
    );
  };

  const toJustificationHint = (userJustification) => {
    return (
      <>
        {toHintRow('Justification number', JustificationUtil.id(userJustification))}
        {toHintRow('Why you need access to this PNR data', JustificationUtil.reason(userJustification))}
        {toHintRow('What\'s the proportionality for this', JustificationUtil.proportionality(userJustification))}
        {toHintRow(
          'Date range for PNR records',
          `${DateTimeUtil.format(JustificationUtil.startDate(userJustification), DATE_FORMATS.SHORT_JUSTIFICATION)}
          to ${DateTimeUtil.format(JustificationUtil.endDate(userJustification), DATE_FORMATS.SHORT_JUSTIFICATION)}`,
        )}
      </>
    );
  };

  const toApprovedOptions = (userJustifications) => {
    return userJustifications.map((userJustification) => {
      return {
        value: userJustification.id,
        label: userJustification.reference,
        hint: toJustificationHint(userJustification),
      };
    });
  };

  return (
    <div className="govuk-grid-column">
      <p className="govuk-body govuk-!-font-weight-bold govuk-!-margin-bottom-2 govuk-!-margin-top-2">Choose how to continue</p>
      {pendingApprovals ? (
        <div className="govuk-!-margin-bottom-2">
          <span id="pending-approvals" className="govuk-body">
            You have {pluralise.withCount(pendingApprovals, '% justification', '% justifications')} waiting for approval.
          </span>
          <span className="govuk-body">&nbsp;Once approved, they will appear here</span>
        </div>
      ) : null}
      <FormGroup
        id={ID}
        fieldId={FIELD_ID}
        error={error}
      >
        <Radios
          id={ID}
          fieldId={FIELD_ID}
          options={[
            {
              value: 'CREATE_JUSTIFICATION',
              label: 'Create a new justification',
            },
            'or',
            ...toApprovedOptions(justifications),
          ]}
          onChange={onChange}
        />
      </FormGroup>
      <ButtonGroup id="form-actions" className="govuk-!-margin-top-3 govuk-!-margin-bottom-0">
        <Button id="submit-justification" onClick={() => internalOnSubmit()}>Continue</Button>
        <Link id="close" onClick={() => onOpenAndCloseAction()}>Cancel and close</Link>
      </ButtonGroup>
    </div>
  );
};

export default ApprovedJustifications;
