// Global imports
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useKeycloak } from '../../../../context/Keycloak';

// Local imports
import { useAxiosInstance } from '../../../../utils/Axios/axiosInstance';

import AxiosRequests from '../../../../api/axiosRequests';

import config from '../../../../utils/config';

export const STATUS = {
  ERROR: 'error',
  FETCHED: 'fetched',
  FETCHING: 'fetching',
  IDLE: 'idle',
};

const useGetRequest = (url) => {
  const { ERROR, FETCHED, FETCHING, IDLE } = STATUS;
  const keycloak = useKeycloak();
  const [status, setStatus] = useState(IDLE);
  const [data, setData] = useState(null);
  const source = axios.CancelToken.source();
  const refDataClient = useAxiosInstance(keycloak, config.refdataApiUrl);

  const canFetchData = () => {
    return ![FETCHING, FETCHED, ERROR].includes(status);
  };

  const fetchData = async () => {
    setStatus(FETCHING);
    await AxiosRequests.fetchRefData(refDataClient, url)
      .then((_data) => {
        setData(_data);
        setStatus(FETCHED);
      })
      .catch(() => {
        setData(null);
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (!url || !refDataClient) {
      return;
    }

    if (canFetchData()) {
      fetchData();
    }

    return (() => {
      AxiosRequests.cancel(source);
    });
  }, [refDataClient, url]);

  return { status, data };
};

export default useGetRequest;
