import { Link } from 'react-router-dom';
import React, { useContext } from 'react';

// Config
import { withCount } from 'pluralise';
import { VIEW } from '../../../../../utils/constants';

// Component(s)
import MatchedRules from './MatchedRules';
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';

// Context(s)
import { ViewContext } from '../../../../../context/ViewContext';

// Utils
import { RisksUtil } from '../../../../../utils';

const TargetIndicatorsSection = ({ targetTask, redirectPath }) => {
  const { view } = useContext(ViewContext);
  const targetingIndicators = RisksUtil.targetingIndicators(RisksUtil.getRisks(targetTask));
  const risks = RisksUtil.getRisks(targetTask);

  const toFormattedIndicators = () => {
    if (!targetingIndicators?.indicators?.length) {
      return null;
    }

    const threatIndicatorList = targetingIndicators.indicators.map((threatIndicator) => threatIndicator.description);
    return (
      <ul className="govuk-list item-list--bulleted">
        <li className="govuk-!-font-weight-bold govuk-!-font-size-16">
          {`${withCount(threatIndicatorList.length, '% indicator', '% indicators')}`}
        </li>
        {threatIndicatorList.map((threat) => (
          <li key={threat} className="threat-indicator-bullet govuk-!-font-size-16">{threat}</li>
        ))}
      </ul>
    );
  };

  return (
    <section className="task-list-target-indicator-section">
      <div className="govuk-grid-row">
        <div className="total-risk-container">
          <ComponentWrapper show={view !== VIEW.RORO}>
            <MatchedRules risks={risks} />
          </ComponentWrapper>
          <ComponentWrapper show={view === VIEW.RORO}>
            <p id="total-risk-score" className="govuk-body-s govuk-!-font-weight-bold govuk-!-margin-top-2">
              Risk Score: {targetingIndicators.score}
            </p>
          </ComponentWrapper>
          <ul id="risk-indicators" className="govuk-list govuk-!-margin-top-0">
            <li className="task-labels-item">
              {toFormattedIndicators()}
            </li>
          </ul>
        </div>
        <div id="view-details-link" className="task-link-container">
          <Link
            aria-label={`View details for task ${targetTask.id}`}
            className="govuk-link govuk-link--no-visited-state govuk-!-font-weight-bold govuk-!-margin-left-5"
            to={`${redirectPath}/${targetTask.id}`}
          >
            View details
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TargetIndicatorsSection;
