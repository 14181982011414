import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { PATHS } from '../../utils/constants';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <footer className="govuk-footer " role="contentinfo">
      <div className="govuk-width-container ">
        <div className="govuk-footer__meta">
          <div
            className="govuk-footer__meta-item govuk-footer__meta-item--grow"
          >
            <Link
              classBlock="govuk-footer__link"
              href={PATHS.ACCESSIBILITY_STATEMENT}
              onClick={(e) => {
                e.preventDefault();
                if (!location?.pathname?.includes(PATHS.ACCESSIBILITY_STATEMENT)) {
                  navigate(PATHS.ACCESSIBILITY_STATEMENT);
                }
                window.scrollTo(0, 0);
              }}
            >
              Accessibility Statement
            </Link>
          </div>
          <div className="govuk-footer__meta-item">
            <a
              className="govuk-footer__link govuk-footer__copyright-logo"
              href="https://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/uk-government-licensing-framework/crown-copyright/"
            >
              © Crown copyright
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
