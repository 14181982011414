import { Button, Hint } from '@ukhomeoffice/cop-react-components';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useContext, useEffect, useState } from 'react';

// Config
import { FORM_MESSAGES } from '../../utils/constants';

// Context
import { PermissionContext } from '../../context/PermissionContext';

// Components
import DeleteTask from './DeleteTask';
import MovementRecord from './MovementRecord';
import Migration from './Migration';
import OutcomeMessage from './OutcomeMessage';
import TargetsClearDown from './TargetsClearDown';
import Versions from './Versions';

// Hook
import useSetTabTitle, { setTabTitle, TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

// Styling
import './AdminPanel.scss';

const AdminPanel = () => {
  const { trackPageView } = useMatomo();
  const [showActionsButtons, setShowActionButtons] = useState(true);
  const [formStates, setFormStates] = useState({
    showDeleteTask: false,
    showMovementRecord: false,
    showMigration: false,
    showClearDownTargets: false,
    showVersions: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isLiveServicesUser } = useContext(PermissionContext);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.HOME);

  const formsClosed = () => {
    const _formStates = Object.keys(formStates);
    return _formStates.every((fs) => !formStates[fs]);
  };

  const setupCommonActions = () => {
    setIsSubmitted(false);
    setShowActionButtons(true);
  };

  const dismissClearDownTargetsForm = () => {
    setFormStates((prev) => {
      return {
        ...prev,
        showClearDownTargets: false,
      };
    });
    setupCommonActions();
  };

  const dismissDeleteTaskForm = () => {
    setFormStates((prev) => {
      return {
        ...prev,
        showDeleteTask: false,
      };
    });
    setupCommonActions();
  };

  const dismissMovementRecordForm = () => {
    setFormStates((prev) => {
      return {
        ...prev,
        showMovementRecord: false,
      };
    });
    setupCommonActions();
  };

  const dismissMigrationForm = () => {
    setFormStates((prev) => {
      return {
        ...prev,
        showMigration: false,
      };
    });
    setupCommonActions();
  };

  const dismissVersionsForm = () => {
    setFormStates((prev) => {
      return {
        ...prev,
        showVersions: false,
      };
    });
    setupCommonActions();
  };

  const onCancelConfirmation = (onCancel) => {
    // eslint-disable-next-line no-alert
    if (confirm(FORM_MESSAGES.ON_CANCELLATION)) {
      onCancel();
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    if (formsClosed()) {
      setTabTitle(TAB_TITLES.ADMIN_PANEL.HOME);
    }
  }, [formStates]);

  if (!isLiveServicesUser) {
    return <p>You are not authorised to view this page.</p>;
  }

  return (
    <div className="govuk-grid-row">
      <section className="govuk-grid-column-full">
        <h1 className="govuk-heading-xl page-header govuk-!-padding-bottom-2 govuk-!-margin-bottom-5">Admin panel</h1>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            {showActionsButtons && (
            <div className="admin-action-buttons">
              <Button
                id="delete-task"
                classModifiers={['warning']}
                onClick={() => {
                  setFormStates((prev) => {
                    return {
                      ...prev,
                      showDeleteTask: true,
                    };
                  });
                  setShowActionButtons(false);
                }}
              >
                Delete a task
              </Button>

              <Button
                id="clear-down-targets"
                classModifiers={['warning']}
                onClick={() => {
                  setFormStates((prev) => {
                    return {
                      ...prev,
                      showClearDownTargets: true,
                    };
                  });
                  setShowActionButtons(false);
                }}
              >
                Targets clear down
              </Button>

              <Button
                id="movement-record"
                classModifiers={['secondary']}
                onClick={() => {
                  setFormStates((prev) => {
                    return {
                      ...prev,
                      showMovementRecord: true,
                    };
                  });
                  setShowActionButtons(false);
                }}
              >
                Movement record
              </Button>

              <Button
                id="migrations"
                classModifiers={['secondary']}
                onClick={() => {
                  setFormStates((prev) => {
                    return {
                      ...prev,
                      showMigration: true,
                    };
                  });
                  setShowActionButtons(false);
                }}
              >
                Migration
              </Button>

              <Button
                id="versions"
                classModifiers={['secondary']}
                onClick={() => {
                  setFormStates((prev) => {
                    return {
                      ...prev,
                      showVersions: true,
                    };
                  });
                  setShowActionButtons(false);
                }}
              >
                Versions
              </Button>
            </div>
            )}
            {formStates.showDeleteTask && (
            <DeleteTask
              isSubmitted={isSubmitted}
              setSubmitted={setIsSubmitted}
              onCancel={() => onCancelConfirmation(dismissDeleteTaskForm)}
            >
              <OutcomeMessage
                message="Task has been deleted"
                onFinish={() => dismissDeleteTaskForm()}
                description={(
                  <>
                    <p className="govuk-body">The request for task deletion has been sent.</p>
                    <Hint>Please be aware that this will delete the task from the system (including all the associated data for the task from S3).</Hint>
                  </>
                    )}
                onCloseTabTitle={TAB_TITLES.ADMIN_PANEL.HOME}
              />
            </DeleteTask>
            )}
            {formStates.showMovementRecord && (
            <MovementRecord
              isSubmitted={isSubmitted}
              setSubmitted={setIsSubmitted}
              onCancel={() => onCancelConfirmation(dismissMovementRecordForm)}
            >
              <OutcomeMessage
                message="The movement record will be downloaded shortly"
                onFinish={() => dismissMovementRecordForm()}
                onCloseTabTitle={TAB_TITLES.ADMIN_PANEL.HOME}
              />
            </MovementRecord>
            )}
            {formStates.showMigration && (
              <Migration
                isSubmitted={isSubmitted}
                setSubmitted={setIsSubmitted}
                onCancel={() => onCancelConfirmation(dismissMigrationForm)}
              >
                <OutcomeMessage
                  message="Migration will begin shortly"
                  onFinish={() => dismissMigrationForm()}
                  onCloseTabTitle={TAB_TITLES.ADMIN_PANEL.HOME}
                />
              </Migration>
            )}
            {formStates.showClearDownTargets && (
            <TargetsClearDown
              isSubmitted={isSubmitted}
              setSubmitted={setIsSubmitted}
              onCancel={() => onCancelConfirmation(dismissClearDownTargetsForm)}
            >
              <OutcomeMessage
                message="The request to clear down targets will begin shortly"
                onFinish={() => dismissClearDownTargetsForm()}
                onCloseTabTitle={TAB_TITLES.ADMIN_PANEL.HOME}
              />
            </TargetsClearDown>
            )}
            {formStates.showVersions && (<Versions onCancel={() => onCancelConfirmation(dismissVersionsForm)} />)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminPanel;
