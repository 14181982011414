// Global m
import { useLocation, useNavigate } from 'react-router-dom';

// Config(s)
import { PATH_TO_MODE_ASSOC } from '../constants';

// Util(s)
import CommonUtil from '../Common/commonUtil';

/**
 * Trigger a redirect in the event the movement mode does not match up with the page path.
 *
 * @param mode The movement mode.
 * @param id The task/ target id.
 * @returns {Promise<void>}
 */
const useRedirectIfRequired = async (mode, id) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!id || !mode) {
    return;
  }

  const findPathByMode = () => {
    return Object.keys(PATH_TO_MODE_ASSOC).find((path) => PATH_TO_MODE_ASSOC[path].includes(mode));
  };

  const checkModeAndPageMismatch = async () => {
    const listPath = CommonUtil.getListPath(location.pathname);
    if (PATH_TO_MODE_ASSOC?.[listPath]?.includes(mode)) {
      return null;
    }

    const redirectPath = findPathByMode();
    if (!redirectPath) {
      return null;
    }

    return `${redirectPath}/${id}`;
  };

  await checkModeAndPageMismatch()
    .then((redirectPath) => {
      if (!redirectPath) {
        return;
      }
      navigate(redirectPath, { replace: true });
    });
};

export default useRedirectIfRequired;
