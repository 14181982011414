export const isTruthy = (arg) => {
  return [true, 'true'].some((condition) => condition === arg);
};

const keycloakClientConfigs = {
  realm: process.env.KEYCLOAK_REALM,
  url: process.env.KEYCLOAK_AUTH_URL,
};

// Remember to also update run.sh, Dockerfile and webpack.config.js
const config = {
  keycloak: {
    clientConfig: {
      cop: {
        ...keycloakClientConfigs,
        clientId: process.env.COP_KEYCLOAK_CLIENT_ID,
      },
      cerberus: {
        ...keycloakClientConfigs,
        clientId: process.env.CERBERUS_KEYCLOAK_CLIENT_ID,
      },
    },
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    minExpiryValidity: 30,
  },
  analyticsSiteId: process.env.ANALYTICS_SITE_ID,
  analyticsUrlBase: process.env.ANALYTICS_URL_BASE,
  environment: process.env.ENVIRONMENT,
  refdataApiUrl: process.env.REFDATA_API_URL,
  fileUploadApiUrl: '/files',
  formApiUrl: '/form-api',
  justificationUIEnabled: isTruthy(process.env.JUSTIFICATION_UI_ENABLED),
  justificationUIUrl: process.env.JUSTIFICATION_UI_URL,
  supportUrl: process.env.SUPPORT_URL,
  copUiUrl: process.env.COP_UI_URL,
  gitInfo: {
    version: process.env.APP_VERSION,
    commitHash: process.env.APP_COMMIT_HASH,
  },
  taskApiUrl: '/cop-targeting-api',
  dayjsConfig: {
    relativeTime: {
      future: '%s before travel',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  },
  taskList: {
    refreshInterval: 180000,
    notificationDuration: 1500,
  },
  targetsList: {
    refreshInterval: 30000,
  },
  countdownInterval: 60000,
  upliftUIEnabled: isTruthy(process.env.UPLIFT_UI_ENABLED),
  generalAviationUIEnabled: isTruthy(process.env.GENERAL_AVIATION_UI_ENABLED),
  reportAccessibility: isTruthy(process.env.REPORT_ACCESSIBILITY),
  enableWebSocket: isTruthy(process.env.ENABLE_WEBSOCKET),
  enableUpliftTargetSheet: isTruthy(process.env.UPLIFT_TARGET_SHEET_ENABLED),
  legacyAirpaxTaskListEnabled: isTruthy(process.env.LEGACY_AIRPAX_TASK_LIST_ENABLED),
  scanAppEnabled: isTruthy(process.env.SCAN_APP_ENABLED),
  feedbackUrl: process.env.FEEDBACK_URL,
  cerberusProfileURL: process.env.CERBERUS_PROFILE_URL,
};

export default config;
