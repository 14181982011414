import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Config(s)
import { SCRIM_TYPE } from '../../../../utils/constants';

// Contexts
import { useScrim } from '../../../../context/ScrimContext';

// Component(s)
import Modal from './components/shared/prompts/Modal';

// Util(s)
import { scrollToElement } from './helper/scrollTo';

// Styling
import './CancelEditModal.scss';

const CancelEditModal = ({ onProceed, onCancel }) => {
  const { openScrim, closeScrim } = useScrim();

  useEffect(() => {
    openScrim(SCRIM_TYPE.FULL);
    scrollToElement('root', null);

    return () => {
      closeScrim();
    };
  }, []);

  return (
    <Modal
      id="cancel-edit-modal"
      classBlock="govuk-grid-row"
      onCancel={onCancel}
      onProceed={onProceed}
      modalProps={{
        heading: 'Task updates will be discarded',
        text: 'Your task updates will not be saved if you proceed.',
        actions: {
          proceed: 'Proceed and discard',
          cancel: 'Cancel',
        },
      }}
    />
  );
};

CancelEditModal.propTypes = {
  onProceed: PropTypes.func,
  onCancel: PropTypes.func,
};

CancelEditModal.defaultProps = {
  onProceed: () => {},
  onCancel: () => {},
};

export default CancelEditModal;
