// Util
import validateDate from '../Datetime/Validation/validateDate';

const HidingConstants = {
  stringValue: 'HIDDEN',
  dateValue: '-1000000000-01-01T00:00:01Z',
  numberValue: -2147483647,
};

const shouldHideObjectArrayByAttr = (arr, attr) => {
  return arr.every((item) => item[attr] === HidingConstants.stringValue);
};

const shouldHideStringArray = (arr) => {
  return arr.every((item) => {
    return item === HidingConstants.stringValue;
  });
};

const shouldHideNumber = (number) => {
  return number === HidingConstants.numberValue;
};

const shouldHideString = (value) => {
  return value === HidingConstants.stringValue;
};

const shouldHideDate = (date) => {
  return date === HidingConstants.dateValue;
};

const getHiddenValue = () => {
  return HidingConstants.stringValue.substring(0, 1) + HidingConstants.stringValue.substring(1).toLocaleLowerCase();
};

const isAllHiddenInStringArray = (arr) => {
  return arr.every((item) => {
    return item === HidingConstants.stringValue || item === getHiddenValue();
  });
};

const isUTCDate = (date) => {
  return validateDate(date);
};

const isDouble = (value) => {
  return value % 1 > 0;
};

const toHiddenFormat = (value) => {
  if (isUTCDate(value)) {
    return HidingConstants.dateValue;
  }

  if (typeof value === 'number' && isDouble(value)) {
    return HidingConstants.numberValue;
  }

  if (typeof value === 'number') {
    return HidingConstants.numberValue;
  }

  return HidingConstants.stringValue;
};

const hide = (data) => {
  for (const k in data) {
    if (typeof data[k] === 'object' && data[k]) {
      hide(data[k]);
    } else {
      data[k] = toHiddenFormat(data[k]);
    }
  }
};

export {
  HidingConstants,
  getHiddenValue,
  hide,
  isAllHiddenInStringArray,
  isUTCDate,
  shouldHideDate,
  shouldHideNumber,
  shouldHideObjectArrayByAttr,
  shouldHideString,
  shouldHideStringArray,
  toHiddenFormat,
  validateDate,
};
