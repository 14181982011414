// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import SBTInfo from './SBTInfo';
import Table from '../../../Table/Table';

// Util(s)
import TargetRisksUtil from '../../../../utils/Risks/Target/targetRisksUtil';

const SectionDivider = ({ restrictWidth, mainSection, rightSection }) => {
  if (!restrictWidth) {
    return (
      <>
        {mainSection}
        {rightSection}
      </>
    );
  }

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        {mainSection}
      </div>
      <div className="govuk-grid-column-one-third">
        {rightSection}
      </div>
    </div>
  );
};

const SBTCheck = ({ selector: _selector, restrictWidth }) => {
  const indicatorMatches = TargetRisksUtil.indicatorMatches(_selector);

  // This object is order according to how data is displayed on the page.
  const LABELS = {
    category: 'Targeting category',
    threatTypeDescription: 'Threat type',
    messageText: 'Message text',
    pointOfContact: 'Point of contact',
    inboundActionCode: 'IB action',
    outboundActionCode: 'OB action',
    warning: 'Warnings',
    entity: 'Entity type',
    descriptor: 'Attribute',
    operator: 'Operator',
    value: 'Value',
  };

  /**
   * Sorts and returns an entity keys according to the dictated by the requiredSortOrder token.
   * @param {*} entity The entity to sort its keys.
   * @param {*} requiredSortOrder The entity used as a reference for the sort.
   * @returns An array of keys sorted according to the requiredSortOrder token.
   */
  const toCustomOrderSbtKeys = (entity, requiredSortOrder) => {
    const entityKeys = Object.keys(entity);
    return Object.keys(requiredSortOrder)
      .map((order) => (entityKeys.includes(order) ? order : null))
      .filter((item) => !!item);
  };

  const formatValue = (value) => {
    if (!value || !value?.length) {
      return null;
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  };

  const toSelectorRows = (selector) => {
    return toCustomOrderSbtKeys(selector, LABELS)
      .map((key) => {
        if (!LABELS[key]) {
          return null;
        }

        return [LABELS[key], formatValue(selector[key])];
      })
      .filter((item) => !!item);
  };

  const toIndicatorMatchesRows = (indicatorMatch) => {
    return Object.keys(indicatorMatch)
      .map((key) => {
        if (!LABELS[key]) {
          return null;
        }

        return [LABELS[key], formatValue(indicatorMatch[key])];
      })
      .filter((item) => !!item);
  };

  return (
    <div id={`selector-${_selector.groupReference}-table`} className="selectors-summary-table">
      <SectionDivider
        restrictWidth={restrictWidth}
        mainSection={(
          <Heading id={`selector-${_selector.groupReference}-group-ref-header`} size="m">
            {_selector.groupReference}
          </Heading>
        )}
      />
      <SectionDivider
        restrictWidth
        mainSection={(
          <Table
            id={`selector-${_selector.groupReference}-datatable`}
            headings={[]}
            rows={toSelectorRows(_selector)}
          />
        )}
        rightSection={<SBTInfo selector={_selector} />}
      />
      <ComponentWrapper show={indicatorMatches?.length}>
        <SectionDivider
          restrictWidth={restrictWidth}
          mainSection={(
            <>
              <Heading id={`indicator-match-${_selector.reference}-header`} size="m">
                {_selector.reference}
              </Heading>
              {indicatorMatches.map((indicatorMatch, index) => (
                <Table
                  id={`indicator-match-${index + 1}-datatable`}
                  key={`indicator-match-${index + 1}`}
                  headings={[]}
                  rows={toIndicatorMatchesRows(indicatorMatch)}
                />
              ))}
            </>
          )}
        />
      </ComponentWrapper>
    </div>
  );
};

SBTCheck.propTypes = {
  selector: PropTypes.shape({
    localReference: PropTypes.string,
    reference: PropTypes.string,
  }),
  restrictWidth: PropTypes.bool,
};

SBTCheck.defaultProps = {
  selector: null,
  restrictWidth: false,
};

SectionDivider.propTypes = {
  restrictWidth: PropTypes.bool,
  mainSection: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rightSection: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

SectionDivider.defaultProps = {
  restrictWidth: false,
  mainSection: null,
  rightSection: null,
};

export default memo(SBTCheck);
