import { useEffect, useState } from 'react';

// Util(s)
import { addEventListener, removeEventListener } from './helper/events';

/**
 * Determines if target element is vertically hidden by the root element token.
 *
 * @param {*} rootRef The root element ref that blocks target ref element.
 * @param {*} targetRef The ref of the element that is hidden by root element.
 * @param {{
 *   initialInView: boolean
 * }} options Hook options to determine if target Ref is in hidden.
 * @returns {boolean} Boolean value on whether element is hidden in viewport.
 */
const useIsHiddenInViewport = (rootRef, targetRef, options = {}) => {
  const { initialInView = true } = options;
  const [isHiddenInViewport, setIsHiddenInViewport] = useState(initialInView);

  const handleEvent = () => {
    if (!rootRef?.current || !targetRef?.current) {
      return;
    }

    const rootRect = rootRef.current.getBoundingClientRect();
    const targetRect = targetRef.current.getBoundingClientRect();
    setIsHiddenInViewport((rootRect.top + rootRect.height > targetRect.top));
  };

  useEffect(() => {
    addEventListener([
      { type: 'scroll', handler: handleEvent, options: { passive: true } },
      { type: 'resize', handler: handleEvent, options: { passive: true } },
    ]);
    handleEvent();

    return () => {
      removeEventListener([
        { type: 'scroll', handler: handleEvent },
        { type: 'resize', handler: handleEvent },
      ]);
    };
  }, []);

  return isHiddenInViewport;
};

export default useIsHiddenInViewport;
