import { HAZARDOUS_MAPPING } from '../goodsUtil';

const toHazardousFormatted = (hazardous) => {
  return HAZARDOUS_MAPPING[hazardous];
};

const getGrossWeight = (goods) => {
  return goods?.grossWeight || null;
};

const getNetWeight = (goods) => {
  return goods?.netWeight || null;
};

const getGoodsWeight = (goods) => {
  return goods?.weight || undefined;
};

const getGoodsDescription = (goods) => {
  return goods?.description || undefined;
};

const getHazardous = (goods) => {
  return goods?.hazardous;
};

const getGoods = (informationSheet) => {
  return informationSheet?.movement?.goods || undefined;
};

const TargetGoodsUtil = {
  get: getGoods,
  description: getGoodsDescription,
  hazardous: getHazardous,
  weight: getGoodsWeight,
  netWeight: getNetWeight,
  grossWeight: getGrossWeight,
  format: {
    hazardous: toHazardousFormatted,
  },
};

export default TargetGoodsUtil;
