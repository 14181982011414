import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '../../../../../context/Keycloak';

// context
import { ApplicationContext } from '../../../../../context/ApplicationContext';
import { ViewContext } from '../../../../../context/ViewContext';

// Services
import AxiosRequests from '../../../../../api/axiosRequests';

// Components
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../components/RenderForm/RenderForm';

// Config
import config from '../../../../../utils/config';
import { VIEW } from '../../../../../utils/constants';

// Hook(s)
import useFetchInformationSheet from '../../../../../utils/Hooks/useFetchInformationSheet';
import useSetTabTitle, { TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';

// Utils
import getTisForm from '../../../../../utils/Form/ReactForm/getTisForm';
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';
import { TargetInformationUtil } from '../../../../../utils';

// Styling
import './IssueTarget.scss';

const IssueTarget = ({ isSubmitted, onCancel, setSubmitted, taskData, children }) => {
  const { taskId } = useParams();
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isLoading, setIsLoading] = useState(true);
  const { getInformationSheet, storeInformationSheet } = useContext(ApplicationContext);
  const { view } = useContext(ViewContext);
  const { informationSheet, isLoading: isLoadingTargetInformationSheet } = useFetchInformationSheet(taskId);

  const toTabTitle = () => {
    const placeholder = TAB_TITLES.TARGET_INFORMATION_SHEET;

    if ([VIEW.AIRPAX, VIEW.AIRPAX_V2].includes(view)) {
      return `${placeholder} (Airpax)`;
    }

    if (view === VIEW.RORO) {
      return `${placeholder} (RoRo)`;
    }

    return placeholder;
  };

  useSetTabTitle(toTabTitle());

  useEffect(() => {
    if (!isLoadingTargetInformationSheet) {
      storeInformationSheet(TargetInformationUtil.prefillPayload(informationSheet, view));
      setIsLoading(false);
    }
  }, [informationSheet]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  return (
    <div id="issue-target-form">
      <RenderForm
        viewOnly={false}
        cacheTisFormData
        form={getTisForm(view, taskData)}
        preFillData={getInformationSheet()}
        onSubmit={
        async ({ data }) => {
          try {
            await AxiosRequests.createTarget(apiClient,
              TargetInformationUtil.submissionPayload(taskData, data, keycloak));
            data?.meta?.documents.forEach((document) => delete document.file);
            storeInformationSheet({});
            setSubmitted();
            window.scrollTo(0, 0);
          } catch (e) {
            storeInformationSheet(TargetInformationUtil.convertToPrefill(data));
          }
        }
      }
        onCancel={onCancel}
      />
    </div>
  );
};

export default IssueTarget;
