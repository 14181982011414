// Global import(s)
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

// Context(s)
import { useTabs } from './TabContext';
import { useView } from './ViewContext';

const NotificationContext = createContext({
  notification: null,
  setNotification: () => {},
  notificationOnTab: {},
  setNotificationOnTab: () => {},
  setupTabNotificationIfRequired: () => {},
  clearTabNotification: () => {},
});

const NotificationProvider = ({ children }) => {
  const { DEFAULTS } = useTabs();
  const { view } = useView();
  const [notification, setNotification] = useState(null);
  const [notificationOnTab, setNotificationOnTab] = useState(DEFAULTS?.[view]?.notifyOn || {});

  const setupTabNotification = (tab, enabled) => {
    if (Object.keys(notificationOnTab).includes(tab)) {
      setNotificationOnTab((prev) => ({
        ...prev,
        [tab]: enabled,
      }));
    }
  };

  const setupTabNotificationIfRequired = (tabs, selectedTab, targetStatus) => {
    const foundTab = tabs.find(({ statuses }) => statuses.includes(targetStatus));
    if (selectedTab && foundTab && selectedTab !== foundTab.id) {
      setupTabNotification(foundTab.id, true);
    }
  };

  const clearTabNotification = () => {
    setNotificationOnTab(DEFAULTS[view]?.notifyOn || {});
  };

  useEffect(() => {
    if (view) {
      setNotificationOnTab(DEFAULTS?.[view]?.notifyOn || {});
    }
  }, [view]);

  const value = useMemo(() => ({
    notification,
    setNotification,
    notificationOnTab,
    setNotificationOnTab,
    setupTabNotificationIfRequired,
    clearTabNotification,
  }), [
    notification,
    setNotification,
    notificationOnTab,
    setNotificationOnTab,
    notificationOnTab,
    setNotificationOnTab,
    setupTabNotificationIfRequired,
    clearTabNotification,
  ]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationContext, NotificationProvider, useNotification };
