// Global import(s)
import { Heading, InsetText } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import AbuseTypes from '../components/AbuseTypes';
import OperationName from '../components/OperationName';
import PublicInterestImmunity from '../components/PublicInterestImmunity';
import ReferralReason from '../components/ReferralReason';
import SelectPnrAccess from '../components/SelectPnrAccess';
import TargetCategory from '../components/TargetCategory';
import TargetRecipients from '../components/TargetRecipients';
import Watchlist from '../components/Watchlist';

// Context
import { useTask } from '../../../../../../../../context/TaskContext';

// Util(s)
import { isIdpSubMode } from '../../../../../../../../utils/Task/taskUtil';
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';

const CreateTarget = ({
  componentErrors,
  formData,
  onChange,
  onChangeRecipient,
  onRemoveRecipient,
  onSetAddActionOption,
  task,
}) => {
  const { subMode } = useTask();

  return (
    <>
      <Heading size="m">Target sheet recipients</Heading>
      {/* TODO: Pivot this inset-text based on credibility checks recorded */}
      <InsetText>HMRC check results are not included in the target sheet sent to the frontline.</InsetText>
      <TargetRecipients
        componentErrors={componentErrors}
        formData={formData}
        onChangeRecipient={onChangeRecipient}
        onRemoveRecipient={(previouslySelectedRecipient) => {
          onSetAddActionOption((prev) => ({
            ...prev,
            previouslySelectedRecipient,
          }));
          onRemoveRecipient(previouslySelectedRecipient);
        }}
        onAddActionOption={() => {
          onSetAddActionOption((prev) => ({
            ...prev,
            show: true,
          }));
        }}
      />

      <ComponentWrapper show={!isIdpSubMode(subMode)}>
        <AbuseTypes onChange={onChange} componentErrors={componentErrors} formData={formData} />
        <ReferralReason onChange={onChange} componentErrors={componentErrors} formData={formData} />
        <TargetCategory onChange={onChange} componentErrors={componentErrors} formData={formData} />
        <OperationName onChange={onChange} formData={formData} />
        <Watchlist onChange={onChange} componentErrors={componentErrors} formData={formData} />
        <PublicInterestImmunity onChange={onChange} componentErrors={componentErrors} formData={formData} />
        <ComponentWrapper show={!task?.hidingEnabled}>
          <SelectPnrAccess
            componentErrors={componentErrors}
            formData={formData}
            id="select-pnr-access"
            onChange={onChange}
            taskId={task.id}
          />
        </ComponentWrapper>
      </ComponentWrapper>
    </>
  );
};

CreateTarget.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
  onSetAddActionOption: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
  task: PropTypes.shape({}).isRequired,
};

CreateTarget.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(CreateTarget);
