import { FORM_ACTIONS } from '../../utils/constants';

export default {
  id: 'note',
  version: '1.0.1',
  name: 'note',
  title: 'Add note',
  type: 'form',
  components: [],
  pages: [{
    id: 'note',
    name: 'note',
    components: [
      {
        type: 'heading',
        size: 'l',
        content: 'Add task note',
      },
      {
        type: 'html',
        tagName: 'p',
        content: 'Enter a progress note for this task.',
      },
      {
        type: 'html',
        tagName: 'p',
        className: 'font--grey',
        content: 'Notes are editable until you either unclaim the task, issue a target or complete your assessment of this task.',
      },
      {
        type: 'html',
        tagName: 'p',
        className: 'font--grey',
        content: 'Task notes are not included on target sheets.',
      },
      {
        id: 'addNote',
        fieldId: 'addNote',
        label: 'Add note',
        'aria-label': 'add note',
        type: 'textarea',
        rows: 3,
        required: true,
        showCharacterCount: true,
        custom_errors: [
          {
            type: 'required',
            message: 'Enter a note on progress with this task',
          },
        ],
      },
    ],
    actions: [
      {
        type: FORM_ACTIONS.SUBMIT,
        validate: true,
        label: 'Continue',
      },
      {
        type: FORM_ACTIONS.CANCEL,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  }],
};
