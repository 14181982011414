import { Details } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ContactTextItem from './ContactTextItem';

// Styling
import './ContactText.scss';

const ContactText = ({ contactTextMatches }) => {
  if (!contactTextMatches || !contactTextMatches?.length) {
    return null;
  }

  const toDetailsSummary = () => {
    const matchedSummary = contactTextMatches
      .filter((contactTextMatch) => contactTextMatch.matched)
      .map((contactTextMatch) => contactTextMatch.text)
      .join('     ');

    return `Contact text${matchedSummary.length ? ` - ${matchedSummary}` : ''}`;
  };

  return (
    <Details id="contact-text" summary={toDetailsSummary()}>
      {contactTextMatches.map((contactTextMatch, idx) => <ContactTextItem key={`${contactTextMatch.text}-${idx}`} id={`contact-text-${contactTextMatch.text}`} contactText={contactTextMatch} />)}
    </Details>
  );
};

ContactText.propTypes = {
  contactTextMatches: PropTypes.arrayOf(PropTypes.shape({})),
};

ContactText.defaultProps = {
  contactTextMatches: [],
};

export default ContactText;
