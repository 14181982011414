import { VIEW } from '../../../../../../../../utils/constants';

/**
 * Checks if the current recipient selection is a previous selection.
 *
 * @param {Object} formData - An object representing the selected options within a form.
 * @param {Object} data - An object representing the selected option.
 * @param {String} view - A string to show what view we are in.
 * @returns {boolean} Returns true if the formData contains the current selection otherwise, false.
 */
const containsRecipient = (formData, data, view) => {
  if (view === VIEW.IDP) {
    if (typeof data?.targetRecipient === 'string') {
      return !!formData?.targetRecipients?.find((opt) => opt.email === data?.targetRecipient);
    }
    return !!formData?.targetRecipients?.find((opt) => opt.email === data?.targetRecipient?.email);
  }
  return !!formData?.targetRecipients?.find((opt) => opt.value === data?.targetRecipient?.value);
};

export default containsRecipient;
