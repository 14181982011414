import { TEXT_DEFAULTS } from '../../../../../../../utils/constants';

export const COMPONENT_IDS = {
  CHECKS: 'checks',
  ATLAS_TRACE: 'atlasTrace',
  CRS_TRACE: 'crsTrace',
  CENTAUR_TRACE: 'centaurTrace',
  DVA_TRACE: 'dvaTrace',
  ENTITY_SEARCH_TRACE: 'entitySearchTrace',
  HMRC_TRACE: 'hmrcTrace',
  INTERPOL_TRACE: 'interpolTrace',
  SIP_TRACE: 'sipTrace',
  OPEN_SOURCE_TRACE: 'openSourceTrace',
  OTHER: 'otherSystems',
  PEGA_TRACE: 'pegaTrace',
  NOT_REQUIRED: 'notRequired',
};

export const CHECK_TYPE = {
  TRACE: 'TRACE',
  OTHER: 'OTHER',
};

export const SYSTEM_CHECKS = {
  ATLAS: 'ATLAS',
  CRS: 'CRS',
  CENTAUR: 'CENTAUR',
  DVA: 'DVA',
  ENTITY_SEARCH: 'ENTITY_SEARCH',
  HMRC: 'HMRC',
  INTERPOL: 'INTERPOL',
  OPEN_SOURCE: 'OPEN_SOURCE',
  OTHER: 'OTHER',
  PEGA: 'PEGA',
  SIP: 'SIP',
};

export const COMMODITIES_CHECKS = [
  SYSTEM_CHECKS.ATLAS,
  SYSTEM_CHECKS.ENTITY_SEARCH,
  SYSTEM_CHECKS.HMRC,
  SYSTEM_CHECKS.SIP,
  SYSTEM_CHECKS.OPEN_SOURCE,
  SYSTEM_CHECKS.OTHER,
];

export const IDP_CHECKS = [
  SYSTEM_CHECKS.ATLAS,
  SYSTEM_CHECKS.CRS,
  SYSTEM_CHECKS.DVA,
  SYSTEM_CHECKS.INTERPOL,
  SYSTEM_CHECKS.PEGA,
  SYSTEM_CHECKS.OTHER,
];

export const GA_CHECKS = [
  SYSTEM_CHECKS.ATLAS,
  SYSTEM_CHECKS.HMRC,
  SYSTEM_CHECKS.SIP,
  SYSTEM_CHECKS.ENTITY_SEARCH,
  SYSTEM_CHECKS.CENTAUR,
  SYSTEM_CHECKS.OPEN_SOURCE,
  SYSTEM_CHECKS.OTHER,
];

export const COMMODITIES_SYSTEM_CHECKS_LABEL = {
  [SYSTEM_CHECKS.ATLAS]: 'Atlas',
  [SYSTEM_CHECKS.HMRC]: 'HMRC',
  [SYSTEM_CHECKS.SIP]: 'SIP',
  [SYSTEM_CHECKS.ENTITY_SEARCH]: 'Entity search',
  [SYSTEM_CHECKS.OPEN_SOURCE]: 'Open source',
  [SYSTEM_CHECKS.OTHER]: TEXT_DEFAULTS.CHECKS.ADTL_CCHECK,
};

export const IDP_SYSTEM_CHECKS_LABEL = {
  [SYSTEM_CHECKS.ATLAS]: 'Atlas',
  [SYSTEM_CHECKS.DVA]: 'DVA',
  [SYSTEM_CHECKS.INTERPOL]: 'i24/7 (interpol)',
  [SYSTEM_CHECKS.CRS]: 'CRS',
  [SYSTEM_CHECKS.PEGA]: 'PEGA',
  [SYSTEM_CHECKS.OTHER]: TEXT_DEFAULTS.CHECKS.ADTL_CCHECK,
};

export const GA_SYSTEM_CHECKS_LABEL = {
  [SYSTEM_CHECKS.ATLAS]: 'Atlas',
  [SYSTEM_CHECKS.SIP]: 'SIP',
  [SYSTEM_CHECKS.HMRC]: 'HMRC',
  [SYSTEM_CHECKS.ENTITY_SEARCH]: 'Entity search',
  [SYSTEM_CHECKS.CENTAUR]: 'Centaur',
  [SYSTEM_CHECKS.OPEN_SOURCE]: 'Open source',
  [SYSTEM_CHECKS.OTHER]: TEXT_DEFAULTS.CHECKS.ADTL_CCHECK,
};

export const CREDIBILITY_FORM_PREFIX = 'passenger';
export const OTHER_CHECKS_FORM_PREFIX = 'otherChecks';
