// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { FRONTLINE_OUTCOME, FRONTLINE_OUTCOME_NAME, TARGETER_OUTCOME, TARGETER_OUTCOME_NAME, TASK_STATUS, TARGETER_COMPLETION_REASON_NAME, TARGETER_COMPLETION_REASON } from '../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { toTaskStatusTagClassname } from '../../../../../../../components/Utils/Component/toClassName';
import { toTimestamp } from '../../../helper/toContent';

// Styling
import './ActionedStatus.scss';

const ActionedStatus = ({ taskStatus, isDismissedByTargeter, isWithdrawnByTargeter, targeterAction, frontlineAction, actionedAt }) => {
  if ([TASK_STATUS.NEW, TASK_STATUS.SELECTED_FOR_DISMISSAL, TASK_STATUS.IN_PROGRESS].includes(taskStatus)) {
    return null;
  }

  const toCompletionReason = (reason) => {
    if (!reason) {
      return null;
    }
    if (reason.toLowerCase().includes('no target required')) {
      return TARGETER_COMPLETION_REASON_NAME[TARGETER_COMPLETION_REASON.NO_TARGET_REQUIRED].toUpperCase();
    }

    if (['false match', 'false selector match', 'false rule match'].includes(reason.toLowerCase())) {
      return TARGETER_COMPLETION_REASON_NAME[TARGETER_COMPLETION_REASON.FALSE_MATCH].toUpperCase();
    }

    // Default to other reason provided
    return TARGETER_COMPLETION_REASON_NAME[TARGETER_COMPLETION_REASON.OTHER].toUpperCase();
  };

  const toFormattedFrontlineOutcome = (outcome) => {
    if (outcome === FRONTLINE_OUTCOME.POSITIVE) {
      return 'Positive Examination';
    }

    if (outcome === FRONTLINE_OUTCOME.NEGATIVE) {
      return 'Negative Examination';
    }

    return FRONTLINE_OUTCOME_NAME[outcome];
  };

  const formattedStatus = (() => {
    if (taskStatus === TASK_STATUS.ISSUED) {
      return 'Target Issued';
    }

    if (taskStatus === TASK_STATUS.RECEIVED) {
      return 'Target Received';
    }

    if (isDismissedByTargeter) {
      return [TARGETER_OUTCOME.DISMISSED, toCompletionReason(targeterAction?.reason)].filter((item) => !!item).join(' - ');
    }

    if (isWithdrawnByTargeter) {
      return [TARGETER_OUTCOME_NAME[TARGETER_OUTCOME.WITHDRAWN], toCompletionReason(targeterAction?.reason)].join(' - ');
    }

    if (targeterAction?.outcome && targeterAction?.outcome.toUpperCase().includes('HIT')) {
      return TARGETER_OUTCOME_NAME[TARGETER_OUTCOME.HIT];
    }

    if (targeterAction?.outcome && targeterAction?.outcome.toUpperCase().includes('TARGET_PENDING')) {
      return TARGETER_OUTCOME_NAME[TARGETER_OUTCOME.TARGET_PENDING];
    }

    if (taskStatus === TASK_STATUS.COMPLETE && !isWithdrawnByTargeter && !isDismissedByTargeter) {
      return toFormattedFrontlineOutcome(frontlineAction?.outcome);
    }

    return null;
  })();
  const formattedActionedAt = toTimestamp({ timestamp: actionedAt }, 'DD MMM YYYY HH:mm');

  if (!formattedStatus && !formattedActionedAt) {
    return null;
  }

  return (
    <p id="actioned-status-wrapper">
      <ComponentWrapper show={formattedStatus}>
        <Tag
          id="actioned-task-status"
          text={formattedStatus}
          className={`tag ${toTaskStatusTagClassname(
            taskStatus,
            targeterAction?.outcome,
            frontlineAction?.outcome,
            isWithdrawnByTargeter,
            isDismissedByTargeter,
          )}`}
        />
      </ComponentWrapper>
      <ComponentWrapper show={formattedActionedAt}>
        <span
          id="actioned-timestamp"
          className="font--grey"
        >
          {formattedActionedAt}
        </span>
      </ComponentWrapper>
    </p>
  );
};

ActionedStatus.propTypes = {
  taskStatus: PropTypes.string.isRequired,
  isWithdrawnByTargeter: PropTypes.bool.isRequired,
  isDismissedByTargeter: PropTypes.bool.isRequired,
  targeterAction: PropTypes.shape({
    outcome: PropTypes.string,
    reason: PropTypes.string,
  }),
  frontlineAction: PropTypes.shape({
    outcome: PropTypes.string,
  }),
  actionedAt: PropTypes.string,
};

ActionedStatus.defaultProps = {
  targeterAction: null,
  actionedAt: null,
  frontlineAction: null,
};

export default ActionedStatus;
