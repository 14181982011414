import { useEffect, useState } from 'react';

// Util(s)
import { addEventListener, removeEventListener } from './helper/events';

export const Direction = {
  UP: 'UP',
  DOWN: 'DOWN',
};

/**
 * Determines scrolling direction.
 *
 * @returns {{scrollDirection, Direction: {DOWN: string, UP: string }}} An object containing boolean tokens for the different scroll directions.
 */
const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(Direction.DOWN);
  let ticking = false;

  const updateScrollDirection = (currentScrollPosition = 0) => {
    setScrollDirection(currentScrollPosition < 0 ? Direction.UP : Direction.DOWN);
  };

  const onScroll = (e) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        updateScrollDirection(e?.deltaY || window?.deltaY);
        ticking = true;
      });
    }
    ticking = false;
  };

  useEffect(() => {
    addEventListener([
      { type: 'wheel', handler: (e) => onScroll(e), options: { passive: true } },
      { type: 'resize', handler: (e) => onScroll(e), options: { passive: true } },
    ]);
    onScroll();

    return () => {
      removeEventListener([
        { type: 'wheel', handler: onScroll },
        { type: 'resize', handler: onScroll },
      ]);
    };
  }, []);

  return { scrollDirection, Direction };
};

export default useScrollDirection;
