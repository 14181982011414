// Config(s)
import { COMPONENT_IDS, SYSTEM_CHECKS } from '../constants';
import { DEFAULT_COMPONENT_ERROR, NOT_REQUIRED_ERROR } from './constants';
import { STRINGS } from '../../../../../../../../utils/constants';

const validateTrace = (system, componentId, entityData, entityIndex) => {
  const formattedSystem = system.toLowerCase();
  const errors = [];
  if (!entityData?.otherChecks?.[formattedSystem]?.trace) {
    errors.push({
      componentId: `${componentId}${entityIndex}`,
      message: DEFAULT_COMPONENT_ERROR,
    });
  }

  if (entityData?.otherChecks?.[formattedSystem]?.trace === 'TRACE' && !entityData?.otherChecks?.[formattedSystem]?.comment) {
    errors.push({
      componentId: `${componentId}Comment${entityIndex}`,
      message: DEFAULT_COMPONENT_ERROR,
    });
  }

  return errors;
};

const validateOtherSystemsChecks = (entityData, entityIndex) => {
  const errors = [];

  entityData?.otherChecks?.[COMPONENT_IDS.OTHER]?.forEach((systemCheck, index) => {
    const individualSystemName = COMPONENT_IDS.OTHER.slice(0, -1);

    if (!systemCheck?.systemChecked && !systemCheck?.trace) {
      errors.push({
        componentId: `${individualSystemName}Checked${index}-${entityIndex}Group`,
        message: DEFAULT_COMPONENT_ERROR,
      });
      return;
    }

    if (!systemCheck?.systemChecked) {
      errors.push({
        componentId: `${individualSystemName}Checked${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }

    if (!systemCheck?.trace) {
      errors.push({
        componentId: `${individualSystemName}Trace${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }

    if (systemCheck?.trace === 'TRACE' && !systemCheck?.comment) {
      errors.push({
        componentId: `${individualSystemName}TraceComment${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }
  });

  return errors;
};

const validateNotRequiredChecks = (entityData, entityIndex) => {
  if (!entityData?.otherChecks?.[COMPONENT_IDS.NOT_REQUIRED]) {
    return [{
      componentId: `${COMPONENT_IDS.NOT_REQUIRED}${entityIndex}`,
      message: NOT_REQUIRED_ERROR,
    }];
  }

  return [];
};

const validateTraceChecks = (system, entityData, entityIndex) => {
  switch (system) {
    case SYSTEM_CHECKS.ATLAS: {
      return validateTrace(system, COMPONENT_IDS.ATLAS_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.CRS: {
      return validateTrace(system, COMPONENT_IDS.CRS_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.CENTAUR: {
      return validateTrace(system, COMPONENT_IDS.CENTAUR_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.DVA: {
      return validateTrace(system, COMPONENT_IDS.DVA_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.INTERPOL: {
      return validateTrace(system, COMPONENT_IDS.INTERPOL_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.PEGA: {
      return validateTrace(system, COMPONENT_IDS.PEGA_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.ENTITY_SEARCH: {
      return validateTrace(system, COMPONENT_IDS.ENTITY_SEARCH_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.HMRC: {
      return validateTrace(system, COMPONENT_IDS.HMRC_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.SIP: {
      return validateTrace(system, COMPONENT_IDS.SIP_TRACE, entityData, entityIndex);
    }
    case SYSTEM_CHECKS.OPEN_SOURCE: {
      return validateTrace(system, COMPONENT_IDS.OPEN_SOURCE_TRACE, entityData, entityIndex);
    }
    default: {
      return [];
    }
  }
};

const validateOtherChecks = (entityData, entityIndex) => {
  const errors = [];
  entityData?.otherChecks?.checks.forEach((check) => {
    if (check === STRINGS.FORM.NOT_REQUIRED) {
      errors.push(...validateNotRequiredChecks(entityData, entityIndex));
    }

    if (check === STRINGS.FORM.OTHER) {
      errors.push(...validateOtherSystemsChecks(entityData, entityIndex));
    }

    errors.push(...validateTraceChecks(check, entityData, entityIndex));
  });
  return errors;
};

export default validateOtherChecks;
