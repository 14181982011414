import { useEffect } from 'react';

/**
 * Basic hook that will hide the back link located top left corner of most pages
 * and restores it on component unmount.
 */
const useHideBackLink = () => {
  useEffect(() => {
    const element = document.getElementById('govuk-back-link');

    if (element) {
      element.style.display = 'none';
      element.style.visibility = 'hidden';
    }

    return () => {
      if (element) {
        element.style.display = 'inline-block';
        element.style.visibility = 'visible';
      }
    };
  }, []);
};

export default useHideBackLink;
