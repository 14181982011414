// Global import(s)
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS, RBAC, STRINGS, VIEW } from '../utils/constants';

// Context(s)
import { useUser } from './UserContext';
import { usePermission } from './PermissionContext';

// Util(s)
import { CommonUtil, StorageUtil } from '../utils';

const ViewContext = createContext({});

const ViewProvider = ({ children }) => {
  const location = useLocation();
  const [view, setView] = useState(null);
  const [isTargetsPath, setIsTargetsPath] = useState(false);
  const [isTasksPath, setIsTasksPath] = useState(false);
  const [isSettingView, setIsSettingView] = useState(true);
  const [disableStickyContainer, setDisableStickyContainer] = useState(false);
  const { hasRole } = usePermission();
  const { userRole } = useUser();
  const isTaskListPage = useMemo(() => PATHS.TASK_LISTS().includes(location.pathname), [location.pathname]);
  const isTargetsListPage = useMemo(() => PATHS.TARGET_LISTS().includes(location.pathname), [location.pathname]);
  const useUpliftLayout = useMemo(() => [
    PATHS.UPLIFT.PREFIX,
    PATHS.TARGETS.AIRPAX,
    PATHS.TARGETS.RORO,
    PATHS.GENERAL_AVIATION,
  ].some((path) => location?.pathname?.startsWith(path)), [location?.pathname]);

  const checkIfTargetsPath = () => {
    return [PATHS.TARGETS.RORO, PATHS.TARGETS.AIRPAX].some((path) => location.pathname.startsWith(path));
  };

  const checkIfTasksPath = () => {
    return [PATHS.RORO, PATHS.AIRPAX, PATHS.UPLIFT.AIRPAX, PATHS.GENERAL_AVIATION].some((path) => location.pathname.startsWith(path));
  };

  useEffect(() => {
    if (!isSettingView) {
      setIsSettingView(true);
    }
  }, [location.pathname, userRole, setIsSettingView]);

  const getMainAirpaxView = () => {
    if (view && localStorage.getItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE)
      && localStorage.getItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE) !== STRINGS.AIRPAX) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE);
      return view;
    }

    if (hasRole(RBAC.TARGETER_AIRPAX_CTBP)) {
      return VIEW.CTBP;
    }

    if (hasRole(RBAC.TARGETER_AIRPAX_OSDT)) {
      return VIEW.OSDT;
    }

    if (hasRole(RBAC.TARGETER_AIRPAX_COMMODITIES)) {
      return VIEW.COMMODITIES;
    }

    if (hasRole(RBAC.TARGETER_AIRPAX_IDP)) {
      return VIEW.IDP;
    }

    return null;
  };

  useEffect(() => {
    if (isSettingView && userRole) {
      let filteredView = getMainAirpaxView();

      if (localStorage.getItem(LOCAL_STORAGE_KEYS.VIEW_SWAP)
        && filteredView !== localStorage.getItem(LOCAL_STORAGE_KEYS.VIEW_SWAP)) {
        filteredView = localStorage.getItem(LOCAL_STORAGE_KEYS.VIEW_SWAP);
      }

      setView(CommonUtil.viewByPathAndRole(location.pathname, userRole, filteredView));
      setIsTargetsPath(checkIfTargetsPath());
      setIsTasksPath(checkIfTasksPath());
      setIsSettingView(false);

      setTimeout(() => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.VIEW_SWAP);
      }, 500);
    }
  }, [isSettingView, userRole, setView, setIsTargetsPath, setIsSettingView]);

  useEffect(() => {
    StorageUtil.remove(LOCAL_STORAGE_KEYS.CACHED_TAB);
  }, [view]);

  const value = useMemo(() => ({
    view,
    setView,
    isSettingView,
    setIsSettingView,
    useUpliftLayout,
    isTargetsPath,
    setIsTargetsPath,
    isTasksPath,
    setIsTasksPath,
    isTaskListPage,
    isTargetsListPage,
    disableStickyContainer,
    setDisableStickyContainer,
  }), [view, isSettingView, useUpliftLayout, isTargetsPath, isTaskListPage, isTargetsListPage, isTasksPath, disableStickyContainer]);

  return (
    <ViewContext.Provider value={value}>
      {children}
    </ViewContext.Provider>
  );
};

const useView = () => useContext(ViewContext);

ViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ViewContext, ViewProvider, useView };
