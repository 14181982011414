import { Checkboxes, FormGroup, Heading, TextInput } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../../constants';

const ReferralReason = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.REFERRAL_REASON}
      required
      label={<Heading id="referral-reason-heading" size="m">Reason for referral</Heading>}
      error={componentErrors?.[COMPONENT_IDS.REFERRAL_REASON]?.message}
    >
      <Checkboxes
        id={COMPONENT_IDS.REFERRAL_REASON}
        fieldId={COMPONENT_IDS.REFERRAL_REASON}
        options={[
          {
            value: 'KNOWN_SUSPECT_BOOKER',
            label: 'Know suspect booker',
          },
          {
            value: 'ONE_WAY_TICKET',
            label: 'One way ticket',
          },
          {
            value: 'HOLD_BAGGAGE',
            label: 'Hold baggage',
          },
          {
            value: 'CASH_PAID',
            label: 'Cash paid',
          },
          {
            value: 'OTHER',
            label: 'Other',
            children: (
              <FormGroup
                id={COMPONENT_IDS.OTHER_TYPES_IDP}
                required
                label="Enter the reason for referral"
                error={componentErrors?.[COMPONENT_IDS.OTHER_TYPES_IDP]?.message}
              >
                <TextInput
                  id={COMPONENT_IDS.OTHER_TYPES_IDP}
                  fieldId={COMPONENT_IDS.OTHER_TYPES_IDP}
                  rows="5"
                  onChange={onChange}
                  value={formData?.[COMPONENT_IDS.OTHER_TYPES_IDP]}
                />
              </FormGroup>
            ),
          },
        ]}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.REFERRAL_REASON]}
      />
    </FormGroup>
  );
};

ReferralReason.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

ReferralReason.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(ReferralReason);
