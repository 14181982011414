import { Button, ButtonGroup } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

const FilterControl = ({ idSuffix, onApply, onClear, filterControlStyles, ...props }) => {
  return (
    <ButtonGroup {...props}>
      <Button
        id={['apply-filters', idSuffix].filter((item) => !!item).join('-')}
        classModifiers="blue"
        onClick={onApply}
      >
        Apply
      </Button>
      <Button
        id={['clear-filters', idSuffix].filter((item) => !!item).join('-')}
        classModifiers="secondary"
        onClick={onClear}
      >
        {filterControlStyles.clearFiltersText}
      </Button>
    </ButtonGroup>
  );
};

export default FilterControl;

FilterControl.propTypes = {
  idSuffix: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterControlStyles: PropTypes.shape({}),
};

FilterControl.defaultProps = {
  idSuffix: null,
  filterControlStyles: {
    clearFiltersText: 'Clear filters',
  },
};
