import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import { ArrowIcon } from '../../../../../../../../components/Icon/Icon';

// Utils
import JourneyUtil from '../../../../../../../../utils/Journey/Uplift/journeyUtil';
import { toEventTime } from '../../../../../TaskList/helper/toEventDate';

// Styling
import './JourneyDetail.scss';

const DateTime = ({ time, location, className, left }) => {
  const timeClassNames = classNames(className, 'journey-time-block-container');

  return (
    <div className={timeClassNames}>
      <span className="journey-time-flex-container">
        <span {...left && { className: 'journey-time-left' }}>
          {toEventTime(time, location, TIME_FORMATS.DATE.TIME)}
        </span>
        <span>
          {toEventTime(time, location, TIME_FORMATS.DATE.DEFAULT)}
        </span>
      </span>
    </div>
  );
};

const Location = ({ location, className }) => {
  return (
    <span className={className}>
      {location}
    </span>
  );
};

const JourneyDetail = ({ journey, locationClassName, timeClassName }) => {
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);

  return (
    <span id="summary-movement-journey-detail">
      <ComponentWrapper show={departureTime && departureLocation}>
        <DateTime time={departureTime} location={departureLocation} className={timeClassName} left />
      </ComponentWrapper>
      <span className="journey-location-container">
        <ComponentWrapper show={departureLocation}>
          <Location location={departureLocation} className={locationClassName} />
        </ComponentWrapper>
        <ComponentWrapper show={departureLocation || arrivalLocation}>
          <ArrowIcon classNames={classNames('journey-arrow', locationClassName)} />
        </ComponentWrapper>
        <ComponentWrapper show={arrivalLocation}>
          <Location location={arrivalLocation} className={locationClassName} />
        </ComponentWrapper>
      </span>
      <ComponentWrapper show={arrivalTime && arrivalLocation}>
        <DateTime time={arrivalTime} location={arrivalLocation} className={timeClassName} />
      </ComponentWrapper>
    </span>
  );
};

JourneyDetail.propTypes = {
  journey: PropTypes.shape({}),
  locationClassName: PropTypes.string,
  timeClassName: PropTypes.string,
};

JourneyDetail.defaultProps = {
  journey: null,
  locationClassName: null,
  timeClassName: null,
};

Location.propTypes = {
  location: PropTypes.string,
  className: PropTypes.string,
};

Location.defaultProps = {
  location: null,
  className: null,
};

DateTime.propTypes = {
  time: PropTypes.number,
  location: PropTypes.string,
  className: PropTypes.string,
  left: PropTypes.bool,
};

DateTime.defaultProps = {
  time: null,
  location: null,
  className: null,
  left: false,
};

export default JourneyDetail;
