import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

// Config(s)
import config from '../../../../../../../utils/config';
import QUERY_KEYS from '../../../../../../../utils/Hooks/constants';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useNotification } from '../../../../../../../context/NotificationContext';
import { useTasks } from '../../../../../../../context/TasksContext';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Components
import CommonButton from '../../../../../../../components/Buttons/CommonButton';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';

const DismissFilteredButton = () => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const queryClient = useQueryClient();
  const [inProgress, setInProgress] = useState(false);
  const { setNotification } = useNotification();
  const { taskIds, removeTasksByIds, setTaskIds, setSelectedForDismissal } = useTasks();

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_COUNT] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FILTER_COUNT] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RULES_MATCHED] }),
    ]);
  };

  const onStageDismissal = async () => {
    setInProgress(true);
    await AxiosRequests.stageDismissal(apiClient, { taskIds })
      .then(async () => {
        setNotification("Filtered tasks moved to 'Dismiss' tab");
        setTaskIds([]);
        setSelectedForDismissal([]);
        removeTasksByIds(taskIds);
        await invalidateQueries();
      })
      .finally(() => setInProgress(false));
  };

  return (
    <CommonButton
      inProgress={inProgress}
      commonProps={{
        id: 'dismiss-filtered-button',
        'aria-label': 'remove filtered tasks',
        className: 'govuk-button govuk-!-font-weight-bold',
        onClick: onStageDismissal,
        children: 'Remove filtered tasks',
      }}
    />
  );
};

export default DismissFilteredButton;
