import React from 'react';

import TargetingIndicators from '../../shared/TargetingIndicators';
import Vehicle from '../../shared/Vehicle';
import Booking from '../../shared/Booking';
import Occupants from '../../shared/Occupants';

const TouristVehicleVersionDetails = ({ version, versionDiff }) => {
  return (
    <div className="govuk-task-details-grid govuk-!-padding-top-4">
      <div className="govuk-grid-column-one-third">
        <div>
          <TargetingIndicators version={version} classModifiers={['bottom-border-thin']} />
          <Vehicle version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Booking version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Occupants version={version} versionDiff={versionDiff} />
        </div>
      </div>
    </div>
  );
};

export default TouristVehicleVersionDetails;
