import { FORM_ACTIONS } from '../../utils/constants';

const getForm = (numberOfTasksToDelete, ruleName) => {
  return {
    id: 'bulkDeleteTask',
    version: '1.0.0',
    name: 'bulkDeleteTask',
    title: 'Bulk Delete Tasks',
    type: 'form',
    components: [],
    pages: [{
      id: 'bulkDeleteTasks',
      name: 'bulkDeleteTasks',
      components: [
        {
          type: 'heading',
          size: 'l',
          content: `Delete ${numberOfTasksToDelete} matches for ${ruleName}`,
        },
        {
          type: 'warning',
          className: 'govuk-!-width-two-thirds',
          content: 'Matches are not recoverable once deleted. Tasks matching only this rule will be entirely deleted and removed from all tabs and task counts. Tasks with other rule matches will remain.',
        },
        {
          type: 'html',
          tagName: 'p',
          content: `Select a reason for deleting matches for ${ruleName}`,
        },
        {
          id: 'reason',
          fieldId: 'reason',
          'aria-label': 'reason',
          type: 'radios',
          className: 'govuk-radios--small',
          required: true,
          data: {
            options: [
              {
                value: 'RULE_APPLIED_IN_ERROR',
                label: 'Rule applied in error',
              },
              {
                value: 'OTHER',
                label: 'Other',
                nested: [
                  {
                    id: 'otherReasonDetail',
                    fieldId: 'otherReasonDetail',
                    label: 'Enter a reason for deleting matches',
                    'aria-label': 'Enter a reason for deleting matches',
                    type: 'text',
                    className: 'govuk-!-width-two-thirds',
                    required: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'authoriser',
          fieldId: 'authoriser',
          label: 'Who is the requestor / authoriser for this deletion?',
          'aria-label': 'Who is the requestor / authoriser for this deletion?',
          type: 'text',
          className: 'govuk-!-width-two-thirds',
          required: true,
        },
      ].filter((component) => !!component),
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Delete matches',
          classModifiers: 'warning',
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    }],
  };
};
const bulkDeleteTask = (numberOfTasksToDelete, ruleName) => {
  return getForm(numberOfTasksToDelete, ruleName);
};

export default bulkDeleteTask;
