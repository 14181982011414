import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

// Utils
import { AccountUtil, CommonUtil, StringUtil, TaskVersionUtil } from '../../../../../../utils';
import renderBlock, { getAllKeyValues, toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams } from '../../../helper/common';

import FIELDS from '../../../helper/Fields';

const Account = ({ version, versionDiff, classModifiers }) => {
  const account = AccountUtil.get(version);
  const accountDiff = AccountUtil.get(versionDiff);
  const address = CommonUtil.address(account);
  const telephoneContact = CommonUtil.telephoneContact(account);
  const mobileContact = CommonUtil.mobileContact(account);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Account details</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Full name',
          {
            content: AccountUtil.name(account),
            url: CommonUtil.entitySearchUrl(account),
          },
          TaskVersionUtil.hasAny(accountDiff, [FIELDS.name]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, account)),
          toPreviousMovementLink(account),
        )}
        {renderBlock(
          'Short name',
          {
            content: AccountUtil.shortName(account),
          },
          TaskVersionUtil.hasAny(accountDiff, [FIELDS.shortName]),
        )}
        {renderBlock(
          'Reference number',
          {
            content: AccountUtil.reference(account),
          },
          TaskVersionUtil.hasAny(accountDiff, [FIELDS.reference]),
        )}
        {renderBlock(
          'Address',
          {
            content: StringUtil.format.address(AccountUtil.address(account)),
          },
          TaskVersionUtil.hasAny(accountDiff, [...getAllKeyValues(FIELDS.address)]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, address)),
        )}
        {renderBlock(
          'Telephone',
          {
            content: AccountUtil.telephone(account),
          },
          TaskVersionUtil.hasAny(accountDiff?.contacts?.phone, [FIELDS.value]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, telephoneContact)),
        )}
        {renderBlock(
          'Mobile',
          {
            content: AccountUtil.mobile(account),
          },
          TaskVersionUtil.hasAny(accountDiff?.contacts?.mobile, [FIELDS.value]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, mobileContact)),
        )}
        {renderBlock(
          'Email',
          {
            content: AccountUtil.email(account),
          },
          TaskVersionUtil.hasAny(accountDiff?.email, [FIELDS.email]),
        )}
      </div>
    </div>
  );
};

export default Account;
