// Config(s)
import { DIRECTION } from '../../../../../utils/TargetInformation/constants';

export const toDepartureArrivalLabel = (direction) => {
  if (direction === DIRECTION.OUTBOUND) {
    return 'to departure';
  }

  if (direction === DIRECTION.INBOUND) {
    return 'to arrival';
  }

  return null;
};

const toMovementDirectionLabel = (direction) => {
  if (direction === DIRECTION.OUTBOUND) {
    return 'OUTBOUND';
  }

  if (direction === DIRECTION.INBOUND) {
    return 'INBOUND';
  }

  return null;
};

export default toMovementDirectionLabel;
