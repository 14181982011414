// Global import(s)
import classNames from 'classnames';
import { useMemo } from 'react';

// Hook(s)
import useScreenSize from '../../../../../../../../utils/Hooks/useScreenSize';

// Util(s)
import StringUtil from '../../../../../../../../utils/String/stringUtil';

const useSummary = () => {
  const screenSizes = useScreenSize();
  const currentScreenSize = useMemo(() => {
    const readableSize = Object.keys(screenSizes).reduce(
      (currentSize, key) => (screenSizes[key] && key) || currentSize,
      null,
    );
    return StringUtil.format.camelToHyphenCase(readableSize || 'isNineteenTwentyOrMorePixels');
  }, [screenSizes]);

  return () => {
    return classNames('task-summary', currentScreenSize);
  };
};

export default useSummary;
