import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const CreateTargetButton = ({ onClick }) => {
  return (
    <Button
      id="create-target-button"
      classModifiers="blue"
      onClick={onClick}
      aria-label="create target for task"
    >
      Create target
    </Button>
  );
};

CreateTargetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateTargetButton;
