// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const SummaryListRow = ({ id, label, value, link }) => {
  const constructListRowLink = () => {
    if (link !== null && value !== 0) {
      return <a href={link}>{value}</a>;
    }
    if (Array.isArray(value)) {
      return value.reduce((acc, curr) => {
        const s = curr
          .toLowerCase()
          .split('_')
          .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
          .join(' ');
        acc += s;
        acc += '\n';
        return acc;
      }, '');
    }
    return value;
  };

  return (
    <dl id={`${id}-list__row`} className="govuk-summary-list__row" key={label}>
      <dt id={`${id}-label`} className="govuk-summary-list__key">
        {label}
      </dt>
      <dd id={`${id}-value`} className="govuk-summary-list__value accordion_item">
        {constructListRowLink()}
      </dd>
    </dl>
  );
};

SummaryListRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.string,
};

SummaryListRow.defaultProps = {
  value: null,
  link: null,
};

export default memo(SummaryListRow);
