import React from 'react';

import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';

import { capitalizeFirstLetter } from '../../../../../utils/String/stringUtil';
import { RisksUtil } from '../../../../../utils';

import './SelectorMatches.scss';

const renderIndicatorMatches = (indicatorMatches) => {
  return indicatorMatches.map((indicators) => {
    return Object.entries(indicators).map(([key, value]) => {
      return (
        <div className="panel-content" key={key}>
          <p className="govuk-body govuk-!-font-size-16 govuk-!-font-weight-bold govuk-!-margin-bottom-0">{capitalizeFirstLetter(key)}</p>
          <p className="govuk-body govuk-!-font-size-16">{value}</p>
        </div>
      );
    });
  });
};

const renderOtherGroupFields = (group) => {
  const otherFields = [
    'requestingOfficer',
    'sourceReference',
    'category',
    'threatType',
    'pointOfContactMessage',
    'pointOfContact',
    'inboundActionCode',
    'outboundActionCode',
    'notes',
    'creator',
  ];
  return Object.keys(group).map((key) => {
    const field = otherFields.includes(key) ? key : false;
    if (!field) {
      return undefined;
    }

    return (
      <div className="govuk-grid-row" key={key}>
        <p className="govuk-heading-s govuk-!-margin-bottom-0 govuk-!-font-size-16 govuk-grid-column-one-half">{field}</p>
        <p className="govuk-body govuk-!-margin-bottom-0 govuk-!-font-size-16 govuk-grid-column-one-half govuk-!-padding-0">{group[key]}</p>
      </div>
    );
  }).filter((item) => !!item);
};

const SelectorMatches = ({ version }) => {
  const groups = RisksUtil.getGroups(version).groups;
  const totalNoOfSelectors = RisksUtil.getGroups(version).totalNumberOfSelectors;

  return (
    <div>
      <h2 className="govuk-heading-m">{totalNoOfSelectors} selector matches</h2>
      { groups?.map((group) => {
        return (
          <div key={group?.groupReference} className="govuk-!-margin-bottom-6">
            <h3 className="govuk-heading-s govuk-!-margin-bottom-2 govuk-!-margin-top-2">{group.groupReference}</h3>
            { renderOtherGroupFields(group) }
            <Tabs>
              <TabList>
                { group.selectors.map((selector) => {
                  return (
                    <Tab key={selector?.id}>
                      <p className="govuk-heading-s govuk-!-font-size-16">{selector.description}</p>
                      <p className="govuk-!-font-size-14 font-light">{selector.reference}</p>
                    </Tab>
                  );
                })}
              </TabList>

              { group.selectors.map((selector) => {
                const indicatorMatches = RisksUtil.getMatches(selector);
                const warning = RisksUtil.getWarning(selector);
                return (
                  <TabPanel key={selector?.reference}>
                    <div className="govuk-grid-row govuk-!-margin-top-1">
                      <p className="govuk-heading-s govuk-!-font-size-16 govuk-grid-column-one-half govuk-!-margin-bottom-0">Selector {selector.reference}</p>
                      <p className="govuk-heading-s govuk-!-font-size-16 govuk-grid-column-one-half govuk-!-padding-right-1 govuk-!-margin-bottom-0 font-warning">{warning}</p>
                    </div>
                    <p className="govuk-body govuk-!-font-size-16">All of these attributes are present in this movement.</p>
                    <div className="panel">
                      { indicatorMatches && renderIndicatorMatches(indicatorMatches) }
                    </div>
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        );
      })}
    </div>
  );
};

export default SelectorMatches;
