import { Heading } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Config(s)
import { CREDIBILITY_FORM_PREFIX } from './constants';

// Contexts
import { useFormData } from '../../../../../../../context/FormDataContext';
import { useTask } from '../../../../../../../context/TaskContext';

// Component(s)
import CredibilityCheck from './CredibilityCheck';
import CredibilityChecksCaption from './CredibilityChecksCaption';

// Util(s)
import { CommonUtil } from '../../../../../../../utils';
import { toOtherChecksData, toPncData } from './helper/toFormCredibilityChecks';

// Styling
import './CredibilityChecks.scss';

const CredibilityChecks = ({ setPassengerPosition, setIsAddPncCheckResultsOpen, passengers }) => {
  const { formData, setFormData } = useFormData();
  const { taskState: { isClaimedByUser, isTargetIssued }, isAddDetail, task, taskId, credibilityChecks, setCredibilityChecks } = useTask();
  const [isEditable, setIsEditable] = useState(false);

  const setupCredibilityChecks = () => {
    if (credibilityChecks) {
      setFormData(credibilityChecks);
      return;
    }

    let data = {};
    passengers?.forEach((passenger, index) => {
      const adjustedIndex = index + 1;
      const containerId = `${CREDIBILITY_FORM_PREFIX}${adjustedIndex}`;

      data = {
        ...data,
        [containerId]: {
          ...data?.[containerId],
          entityId: CommonUtil.entityId(passenger),
          pnc: toPncData(passenger),
          otherChecks: toOtherChecksData(passenger),
        },
      };
    });

    setFormData(data);
  };

  useEffect(() => {
    if (task && taskId) {
      setupCredibilityChecks();
    }
  }, [task, taskId]);

  useEffect(() => {
    const hasData = () => {
      return formData && Object.keys(formData)?.length;
    };

    if (taskId && hasData()) {
      setCredibilityChecks(formData);
    }
  }, [formData]);

  useEffect(() => {
    setIsEditable((isClaimedByUser && !isTargetIssued) || (isTargetIssued && isAddDetail));
  }, [isClaimedByUser, isAddDetail]);

  return (
    <div id="credibility-checks" className="govuk-grid-row">
      <div className="govuk-grid-column-full">
        <Heading size="l">Credibility checks</Heading>
        <div id="credibility-checks-caption">
          <CredibilityChecksCaption isEditable={isEditable} />
        </div>
        <div
          id="credibility-checks-container"
          className={classNames('credibility-checks-container', !isEditable && 'editable')}
        >
          {CommonUtil.uniqueId(passengers)
            .map((passenger, index) => (
              <CredibilityCheck
                key={passenger.id}
                passenger={passenger}
                passengerPosition={index}
                setIsAddPncCheckResultsOpen={setIsAddPncCheckResultsOpen}
                setPassengerPosition={setPassengerPosition}
                isEditable={isEditable}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

CredibilityChecks.propTypes = {
  setPassengerPosition: PropTypes.func.isRequired,
  setIsAddPncCheckResultsOpen: PropTypes.func.isRequired,
  passengers: PropTypes.arrayOf(PropTypes.shape({})),
};

CredibilityChecks.defaultProps = {
  passengers: [],
};

export default CredibilityChecks;
