// Global import(s)
import { FormGroup, Heading, TextArea } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const ReasonForAlert = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.REFERRAL_REASON}
      label={<Heading id="reason-for-alert-heading" size="m">Reason for alert</Heading>}
      error={componentErrors?.[COMPONENT_IDS.REFERRAL_REASON]?.message}
      hint="Please use generic terms for intelligence sources and do not mention intel program names."
    >
      <TextArea
        id={COMPONENT_IDS.REFERRAL_REASON}
        fieldId={COMPONENT_IDS.REFERRAL_REASON}
        rows="5"
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.REFERRAL_REASON]}
      />
    </FormGroup>
  );
};

ReasonForAlert.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

ReasonForAlert.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(ReasonForAlert);
