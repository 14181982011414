// Global Import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import DepartureArrivalCountDown from './DepartureArrivalCountDown';
import FlightNumber from '../components/FlightNumber';
import JourneyDetail from '../components/JourneyDetail';
import MultiMatchIndicator from '../../../../../TaskList/components/shared/tags/MultiMatchIndicator';

// Styling
import './SummaryExtraSmall.scss';

const SummaryExtraSmall = ({ task, journey, flight, pnrLocator }) => {
  return (
    <div className="task-summary-extra-small">
      <div className="task-summary-line task-summary-line-one">
        {/* Departs in ... */}
        <div className="departure-arrival-countdown-container">
          <DepartureArrivalCountDown
            journey={journey}
            numberClassName="ho-heading-s"
            unitClassName="ho-body"
            labelClassName="ho-body-xs label-block"
          />
        </div>
        {/* placeholder for MM and BKG MM badge */}
        <div className="multi-match-container">
          <MultiMatchIndicator task={task} />
        </div>
        {/* PNR Locator */}
        <div className="pnr-locator-container">
          <span className="ho-heading-s">
            {pnrLocator}
          </span>
        </div>
      </div>

      <div className="task-summary-line task-summary-line-two">
        {/* Journey details */}
        <JourneyDetail
          journey={journey}
          locationClassName="ho-heading-s"
          timeClassName="ho-body-xs flex-direction-column align-self-center"
        />
      </div>

      <div className="task-summary-line task-summary-line-three">
        {/* Flight number */}
        <FlightNumber flight={flight} className="ho-body" />
        {/* placeholder for number of passengers of interest */}
        <div className="text-align__center" />
        {/* placeholder for API/PNR tags */}
        <div />
      </div>
    </div>
  );
};

SummaryExtraSmall.propTypes = {
  task: PropTypes.shape({}).isRequired,
  journey: PropTypes.shape({}).isRequired,
  flight: PropTypes.shape({}).isRequired,
  pnrLocator: PropTypes.string.isRequired,
};

export default SummaryExtraSmall;
