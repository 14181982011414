import React from 'react';
import classNames from 'classnames';

import { EnrichmentUtil } from '../../../../../utils';

const hasPreviousSeizures = (seizureCount) => {
  return !!seizureCount;
};

const EnrichmentCount = ({ labelText, movementStats, classnames }) => {
  if (!labelText) {
    return (
      <span
        className={classNames(
          `${hasPreviousSeizures(EnrichmentUtil.seizureCount(movementStats)) ? 'font--red' : ''}`,
          classnames,
        )}
      >
        {EnrichmentUtil.format.taskList(movementStats)}
      </span>
    );
  }

  return (
    <p
      className={classNames(
        'govuk-body-s',
        'govuk-!-margin-bottom-1',
        'govuk-!-font-weight-regular',
        classnames,
      )}
    >
      {labelText}
      <span className="dot dot--dark-grey" />
      <span
        className={`${hasPreviousSeizures(EnrichmentUtil.seizureCount(movementStats))
          ? 'font--red' : ''}`}
      >
        {EnrichmentUtil.format.taskList(movementStats)}
      </span>
    </p>
  );
};

export default EnrichmentCount;
