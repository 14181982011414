// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { DIRECTION } from '../../../../../utils/TargetInformation/constants';
import { MOVEMENT_MODES, VIEW } from '../../../../../utils/constants';

// Context
import { useView } from '../../../../../context/ViewContext';

// Component
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import DatetimeUtil from '../../../../../utils/Datetime/datetimeUtil';
import TargetJourneyUtil from '../../../../../utils/Journey/Target/targetJourneyUtil';
import TargetMovementUtil from '../../../../../utils/Movement/Target/targetMovementUtil';

import './TargetHeader.scss';

const TargetHeader = ({ canRecordOutcome, mode, onRecordOutcome, informationSheet }) => {
  const journey = TargetJourneyUtil.get(informationSheet);
  const direction = TargetJourneyUtil.direction(journey);
  const { view } = useView();

  if (!journey) {
    return null;
  }

  const isAirPaxView = () => {
    return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU, VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.OSDT, VIEW.CTBP, VIEW.IDP].includes(view);
  };

  const directionSyntax = (word) => {
    if (isAirPaxView()) {
      return word;
    }
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  };

  const toDirectionArrow = () => {
    return String.fromCharCode(10142);
  };

  const toDirectionHeader = () => {
    if (direction === DIRECTION.INBOUND) {
      return `${directionSyntax('arriving')} ${DatetimeUtil.format(TargetJourneyUtil.arrivalDate(journey), 'DD MMMM YYYY')}`
        + ` at ${DatetimeUtil.format(TargetJourneyUtil.arrivalDate(journey), 'HH:mm')}`;
    }

    return `${directionSyntax('departing')} ${DatetimeUtil.format(TargetJourneyUtil.departureDate(journey), 'DD MMMM YYYY')}`
      + ` at ${DatetimeUtil.format(TargetJourneyUtil.departureDate(journey), 'HH:mm')}`;
  };

  const toRoRoHeader = () => {
    return (
      <>
        {TargetMovementUtil.vesselName(informationSheet)}
        <br />
        {toDirectionHeader()}
      </>
    );
  };

  const toHeading = () => {
    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      return `${journey.id} ${journey.departure.location} ${toDirectionArrow()} ${journey.arrival.location} ${toDirectionHeader()}`;
    }
    if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
      return 'Alert sheet';
    }

    return toRoRoHeader();
  };

  return (
    <>
      <ComponentWrapper show={canRecordOutcome}>
        <Button
          id="record-outcome-button-top"
          onClick={onRecordOutcome}
        >
          Record outcome
        </Button>
      </ComponentWrapper>
      {/* TODO This component wrapper should be uncommented when we get
          targetId from the informationSheet */}
      {/* <ComponentWrapper show={mode === MOVEMENT_MODES.AIR_PASSENGER}>
        <p id="target-reference-text">Target reference</p>
      </ComponentWrapper> */}
      <h1 id="target-info-header" className="ho-heading-xl">{toHeading()}</h1>
    </>
  );
};

TargetHeader.propTypes = {
  canRecordOutcome: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onRecordOutcome: PropTypes.func.isRequired,
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(TargetHeader);
