import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { toContent, toTimestamp, toCreator } from '../../../helper/toContent';

const TaskActivityItem = ({ activity, ...props }) => {
  return (
    <div {...props} className="govuk-grid-row">
      <div className="govuk-grid-column-one-third">
        <p id={`activity-content-${activity.id}`}>{toContent(activity)}</p>
      </div>
      <div className="govuk-grid-column-one-third">
        <p id={`activity-timestamp-${activity.id}`}>{toTimestamp(activity)}</p>
      </div>
      <div className="govuk-grid-column-one-third">
        <p id={`activity-creator-${activity.id}`}>{toCreator(activity)}</p>
      </div>
    </div>
  );
};

TaskActivityItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TaskActivityItem;
