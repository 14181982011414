import { resetToZeroIfRequired } from '../Number/numberUtil';
import { getMovementStats } from '../Common/commonUtil';

const examinationCount = (movementStats) => {
  return resetToZeroIfRequired(movementStats?.examinationCount);
};

const movementCount = (movementStats) => {
  return resetToZeroIfRequired(movementStats?.movementCount);
};

const seizureCount = (movementStats) => {
  return resetToZeroIfRequired(movementStats?.seizureCount);
};

const hasPersonsWithPreviousSeizures = (otherPersons) => {
  if (!otherPersons || !otherPersons?.length) {
    return false;
  }

  return !!otherPersons.find((person) => {
    return seizureCount(getMovementStats(person)) >= 1;
  });
};

const formatToTaskList = (movementStats) => {
  return `${movementCount(movementStats)}/${examinationCount(movementStats)}/${seizureCount(movementStats)}`;
};

const EnrichmentUtil = {
  examinationCount,
  movementCount,
  seizureCount,
  personsWithPreviousSeizures: hasPersonsWithPreviousSeizures,
  format: {
    taskList: formatToTaskList,
  },
};

export default EnrichmentUtil;

export { examinationCount, hasPersonsWithPreviousSeizures, movementCount, seizureCount, formatToTaskList };
