import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useKeycloak } from '../../context/Keycloak';
import { useAxiosInstance } from '../Axios/axiosInstance';
import { usePermission } from '../../context/PermissionContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

const useFetchTargetsTabCount = (requestParams, selectedTab, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTarget } = usePermission();

  const getTargetsCount = async (params) => {
    if (!canReadTarget) {
      return {};
    }

    return AxiosRequests.getTargetsTabCounts(apiClient, params);
  };

  const { data: targetsTabCounts, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TARGETS_TAB_COUNTS, requestParams, selectedTab],
    queryFn: () => getTargetsCount(requestParams),
    initialData: {},
    refetchInterval: config.targetsList.refreshInterval,
    enabled,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { targetsTabCounts, isLoading };
};

export default useFetchTargetsTabCount;
