// Global imports
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link, Tag, Utils } from '@ukhomeoffice/cop-react-components';

// Config(s)
import { PHASE_BANNER } from '../../utils/constants';
import { toPathClassname } from '../Utils/Component/toClassName';
import config from '../../utils/config';

// Styles
import './PhaseBanner.scss';

const extractSingleState = (arr) => {
  const env = config?.environment;
  const parts = arr.split(`/${env.toUpperCase()}`);
  return !!parts[1]?.length || false;
};

const DEFAULT_CLASS = 'govuk-phase-banner';
const PhaseBanner = () => {
  const location = useLocation();
  const classes = Utils.classBuilder(DEFAULT_CLASS, []);
  const inProd = config.environment === 'prod';
  const isSingle = extractSingleState(location.pathname);

  return (
    <div className={`${classes()} hods-width-container ${toPathClassname(location.pathname)} ${isSingle ? 'single-page' : ''}`} role="complementary">
      <p className={classes('content')}>
        <Tag className={classes('content__tag')}>BETA</Tag>
        {!inProd && <Tag className={classes('content__tag')}>{config.environment}</Tag>}
        <span className={classes('text')}>
          {PHASE_BANNER.NEW_SERVICE_1}&nbsp;
          <Link
            className="govuk-link"
            href={`${config.feedbackUrl}ess?id=take_survey&type_id=e0e2125b1bdd1110f8655946464bcbf7`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {PHASE_BANNER.NEW_SERVICE_2}
          </Link>
          &nbsp;
          {PHASE_BANNER.NEW_SERVICE_3}
        </span>
      </p>
    </div>
  );
};

export default PhaseBanner;
