// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import PreviousNext from '../movement/generics/PreviousNext';
import SummaryListRow from '../movement/generics/SummaryListRow';

// Util(s)
import TargetInformationUtil from '../../../../../utils/TargetInformation/targetInformationUtil';
import TargetPersonUtil from '../../../../../utils/Person/Target/targetPersonUtil';

// Styling
import './TargetDetails.scss';

const TargetDetails = ({ informationSheet }) => {
  const panelData = (() => {
    return [
      {
        id: 'target-category',
        label: 'Target category',
        value: informationSheet?.targetCategory || informationSheet?.risks?.selector?.category || 'C',
      },
      {
        id: 'suspected-abuse-type',
        label: 'Suspected abuse type',
        value: TargetInformationUtil.suspectedAbuseTypes(informationSheet),
      },
      {
        id: 'operation-name',
        label: 'Operation name',
        value: informationSheet.operation,
      },
      {
        id: 'reason-for-referral',
        label: 'Reason for referral',
        value: informationSheet.selectionReasoning,
      },
      {
        id: 'all-pax-to-wl',
        label: 'All pax added to WL?',
        value: TargetPersonUtil.allOnWhitelist(informationSheet) ? 'Yes' : 'No',
      },
      {
        id: 'public-interest-immunity',
        label: 'Public interest immunity?',
        value: informationSheet.publicInterestImmunity ? 'Yes' : 'No',
      },
    ];
  })();

  const peoplePanelData = (() => {
    return [
      {
        id: 'persons-of-interest',
        label: 'Persons of interest',
        value: TargetPersonUtil.allOfInterest(informationSheet).length,
        link: '#persons-of-interest',
      },
      {
        id: 'co-travellers',
        label: 'Co-travellers',
        value: TargetPersonUtil.coTravellers(informationSheet).length,
        link: '#co-travellers',
      },
      {
        id: 'under-18s',
        label: 'Under 18s',
        value: TargetPersonUtil.under18s(informationSheet),
        link: '#under-18s',
      },
    ];
  })();

  return (
    <div id="target-details" className="govuk-grid-row target-info-summary">
      <div className="govuk-grid-column-full">
        <PreviousNext
          id="target-details"
          index={0}
          size={1}
        />
        <Heading id="target-details-header" size="l">Target details</Heading>
        <div id="target-details-body" className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds left-side">
            <div className="govuk-summary-list">
              {panelData
                ?.filter((item) => !!item)
                ?.map((item) => (
                  <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
                ))}
            </div>
          </div>
          <div className="govuk-grid-column-one-third right-side">
            <div id="people-on-this-targeting-sheet">
              <Heading size="m">People on this target sheet</Heading>
              <div className="govuk-summary-list">
                {peoplePanelData
                  ?.filter((item) => !!item)
                  ?.map((item) => (
                    <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} link={item.link} />
                  ))}
              </div>
            </div>
            <div id="handling-instructions">
              <Heading size="m">Handling instructions</Heading>
              <p>No further dissemination without permission of the originator.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TargetDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(TargetDetails);
