// Global import(s)
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './Table.scss';

const Table = ({ className, headings, rows, ...props }) => {
  return (
    <table {...props} role="table" className={classNames('hods-table', className)}>
      <thead className="hods-table__heading">
        <tr role="row">
          {headings.map((header) => (
            <th key={header} role="columnheader" scope="col">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="hods-table__body">
        {rows.map((row, rowIndex) => {
          if (Array.isArray(row)) {
            return (
              <tr key={`table-row-${rowIndex}`} role="row" className="hods-table__row">
                {row.map((cell, cellIndex) => (
                  // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
                  <td key={`table-cell-${cellIndex}`} role="cell" className="hods-table__cell">
                    <span className="hods-table__heading">
                      {headings[cellIndex]}
                    </span>
                    <span className="hods-table__value">
                      {cell}
                    </span>
                  </td>
                ))}
              </tr>
            );
          }

          return row;
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    )),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Table.defaultProps = {
  className: null,
};

export default Table;
