// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { DIRECTION } from '../../../../../utils/TargetInformation/constants';
import { TIME_FORMATS, STRINGS } from '../../../../../utils/constants';

// Util(s)
import DateTimeUtil from '../../../../../utils/Datetime/Uplift/datetimeUtil';
import { TargetCommonUtil, TargetJourneyUtil } from '../../../../../utils';

// Styling
import './TargetListCardHeader.scss';

const isInbound = (direction) => {
  return direction === DIRECTION.INBOUND;
};

const isUKLocation = (location) => {
  const UK = 'United Kingdom';
  return TargetJourneyUtil.format.airportCountry(location) === UK;
};

const toDepartureStatusText = (direction) => {
  if (isInbound(direction)) {
    return 'Arriving';
  }
  return 'Departing';
};

const toArrivalLocation = (groupedOverview, view) => {
  let location = groupedOverview?.arrival?.location;
  if (!location) {
    return STRINGS.UNKNOWN;
  }

  if (!TargetCommonUtil.isViewTargetAirpax(view)) {
    return location;
  }

  if (!isUKLocation(location)) {
    location += ` (${TargetJourneyUtil.format.airportCity(location)})`;
  }
  return location;
};

const toDepartureLocation = (groupedOverview, view) => {
  let location = groupedOverview?.departure?.location;
  if (!location) {
    return STRINGS.UNKNOWN;
  }

  if (!TargetCommonUtil.isViewTargetAirpax(view)) {
    return location;
  }

  if (!isUKLocation(location)) {
    location += ` (${TargetJourneyUtil.format.airportCity(location)})`;
  }
  return location;
};

const toTime = (groupedOverview, direction) => {
  const arrivalTime = groupedOverview?.arrival?.time;
  const departureTime = groupedOverview?.departure?.time;
  const eventDate = isInbound(direction) ? arrivalTime : departureTime;
  return DateTimeUtil.format.asUTC(eventDate, null, TIME_FORMATS.LONG_DAY_DATE);
};

const toAirpaxHeader = (
  flightNumber,
  departureLocation,
  arrivalLocation,
  departureStatus,
  time,
) => {
  return (
    <div className="airpax-targets-header">
      <span className="ho-body-l">
        <span id="flight-number" className="font-bold">
          {flightNumber}
        </span>
        &nbsp;
        <span id="departure-location">
          {departureLocation}
        </span>
        &nbsp;to&nbsp;
        <span id="arrival-location" className="capitalize">
          {arrivalLocation}
        </span>
        &nbsp;
        <span className="dot" />
        <span id="direction">&nbsp;{departureStatus}</span>
        <span id="time">&nbsp;{time}</span>
      </span>
    </div>
  );
};

const toRoRoHeader = (
  vesselName,
  departureLocation,
  arrivalLocation,
  departureStatus,
  time,
  direction,
) => {
  return (
    <div className="govuk-!-font-size-24 govuk-!-font-weight-regular govuk-!-margin-top-5 govuk-!-margin-bottom-5 govuk-!-padding-left-1">
      <span id="vessel-name" className="capitalize font-bold">
        {vesselName}{' '}
      </span>
      <span className="dot" /> <span id="direction">{departureStatus} </span>
      <span id="location" className="capitalize">
        {isInbound(direction) ? arrivalLocation : departureLocation}&nbsp;
      </span>
      <span id="time">{time}</span>
    </div>
  );
};

const TargetListCardHeader = ({ groupedOverview, view }) => {
  const direction = groupedOverview?.direction || undefined;

  if (!direction) {
    return null;
  }

  const vesselName = groupedOverview?.name || STRINGS.UNKNOWN;
  const departureStatus = toDepartureStatusText(direction);

  const departureLocation = toDepartureLocation(groupedOverview, view);
  const arrivalLocation = toArrivalLocation(groupedOverview, view);
  const time = toTime(groupedOverview, direction);

  if (TargetCommonUtil.isViewTargetAirpax(view)) {
    return toAirpaxHeader(vesselName, departureLocation, arrivalLocation, departureStatus, time);
  }

  return toRoRoHeader(
    vesselName,
    departureLocation,
    arrivalLocation,
    departureStatus,
    time,
    direction,
  );
};

export default TargetListCardHeader;

TargetListCardHeader.propTypes = {
  groupedOverview: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }).isRequired,
  view: PropTypes.string.isRequired,
};
