import { FORM_ACTIONS } from '../utils/constants';

export default {
  id: 'withdrawTask',
  version: '0.0.1',
  name: 'withdrawTask',
  title: 'Withdraw task',
  type: 'wizard',
  components: [],
  pages: [
    {
      id: 'reason-for-withdrawing',
      name: 'reason-for-withdrawing',
      components: [
        {
          type: 'heading',
          size: 'l',
          content: 'Provide a reason for withdrawing the target',
        },
        {
          id: 'reasonForWithdrawing',
          fieldId: 'reasonForWithdrawing',
          className: 'govuk-!-margin-bottom-3',
          label: 'This reason will be saved in the task activity',
          'aria-label': 'this reason will be saved in the task activity',
          type: 'textarea',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Enter a reason for withdrawing the target',
            },
          ],
        },
        {
          type: 'heading',
          size: 's',
          content: 'What happens next',
        },
        'The task will return to \'in progress\'.',
      ],
      actions: [
        {
          type: FORM_ACTIONS.SUBMIT,
          label: 'Withdraw target',
          classModifiers: 'warning',
          validate: true,
        },
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
      ],
    },
  ],
};
