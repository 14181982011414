import React from 'react';
import PropTypes from 'prop-types';

import './SubHeaderModule.scss';

const SubHeaderModule = ({ items, ...props }) => {
  return (
    <div className="sub-header-module-container">
      <div {...props} className="govuk-grid-row sub-header-module">
        {items.map((item) => item.content)}
      </div>
    </div>
  );
};

SubHeaderModule.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node,
  })),
};

SubHeaderModule.defaultProps = {
  items: [],
};

export default SubHeaderModule;
