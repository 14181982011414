// Config
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';

export const savePreviousLocation = () => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION, location?.pathname);
};

const lastKnownLocationCheck = () => {
  if (localStorage.getItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION)) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_KNOWN_LOCATION,
      localStorage.getItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION));
    localStorage.removeItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION);
  }
};

export default lastKnownLocationCheck;
