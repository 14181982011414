import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const UpdateButton = ({ onClick }) => {
  return (
    <Button
      id="update-button"
      classModifiers="blue"
      onClick={onClick}
      aria-label="update the task"
    >
      Update
    </Button>
  );
};

UpdateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UpdateButton;
