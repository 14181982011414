const getName = (consignee) => {
  return consignee?.name || undefined;
};

const getAddress = (consignor) => {
  return consignor?.address || undefined;
};

const getConsignor = (targetTask) => {
  return targetTask?.movement?.consignor || undefined;
};

const ConsignorUtil = {
  address: getAddress,
  get: getConsignor,
  name: getName,
};

export default ConsignorUtil;

export {
  getAddress,
  getConsignor,
  getName,
};
