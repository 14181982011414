const addThumbUrl = (person) => {
  if (person?.photograph?.url && !person?.photograph?.url?.startsWith('blob:')) {
    const file = person?.photograph?.file;
    if (file) {
      return {
        ...person,
        photograph: {
          ...person.photograph,
          url: URL.createObjectURL(file),
        },
      };
    }
  }
  return person;
};

const toFormData = (formData) => {
  let tisPrefillData = {};
  if (formData) {
    tisPrefillData = {
      ...formData,
      ...(formData?.otherPersons?.length && { otherPersons: formData?.otherPersons.map((person) => addThumbUrl(person)) }),
      ...(formData?.person && { person: addThumbUrl(formData?.person) }),
    };
  }
  return tisPrefillData;
};

export default toFormData;
