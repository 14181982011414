// Util(s)
import { getTab } from '../../../../../utils/Common/commonUtil';

const toTableHeaders = (DEFAULTS, view, selectedTab) => {
  const tab = getTab(DEFAULTS[view].tabs, selectedTab);
  if (!tab) {
    return [];
  }

  return tab.tableHeaders;
};

export default toTableHeaders;
