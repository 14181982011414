import React, { createContext, useContext, useMemo, useState } from 'react';

const ModuleContext = createContext({});

const ModuleProvider = ({ children }) => {
  const [modules, setModules] = useState([]);

  const registerModule = (moduleName) => {
    setModules((prev) => [...prev, moduleName]);
  };

  const removeModule = (moduleName) => {
    setModules(modules.filter((module) => module !== moduleName));
  };

  const value = useMemo(() => ({
    modules,
    registerModule,
    removeModule,
  }), [modules]);

  return (
    <ModuleContext.Provider value={value}>
      {children}
    </ModuleContext.Provider>
  );
};

const useModule = () => useContext(ModuleContext);

export { ModuleContext, ModuleProvider, useModule };
