// Global import(s)
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, createContext, useContext } from 'react';

// Config(s)
import { RBAC } from '../utils/constants';

// Context(s)
import { useKeycloak } from './Keycloak';

const PermissionContext = createContext({
  canCreateTask: false,
  canReadTask: false,
  canUpdateTask: false,
  canCreateTarget: false,
  canReadTarget: false,
  canUpdateTarget: false,
  isLiveServicesUser: false,
  isAdminUser: false,
  canViewCommodities: false,
  canViewIdp: false,
  canViewCtbp: false,
  canViewOsdt: false,
  isIdpUser: false,
  isCommoditiesUser: false,
  canBulkDelete: false,
  hasRole: () => {},
});

const PermissionProvider = ({ children }) => {
  const keycloak = useKeycloak();
  const userRoles = keycloak?.tokenParsed?.realm_access?.roles || [];
  const userGroups = keycloak?.tokenParsed?.groups || [];
  const [canCreateTask, setCanCreateTask] = useState(false);
  const [canReadTask, setCanReadTask] = useState(false);
  const [canUpdateTask, setCanUpdateTask] = useState(false);
  const [canCreateTarget, setCanCreateTarget] = useState(false);
  const [canReadTarget, setCanReadTarget] = useState(false);
  const [canUpdateTarget, setCanUpdateTarget] = useState(false);
  const [canBulkDelete, setCanBulkDelete] = useState(false);
  const [isLiveServicesUser, setIsLiveServicesUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [canViewCommodities, setCanViewCommodities] = useState(false);
  const [canViewIdp, setCanViewIdp] = useState(false);
  const [canViewCtbp, setCanViewCtbp] = useState(false);
  const [canViewOsdt, setCanViewOsdt] = useState(false);
  const [isIdpUser, setIsIdpUser] = useState(false);
  const [isCommoditiesUser, setIsCommoditiesUser] = useState(false);

  const hasAdminRole = () => {
    return userRoles.includes(RBAC.COP_ADMIN);
  };

  const hasLiveServicesRole = () => {
    return userRoles.includes(RBAC.LIVE_SERVICES);
  };

  const hasTaskCreatePermission = () => {
    return userRoles.includes(RBAC.TASK_CREATE);
  };

  const hasTaskReadPermission = () => {
    return userRoles.includes(RBAC.TASK_READ);
  };

  const hasTaskUpdatePermission = () => {
    return userRoles.includes(RBAC.TASK_UPDATE);
  };

  const hasTargetCreatePermission = () => {
    return userRoles.includes(RBAC.TARGET_CREATE);
  };

  const hasTargetReadPermission = () => {
    return userRoles.includes(RBAC.TARGET_READ);
  };

  const hasTargetUpdatePermission = () => {
    return userRoles.includes(RBAC.TARGET_UPDATE);
  };

  const hasCommoditiesViewPermission = () => {
    return userRoles.includes(RBAC.TASK_AIRPAX_COMMODITIES_READ);
  };

  const hasIdpViewPermission = () => {
    return userRoles.includes(RBAC.TASK_AIRPAX_IDP_READ);
  };

  const hasCtbpViewPermission = () => {
    return userRoles.includes(RBAC.TASK_AIRPAX_CTBP_READ);
  };

  const hasOsdtViewPermission = () => {
    return userRoles.includes(RBAC.TARGETER_AIRPAX_OSDT);
  };

  const hasIdpTargeterPermission = () => {
    return userRoles.includes(RBAC.TARGETER_AIRPAX_IDP);
  };

  const hasCommoditiesTargeterPermission = () => {
    return userRoles.includes(RBAC.TARGETER_AIRPAX_COMMODITIES);
  };

  const hasBulkDeletePermission = () => {
    return userRoles.includes(RBAC.TASK_RULE_BULK_DELETE);
  };

  const hasRole = useCallback((role) => {
    if (Array.isArray(role)) {
      return role.every((r) => userRoles.includes(r));
    }
    return userRoles.includes(role);
  }, [userRoles]);

  const hasRoRoFrontLineOfficerGroup = () => {
    return userGroups.some((group) => group.startsWith('/GP'));
  };

  const hasAirFrontLineOfficerGroup = () => {
    return userGroups.some((group) => group.startsWith('/AP'));
  };

  useEffect(() => {
    if (keycloak) {
      setCanCreateTask(hasTaskCreatePermission());
      setCanReadTask(hasTaskReadPermission());
      setCanUpdateTask(hasTaskUpdatePermission());
      setCanCreateTarget(hasTargetCreatePermission());
      setCanReadTarget(hasTargetReadPermission());
      setCanUpdateTarget(hasTargetUpdatePermission());
      setIsLiveServicesUser(hasLiveServicesRole());
      setIsAdminUser(hasAdminRole());
      setCanViewCommodities(hasCommoditiesViewPermission());
      setCanViewIdp(hasIdpViewPermission());
      setCanViewCtbp(hasCtbpViewPermission());
      setCanViewOsdt(hasOsdtViewPermission());
      setIsIdpUser(hasIdpTargeterPermission());
      setIsCommoditiesUser(hasCommoditiesTargeterPermission());
      setCanBulkDelete(hasBulkDeletePermission());
    }
  }, [keycloak]);

  const value = useMemo(() => ({
    canCreateTask,
    canReadTask,
    canUpdateTask,
    canCreateTarget,
    canReadTarget,
    canUpdateTarget,
    isLiveServicesUser,
    isAdminUser,
    canViewCommodities,
    canViewIdp,
    canViewCtbp,
    canViewOsdt,
    isIdpUser,
    isCommoditiesUser,
    canBulkDelete,
    hasRole,
    hasRoRoFrontLineOfficerGroup,
    hasAirFrontLineOfficerGroup,
  }), [
    canCreateTask,
    canReadTask,
    canUpdateTask,
    canCreateTarget,
    canReadTarget,
    canUpdateTarget,
    isLiveServicesUser,
    isAdminUser,
    canViewCommodities,
    canViewIdp,
    canViewCtbp,
    canViewOsdt,
    isIdpUser,
    isCommoditiesUser,
    canBulkDelete,
    hasRole,
    hasRoRoFrontLineOfficerGroup,
    hasAirFrontLineOfficerGroup,
  ]);

  return (
    <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
  );
};

const usePermission = () => useContext(PermissionContext);

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PermissionContext, PermissionProvider, usePermission };
