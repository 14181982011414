const toCustomActions = (actions) => {
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      actions.onApply();
    }
  };

  return {
    // componentId: { ... actions in here }
    searchText: {
      onKeyDown,
    },
    journeyId: {
      onKeyDown,
    },
  };
};

export default toCustomActions;
