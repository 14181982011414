import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import { DATE_FORMATS, STRINGS } from '../../../../../utils/constants';
import { JourneyUtil, MovementUtil, TaskVersionUtil } from '../../../../../utils';

import { DEFAULT_HIGHLIGHT_CLASS } from '../../helper/common';

const Itinerary = ({ version, versionDiff }) => {
  const itineraries = JourneyUtil.movementItinerary(JourneyUtil.get(version));
  const itineraryDiff = JourneyUtil.movementItinerary(JourneyUtil.get(versionDiff));
  const patchedItineraries = TaskVersionUtil.patch(itineraries, itineraryDiff);

  return (
    <div className="task-details-container">
      <Heading className="govuk-!-margin-top-0" size="m">Itinerary</Heading>
      <div className="bottom-border-thin" />
      {itineraries && itineraries.map((itinerary, index) => {
        return (
          <div
            key={itinerary?.id}
            className={classNames(
              `${index !== itineraries.length - 1 && 'bottom-border-thin'}`,
              'govuk-!-margin-top-1',
              'govuk-!-padding-bottom-1',
              !TaskVersionUtil.isSame(itinerary, patchedItineraries[index]) && DEFAULT_HIGHLIGHT_CLASS,
            )}
          >
            {index !== 0 && index <= itineraries.length - 1 && MovementUtil.itinRelativeTime(index, itinerary, itineraries)}
            <div className="font__bold">
              {MovementUtil.itinFlightNumber(itinerary)} <span className="dot__light" />&nbsp;
              {JourneyUtil.departureLoc(itinerary)} <span className="right-arrow">&#8594;</span>&nbsp;
              {JourneyUtil.arrivalLoc(itinerary)} <span className="dot__light" />&nbsp;
              {JourneyUtil.formatDepartureTime(itinerary, DATE_FORMATS.LONG_DAY_DATE)}
            </div>
            <div className="font__light">
              {MovementUtil.itinDepartureCountryCode(itinerary)} <span className="right-arrow">&#8594;</span>&nbsp;
              {MovementUtil.itinArrivalCountryCode(itinerary)} <span className="dot__light" />&nbsp;
              {STRINGS.ARRIVAL_TEXT} {JourneyUtil.formatArrivalTime(itinerary, DATE_FORMATS.LONG)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Itinerary;
