// Global import(s)
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TAB_STATUS } from '../../constants';

// Util(s)
import TargetCommonUtil from '../../../../../utils/Common/Target/targetCommonUtil';

const TargetUserInfo = ({ selectedTab, assignedUsers, assignedTeams, accepter, view }) => {
  const toName = (user) => {
    const name = Object.keys(user || {})
      .filter((k) => ['firstName', 'lastName'].includes(k))
      .map((k) => user[k])
      .filter((item) => !!item)
      .join(' ');

    if (!name) {
      return null;
    }

    return name;
  };

  const toAssignedUsers = () => {
    return assignedUsers
      ?.filter((user) => user.email !== accepter?.email)
      .map((user) => {
        return {
          key: `name-${user.email}`,
          content: `${toName(user)} - ${user.email}`,
        };
      });
  };

  const toAssignedTeams = () => {
    return assignedTeams?.map((team) => {
      return {
        key: `name-${team.name}`,
        content: team.name,
      };
    });
  };

  const toAccepter = () => {
    if (!accepter.teamName) {
      return `${toName(accepter)} - ${accepter?.email}`;
    }

    return `${toName(accepter)} (${accepter?.teamName}) - ${accepter?.email}`;
  };

  if (TargetCommonUtil.isSeniorOfficersAirpaxView(view) && [TAB_STATUS.ASSIGNED, TAB_STATUS.ACCEPTED].includes(selectedTab)) {
    const allAssignees = sortBy([...toAssignedUsers(), ...toAssignedTeams()], ['content']);

    return (
      <div id="target-user-info" className="govuk-!-padding-left-1 govuk-!-padding-bottom-2">
        <p id="assigned-to-label" className="govuk-!-margin-bottom-0 govuk-!-font-weight-bold">
          Assigned to:&nbsp;
        </p>
        <ul id="assigned-to-assignee" className="govuk-!-margin-top-1">
          {allAssignees?.map((item) => (
            <li id="target-assignee" key={item.key}>
              {item.content}
            </li>
          ))}
        </ul>
        <p
          id="accepted-by-label"
          className="govuk-!-margin-top-2 govuk-!-margin-bottom-0 govuk-!-font-weight-bold"
        >
          Accepted by:&nbsp;
        </p>
        {accepter && (
          <ul id="accepted-by" className="govuk-!-margin-top-1 govuk-!-margin-bottom-1">
            <li id="target-acceptee">{toAccepter()}</li>
          </ul>
        )}
      </div>
    );
  }

  return null;
};

export default TargetUserInfo;

TargetUserInfo.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  assignedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  assignedTeams: PropTypes.arrayOf(PropTypes.shape({})),
  accepter: PropTypes.shape({
    email: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired,
  }),
  view: PropTypes.string.isRequired,
};

TargetUserInfo.defaultProps = {
  assignedUsers: [],
  assignedTeams: [],
  accepter: null,
};
