import React from 'react';
import PropTypes from 'prop-types';

// Component(s)
import Tabs from '../../../../components/Tabs/Tabs';

const FilterTab = ({ items, onTabClick }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Tabs
      className="hods-tabs"
      items={items}
      onTabClick={onTabClick}
      hasFilters
    />
  );
};

FilterTab.propTypes = {
  items: PropTypes.shape({}),
  onTabClick: PropTypes.func.isRequired,
};

FilterTab.defaultProps = {
  items: PropTypes.shape({}),
};

export default FilterTab;
