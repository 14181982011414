/**
 * Iterates over an array of components and brings any nested
 * components that should be visible into the top level array.
 * The values provided in data are used to determine if any
 * nested components should be visible.
 *
 * Given this structure in the components array:
 *  - Radios
 *    - Radio Option 1 (Not selected)
 *      - Nested 1
 *      - Nested 2
 *    - Radio Option 2 (Selected)
 *      - Nested 3
 *  - Text Input
 *
 * The result will be the following array:
 *  - Radios
 *  - Nested 3
 *  - Text Input
 *
 * @param {*} components An array of components.
 * @param {*} formData A chunk of form data used to determine if
 *                 any nested components are visible or not.
 * @returns A new array consisting of components interspersed
 *          with any visible nested components.
 */
const elevateNestedComponents = (components, formData) => {
  let allComponents = [];
  components?.forEach((component) => {
    allComponents.push(component);
    component.data?.options?.forEach((option) => {
      // If this option has nested components and is
      // selected, then add its nested components to the array.
      const sourceData = formData?.[component.id];
      if (Array.isArray(option.nested) && sourceData === option.value) {
        allComponents = allComponents.concat(option.nested);
      }
    });
  });
  return allComponents;
};

export default elevateNestedComponents;
