import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './TaskActionToolbarMenu.scss';

const TaskActionToolbarMenu = ({ menuOpen, setMenuOpen }) => {
  return (
    <Button
      id="action-toolbar-menu-button"
      classBlock={
        menuOpen
          ? 'govuk-header__menu-button govuk-header__menu-button--open'
          : 'govuk-header__menu-button'
      }
      aria-label="Show or hide action buttons"
      onClick={(e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
      }}
    >
      Menu
    </Button>
  );
};

TaskActionToolbarMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

export default TaskActionToolbarMenu;
