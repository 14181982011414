// Global import(s)
import { useQuery } from '@tanstack/react-query';

// Config(s)
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchBorderEvent = (taskId, hasEab) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTarget, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const getBorderEvent = async () => {
    if (!canReadTarget || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return null;
    }

    return AxiosRequests.borderEventData(apiClient, taskId);
  };

  const { data: borderEvent } = useQuery({
    queryKey: [QUERY_KEYS.BORDER_EVENT, taskId],
    queryFn: () => getBorderEvent(),
    initialData: null,
    enabled: hasEab,
  });

  return { borderEvent };
};

export default useFetchBorderEvent;
