// Global import(s)
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import config from '../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../context/Keycloak';
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useView } from '../../../context/ViewContext';

// Services
import AxiosRequests from '../../../api/axiosRequests';

// Component(s)
import Header from './components/Header';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import PreviousTaskListCard from './components/PreviousTaskListCard';

// Hook(s)
import { useAxiosInstance } from '../../../utils/Axios/axiosInstance';

// Util(s)
import { getTask } from '../../../utils/PreviousTask/previousTaskUtil';

// Styling
import './PreviousTaskListPage.scss';

const PreviousTaskListPage = () => {
  const location = useLocation();
  const keycloak = useKeycloak();
  const { trackPageView } = useMatomo();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();
  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [previousTasks, setPreviousTasks] = useState([]);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const getPreviousTasks = async (payload) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      setEntity({});
      setPreviousTasks([]);
      setIsLoading(false);
      return;
    }

    await AxiosRequests.getOtherTasks(apiClient, payload)
      .then((data) => {
        setEntity(data?.entity);
        setPreviousTasks(data?.entries);
      })
      .catch(() => {
        setEntity({});
        setPreviousTasks([]);
      })
      .finally(() => setIsLoading(false));
  };

  const sanitiseValue = (value) => {
    if (!value) {
      return null;
    }
    return (value === 'null') ? null : value;
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const movementIds = sanitiseValue(queryParams?.['movement-id']);

    const toMovementIdList = () => {
      if (!movementIds) {
        return null;
      }
      return movementIds.split(',').filter((id) => !!id) || null;
    };

    getPreviousTasks({
      filterParams: {
        movementIds: toMovementIdList(),
        entityId: {
          type: sanitiseValue(queryParams?.['entity-type']),
          svxId: sanitiseValue(queryParams?.['svx-id']),
          poleV1Id: sanitiseValue(queryParams?.['pole-v1-id']),
          poleV2Id: sanitiseValue(queryParams?.['pole-v2-id']),
          pnrPoleV1Id: sanitiseValue(queryParams?.['pnr-pole-v1-id']),
          pnrPoleV2Id: sanitiseValue(queryParams?.['pnr-pole-v2-id']),
        },
      },
    });

    return () => {
      AxiosRequests.cancel(source);
    };
  }, [location]);

  useEffect(() => {
    trackPageView();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
    return <p>You are not authorised to view these tasks.</p>;
  }

  return (
    <>
      {/* TODO: Make this not reliant on persons */}
      {entity && previousTasks.length ? (
        <>
          <Header
            previousTaskCount={previousTasks?.length}
            description={entity?.description}
            url={entity?.entitySearchUrl}
          />
          <div className="govuk-grid-row">
            <section className="govuk-grid-column-full">
              {previousTasks.map((task) => {
                return <PreviousTaskListCard key={getTask(task)?.id} task={task} />;
              })}
            </section>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PreviousTaskListPage;
