import _ from 'lodash';
import moment from 'moment';
import lookup from 'country-code-lookup';

import {
  DATE_FORMATS,
  STRINGS,
} from '../constants';

import { formatField } from '../FieldFormat/fieldFormatterUtil';
import CommonUtil from '../Common/commonUtil';
import { getFormattedDate, validateDate } from '../Datetime/datetimeUtil';
import { shouldMaskDate, shouldMaskString, getMaskedValue } from '../Masking/maskingUtil';
import { getHiddenValue, shouldHideDate, shouldHideString } from '../Hiding/hidingUtil';
import PersonUtil from '../Person/personUtil';

const calculateExpiry = (passportExpiry, arrivalTime) => {
  if (passportExpiry === getHiddenValue() || arrivalTime === getHiddenValue()) {
    return getHiddenValue();
  }

  if (!validateDate(passportExpiry) || !validateDate(arrivalTime)) {
    return STRINGS.UNKNOWN;
  }

  const expiry = `${arrivalTime},${moment(passportExpiry).format(DATE_FORMATS.UTC)}`;
  return formatField('BOOKING_DATETIME', expiry)
    .split(', ')[1]
    .replace(STRINGS.BEFORE_TRAVEL_TEXT, STRINGS.AFTER_TRAVEL_TEXT)
    .replace(STRINGS.AGO_TEXT, STRINGS.BEFORE_TRAVEL_TEXT)
    .replace(STRINGS.A_SMALL_TEXT, STRINGS.A_TITLE_CASE_TEXT)
    .replace(STRINGS.AN_SMALL_TEXT, STRINGS.AN_TITLE_CASE_TEXT);
};

const hasDocument = (person) => {
  return !!person?.document;
};

const getDocument = (person) => {
  if (hasDocument(person)) {
    return person.document;
  }
  return null;
};

const getDocumentExpiryDate = (document) => {
  if (!document?.expiry) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskDate(document.expiry)) {
    return getMaskedValue();
  }
  if (shouldHideDate(document.expiry)) {
    return getHiddenValue();
  }
  return document?.expiry || undefined;
};

const getDocumentExpiry = (document, taskDetails = false) => {
  const expiryPrefix = 'Expires';
  if (!document?.expiry) {
    return taskDetails ? STRINGS.UNKNOWN : `${expiryPrefix} ${STRINGS.UNKNOWN}`;
  }
  if (shouldMaskDate(document.expiry)) {
    return getMaskedValue();
  }
  if (shouldHideDate(document.expiry)) {
    return null;
  }
  return taskDetails ? `${getFormattedDate(document?.expiry, DATE_FORMATS.SHORT_ALT)}` : `${expiryPrefix} ${getFormattedDate(document?.expiry, DATE_FORMATS.SHORT_ALT)}`;
};

const getDocumentValidityDate = (document) => {
  if (!document?.validFrom) {
    return STRINGS.UNKNOWN;
  }

  if (shouldMaskDate(document.validFrom)) {
    return getMaskedValue();
  }

  if (shouldHideDate(document.validFrom)) {
    return getHiddenValue();
  }

  return document?.validFrom || undefined;
};

const getDocumentValidity = (document, taskDetails = false) => {
  const validityPrefix = 'Valid from';
  if (!document?.validFrom) {
    return taskDetails ? STRINGS.UNKNOWN : `${validityPrefix} ${STRINGS.UNKNOWN}`;
  }

  if (shouldMaskDate(document.validFrom)) {
    return getMaskedValue();
  }

  if (shouldHideDate(document.validFrom)) {
    return null;
  }

  return taskDetails ? `${getFormattedDate(document?.validFrom, DATE_FORMATS.SHORT_ALT)}` : `${validityPrefix} ${getFormattedDate(document?.validFrom, DATE_FORMATS.SHORT_ALT)}`;
};

const getDocumentType = (document) => {
  if (!document?.type) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(document.type)) {
    return getHiddenValue();
  }

  return document.type;
};

const getDocumentTypeFormatted = (document) => {
  const type = getDocumentType(document);
  return _.startCase(_.toLower(type));
};

const getDocumentNumber = (document) => {
  if (!document?.number) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(document.number)) {
    return null;
  }
  return document.number;
};

const getDocumentName = (person) => {
  if (!person?.name) {
    return STRINGS.UNKNOWN;
  }

  const firstName = PersonUtil.firstname(person);
  const lastName = PersonUtil.lastname(person);

  return CommonUtil.commaSeparated(lastName ? lastName.toUpperCase() : null, firstName);
};

const getDocumentCountryOfIssue = (document, taskDetails = false) => {
  const countryOfIssuePrefix = 'Issued by';

  if (shouldMaskString(document?.countryOfIssue)) {
    return getMaskedValue();
  }

  if (shouldHideString(document?.countryOfIssue)) {
    return null;
  }

  if (!document?.countryOfIssue) {
    return taskDetails ? STRINGS.UNKNOWN : `${countryOfIssuePrefix} ${STRINGS.UNKNOWN}`;
  }

  if (taskDetails) {
    try {
      return lookup?.byIso(document.countryOfIssue) !== null
        ? `${lookup?.byIso(document.countryOfIssue).country} (${CommonUtil.iso3Code(document.countryOfIssue)})`
        : `${countryOfIssuePrefix} ${STRINGS.UNKNOWN}`;
    } catch {
      return `${countryOfIssuePrefix} ${STRINGS.UNKNOWN}`;
    }
  }

  try {
    return lookup?.byIso(document.countryOfIssue) !== null
      ? `${countryOfIssuePrefix} ${CommonUtil.iso3Code(document.countryOfIssue)}`
      : `${countryOfIssuePrefix} ${STRINGS.UNKNOWN}`;
  } catch {
    return `${countryOfIssuePrefix} ${STRINGS.UNKNOWN}`;
  }
};

const getDocumentCountryOfIssueCode = (document) => {
  if (!document?.countryOfIssue) {
    return STRINGS.UNKNOWN;
  }

  if (shouldMaskString(document.countryOfIssue)) {
    return getMaskedValue();
  }

  if (shouldHideString(document.countryOfIssue)) {
    return getHiddenValue();
  }

  return CommonUtil.iso3Code(document.countryOfIssue);
};

const getDocumentNationality = (document, taskDetails = false) => {
  return getDocumentCountryOfIssue(document, taskDetails);
};

const getDocumentDOB = (document) => {
  if (!document?.dateOfBirth) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskDate(document.dateOfBirth)) {
    return getMaskedValue();
  }
  return getFormattedDate(document?.dateOfBirth, DATE_FORMATS.SHORT_ALT);
};

const DocumentUtil = {
  get: getDocument,
  docExpiry: getDocumentExpiry,
  docExpiryDate: getDocumentExpiryDate,
  docValidity: getDocumentValidity,
  docValidityDate: getDocumentValidityDate,
  docType: getDocumentType,
  docTypeFormatted: getDocumentTypeFormatted,
  docNumber: getDocumentNumber,
  docName: getDocumentName,
  docCountry: getDocumentCountryOfIssue,
  docCountryCode: getDocumentCountryOfIssueCode,
  docNationality: getDocumentNationality,
  docDOB: getDocumentDOB,
  calculateExpiry,
};

export default DocumentUtil;

export {
  getDocument,
  getDocumentExpiry,
  getDocumentExpiryDate,
  getDocumentValidity,
  getDocumentValidityDate,
  getDocumentType,
  getDocumentNumber,
  getDocumentName,
  getDocumentCountryOfIssue,
  getDocumentCountryOfIssueCode,
  getDocumentNationality,
  getDocumentDOB,
  calculateExpiry,
};
