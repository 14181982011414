import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../context/Keycloak';

// Config
import config from '../../utils/config';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../components/RenderForm/RenderForm';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../utils/Hooks/useSetTabTitle';

// Utils
import { Renderers } from '../../utils/Form/ReactForm';
import { StringUtil } from '../../utils';

// Form
import clearDownTargets from '../../forms/clearDownTargets';

// Styling
import './TargetsClearDown.scss';

const TargetsClearDown = ({ isSubmitted, setSubmitted, onCancel, onFinish, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isLoading, setIsLoading] = useState(false);

  useSetTabTitle(TAB_TITLES.ADMIN_PANEL.CLEAR_DOWN_TARGETS);

  const isValid = (value) => {
    return !(!value && value !== 0);
  };

  const formatToTwoDigits = (dateComponent) => {
    return StringUtil.padStart(dateComponent, 2, '0');
  };

  const toCleanedDate = (date) => {
    if (!date) {
      return null;
    }

    const [day, month, year] = date.split('-');
    if (!isValid(day) || !isValid(month) || !isValid(year)) {
      return null;
    }

    return [year, formatToTwoDigits(month), formatToTwoDigits(day)].join('-');
  };

  const toCleanedTime = (time) => {
    if (!time) {
      return null;
    }

    const [hour, minute] = time.split(':');
    if (!isValid(hour) || !isValid(minute)) {
      return null;
    }

    return [formatToTwoDigits(hour), formatToTwoDigits(minute)].join(':');
  };

  const toFormattedDate = (date, time, isEndDate = false) => {
    if (!date || !time) {
      return null;
    }

    const dateSuffix = isEndDate ? '59Z' : '00Z';
    const formattedDate = toCleanedDate(date);
    const formattedTime = toCleanedTime(time);

    if (!formattedDate || !formattedTime) {
      return null;
    }

    return `${formattedDate}T${formattedTime}:${dateSuffix}`;
  };

  const toSubmissionPayload = (data) => {
    return {
      note: 'Cleared down old targets',
      filterParams: {
        movementModes: data?.movementModes || [],
        groupCodes: [],
        teamCodes: [],
        journeyDirections: data?.journeyDirections || [],
        assignees: [],
        event: {
          startDate: toFormattedDate(data?.eventStartDate?.date, data?.eventStartDate?.time),
          endDate: toFormattedDate(data?.eventEndDate?.date, data?.eventEndDate?.time, true),
        },
        created: {
          startDate: toFormattedDate(data?.createdStartDate?.date, data?.createdStartDate?.time),
          endDate: toFormattedDate(data?.createdEndDate?.date, data?.createdEndDate?.time, true),
        },
        categories: [],
      },
      batchSize: 200,
    };
  };

  const onClearDown = async (data) => {
    setIsLoading(true);
    await AxiosRequests.targetsClearDown(apiClient, toSubmissionPayload(data))
      .then(() => {
        setSubmitted(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={clearDownTargets}
      viewOnly={false}
      onSubmit={async ({ data }) => onClearDown(data)}
      renderer={Renderers.REACT}
      onFinish={onFinish}
      onCancel={onCancel}
    />
  );
};

TargetsClearDown.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  setSubmitted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default TargetsClearDown;
