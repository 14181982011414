import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// Config
import config from '../../../../../../../utils/config';
import { CONTENT_TYPE, DATE_FORMATS } from '../../../../../../../utils/constants';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../context/TaskContext';

// Components
import DetailModal from './DetailModal';
import UpliftedForm from '../forms/UpliftedForm';

// Helpers
import addAdditionalContent from '../../../helper/addAdditionalContent';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import BaggageUtil from '../../../../../../../utils/Baggage/baggageUtil';
import BookingUtil from '../../../../../../../utils/Booking/bookingUtil';
import { DateTimeUtil } from '../../../../../../../utils';
import DocumentUploader from '../../../../../../../utils/Form/ReactForm/Uplift/documentUploader';
import PersonUtil from '../../../../../../../utils/Person/Uplift/personUtil';

// Form
import form from '../../../../../../../forms/uplift/addDetail';

// Styling
import './AddDetail.scss';

const AddDetail = ({ onClose, offsetTop, ...props }) => {
  const keycloak = useKeycloak();
  const uploadApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);
  const { task, setTask, originalTask, setOriginalTask, additionalContent, setAdditionalContent, taskId } = useTask();
  const booking = BookingUtil.get(task);
  const baggage = BaggageUtil.get(task);
  const persons = PersonUtil.allPersons(task);

  const [submissionPayload, setSubmissionPayload] = useState(null);

  useSetTabTitle(TAB_TITLES.ADD_TASK_DETAIL);

  const insertIf = (condition, elements) => {
    if (condition) {
      if (Array.isArray(elements)) {
        return elements;
      }
      return [elements];
    }

    return [];
  };

  const toOtherPersonsEntityContent = (entities, data, dateTimeStamp) => {
    const toEntityPosition = (entityName) => {
      const nameParts = entityName.split('_');
      return Number(nameParts[1]);
    };

    const toOtherPersonsPassengerContent = () => {
      return entities.map((entity) => {
        const entityPosition = toEntityPosition(entity);
        const positionAdjustment = 2;

        if (entity === `PASSENGER_${entityPosition}`) {
          return [
            ...insertIf(data?.[`passenger${entityPosition}Details`], {
              type: 'PASSENGER',
              entityId: task.movement.otherPersons[entityPosition - positionAdjustment]?.entityId,
              content: {
                type: CONTENT_TYPE.TEXT,
                content: data?.[`passenger${entityPosition}Details`],
              },
              timestamp: dateTimeStamp,
            }),
          ];
        }

        if (entity === `PASSENGER_${entityPosition}_PHOTO`) {
          return [
            ...insertIf(data?.[`passenger${entityPosition}Photo`]?.photo, {
              type: 'PASSENGER',
              entityId: task.movement.otherPersons[entityPosition - positionAdjustment]?.entityId,
              content: {
                type: CONTENT_TYPE.PHOTO,
                file: data?.[`passenger${entityPosition}Photo`]?.photo,
                filename: data?.[`passenger${entityPosition}Photo`]?.photo?.name,
                source: data?.[`passenger${entityPosition}Photo`]?.source,
                approximateDateTaken: data?.[`passenger${entityPosition}Photo`]?.approximateDate,
              },
              timestamp: dateTimeStamp,
            }),
          ];
        }

        if (entity === `PASSENGER_${entityPosition}_DOCUMENT`) {
          return [{
            type: 'DOCUMENT',
            entityId: task.movement.otherPersons[entityPosition - positionAdjustment]?.document?.entityId,
            content: {
              type: CONTENT_TYPE.TEXT,
              content: data?.[`passenger${entityPosition}DocumentDetails`],
            },
            timestamp: dateTimeStamp,
          }];
        }

        return [];
      })
        .flat(1);
    };

    return [
      ...toOtherPersonsPassengerContent(),
    ];
  };

  const toEntityContent = (entity, data, dateTimeStamp) => {
    if (entity === 'BOOKING') {
      return [{
        type: 'BOOKING',
        content: {
          type: CONTENT_TYPE.TEXT,
          content: data.bookingDetails,
        },
        timestamp: dateTimeStamp,
      }];
    }

    if (entity === 'BAGGAGE') {
      return [{
        type: 'BAGGAGE',
        content: {
          type: CONTENT_TYPE.TEXT,
          content: data.baggageDetails,
        },
        timestamp: dateTimeStamp,
      }];
    }

    if (entity === 'VOYAGE') {
      return [{
        type: 'VOYAGE',
        content: {
          type: CONTENT_TYPE.TEXT,
          content: data.voyageDetails,
        },
        timestamp: dateTimeStamp,
      }];
    }

    if (entity === 'PASSENGER_1') {
      return [
        ...insertIf(data?.passenger1Details, {
          type: 'PASSENGER',
          entityId: task?.movement?.person?.entityId,
          content: {
            type: CONTENT_TYPE.TEXT,
            content: data?.passenger1Details,
          },
          timestamp: dateTimeStamp,
        }),
      ];
    }

    if (entity === 'PASSENGER_1_PHOTO') {
      return [
        ...insertIf(data?.passenger1Photo?.photo, {
          type: 'PASSENGER',
          entityId: task?.movement?.person?.entityId,
          content: {
            type: CONTENT_TYPE.PHOTO,
            file: data?.passenger1Photo?.photo,
            filename: data?.passenger1Photo?.photo?.name,
            source: data?.passenger1Photo?.source,
            approximateDateTaken: data?.passenger1Photo?.approximateDate,
          },
          timestamp: dateTimeStamp,
        }),
      ];
    }

    if (entity.startsWith('PASSENGER_1_DOCUMENT')) {
      return [{
        type: 'DOCUMENT',
        entityId: task?.movement?.person?.document?.entityId,
        content: {
          type: CONTENT_TYPE.TEXT,
          content: data?.passenger1DocumentDetails,
        },
        timestamp: dateTimeStamp,
      }];
    }
  };

  const toSubmissionPayload = (data, dateTimeStamp) => {
    const otherPersonEntities = data.addDetail.filter((entity) => entity.startsWith('PASSENGER_') && !entity.startsWith('PASSENGER_1'));
    const entitiesToUpdate = data.addDetail.filter((entity) => !otherPersonEntities.includes(entity));

    const entitiesContents = entitiesToUpdate
      .map((entity) => toEntityContent(entity, data, dateTimeStamp))
      .filter((item) => !!item)
      .flat(1);

    const otherPersonsEntityContents = toOtherPersonsEntityContent(otherPersonEntities, data, dateTimeStamp);

    return [
      ...entitiesContents,
      ...otherPersonsEntityContents,
    ];
  };

  const uploadPhotoDocument = async (photoSubmission) => {
    const uploadedToUrl = await DocumentUploader.upload(uploadApiClient, taskId, photoSubmission.content.file.file);
    if (uploadedToUrl) {
      photoSubmission.content.url = uploadedToUrl;
      delete photoSubmission.content.file;
    }
    return photoSubmission;
  };

  const uploadDocuments = async (payload) => {
    const photoSubmissions = payload.filter((submission) => submission.content.type === CONTENT_TYPE.PHOTO);
    if (photoSubmissions.length > 0) {
      return Promise.all(photoSubmissions.map((submission) => uploadPhotoDocument(submission)));
    }
    return Promise.resolve();
  };

  const checkIfOriginalTaskSet = () => !!originalTask;

  const onSubmit = async ({ data }) => {
    const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
    const payload = toSubmissionPayload(data, dateTimeStamp);
    await uploadDocuments(payload);

    setSubmissionPayload(payload);
  };

  useEffect(() => {
    if (submissionPayload && submissionPayload.length > 0) {
      const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
      const user = {
        active: true,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name,
        email: keycloak.tokenParsed.email,
      };

      if (!checkIfOriginalTaskSet()) {
        setOriginalTask(JSON.parse(JSON.stringify(task)));
      }

      setTask(addAdditionalContent(task, submissionPayload, user, dateTimeStamp));
      setAdditionalContent(additionalContent ? [...additionalContent, ...submissionPayload] : submissionPayload);
      onClose();
    }
  }, [submissionPayload, onClose]);

  return (
    <div {...props}>
      <UpliftedForm
        form={form(booking, baggage, persons)}
        onSubmit={onSubmit}
        onCancel={onClose}
        offsetTop={offsetTop}
        uploadDocument={false}
        cancelModal={<DetailModal onProceed={onClose} />}
      />
    </div>
  );
};

AddDetail.propTypes = {
  onClose: PropTypes.func,
  offsetTop: PropTypes.number.isRequired,
};

AddDetail.defaultProps = {
  onClose: () => {},
};

export default AddDetail;
