import PropTypes from 'prop-types';
import React from 'react';

const FlightCode = ({ flightNumber, tag }) => {
  if (!flightNumber) {
    return null;
  }

  const Tag = tag;
  return (
    <Tag id="flight-number" className="govuk-!-font-weight-bold">
      {flightNumber}
    </Tag>
  );
};

FlightCode.propTypes = {
  flightNumber: PropTypes.string,
  tag: PropTypes.string,
};

FlightCode.defaultProps = {
  flightNumber: null,
  tag: 'p',
};

export default FlightCode;
