import { Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Config
import { SCRIM_TYPE } from '../../../utils/constants';

// Context
import { useScrim } from '../../../context/ScrimContext';

// Styling
import './Scrim.scss';

const DEFAULT_CLASS = 'hods-scrim';

const Scrim = ({ id, classBlock, classModifiers, ...props }) => {
  const { currentScrimType } = useScrim();

  const getClassByScrimType = () => {
    if (currentScrimType === SCRIM_TYPE.FULL) {
      return 'govuk-grid-column-full';
    }
    return 'govuk-grid-column-two-thirds';
  };

  const classes = Utils.classBuilder(classBlock, classModifiers, getClassByScrimType());
  return (
    <div
      className="govuk-grid-row"
      style={{
        height: '100%',
      }}
    >
      <div
        className={classes()}
        style={{
          ...currentScrimType && {
            zIndex: 2,
            position: 'absolute',
          },
        }}
        {...props}
      />
    </div>
  );
};

Scrim.propTypes = {
  id: PropTypes.string,
  classBlock: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Scrim.defaultProps = {
  id: null,
  classBlock: DEFAULT_CLASS,
  classModifiers: [],
};

export default Scrim;
