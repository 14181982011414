// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import Table from '../../../../../../components/Table/Table';

const SystemChecks = ({ systemChecks }) => {
  if (!systemChecks || !systemChecks?.length) {
    return null;
  }

  return (
    <div id="system-checks" className="govuk-!-padding-bottom-8">
      <Heading size="m">System checks</Heading>
      <Table
        className="system-checks"
        headings={['System', 'Result', 'Comments']}
        rows={systemChecks.map((nominalCheck, index) => [
          <span key={nominalCheck?.type} id={`nominal-type-${index}`}>{nominalCheck?.type}</span>,
          <span key={nominalCheck?.type} id={`nominal-checks-${index}`}>{nominalCheck?.checks}</span>,
          <span key={nominalCheck?.type} id={`nominal-comments-${index}`}>{nominalCheck?.comments}</span>,
        ])}
      />
    </div>
  );
};

SystemChecks.propTypes = {
  systemChecks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default memo(SystemChecks);
