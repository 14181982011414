import React from 'react';
import * as pluralise from 'pluralise';

import { ExclamationCircleFilled } from '../Icon/Icon';

import { getHiddenValue } from '../../utils/Hiding/hidingUtil';

import './PreviousTasksCount.scss';

const PreviousTasksCount = ({ numberOfPreviousTasks }) => {
  if (!numberOfPreviousTasks || numberOfPreviousTasks === getHiddenValue()) {
    return null;
  }

  return (
    <li className="previous-task-container govuk-!-font-weight-bold">
      <ExclamationCircleFilled />
      <span>
        {pluralise.withCount(numberOfPreviousTasks, '% previous task', '% previous tasks')}
      </span>
    </li>
  );
};

export default PreviousTasksCount;
