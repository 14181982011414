// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Config(s)
import config from '../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';

// Styling
import '../assets/Button.scss';

const ClaimTaskButton = ({ taskId, redirectPath, isTaskDetails, ...props }) => {
  const keycloak = useKeycloak();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  const onClaim = async () => {
    setInProgress(true);
    await AxiosRequests.claim(apiClient, taskId)
      .then(() => {
        if (isTaskDetails) {
          navigate(0);
          return;
        }
        navigate(`${redirectPath}/${taskId}`);
      })
      .catch(() => setInProgress(false));
  };

  return (
    <>
      <ComponentWrapper show={inProgress}>
        <span id="in-progress-dialog" className="ho-body">Please wait...</span>
      </ComponentWrapper>
      <ComponentWrapper show={!inProgress}>
        <Button aria-label={`claim task ${taskId}`} onClick={onClaim} {...props}>Claim</Button>
      </ComponentWrapper>
    </>
  );
};

ClaimTaskButton.propTypes = {
  taskId: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  isTaskDetails: PropTypes.bool,
};

ClaimTaskButton.defaultProps = {
  isTaskDetails: false,
};

export default ClaimTaskButton;
