import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import Person from './Person';

const Persons = ({ persons, booking, flight, journey, movementId, movementIds, s4Person }) => {
  if (!persons?.length) {
    return null;
  }

  return (
    <>
      {persons.map((person, index) => (
        <Person
          key={person.id}
          positionedAt={index}
          booking={booking}
          person={person}
          flight={flight}
          journey={journey}
          movementId={movementId}
          movementIds={movementIds}
          s4Person={index === 0 ? s4Person : null}
        />
      ))}
    </>
  );
};

Persons.propTypes = {
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  booking: PropTypes.shape({}).isRequired,
  flight: PropTypes.shape({}).isRequired,
  journey: PropTypes.shape({}).isRequired,
  s4Person: PropTypes.shape({}),
};

Persons.defaultProps = {
  s4Person: null,
};

export default Persons;
