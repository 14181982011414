// Global import(s)
import { withCount } from 'pluralise';

const getCountForOptionHint = (fieldId, value, groupCounts) => {
  if (fieldId === 'groupCodes') {
    return groupCounts?.find((group) => group.groupCode === value)?.count;
  }
  return 0;
};

const setupOptions = (component, groupCounts) => {
  return component.data.options.map((opt) => {
    let count = getCountForOptionHint(component.fieldId, opt.value, groupCounts);
    count = count === undefined ? 0 : count;
    const originalHint = opt.originalHint || opt.hint;
    const hint = count === null ? originalHint : `${withCount(count, '% target', '% targets', '% targets')}`;
    return {
      ...opt,
      count,
      originalHint,
      hint,
    };
  });
};

const setupComponentOptions = (component, groupCounts) => {
  if (component.data?.options && component.dynamicOptions) {
    component.data.options = setupOptions(component, groupCounts);
  }
  return component;
};

const setupFilterGroupCounts = (form, groupCounts) => {
  form.pages = form.pages.map((page) => {
    return {
      ...page,
      components: page.components.map((component) => {
        return setupComponentOptions(component, groupCounts);
      }),
    };
  });
  return form;
};

export default setupFilterGroupCounts;
