import React from 'react';

import { useKeycloak } from '../../../../../context/Keycloak';
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';

// Services
import AxiosRequests from '../../../../../api/axiosRequests';

// Config
import config from '../../../../../utils/config';

// Components
import RenderForm from '../../../../../components/RenderForm/RenderForm';

// Hook
import useSetTabTitle, { TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';

// Form
import withdrawTask from '../../../../../forms/withdrawTaskCerberus';

const WithdrawTask = ({ taskId, isSubmitted, onCancel, setSubmitted, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  useSetTabTitle(TAB_TITLES.TARGET_WITHDRAW);

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={withdrawTask}
      preFillData={{ taskId }}
      onSubmit={
        async ({ data: { reasonForWithdrawing } }) => {
          await AxiosRequests.withdrawTask(apiClient, taskId, { reason: reasonForWithdrawing });
          setSubmitted();
        }
      }
      onCancel={onCancel}
    />
  );
};

export default WithdrawTask;
