import React from 'react';

// Components
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Header from '../Headers/Header';
import Footer from './Footer';

const DefaultLayout = ({ beforeMain, children }) => {
  return (
    <>
      <Header />
      <div className="govuk-width-container">
        {beforeMain}
        <main className="govuk-main-wrapper" id="main-content" role="main">
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default DefaultLayout;
