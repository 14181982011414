import React from 'react';

// Components
import NoJustification from '../NoJustification';
import ApprovedJustifications from '../ApprovedJustifications';

// Utils
import { JustificationUtil } from '../../../../../../../utils';

const toJustificationOptions = (justifications, onOpenAndCloseAction, toCreateJustification, getTaskWithJustification) => {
  if (!JustificationUtil.has(justifications?.data)) {
    return (
      <NoJustification
        pendingApprovals={justifications.pendingApprovals}
        onOpenAndCloseAction={onOpenAndCloseAction}
        toCreateJustification={toCreateJustification}
      />
    );
  }

  return (
    <ApprovedJustifications
      justifications={justifications.data}
      pendingApprovals={justifications.pendingApprovals}
      onOpenAndCloseAction={onOpenAndCloseAction}
      getTaskWithJustification={getTaskWithJustification}
    />
  );
};

export default toJustificationOptions;
