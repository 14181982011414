import { Button } from '@ukhomeoffice/cop-react-components';
import React from 'react';

const ActionButton = ({ classes, onClick, buttonText, permission, enabled }) => {
  if (!permission) {
    return null;
  }

  if (!enabled) {
    return null;
  }

  return (
    <Button
      className={classes}
      onClick={() => {
        onClick();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default ActionButton;
