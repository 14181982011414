import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import { MOVEMENT_MODES } from '../../../../../../utils/constants';

import { NumberUtil } from '../../../../../../utils';

import { DEFAULT_HIGHLIGHT_CLASS } from '../../../helper/common';

import './OccupantCount.scss';

// Ordered in how it is to be rendered.
const COUNT_KEYS = [
  'numberOfInfants',
  'numberOfChildren',
  'numberOfAdults',
  'numberOfOaps',
  'numberOfUnknowns',
];

const LABELS = {
  numberOfInfants: 'Infants',
  numberOfChildren: 'Children',
  numberOfAdults: 'Adults',
  numberOfOaps: 'OAPs',
};

const toCountsBlock = (occupantCounts, occupantCountsDiff, keys) => {
  return keys.map((k) => {
    const classnames = NumberUtil.greaterThanZero(occupantCounts[k]) ? ['font__bold'] : ['font__grey'];
    const highlight = occupantCounts[k] !== occupantCountsDiff[k] ? DEFAULT_HIGHLIGHT_CLASS : null;
    return (
      <div
        className={classNames('govuk-task-details-grid-row bottom-border', classnames)}
        key={k}
      >
        <span className={classNames('govuk-grid-key', highlight)}>
          {LABELS[k]}
        </span>
        <span className={classNames('govuk-grid-value', highlight)}>
          {occupantCounts[k]}
        </span>
      </div>
    );
  });
};

const containsOneNonZeroCount = (occupantCounts) => {
  if (!occupantCounts) {
    return false;
  }
  return COUNT_KEYS.some((k) => occupantCounts[k] > 0);
};

const OccupantCount = ({ mode,
  primaryTraveller,
  coTravellers,
  occupantCounts,
  occupantCountsDiff,
  classModifiers,
  label }) => {
  if (![MOVEMENT_MODES.ACCOMPANIED_FREIGHT, MOVEMENT_MODES.TOURIST].includes(mode)) {
    return null;
  }

  let jsxBlock = null;
  if (!primaryTraveller && !coTravellers?.length && containsOneNonZeroCount(occupantCounts)) {
    jsxBlock = toCountsBlock(occupantCounts, occupantCountsDiff, COUNT_KEYS.filter((k) => k !== 'numberOfUnknowns'));
  }

  if (primaryTraveller && !coTravellers?.length && containsOneNonZeroCount(occupantCounts)) {
    jsxBlock = toCountsBlock(occupantCounts, occupantCountsDiff, COUNT_KEYS.filter((k) => k !== 'numberOfUnknowns'));
  }

  if (!primaryTraveller && !coTravellers?.length && !containsOneNonZeroCount(occupantCounts)) {
    jsxBlock = toCountsBlock(occupantCounts, occupantCountsDiff, COUNT_KEYS.filter((k) => k === 'numberOfUnknowns'));
  }

  if (!jsxBlock) {
    return null;
  }

  return (
    <>
      {label ? <Heading className="govuk-!-margin-top-0" size="m">{label}</Heading> : null}
      <div className={classNames('govuk-task-details-counts-container', classModifiers)}>
        <div className="govuk-task-details-grid-row bottom-border">
          <span className="govuk-grid-key font__light">Category</span>
          <span className="govuk-grid-value font__light">Number</span>
        </div>
        <div className="task-details-container">
          {jsxBlock}
        </div>
      </div>
    </>
  );
};

export default OccupantCount;
