// Util(s)
import { shouldHideNumber } from '../../Hiding/hidingUtil';
import { getMaskedValue, shouldMaskNumber } from '../../Masking/maskingUtil';

const getGroupSize = (task) => {
  if (!task?.movement?.groupSize) {
    return null;
  }

  if (shouldHideNumber(task.movement.groupSize)) {
    return null;
  }

  if (shouldMaskNumber(task.movement.groupSize)) {
    return getMaskedValue();
  }

  return task.movement.groupSize;
};

const getBookingVsIntent = (task) => {
  if (!task?.movement?.bookingVsIntent) {
    return null;
  }

  if (shouldHideNumber(task.movement.bookingVsIntent)) {
    return null;
  }

  if (shouldMaskNumber(task.movement.bookingVsIntent)) {
    return getMaskedValue();
  }

  return task.movement.bookingVsIntent;
};

const getMovementId = (task) => {
  if (!task?.movement?.id) {
    return null;
  }
  return task.movement.id;
};

const getMovement = (task) => {
  return task?.movement || null;
};

const MovementUtil = {
  bookingVsIntent: getBookingVsIntent,
  get: getMovement,
  groupSize: getGroupSize,
  movementId: getMovementId,
};

export default MovementUtil;

export { getBookingVsIntent, getGroupSize, getMovement, getMovementId };
