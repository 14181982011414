// Global imports
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isLeapYear from 'dayjs/plugin/isLeapYear';

// Local imports
import { DATE_FORMAT, DATETIME_FORMAT, formatString } from './utils';

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isLeapYear);

export const maxMonthDays = (month, year) => {
  if (month === '02') {
    return dayjs().year(year).isLeapYear() ? 29 : 28;
  }
  if (['04', '06', '09', '11'].includes(month)) {
    return 30;
  }
  return 31;
};

/**
 * Checks if a date passed is a valid date.
 * This will validate for 'leap years', missing components, invalid day, month or year components.
 * This will also check a datetime value.
 *
 * Note that an empty string will be considered invalid.
 *
 * @param {string} date  date as a string
 * @param {*} component  the component config
 * @param {*} customErrors  an array of custom errors
 * @returns an error message or undefined if the date is valid
 */
const validateDate = (component, date, customErrors) => {
  if (!date) {
    if (Array.isArray(customErrors)) {
      const result = customErrors.filter((error) => {
        return error.type === 'required';
      });
      if (result && result.length > 0 && result[0].message) {
        return result[0].message;
      }
    }
    return `${component?.label || component?.fieldId} is required`;
  }

  const formattedDate = formatString(date);
  const isDateTime = component.fieldId.includes('Range');
  const [day, month, year, hour, minute] = date.split('-');
  const intDay = parseInt(day, 10);
  const intMonth = parseInt(month, 10);
  const intHour = parseInt(hour, 10);
  const intMinute = parseInt(minute, 10);

  if (year.length === 0) {
    return 'Date must include a year';
  }
  if (year.length !== 4) {
    return 'Year must be 4 numbers';
  }

  if (month.length === 0 || !month) {
    return 'Date must include a month';
  }
  if (intMonth > 12 || intMonth < 1) {
    return 'Month must be between 1 and 12';
  }

  if (day.length === 0 || !day) {
    return 'Date must include a day';
  }
  const maxDays = maxMonthDays(month, year);
  if (intDay > maxDays || intDay < 1) {
    return `Day must be between 1 and ${maxDays}`;
  }

  if (isDateTime) {
    if (!hour || hour.length === 0) {
      return 'Date must include an hour';
    }
    if (intHour > 23 || intHour < 0) {
      return 'Hour must be between 0 and 23';
    }

    if (!minute || minute.length === 0) {
      return 'Date must include a minute';
    }
    if (intMinute > 59 || intMinute < 0) {
      return 'Minute must be between 0 and 59';
    }
  }

  const FORMAT = !isDateTime ? DATE_FORMAT : DATETIME_FORMAT;
  if (dayjs(formattedDate, FORMAT).format(FORMAT) !== formattedDate) {
    return 'Enter a valid date';
  }

  return undefined;
};

export default validateDate;
