import { shouldMaskDate, shouldMaskString, getMaskedValue } from '../Masking/maskingUtil';
import { shouldHideDate, shouldHideString } from '../Hiding/hidingUtil';

const getJourney = (task) => {
  return task?.movement?.s4Journey || null;
};

const getManifestType = (journey) => {
  if (!journey?.manifestType) {
    return null;
  }

  if (shouldMaskString(journey.manifestType)) {
    return getMaskedValue();
  }

  if (shouldHideString(journey.manifestType)) {
    return null;
  }

  return journey.manifestType;
};

const getManifestArrivalDateTime = (journey) => {
  if (!journey?.manifestArrivalAt) {
    return null;
  }

  if (shouldMaskDate(journey.manifestArrivalAt)) {
    return getMaskedValue();
  }

  if (shouldHideDate(journey.manifestArrivalAt)) {
    return null;
  }

  return journey.manifestArrivalAt;
};

const getOriginalSentAt = (journey) => {
  if (!journey?.originalSentAt) {
    return null;
  }

  if (shouldMaskDate(journey.originalSentAt)) {
    return getMaskedValue();
  }

  if (shouldHideDate(journey.originalSentAt)) {
    return null;
  }

  return journey.originalSentAt;
};

const JourneyS4Util = {
  get: getJourney,
  manifestType: getManifestType,
  manifestArrivalDateTime: getManifestArrivalDateTime,
  originalSentAt: getOriginalSentAt,
};

export default JourneyS4Util;

export {
  getJourney,
  getManifestType,
  getManifestArrivalDateTime,
  getOriginalSentAt,
};
