const addLocalStorageItem = (key, payload) => {
  if (key && payload !== 0) {
    if (typeof payload === 'object') {
      localStorage.setItem(key, JSON.stringify(payload));
    } else {
      localStorage.setItem(key, payload);
    }
  }
};

const removeLocalStorageItem = (key) => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};

const isLocalStoredPresent = (key) => {
  return localStorage.getItem(key) !== null
    && localStorage.getItem(key) !== 'null';
};

/**
 * Gets a particular field out of the stored data.
 * This can also equally return the whole stored data.
 *
 * @param {*} key The key the stored data is associated with.
 * @param {*} value The value to be returned from the stored data.
 *            Omitting this parameter will return just the whole stored data item.
 * @returns A particular item from within the stored data or the whole stored data.
 */
const getLocalStoredItemByKeyValue = (key, value = undefined) => {
  if (!isLocalStoredPresent(key)) {
    return null;
  }
  if (!value && value !== 0) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      // Must be a string
      return localStorage.getItem(key);
    }
  }
  return JSON.parse(localStorage.getItem(key))[value];
};

const StorageUtil = {
  add: addLocalStorageItem,
  get: getLocalStoredItemByKeyValue,
  remove: removeLocalStorageItem,
};

export default StorageUtil;

export {
  addLocalStorageItem,
  getLocalStoredItemByKeyValue,
  removeLocalStorageItem,
};
