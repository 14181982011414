// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Config(s)
import config from '../config';
import { GROUP_MEMBER, ROLE } from '../constants';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { useUser } from '../../context/UserContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchUser = () => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();

  const { setUserGroupMember,
    setUserRole,
    setUserGroups,
    setIsHigherOfficer,
    setIsRCCUOfficer,
    setIsInterceptingOfficer } = useUser();

  const setupUserGroups = (data) => {
    setUserGroups({
      airPaxFrontLineGroups: data?.airPaxFrontLineGroups || [],
      roRoFrontLineGroups: data?.roRoFrontLineGroups || [],
      rccuGroups: data?.rccuGroups || [],
    });
  };

  const setupUserGroupMember = (data) => {
    if ((data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) && data?.roRoFrontLineGroups?.length) {
      setUserGroupMember(GROUP_MEMBER.BOTH);
      return;
    }

    if ((data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) && !data?.roRoFrontLineGroups?.length) {
      setUserGroupMember(GROUP_MEMBER.AIRPAX);
      return;
    }

    if (data?.roRoFrontLineGroups?.length && !data.airPaxFrontLineGroups?.length && !data?.rccuGroups?.length) {
      setUserGroupMember(GROUP_MEMBER.RORO);
      return;
    }

    setUserGroupMember(GROUP_MEMBER.NONE);
  };

  const setupUserRole = (data) => {
    if (data?.rccuOfficer) {
      setUserRole(ROLE.RCCU);
      return;
    }

    if (data?.higherOfficer) {
      setUserRole(ROLE.HIGHER_OFFICER);
      return;
    }

    setUserRole(ROLE.INTERCEPTING_OFFICER);
  };

  const setupHigherOfficer = (data) => {
    setIsHigherOfficer(!!data?.higherOfficer);
  };

  const setupRCCUOfficer = (data) => {
    setIsRCCUOfficer(!!data?.rccuOfficer);
  };

  const setupInterceptingOfficer = (data) => {
    setIsInterceptingOfficer(!!data?.interceptingOfficer);
  };

  const getUserDetails = async () => {
    return AxiosRequests.userDetails(apiClient);
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: () => getUserDetails(),
    initialData: null,
  });

  useEffect(() => {
    if (data) {
      setupUserGroupMember(data);
      setupUserRole(data);
      setupUserGroups(data);
      setupHigherOfficer(data);
      setupRCCUOfficer(data);
      setupInterceptingOfficer(data);
    }

    return () => AxiosRequests.cancel(source);
  }, [data]);
};

export default useFetchUser;
