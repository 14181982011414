import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
// Utils
import { getJourney, getArrivalTime } from '../../../../../utils/Journey/journeyUtil';

import {
  DocumentUtil,
  PersonUtil, TaskVersionUtil, CommonUtil, JourneyUtil,
} from '../../../../../utils';

import renderBlock, { toField, toPreviousTaskLinkParams, toPreviousTaskLink, toPreviousMovementLink } from '../../helper/common';

import FIELDS from '../../helper/Fields';

const Document = ({ version, versionDiff }) => {
  const person = PersonUtil.get(version);
  const personDiff = PersonUtil.get(versionDiff);
  const document = DocumentUtil.get(person);
  const documentDiff = DocumentUtil.get(personDiff);
  const journey = getJourney(version);
  const journeyDiff = getJourney(versionDiff);
  const documentJourneyDiff = { ...documentDiff, ...journeyDiff?.arrival };
  const departureDate = JourneyUtil.departureTime(journey);

  return (
    <div className="task-details-container bottom-border-thin govuk-!-margin-bottom-2">
      <Heading className="govuk-!-margin-top-0" size="m">Document</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Type',
          {
            content: DocumentUtil.docType(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Number',
          {
            content: DocumentUtil.docNumber(document),
            url: CommonUtil.entitySearchUrl(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.number]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, document)),
          toPreviousMovementLink(document),
        )}
        {renderBlock(
          'Document nationality',
          {
            content: DocumentUtil.docNationality(document, true),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.countryOfIssue]),
        )}
        {renderBlock(
          'Country of issue',
          {
            content: DocumentUtil.docCountry(document, true),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.countryOfIssue]),
        )}
        {renderBlock(toField('Valid from',
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.validFrom])),
        [
          toField(DocumentUtil.docValidity(document, true),
            TaskVersionUtil.hasAny(documentDiff, [FIELDS.validFrom])),
          toField(DocumentUtil.calculateExpiry(DocumentUtil.docValidityDate(document), departureDate),
            TaskVersionUtil.hasAny(documentDiff, [FIELDS.validFrom])),
        ])}
        {renderBlock(
          toField('Valid To',
            TaskVersionUtil.hasAny(documentJourneyDiff, [FIELDS.expiry, FIELDS.time])),
          [
            toField(DocumentUtil.docExpiry(document, true),
              TaskVersionUtil.hasAny(documentJourneyDiff, [FIELDS.expiry])),
            toField(DocumentUtil.calculateExpiry(DocumentUtil.docExpiryDate(document), getArrivalTime(journey)),
              TaskVersionUtil.hasAny(documentJourneyDiff, [FIELDS.expiry, FIELDS.time])),
          ],
        )}
        {renderBlock(
          'Name',
          {
            content: DocumentUtil.docName(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.name]),
        )}
        {renderBlock(
          'Date of birth',
          {
            content: PersonUtil.dob(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.dateOfBirth]),
        )}
      </div>
    </div>
  );
};

export default Document;
