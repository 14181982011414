import airports from '@nitro-land/airport-codes';
import { Tag } from '@ukhomeoffice/cop-react-components';
import lookup from 'country-code-lookup';
import React from 'react';

import {
  DEPARTURE_STATUS,
  STRINGS,
  MOVEMENT_DESCRIPTION,
  MOVEMENT_ROLE,
  TAGS,
  TASK_OUTCOME,
  TASK_OUTCOME_NAME,
} from '../constants';

import {
  calculateTimeDifference,
  isInPast,
  toDateTimeList,
  toRelativeTime,
} from '../Datetime/datetimeUtil';
import { getTotalNumberOfPersons } from '../Person/personUtil';
import CommonUtil from '../Common/commonUtil';
import JourneyUtil from '../Journey/journeyUtil';
import FlightUtil from '../Flight/flightUtil';
import { getHiddenValue, HidingConstants, shouldHideDate, shouldHideString, shouldHideStringArray } from '../Hiding/hidingUtil';

const getOccupantCounts = (targetTask) => {
  return targetTask?.movement?.occupants || null;
};

const getOutcomeTagBlock = (outcome) => {
  const outcomeText = TASK_OUTCOME_NAME[outcome];
  let classname = 'generic-outcome';
  if (outcome === TASK_OUTCOME.POSITIVE) {
    classname = 'positive-outcome';
  }

  return (
    outcomeText && (
      <Tag className={`tag tag--${classname}`} text={outcomeText} />
    )
  );
};

const getOutcomeFrontlineArrests = (outcome) => {
  if (!outcome) {
    return false;
  }
  return outcome?.arrests;
};

const getOutcomeFrontlineOfficer = (outcome) => {
  if (!outcome) {
    return STRINGS.UNKNOWN;
  }
  return outcome?.frontlineOfficer || STRINGS.UNKNOWN;
};

const getOutcomeStatusTag = (outcome) => {
  if (!outcome) {
    return null;
  }
  return getOutcomeTagBlock(TASK_OUTCOME[outcome]) || null;
};

const getOutcome = (targetTask) => {
  return targetTask?.frontLineAction?.outcome
    || targetTask?.targeterAction?.outcome;
};

const getMovementMode = (targetTask) => {
  return targetTask?.movement?.mode || null;
};

const getIconDescription = (targetTask) => {
  return targetTask?.movement?.description || null;
};

const isValid = (obj) => {
  return obj !== null && obj !== undefined && obj !== '';
};

const toVoyageText = (dateTime, isTaskDetails = false, prefix = '') => {
  if (shouldHideDate(dateTime)) {
    return null;
  }

  const time = toRelativeTime(dateTime);
  const isPastDate = isInPast(dateTime);
  if (isPastDate !== STRINGS.UNKNOWN) {
    if (!isTaskDetails) {
      if (isPastDate) {
        return `arrived ${time}`.trim();
      }
      return `arriving in ${time.replace(STRINGS.BEFORE_TRAVEL_TEXT, '')}`.trim();
    }
    if (isPastDate) {
      return `arrived at ${prefix} ${time}`.trim();
    }
    return `arrival at ${prefix} in ${time.replace(STRINGS.BEFORE_TRAVEL_TEXT, '')}`.trim();
  }

  return STRINGS.UNKNOWN;
};

const getRelistedStatus = (targetTask) => {
  if (!targetTask?.relisted) {
    return undefined;
  }

  return (
    <Tag className="tag tag--relisted-task" text={TAGS.RELISTED} />
  );
};

const getUpdatedStatus = (targetTask) => {
  if (targetTask.latestVersionNumber <= 1) {
    return undefined;
  }

  return (
    <Tag className="tag tag--updated-task" text={TAGS.UPDATED} />
  );
};

const getWithdrawnStatus = (targetTask) => {
  if (!targetTask?.withdrawn) {
    return undefined;
  }

  return (
    <Tag className="tag tag--withdrawn-task" text={TAGS.WITHDRAWN} />
  );
};

const getItineraryFlightNumber = (itinerary) => {
  if (!itinerary?.id) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(itinerary.id)) {
    return getHiddenValue();
  }

  return itinerary.id;
};

const getByIataCode = (iataCode) => {
  return airports.findWhere({ iata: iataCode });
};

const getCityByIataCode = (iataCode) => {
  if (!iataCode) {
    return STRINGS.UNKNOWN;
  }
  const city = getByIataCode(iataCode)?.get('city');
  if (!city) {
    return STRINGS.UNKNOWN;
  }
  return city;
};

const toRoute = (route) => {
  if (!route) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideStringArray(route)) {
    return null;
  }
  return route.map((r, index) => {
    return (<span key={r}>{r === HidingConstants.stringValue ? getHiddenValue() : r} {index < route.length - 1 && <>&#8594;</>} </span>);
  });
};

const getSeatNumber = (flight, taskDetails = false) => {
  const seatPrefix = 'seat';
  if (!flight?.seatNumber) {
    return !taskDetails ? `${seatPrefix} ${STRINGS.UNKNOWN}` : STRINGS.UNKNOWN;
  }
  if (shouldHideString(flight.seatNumber)) {
    return getHiddenValue();
  }
  return !taskDetails ? `${seatPrefix} ${flight.seatNumber}` : flight.seatNumber;
};

const hasFlight = (targetTask) => {
  return !!targetTask?.movement?.flight;
};

const getFlight = (targetTask) => {
  if (hasFlight(targetTask)) {
    return targetTask.movement.flight;
  }
  return null;
};

const toFormattedLocation = (location) => {
  if (!location) {
    return STRINGS.UNKNOWN;
  }

  if (location === getHiddenValue() || shouldHideString(location)) {
    return null;
  }

  const airport = getByIataCode(location);
  return airport ? `${airport.get('city')}, ${airport.get('country')}` : STRINGS.UNKNOWN;
};

/**
 * If country code is not provided, it will use the arrival location
 * to extract the arrival country code.
 */
const getItineraryArrivalCountryCode = (itinerary) => {
  if (shouldHideString(itinerary?.arrival?.country)) {
    return getHiddenValue();
  }

  if (!itinerary?.arrival?.country) {
    const arrivalLoc = JourneyUtil.arrivalLoc(itinerary);
    if (!arrivalLoc) {
      return STRINGS.UNKNOWN;
    }
    const arrivalCountry = getByIataCode(arrivalLoc)?.get('country');
    if (!arrivalCountry) {
      return STRINGS.UNKNOWN;
    }
    if (lookup.byCountry(arrivalCountry) !== null) {
      return lookup.byCountry(arrivalCountry).iso3;
    }
    return STRINGS.UNKNOWN;
  }
  return CommonUtil.iso3Code(itinerary.arrival.country);
};

/**
 * If country code is not provided, it will use the departure location
 * to extract the arrival country code.
 */
const getItineraryDepartureCountryCode = (itinerary) => {
  if (shouldHideString(itinerary?.departure?.country)) {
    return getHiddenValue();
  }

  if (!itinerary?.departure?.country) {
    const departureLoc = JourneyUtil.departureLoc(itinerary);
    if (!departureLoc) {
      return STRINGS.UNKNOWN;
    }
    const departureCountry = getByIataCode(departureLoc)?.get('country');
    if (!departureCountry) {
      return STRINGS.UNKNOWN;
    }
    if (lookup.byCountry(departureCountry) !== null) {
      return lookup.byCountry(departureCountry).iso3;
    }
    return STRINGS.UNKNOWN;
  }
  return CommonUtil.iso3Code(itinerary.departure.country);
};

const getAirlineOperator = (flight) => {
  if (!flight?.operator) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(flight.operator)) {
    return null;
  }
  return flight.operator;
};

const getDepartureStatus = (targetTask, taskDetails = false) => {
  const departureStatus = FlightUtil.departureStatus(FlightUtil.get(targetTask));
  if (departureStatus) {
    if ([STRINGS.UNKNOWN, STRINGS.UNKNOWN.toUpperCase()].includes(departureStatus)) {
      return <span>{STRINGS.UNKNOWN}</span>;
    }

    if (departureStatus === getHiddenValue()) {
      return null;
    }

    return (
      <>
        {taskDetails && <span>{DEPARTURE_STATUS[departureStatus]?.description || STRINGS.UNKNOWN} </span>}
        <Tag className={`airpax-status airpax-status--${DEPARTURE_STATUS[departureStatus]?.classname}`}>
          {DEPARTURE_STATUS[departureStatus]?.code}
        </Tag>
      </>
    );
  }

  return (
    <>
      {taskDetails && <span>{STRINGS.UNKNOWN}</span>}
    </>
  );
};

// TODO: Remove and replace all usage with that in uplifted person util.
const getMovementTypeText = (targetTask) => {
  const personRole = targetTask?.movement?.person?.role;

  if (!personRole) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(personRole)) {
    return null;
  }

  if (personRole === MOVEMENT_ROLE.PASSENGER) {
    return 'Passenger';
  }

  if (personRole === MOVEMENT_ROLE.AIR_CREW) {
    return 'Crew';
  }

  if (personRole === MOVEMENT_ROLE.PILOT) {
    return 'Pilot';
  }

  return STRINGS.UNKNOWN;
};

const toDescriptionText = (targetTask) => {
  const movementDescription = targetTask?.movement?.description;
  const movementRole = targetTask?.movement?.person?.role;

  if (shouldHideString(movementRole) || shouldHideString(movementDescription)) {
    return null;
  }

  if (movementRole === MOVEMENT_ROLE.PASSENGER) {
    if (movementDescription === MOVEMENT_DESCRIPTION.INDIVIDUAL) {
      return 'Single passenger';
    }
    if (movementDescription === MOVEMENT_DESCRIPTION.GROUP) {
      return `In group of ${getTotalNumberOfPersons(targetTask)}`;
    }
  }

  if (movementRole === MOVEMENT_ROLE.AIR_CREW) {
    return 'Crew member';
  }

  return STRINGS.UNKNOWN;
};

const toAirlineName = (flight) => {
  if (!flight || !flight?.carrier || !flight?.carrier?.name) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(flight.carrier.name)) {
    return null;
  }

  return flight.carrier.name;
};

const toItineraryRelativeTime = (index, itinerary, itineraries) => {
  const previousLegArrivalTime = JourneyUtil.arrivalTime((itineraries[index - 1]));
  const nextLegDepartureTime = JourneyUtil.departureTime((itinerary));

  if (previousLegArrivalTime === getHiddenValue() || nextLegDepartureTime === getHiddenValue()) {
    return (
      <div className="font__light">
        {getHiddenValue()}
      </div>
    );
  }

  return (
    <div className="font__light">
      {calculateTimeDifference(
        toDateTimeList(previousLegArrivalTime, nextLegDepartureTime), undefined, STRINGS.LATER_TEXT,
      )}
    </div>
  );
};

const MovementUtil = {
  airlineName: toAirlineName,
  airlineOperator: getAirlineOperator,
  convertMovementRoute: toRoute,
  description: toDescriptionText,
  formatLoc: toFormattedLocation,
  iataToCity: getCityByIataCode,
  iconDescription: getIconDescription,
  isValid,
  itinArrivalCountryCode: getItineraryArrivalCountryCode,
  itinDepartureCountryCode: getItineraryDepartureCountryCode,
  itinFlightNumber: getItineraryFlightNumber,
  itinRelativeTime: toItineraryRelativeTime,
  movementFlight: getFlight,
  movementMode: getMovementMode,
  movementType: getMovementTypeText,
  occupantCounts: getOccupantCounts,
  outcome: getOutcome,
  outcomeFLO: getOutcomeFrontlineOfficer,
  outcomeFLOArrests: getOutcomeFrontlineArrests,
  outcomeStatusTag: getOutcomeStatusTag,
  relistStatus: getRelistedStatus,
  seatNumber: getSeatNumber,
  status: getDepartureStatus,
  updatedStatus: getUpdatedStatus,
  voyageText: toVoyageText,
  withdrawnStatus: getWithdrawnStatus,
};

export default MovementUtil;

export {
  getAirlineOperator,
  getCityByIataCode,
  getDepartureStatus,
  getFlight,
  getIconDescription,
  getItineraryArrivalCountryCode,
  getItineraryDepartureCountryCode,
  getItineraryFlightNumber,
  getMovementMode,
  getMovementTypeText,
  getOccupantCounts,
  getRelistedStatus,
  getSeatNumber,
  getUpdatedStatus,
  getWithdrawnStatus,
  isValid,
  toAirlineName,
  toDescriptionText,
  toFormattedLocation,
  toItineraryRelativeTime,
  toRoute,
  toVoyageText,
};
