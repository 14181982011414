import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Config(s)
import config from '../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useFullScreen } from '../../../../../../../context/FullScreenContext';
import { useTask } from '../../../../../../../context/TaskContext';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Component(s)
import OutcomeCaption from './components/OutcomeCaption';
import OutcomeMessage from '../prompts/OutcomeMessage';
import UpliftedForm from '../forms/UpliftedForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';
import { setTabTitle, TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import { CommonUtil } from '../../../../../../../utils';
import { escapeString } from '../../../../../../../utils/String/stringUtil';
import { isIdpSubMode } from '../../../../../../../utils/Task/taskUtil';
import toAdditionalContentAndCredibilityChecksPayload from '../../../helper/toAdditionalContentAndCredibilityChecksPayload';
import { getCredibilityChecksPayload } from '../../../helper/updateCredibilityChecks';

// Form
import form from '../../../../../../../forms/uplift/withdrawTarget';

// Styling
import './WithdrawTarget.scss';

const WithdrawTarget = ({ targetId, recipients, onCancel, onClose, ...props }) => {
  const keycloak = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [submitted, setSubmitted] = useState(false);
  const { setTask, setIsUpdateTask, subMode, additionalContent, setAdditionalContent, credibilityChecks, setCredibilityChecks } = useTask();
  const { setFullScreen } = useFullScreen();

  setTabTitle(TAB_TITLES.WITHDRAW_A_TARGET);

  const toSubmissionPayload = (data, content, checks) => {
    return {
      update: toAdditionalContentAndCredibilityChecksPayload(content, checks),
      complete: {
        reason: data.newAssessmentResult,
        otherReasonDetail: data.newAssessmentResult === 'OTHER' ? escapeString(data.otherReasonDetail) : null,
      },
      withdraw: {
        reason: escapeString(data.withdrawalReason),
      },
    };
  };

  const onSubmit = async ({ data }) => {
    await AxiosRequests.withdrawAndCompleteTask(apiClient, targetId, toSubmissionPayload(data, additionalContent, getCredibilityChecksPayload(credibilityChecks, subMode)))
      .then((updatedTask) => {
        setTask(updatedTask);
        setSubmitted(true);
        setCredibilityChecks(null);
        setAdditionalContent(null);
        setIsUpdateTask(false);
      });
  };

  const onInternalCancel = () => {
    setTimeout(() => setTabTitle(TAB_TITLES.EDIT_TARGET), 50);
    onCancel();
  };

  useEffect(() => {
    if (submitted) {
      setFullScreen(
        <OutcomeMessage
          oldTabTitle={TAB_TITLES.WITHDRAW_A_TARGET}
          newTabTitle={TAB_TITLES.TARGET_WITHDRAWN}
          title="Target withdrawn for"
          caption={<OutcomeCaption recipients={recipients} />}
          onBackToTask={() => onClose()}
          onBackToTaskList={() => navigate(CommonUtil.getListPath(location.pathname))}
        />,
      );
    }
  }, [submitted]);

  useEffect(() => {
    return () => setTabTitle(TAB_TITLES.TASK_DETAILS);
  }, []);

  return (
    <div {...props}>
      <UpliftedForm
        form={form(isIdpSubMode(subMode))}
        fullScreenOnly
        onSubmit={onSubmit}
        onCancel={onInternalCancel}
      />
    </div>
  );
};

WithdrawTarget.propTypes = {
  targetId: PropTypes.string.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(WithdrawTarget);
