import PropTypes from 'prop-types';
import React from 'react';

import { Navigate, generatePath, useParams } from 'react-router-dom';

/**
 * Redirect component
 *
 * @param {*} to string to use as the base path
 *
 * This component is used in the routes where the taskId needs to be
 * passed on through a redirect (navigate);
 */
const Redirect = ({ to }) => {
  const params = useParams();
  return <Navigate to={generatePath(to, params)} />;
};

export default Redirect;

export { Redirect as RedirectWithParams };

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};
