import React from 'react';

import { STRINGS } from '../../../../utils/constants';

import { PersonUtil } from '../../../../utils';

const toCoTravellers = (otherPersons) => {
  if (!Array.isArray(otherPersons) || !otherPersons || !otherPersons?.length) {
    return [<li key="no-co-traveller" className="govuk-!-font-weight-bold">{STRINGS.NONE}</li>];
  }

  const maxToDisplay = 4;
  const coTravellers = otherPersons.slice(0, maxToDisplay);
  const remainingCoTravellers = otherPersons.slice(maxToDisplay, otherPersons.length);

  return coTravellers.map((coTraveller, index) => {
    return (
      <li key={coTraveller?.entityId?.poleV1Id || coTraveller?.entityId?.poleV2Id}>
        {PersonUtil.fullname(coTraveller)}{(index !== coTravellers.length - 1) ? ',' : ''}
        {(remainingCoTravellers.length && index === (maxToDisplay - 1)) ? ` plus ${remainingCoTravellers.length} more` : ''}
      </li>
    );
  }).filter((item) => !!item);
};

export default toCoTravellers;
