import React from 'react';

const SeizuresSection = ({ seizures }) => {
  return (
    <section id="previous-task-list-seizures-section" className="previous-task-list-seizures-section">
      <div className="section-header">
        Seizures
      </div>
      {seizures && seizures.length ? seizures.map((seizure) => {
        return (
          <div className="govuk-grid-row section-body govuk-!-margin-bottom-1" key={seizure?.description}>
            <span className="govuk-grid-column-one-half seizure-description">
              {seizure?.description}
            </span>
            <span className="govuk-grid-column-one-half seizure-quantity">
              {seizure?.quantity}
            </span>
          </div>
        );
      }) : null}
    </section>
  );
};

export default SeizuresSection;
