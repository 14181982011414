import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { STRINGS } from '../../../../../../utils/constants';

// Components
import Accordion from '../../shared/custom/Accordion';
import AccordionRow from '../../shared/AccordionRow';
import PersonTemplate from './PersonTemplate';

// Utils
import {
  CommonUtil,
  TargetConsigneeUtil,
  TargetConsignorUtil,
  TargetDocumentUtil,
  TargetGoodsUtil,
  TargetHaulierUtil,
  TargetPersonUtil,
  TargetTrailerUtil,
  TargetVehicleUtil,
} from '../../../../../../utils';
import { toPncWarningTag } from '../../shared/helper/adaptPncContent';

const RoRoTargetingInformation = ({ informationSheet }) => {
  const toConsignmentAndHaulier = () => {
    const goods = TargetGoodsUtil.get(informationSheet);
    const haulier = TargetHaulierUtil.get(informationSheet);
    const consignor = TargetConsignorUtil.get(informationSheet);
    const consignee = TargetConsigneeUtil.get(informationSheet);

    return {
      id: 'consignment-and-haulier',
      heading: 'Consignment and haulier',
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Manifested Load"
            value={TargetGoodsUtil.description(goods)}
            id="manifested-load"
          />
          <AccordionRow
            label="Manifested Weight"
            value={TargetGoodsUtil.weight(goods)}
            id="manifested-weight"
          />
          <AccordionRow
            label="Gross Weight"
            value={TargetGoodsUtil.grossWeight(goods)}
            id="gross-weight"
          />
          <AccordionRow
            label="Net Weight"
            value={TargetGoodsUtil.netWeight(goods)}
            id="net-weight"
          />
          <AccordionRow
            label="Hazardous"
            value={TargetGoodsUtil.format.hazardous(TargetGoodsUtil.hazardous(goods))}
            id="hazardous"
          />
          <AccordionRow
            label="Consignor"
            value={TargetConsignorUtil.name(consignor)}
            id="consignor-name"
          />
          <AccordionRow
            label="Address"
            value={TargetConsignorUtil.address(consignor)}
            id="consignor-address"
          />
          <AccordionRow
            label="Consignee"
            value={TargetConsigneeUtil.name(consignee)}
            id="consignee-name"
          />
          <AccordionRow
            label="Address"
            value={TargetConsigneeUtil.address(consignee)}
            id="consignee-address"
          />
          <AccordionRow
            label="Haulier"
            value={TargetHaulierUtil.name(haulier)}
            id="haulier-name"
          />
          <AccordionRow
            label="Address"
            value={TargetHaulierUtil.address(haulier)}
            id="haulier-address"
          />
        </dl>
      ),
    };
  };

  const toTrailer = () => {
    const trailer = TargetTrailerUtil.get(informationSheet);
    if (!trailer) {
      return null;
    }

    return {
      id: 'trailer-details',
      heading: `Trailer: ${TargetTrailerUtil.registration(trailer) || STRINGS.UNKNOWN}`,
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Trailer type"
            value={TargetTrailerUtil.type(trailer)}
            id="trailer-type"
          />
          <AccordionRow
            label="Trailer registration nationality"
            value={TargetTrailerUtil.nationality(trailer)}
            id="trailer-registration-nationality"
          />
        </dl>
      ),
    };
  };

  const toVehicle = () => {
    const vehicle = TargetVehicleUtil.get(informationSheet);
    if (!vehicle) {
      return null;
    }

    return {
      id: 'vehicle-details',
      heading: `Vehicle: ${TargetVehicleUtil.registration(vehicle) || STRINGS.UNKNOWN}`,
      expanded: false,
      children: (
        <dl className="govuk-summary-list">
          <AccordionRow
            label="Vehicle make"
            value={TargetVehicleUtil.make(vehicle)}
            id="vehicle-make"
          />
          <AccordionRow
            label="Vehicle model"
            value={TargetVehicleUtil.model(vehicle)}
            id="vehicle-model"
          />
          <AccordionRow
            label="Vehicle colour"
            value={TargetVehicleUtil.colour(vehicle)}
            id="vehicle-colour"
          />
          <AccordionRow
            label="Vehicle registration nationality"
            value={TargetVehicleUtil.nationality(vehicle)}
            id="vehicle-registration-number"
          />
        </dl>
      ),
    };
  };

  const toPassengers = () => {
    const coTravellers = CommonUtil.uniqueId(TargetPersonUtil.others(informationSheet));

    if (!coTravellers.length) {
      return [];
    }

    return coTravellers.map((person, index) => {
      const document = TargetDocumentUtil.doc(person);
      return {
        id: `associated-passenger-details-${index}`,
        key: person.id,
        heading: `Associated passenger: ${TargetPersonUtil.fullName(person) || STRINGS.UNKNOWN}`,
        summary: toPncWarningTag(person),
        expanded: false,
        children: <PersonTemplate person={person} document={document} />,
      };
    });
  };

  const toMainPerson = () => {
    const person = TargetPersonUtil.person(informationSheet);
    if (!person) {
      return null;
    }

    const document = TargetDocumentUtil.doc(person);

    return {
      id: 'person-details',
      heading: `Driver: ${TargetPersonUtil.fullName(person)}`,
      summary: toPncWarningTag(person),
      expanded: false,
      children: (
        <div>
          <PersonTemplate person={person} document={document} />
        </div>
      ),
    };
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <Accordion
          classnames="target-information-accordion"
          id={`target-information-${informationSheet.id}`}
          items={[
            toMainPerson(),
            ...toPassengers(),
            toVehicle(),
            toTrailer(),
            toConsignmentAndHaulier(),
          ].filter((item) => !!item)}
        />
      </div>
    </div>
  );
};

export default RoRoTargetingInformation;

RoRoTargetingInformation.propTypes = {
  informationSheet: PropTypes.shape({}),
};

RoRoTargetingInformation.defaultProps = {
  informationSheet: null,
};
