import { FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config
import { OPERATION } from '../../../../../../../../../utils/constants';

// Component(s)
import CommoditiesAddRecipient from './CommoditiesAddRecipient';
import CommoditiesChangeRecipient from './CommoditiesChangeRecipient';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LinkButton from '../../../../../../../../../components/Buttons/LinkButton';

const AddRecipient = ({ actionType, formData, setFormData, backToCreateTarget, backTargetLabel, previouslySelectedRecipient, type, targetRecipientOptions }) => {
  const toCommoditiesLabelByAction = () => {
    if (type === OPERATION.ADD) {
      return 'Add recipient';
    }

    if (type === OPERATION.CHANGE) {
      return 'Change recipient';
    }

    return null;
  };

  return (
    <div id="add-recipient-container-idp">
      <LinkButton
        id="back-to-create-target"
        className="govuk-back-link"
        onClick={() => backToCreateTarget()}
      >
        {`Back to ${backTargetLabel}`}
      </LinkButton>
      <FormGroup
        id="add-change-recipient-idp"
        label={(
          <>
            <Heading size="l">{toCommoditiesLabelByAction()}</Heading>
          </>
        )}
      >
        <ComponentWrapper show={type === OPERATION.ADD}>
          <CommoditiesAddRecipient
            previouslySelectedRecipient={previouslySelectedRecipient}
            onSave={backToCreateTarget}
            onCancel={backToCreateTarget}
            formData={formData}
            setFormData={setFormData}
            targetRecipientOptions={targetRecipientOptions}
            actionType={actionType}
          />
        </ComponentWrapper>
        <ComponentWrapper show={type === OPERATION.CHANGE}>
          <CommoditiesChangeRecipient
            previouslySelectedRecipient={previouslySelectedRecipient}
            onSave={backToCreateTarget}
            onCancel={backToCreateTarget}
            formData={formData}
            setFormData={setFormData}
            targetRecipientOptions={targetRecipientOptions}
            actionType={actionType}
          />
        </ComponentWrapper>
      </FormGroup>
    </div>
  );
};

AddRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  formData: PropTypes.shape({}).isRequired,
  backToCreateTarget: PropTypes.func.isRequired,
  backTargetLabel: PropTypes.string,
  previouslySelectedRecipient: PropTypes.string,
  type: PropTypes.string.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

AddRecipient.defaultProps = {
  backTargetLabel: 'Create target',
  previouslySelectedRecipient: null,
  targetRecipientOptions: [],
};

export default memo(AddRecipient);
