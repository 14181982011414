// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import SBTCheck from './SBTCheck';

// Styling
import './SBTChecks.scss';

const SBTChecks = ({ value: selectors, restrictWidth, ...props }) => {
  if (!selectors || !selectors?.length) {
    return null;
  }

  return (
    <div {...props}>
      {selectors.map((selector) => (
        <SBTCheck key={selector?.groupReference} selector={selector} restrictWidth={restrictWidth} />
      ))}
    </div>
  );
};

SBTChecks.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  restrictWidth: PropTypes.bool,
};

SBTChecks.defaultProps = {
  value: null,
  restrictWidth: false,
};

export default memo(SBTChecks);
