// Global imports
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import AlertReason from '../details/general_aviation/AlertReason';
import FlightDetails from '../flight/FlightDetails';
import InterceptionQuestions from '../InterceptionQuestions';
import Persons from '../person/Persons';
import PersonsOnBoardCount from '../person/general_aviation/PersonsOnBoardCount';
import PreArrival from '../details/uplift/PreArrival';
import TargetingDetails from '../details/uplift/TargetingDetails';

const GeneralAviationTargetSheet = ({ informationSheet, persons, mode }) => {
  return (
    <>
      <FlightDetails informationSheet={informationSheet} mode={mode} />
      <PreArrival informationSheet={informationSheet} mode={mode} />
      <AlertReason informationSheet={informationSheet} />
      <PersonsOnBoardCount informationSheet={informationSheet} />
      <Persons persons={persons} mode={mode} />
      <InterceptionQuestions informationSheet={informationSheet} />
      <TargetingDetails informationSheet={informationSheet} mode={mode} />
    </>
  );
};

GeneralAviationTargetSheet.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  persons: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
};

export default memo(GeneralAviationTargetSheet);
