// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../../utils/constants';

// Component(s)
import AirpaxTaskDetail from '../../airpax/TaskDetail';
import GeneralAviationTaskDetail from '../../general_aviation/TaskDetail';

// Util(s)
import MovementUtil from '../../../../../../../utils/Movement/movementUtil';

const ModeTaskDetail = ({ task }) => {
  const getDetails = () => {
    const mode = MovementUtil.movementMode(task);
    switch (mode) {
      case MOVEMENT_MODES.AIR_PASSENGER:
        return <AirpaxTaskDetail task={task} />;
      case MOVEMENT_MODES.GENERAL_AVIATION:
        return <GeneralAviationTaskDetail task={task} />;
      default:
        return null;
    }
  };

  return getDetails();
};

ModeTaskDetail.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default memo(ModeTaskDetail);
