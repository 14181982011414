import PropTypes from 'prop-types';

/**
 * A component wrapper.
 *
 * @param show A boolean flag to determine whether to return children element(s) or not.
 * @param children JSX element(s).
 * @returns {*|null} Nothing or children components.
 */
const ComponentWrapper = ({ show, children }) => {
  if (!show) {
    return null;
  }

  return children;
};

ComponentWrapper.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
};

ComponentWrapper.defaultProps = {
  show: null,
};

export default ComponentWrapper;
