// Config(s)
import { DIRECTION } from '../../../../../../../../utils/TargetInformation/constants';

// eslint-disable-next-line import/prefer-default-export
export const toDepartureArrivalLabel = (direction) => {
  if (direction === DIRECTION.OUTBOUND) {
    return 'Departs in : ';
  }

  if (direction === DIRECTION.INBOUND) {
    return 'Arrives in : ';
  }

  return null;
};
