import React from 'react';
import { Tag } from '@ukhomeoffice/cop-react-components';
import { FRONTLINE_OUTCOME, FRONTLINE_OUTCOME_NAME, STRINGS } from '../../../../utils/constants';

const getColourClass = (outcome) => {
  switch (outcome) {
    case FRONTLINE_OUTCOME.POSITIVE:
      return 'outcome--green';
    case FRONTLINE_OUTCOME.NEGATIVE:
    case FRONTLINE_OUTCOME.NO_SHOW:
    case FRONTLINE_OUTCOME.MISSED:
    case FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES:
    default:
      return 'outcome--dark-grey';
  }
};

const getText = (outcome) => {
  return FRONTLINE_OUTCOME_NAME[outcome] || STRINGS.UNKNOWN_TEXT;
};

export const formatOutcome = (outcome) => {
  return (
    <Tag
      className={getColourClass(outcome)}
      text={getText(outcome)}
    />
  );
};

export default formatOutcome;
