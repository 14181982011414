// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { mapValues } from 'lodash';
import { useEffect } from 'react';

// Config(s)
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { useTeam } from '../../context/TeamContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchTeam = () => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.refdataApiUrl);
  const { team, setTeam, setTeamGroup } = useTeam();
  const teamId = keycloak?.tokenParsed?.team_id;

  const fetchGroup = async () => {
    return AxiosRequests.fetchRefData(
      apiClient,
      `/v2/entities/groups?filter=teamid=eq.${team.id}`,
      {
        params: {
          select: 'id,displayname,code,grouptypeid,branchid,keycloakgrouppath,locationid,name,selfmanaged,teamid',
        },
      });
  };

  const fetchTeam = async () => {
    return AxiosRequests.fetchRefData(apiClient, `/v2/entities/team?filter=id=eq.${teamId}`);
  };

  const { data: teamData } = useQuery({
    queryKey: [QUERY_KEYS.TEAM],
    queryFn: () => fetchTeam(),
    initialData: null,
    cacheTime: 0,
    enabled: !!teamId,
  });

  const { data: groupData } = useQuery({
    queryKey: [QUERY_KEYS.TEAM_GROUP],
    queryFn: () => fetchGroup(),
    initialData: null,
    cacheTime: 0,
    enabled: !!team?.id,
  });

  useEffect(() => {
    if (teamData?.length) {
      // Coerces values of team object from numbers to strings to use in shift
      const reformattedTeam = mapValues(teamData[0], (elem) => (typeof elem === 'number' ? String(elem) : elem));
      setTeam(reformattedTeam);
    }

    return () => AxiosRequests.cancel(source);
  }, [teamData]);

  useEffect(() => {
    if (groupData?.length) {
      setTeamGroup(groupData[0]);
    }

    return () => AxiosRequests.cancel(source);
  }, [groupData]);
};

export default useFetchTeam;
