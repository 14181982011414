import React from 'react';
import PropTypes from 'prop-types';

import './HeaderModule.scss';

const HeaderModule = ({ items, ...props }) => {
  return (
    <div className="header-module-container">
      <div {...props} className="govuk-grid-row header-module">
        {items.map((item, idx) => {
          return (
            <div key={`header-module-${idx}`}>
              {item.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

HeaderModule.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node,
  })),
};

HeaderModule.defaultProps = {
  items: [],
};

export default HeaderModule;
