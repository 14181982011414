import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const RelistTaskButton = ({ onClick }) => {
  return (
    <Button
      id="relist-task-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="relist task"
    >
      Relist task
    </Button>
  );
};

RelistTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RelistTaskButton;
