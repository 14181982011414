// Global imports
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../utils/constants';

// Component(s)
import AdditionalContent from '../../content/AdditionalContent';
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import { TargetCommonUtil, TargetJourneyUtil } from '../../../../../../../utils';
import DateTimeUtil from '../../../../../../../utils/Datetime/Uplift/datetimeUtil';

const FlightDetails = ({ informationSheet }) => {
  const journey = TargetJourneyUtil.get(informationSheet);
  const departureLocation = TargetJourneyUtil.departureLocation(journey);
  const departureDate = TargetJourneyUtil.departureDate(journey);
  const arrivalLocation = TargetJourneyUtil.arrivalLocation(journey);
  const departureTimezone = DateTimeUtil.timezone(departureLocation);
  const additionalContents = TargetCommonUtil.additionalTextContents(journey);

  const panelData = (() => {
    return [
      {
        id: 'flight-number',
        label: 'Flight number',
        value: TargetJourneyUtil.id(journey),
      },
      {
        id: 'event-date',
        label: 'Date of flight',
        value: DateTimeUtil.local.format(departureDate, departureTimezone, TIME_FORMATS.DATE.DEFAULT),
      },
      {
        id: 'event-time',
        label: 'Departure time (local time)',
        value: DateTimeUtil.local.format(departureDate, departureTimezone, TIME_FORMATS.DATE.TIME),
      },
      {
        id: 'departure-port',
        label: 'Port of departure',
        value: TargetJourneyUtil.format.airportName(departureLocation),
      },
      {
        id: 'arrival-port',
        label: 'Port of arrival',
        value: TargetJourneyUtil.format.airportName(arrivalLocation),
      },
    ];
  })();

  return (
    <div id="flight-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Flight details</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
        <AdditionalContent additions={additionalContents} heading="Journey additions" />
      </div>
    </div>
  );
};

FlightDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(FlightDetails);
