import StringUtil from '../../../../utils/String/stringUtil';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY-HH-mm';

/**
 * Prepends a leading zero if the 'date component' parameter contains only a single digit.
 * if the parameter contains more than one digit it is returned unchanged.
 * If the parameter contains a non-numeric value it returned as an empty string.
 * @param {string} dateComponent - the value representing a day or month.
 * @returns  the value (appended with a leading zero, if the value passed in was a single digit)
 */
export const formatInTwoDigits = (dateComponent) => {
  return StringUtil.padStart(dateComponent, 2, '0');
};

export const formatString = (date) => {
  const [day, month, year, hour, minute] = date.split('-');
  if (hour || minute) {
    return `${formatInTwoDigits(day)}-${formatInTwoDigits(month)}-${year}-${formatInTwoDigits(hour)}-${formatInTwoDigits(minute)}`;
  }
  return `${formatInTwoDigits(day)}-${formatInTwoDigits(month)}-${year}`;
};

export const formattedTime = (time) => {
  const [hour, minute] = time.split(':');
  return `${formatInTwoDigits(hour)}:${formatInTwoDigits(minute)}`;
};
