import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const CompleteAssessmentButton = ({ onClick }) => {
  return (
    <Button
      id="complete-assessment-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="complete assessment on task"
    >
      Complete assessment
    </Button>
  );
};

CompleteAssessmentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CompleteAssessmentButton;
