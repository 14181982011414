import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const UpdateTargetButton = ({ onClick }) => {
  return (
    <Button
      id="update-target-button"
      classModifiers="blue"
      onClick={onClick}
      aria-label="uppdate previosuly issue target for task"
    >
      Update target
    </Button>
  );
};

UpdateTargetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UpdateTargetButton;
