// Global import(s)
import * as pluralise from 'pluralise';
import React from 'react';

// Config
import { DESCRIPTION_MAPPING, ICON, ICON_MAPPING, MOVEMENT_MODES } from '../../../../../utils/constants';

// Component
import Icon, { ShipIcon } from '../../../../../components/Icon/Icon';

// Util(s)
import asUnknownIfRequired from '../../../../../utils/Transform/valueSanitizer';
import { JourneyUtil, MovementUtil, TrailerUtil, VehicleUtil, VesselUtil } from '../../../../../utils';

// Styling
import '../__assets__/VoyageSection.scss';

const VoyageSection = ({ mode,
  journey,
  vessel,
  vehicle,
  trailer,
  iconDescription,
  arrivalTime,
  totalPersons }) => {
  const vehicleRegistration = asUnknownIfRequired(VehicleUtil.registration(vehicle));
  const vehicleMake = asUnknownIfRequired(VehicleUtil.make(vehicle));
  const vehicleModel = asUnknownIfRequired(VehicleUtil.model(vehicle));

  const toTouristContent = () => {
    const iconFromDescription = ICON_MAPPING[mode]?.[iconDescription];

    if (!iconFromDescription) {
      return null;
    }

    if (iconFromDescription === ICON.CAR) {
      return vehicleRegistration;
    }

    if ([ICON.INDIVIDUAL, ICON.GROUP].includes(iconFromDescription)) {
      return pluralise.withCount(totalPersons, '% foot passenger', '% foot passengers');
    }

    return iconFromDescription;
  };

  const renderModeSection = () => {
    if (mode === MOVEMENT_MODES.ACCOMPANIED_FREIGHT) {
      return (
        <>
          <div id="voyage-mode-type-icon">
            <Icon name={ICON_MAPPING[mode]?.[iconDescription]} />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s content-line-one govuk-!-margin-bottom-0">
              {vehicleMake} {vehicleModel}
            </p>
            <span className="govuk-body-s govuk-!-margin-bottom-0">
              <span className="govuk-!-font-weight-bold">
                {vehicleRegistration}
              </span>
              {TrailerUtil.registration(trailer) && (
                <>
                  <span className="govuk-!-font-weight-regular">
                &nbsp;with&nbsp;
                  </span>
                  <span className="govuk-!-font-weight-bold">
                    {TrailerUtil.registration(trailer)}
                  </span>
                </>
              )}
            </span>
          </div>
        </>
      );
    }

    if (mode === MOVEMENT_MODES.UNACCOMPANIED_FREIGHT) {
      return (
        <>
          <div id="voyage-mode-type-icon">
            <Icon name={ICON_MAPPING[mode]?.[iconDescription]} />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s content-line-one govuk-!-margin-bottom-0">&nbsp;</p>
            <span className="govuk-!-font-weight-bold">
              {TrailerUtil.registration(trailer)}
            </span>
          </div>
        </>
      );
    }

    if (mode === MOVEMENT_MODES.TOURIST) {
      return (
        <>
          <div id="voyage-mode-type-icon">
            <Icon name={ICON_MAPPING[mode]?.[iconDescription]} />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s content-line-one govuk-!-margin-bottom-0">
              {DESCRIPTION_MAPPING[iconDescription]}
            </p>
            <span className="govuk-!-font-weight-bold">
              {toTouristContent()}
            </span>
          </div>
        </>
      );
    }

    return null;
  };

  const renderVoyageSection = () => {
    return (
      <>
        <div id="voyage-movement-details">
          <p className="content-line-one">
            {`${VesselUtil.operator(vessel)} voyage of ${VesselUtil.name(vessel)}, ${MovementUtil.voyageText(arrivalTime)}`}
          </p>
          <p className="govuk-body-s content-line-two">
            {`${JourneyUtil.formatDepartureTime(journey)}`}
            <span className="dot" />
            <span className="govuk-!-font-weight-bold">{JourneyUtil.departureLoc(journey)}</span>
            <span className="right-arrow"> &#8594; </span>
            <span className="govuk-!-font-weight-bold"> {JourneyUtil.arrivalLoc(journey)}</span>
            <span className="dot" />
            {JourneyUtil.formatArrivalTime(journey)}
          </p>
        </div>
        <div id="voyage-movement-icon">
          <ShipIcon />
        </div>
      </>
    );
  };

  return (
    <div id="task-list-voyage-summary">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-one-third voyage-description-container">
          {renderModeSection()}
        </div>
        <div className="govuk-grid-column-two-thirds voyage-details-container">
          {renderVoyageSection()}
        </div>
      </div>
    </div>
  );
};

export default VoyageSection;
