// Global import(s)
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchGroupCounts = (requestParams, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTarget } = usePermission();

  const getGroupCounts = async (params) => {
    if (!canReadTarget) {
      return [];
    }
    return AxiosRequests.getTargetsGroupCounts(apiClient, params);
  };

  const { data: groupCounts, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TARGETS_GROUP_COUNTS, requestParams],
    queryFn: () => getGroupCounts(requestParams),
    initialData: [],
    refetchInterval: config.targetsList.refreshInterval,
    enabled,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { groupCounts, isLoading };
};

export default useFetchGroupCounts;
