import { shouldMaskDate, shouldMaskString, getMaskedValue } from '../Masking/maskingUtil';
import { shouldHideDate, shouldHideString } from '../Hiding/hidingUtil';

const getPerson = (task) => {
  return task?.movement?.s4Person || null;
};

const getCreatedAt = (person) => {
  if (!person?.createdAt) {
    return null;
  }

  if (shouldMaskDate(person.createdAt)) {
    return getMaskedValue();
  }

  if (shouldHideDate(person.createdAt)) {
    return null;
  }

  return person.createdAt;
};

const getTypeIdentifer = (person) => {
  if (!person?.typeIdentifier) {
    return null;
  }

  if (shouldMaskString(person.typeIdentifier)) {
    return getMaskedValue();
  }

  if (shouldHideString(person.typeIdentifier)) {
    return null;
  }

  return person.typeIdentifier;
};

const getPortOfEmbark = (person) => {
  if (!person?.portOfEmbark) {
    return null;
  }

  if (shouldMaskString(person.portOfEmbark)) {
    return getMaskedValue();
  }

  if (shouldHideString(person.portOfEmbark)) {
    return null;
  }

  return person.portOfEmbark;
};

const getDistinct = (person) => {
  if (!person?.distinct && person?.distinct !== false) {
    return null;
  }

  if (shouldMaskString(person.distinct)) {
    return getMaskedValue();
  }

  if (shouldHideString(person.distinct)) {
    return null;
  }

  return person.distinct;
};

const getTypeCode = (person) => {
  if (!person?.typeCode) {
    return null;
  }

  if (shouldMaskString(person.typeCode)) {
    return getMaskedValue();
  }

  if (shouldHideString(person.typeCode)) {
    return null;
  }

  return person.typeCode;
};

const getSentAt = (person) => {
  if (!person?.sentAt) {
    return null;
  }

  if (shouldMaskDate(person.sentAt)) {
    return getMaskedValue();
  }

  if (shouldHideDate(person.sentAt)) {
    return null;
  }

  return person.sentAt;
};

const PersonS4Util = {
  get: getPerson,
  createdAt: getCreatedAt,
  typeIdentifer: getTypeIdentifer,
  portOfEmbark: getPortOfEmbark,
  distinct: getDistinct,
  typeCode: getTypeCode,
  sentAt: getSentAt,
};

export default PersonS4Util;

export {
  getCreatedAt,
  getPerson,
  getTypeIdentifer,
  getPortOfEmbark,
  getDistinct,
  getTypeCode,
  getSentAt,
};
