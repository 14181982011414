import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import Occupant from './Occupant';
import OccupantCount from './OccupantCount';

import { DocumentUtil, JourneyUtil, MovementUtil, PersonUtil, TaskVersionUtil } from '../../../../../../utils';

const Occupants = ({ version, versionDiff, classModifiers }) => {
  // This object is the current version that has been patched with changed data from previous version.
  const patchedPreviousVersion = TaskVersionUtil.patchPrevious(version, versionDiff);
  const mode = MovementUtil.movementMode(version);

  // Data from current version
  const journey = JourneyUtil.get(version);
  const departureTime = JourneyUtil.departureTime(journey);
  const primaryTraveller = PersonUtil.get(version);
  const otherPersons = PersonUtil.getOthers(version);
  const secondaryCoTraveller = otherPersons[0] || undefined;
  const tertiaryCoTravellers = otherPersons?.slice(1) || [];
  const occupantCounts = MovementUtil.occupantCounts(version);

  // Diff data from previous version
  const journeyDiff = JourneyUtil.get(patchedPreviousVersion);
  const primaryTravellerDiff = PersonUtil.get(patchedPreviousVersion);
  const otherPersonsDiff = PersonUtil.getOthers(patchedPreviousVersion);
  const secondaryCoTravellerDiff = otherPersonsDiff[0] || undefined;
  const tertiaryCoTravellersDiff = otherPersonsDiff?.slice(1) || [];
  const occupantCountsDiff = MovementUtil.occupantCounts(patchedPreviousVersion);

  return (
    <div className={classNames('task-details-container', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Occupants</Heading>
      <OccupantCount
        mode={mode}
        primaryTraveller={primaryTraveller}
        coTravellers={otherPersons}
        occupantCounts={occupantCounts}
        occupantCountsDiff={occupantCountsDiff}
        classModifiers={primaryTraveller ? ['govuk-!-padding-bottom-1'] : []}
      />
      <Occupant
        person={primaryTraveller}
        personDiff={TaskVersionUtil.diff(primaryTraveller, primaryTravellerDiff)}
        document={DocumentUtil.get(primaryTraveller)}
        documentDiff={TaskVersionUtil.diff(DocumentUtil.get(primaryTraveller), DocumentUtil.get(primaryTravellerDiff))}
        departureTime={departureTime}
        journeyDiff={TaskVersionUtil.diff(journey, journeyDiff)}
        classModifiers={secondaryCoTraveller ? ['bottom-border-thin'] : []}
        labelText="Driver"
        version={version}
      />

      {secondaryCoTraveller ? (
        <Occupant
          person={secondaryCoTraveller}
          personPosition={2}
          personDiff={TaskVersionUtil.diff(secondaryCoTraveller, secondaryCoTravellerDiff)}
          document={DocumentUtil.get(secondaryCoTraveller)}
          documentDiff={TaskVersionUtil.diff(DocumentUtil.get(secondaryCoTraveller), DocumentUtil.get(secondaryCoTravellerDiff))}
          departureTime={departureTime}
          journeyDiff={TaskVersionUtil.diff(journey, journeyDiff)}
          classModifiers={tertiaryCoTravellers?.length
            ? ['govuk-!-padding-top-4', 'bottom-border-thin'] : ['govuk-!-padding-top-4']}
          labelText="Occupant"
          version={version}
        />
      ) : null}

      {tertiaryCoTravellers?.length ? (
        <details className="govuk-details govuk-!-padding-top-2" data-module="govuk-details">
          <summary className="govuk-details__summary">
            <span className="govuk-details__summary-text">Show more</span>
          </summary>
          <div className="govuk-hidden-passengers">
            {tertiaryCoTravellers.map((_person, index) => {
              return (
                <Occupant
                  key={_person?.entityId?.poleV1Id || _person?.entityId?.poleV2Id}
                  person={_person}
                  personPosition={index + 3}
                  personDiff={TaskVersionUtil.diff(_person, tertiaryCoTravellersDiff[index])}
                  document={DocumentUtil.get(_person)}
                  documentDiff={TaskVersionUtil.diff(DocumentUtil.get(_person), DocumentUtil.get(tertiaryCoTravellersDiff[index]))}
                  departureTime={departureTime}
                  journeyDiff={TaskVersionUtil.diff(journey, journeyDiff)}
                  classModifiers={index !== tertiaryCoTravellers.length - 1
                    ? ['govuk-!-padding-top-4', 'bottom-border-thin'] : ['govuk-!-padding-top-4']}
                  labelText="Occupant"
                  version={version}
                />
              );
            })}
          </div>
        </details>
      ) : null}
    </div>
  );
};

export default Occupants;
