import { FORM_ACTIONS } from '../utils/constants';

export default {
  id: 'clearDownTargets',
  version: '1.0.0',
  name: 'clearDownTargets',
  title: 'Clear down targets',
  type: 'form',
  components: [],
  pages: [
    {
      id: 'clearDownTargets',
      name: 'clearDownTargets',
      components: [
        {
          type: 'heading',
          size: 'l',
          content: 'Clear down targets',
        },
        {
          id: 'movementModes',
          fieldId: 'movementModes',
          label: 'Movement Modes',
          'aria-label': 'movement modes',
          type: 'checkboxes',
          data: {
            options: [
              {
                value: 'AIR_PASSENGER',
                label: 'Air Passenger',
              },
              {
                value: 'RORO_ACCOMPANIED_FREIGHT',
                label: 'RoRo Accompanied Freight',
              },
              {
                value: 'RORO_UNACCOMPANIED_FREIGHT',
                label: 'RoRo Unaccompanied Freight',
              },
              {
                value: 'RORO_TOURIST',
                label: 'RoRo Tourist',
              },
            ],
          },
        },
        {
          id: 'journeyDirections',
          fieldId: 'journeyDirections',
          label: 'Direction of travel',
          'aria-label': 'direction of travel',
          type: 'checkboxes',
          data: {
            options: [
              {
                value: 'INBOUND',
                label: 'Inbound',
              },
              {
                value: 'OUTBOUND',
                label: 'Outbound',
              },
            ],
          },
        },
        {
          id: 'eventDateLabel',
          type: 'html',
          size: 'p',
          content: 'Enter the target event date range',
        },
        {
          id: 'eventDateHint',
          type: 'html',
          size: 'p',
          className: 'govuk-hint',
          content: 'This will be for targets departing/ arriving within the specified date range',
        },
        {
          id: 'eventStartDate',
          fieldId: 'eventStartDate',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'From',
              type: 'date',
            },
            {
              id: 'time',
              fieldId: 'time',
              label: '',
              type: 'time',
            },
          ],
        },
        {
          id: 'eventEndDate',
          fieldId: 'eventEndDate',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'To',
              type: 'date',
              hideOptionalSuffix: true,
            },
            {
              id: 'time',
              fieldId: 'time',
              label: 'To',
              type: 'time',
            },
          ],
        },
        {
          id: 'createdDateLabel',
          type: 'html',
          size: 'p',
          content: 'Enter the target creation date range',
        },
        {
          id: 'createdDateHint',
          type: 'html',
          size: 'p',
          className: 'govuk-hint',
          content: 'This will be for targets issued within the specified date range',
        },
        {
          id: 'createdStartDate',
          fieldId: 'createdStartDate',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'From',
              type: 'date',
            },
            {
              id: 'time',
              fieldId: 'time',
              label: 'From',
              type: 'time',
            },
          ],
        },
        {
          id: 'createdEndDate',
          fieldId: 'createdEndDate',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'To',
              type: 'date',
            },
            {
              id: 'time',
              fieldId: 'time',
              label: 'To',
              type: 'time',
            },
          ],
        },
      ],
      actions: [
        {
          type: FORM_ACTIONS.CANCEL,
          label: 'Cancel',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.SUBMIT,
          validate: true,
          label: 'Submit',
          classModifiers: 'warning',
        },
      ],
    },
  ],
};
