import React from 'react';

const SpinnerItem = ({ style, transform }) => {
  return (
    <rect
      fill="#0b0c0c"
      width="12"
      height="5"
      rx="2.5"
      ry="2.5"
      transform={transform}
      opacity="0"
      className="loading-spinner--spin"
      style={style}
    />
  );
};

export default SpinnerItem;
