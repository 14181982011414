const getVehicleGrossWeight = (vehicle) => {
  return vehicle?.grossWeight || null;
};

const getVehicleNetWeight = (vehicle) => {
  return vehicle?.netWeight || null;
};

const getVehicleModel = (vehicle) => {
  return vehicle?.model || null;
};

const getVehicleMake = (vehicle) => {
  return vehicle?.make || null;
};

const getVehicleColour = (vehicle) => {
  return vehicle?.colour || null;
};

const getNationality = (vehicle) => {
  return vehicle?.nationality || null;
};

const getType = (vehicle) => {
  return vehicle?.type || null;
};

const getRegistration = (vehicle) => {
  return vehicle?.registration || null;
};

const getVehicle = (task) => {
  return task?.movement?.vehicle || null;
};

const VehicleUtil = {
  colour: getVehicleColour,
  get: getVehicle,
  getGrossWeight: getVehicleGrossWeight,
  getNetWeight: getVehicleNetWeight,
  make: getVehicleMake,
  model: getVehicleModel,
  nationality: getNationality,
  registration: getRegistration,
  type: getType,
};

export default VehicleUtil;

export {
  getNationality,
  getRegistration,
  getType,
  getVehicle,
  getVehicleColour,
  getVehicleGrossWeight,
  getVehicleMake,
  getVehicleModel,
  getVehicleNetWeight,
};
