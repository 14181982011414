/**
 * Attach event listener(s)
 *
 * @param {[{
 *   type: string,
 *   handler: function
 *   options: object
 * }]} events An array of event objects consisting of the event type, event handler adnm the event options.
 * @param options The event lister options.
 */
const addEventListener = (events = []) => {
  events.forEach(({ type, handler, options }) => window.addEventListener(type, handler, options));
};

/**
 * Remove event listener(s)
 *
 * @param {[{
 *   type: string,
 *   handler: function
 * }]} events An array of event objects consisting of the event type and the event handler.
 */
const removeEventListener = (events = []) => {
  events.forEach(({ type, handler }) => window.removeEventListener(type, handler));
};

export { addEventListener, removeEventListener };
