// Global import(s)
import { ButtonGroup, Link, Panel } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import config from '../../../../../utils/config';
import { LOCAL_STORAGE_KEYS } from '../../../../../utils/constants';

// Context(s)
import { useTabs } from '../../../../../context/TabContext';
import { useView } from '../../../../../context/ViewContext';

// Styling
import './ScanAppOutcome.scss';

const ScanAppOutcome = ({ movement, targetId }) => {
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const trailerReg = () => {
    return movement?.trailer?.registration;
  };

  const vehicleReg = () => {
    return movement?.vehicle?.registration;
  };

  const registration = () => {
    return vehicleReg() || trailerReg() || 'Unknown registration';
  };

  const onClickTargetDetails = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TARGET_DETAILS_BACK_PATH, DEFAULTS[view].redirectPath);
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <div className="selected-for-scan-container" id="selected-for-scan-container">
          <Panel aria-label="selected for scan" id="selected-for-scan" title={`${registration()} has been sent for scan`}>
            <p id="your-reference-number-label" aria-label="your reference number" className="govuk-body-s govuk-!-margin-bottom-0">
              Your reference number
            </p>
            <p id="your-reference-number" aria-label={targetId} className="govuk-body-s govuk-!-margin-bottom-0">
              {targetId}
            </p>
          </Panel>
        </div>
        <p className="hods-status-message__extra">You can:</p>
        <ButtonGroup id="return-options">
          <Link
            aria-label="return to the target details page"
            className="govuk-link"
            href={`${DEFAULTS[view].redirectPath}/${targetId}`}
            onClick={onClickTargetDetails}
            id="return-to-target-details-page"
          >
            return to the target details page
          </Link>
          <Link aria-label="return to the main target page" className="govuk-link" href={`${DEFAULTS[view].redirectPath}`} id="return-to-target-page">
            return to the main target page
          </Link>
          <Link aria-label="return to the homepage" className="govuk-link" href={config.copUiUrl} id="return-to-homepage">
            return to the homepage
          </Link>
        </ButtonGroup>
      </div>
    </div>
  );
};

ScanAppOutcome.propTypes = {
  movement: PropTypes.shape({}).isRequired,
  targetId: PropTypes.string.isRequired,
};

export default memo(ScanAppOutcome);
