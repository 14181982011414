// Global imports
import axios from 'axios';
import React, { useEffect, useState } from 'react';

// Local imports
import config from '../../utils/config';
import { useAxiosInstance } from '../../utils/Axios/axiosInstance';
import FormUtils, { Renderers } from '../../utils/Form/ReactForm';
import { useKeycloak } from '../../context/Keycloak';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ReactForm from './Forms/ReactForm';

const RenderForm = ({
  formName,
  form: _form,
  onSubmit,
  onFinish,
  onCancel,
  onChange,
  preFillData,
  saveTisData,
  viewOnly,
  uploadDocument,
  overrideSubmit,
  children,
}) => {
  const [form, setForm] = useState(_form);
  const [isLoaderVisible, setLoaderVisibility] = useState(true);
  const [formattedPreFillData, setFormattedPreFillData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const keycloak = useKeycloak();
  const formApiClient = useAxiosInstance(keycloak, config.formApiUrl);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const loadForm = async () => {
      try {
        if (formName) {
          const formRenderer = FormUtils.getRenderer(formName);
          const formEndpoint = formRenderer === Renderers.REACT ? 'copform' : 'form';
          const { data } = await formApiClient.get(`/${formEndpoint}/name/${formName}`);
          setForm(data);
        }
      } finally {
        setLoaderVisibility(false);
      }
    };

    loadForm();
    return () => {
      source.cancel('Cancelling request');
    };
  }, []);

  useEffect(() => {
    setFormattedPreFillData({
      data: {
        environmentContext: {
          referenceDataUrl: config.refdataApiUrl,
        },
        ...preFillData || {},
      },
    });
  }, [preFillData]);

  if (submitted && children) {
    return children;
  }

  if (isLoaderVisible) {
    return <LoadingSpinner />;
  }

  return (
    <ReactForm
      form={form}
      formattedPreFillData={formattedPreFillData}
      saveTisData={saveTisData}
      onFinish={onFinish}
      setSubmitted={setSubmitted}
      setLoaderVisibility={setLoaderVisibility}
      onCancel={onCancel}
      onChange={onChange}
      onSubmit={onSubmit}
      viewOnly={viewOnly}
      uploadDocument={uploadDocument}
      overrideSubmit={overrideSubmit}
    />
  );
};

export default RenderForm;
