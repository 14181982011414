import React from 'react';

const toAccordionRow = (label, value, id) => {
  return (
    <div className="govuk-summary-list__row" key={label}>
      <dt id={`${id}-label`} className="govuk-summary-list__key">{label}</dt>
      <dd id={`${id}-value`} className="govuk-summary-list__value accordion_item">{value}</dd>
      <dd className="govuk-summary-list__actions" />
    </div>
  );
};

export default toAccordionRow;
