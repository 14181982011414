// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../../utils/constants';

// Component(s)
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import { TargetMovementUtil, TargetInformationUtil, TargetRisksUtil } from '../../../../../../../utils';

const PreArrival = ({ informationSheet, mode }) => {
  const selector = TargetRisksUtil.selector(informationSheet);

  const panelData = (() => {
    return [
      mode === MOVEMENT_MODES.GENERAL_AVIATION && {
        id: 'category',
        label: 'Category',
        value: TargetRisksUtil.category(selector),
      },
      {
        label: 'Operation',
        value: TargetMovementUtil.operation(informationSheet),
        id: 'operation',
      },
      {
        id: 'interest-immunity',
        label: 'Public interest immunity',
        value: TargetInformationUtil.informationSheet.interestImmunity(informationSheet),
      },
    ];
  })();

  return (
    <div id="pre-arrival-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Pre-arrival details</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
      </div>
    </div>
  );
};

PreArrival.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
};

export default memo(PreArrival);
