import { Checkboxes, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// Config
import { COMPONENT_IDS, OTHER_CHECKS_FORM_PREFIX, SYSTEM_CHECKS } from './constants';
import { STRINGS } from '../../../../../../../utils/constants';

// Context(s)
import { useChecksValidation } from '../../../../../../../context/ChecksValidationContext';
import { useFormData } from '../../../../../../../context/FormDataContext';
import { useTasks } from '../../../../../../../context/TasksContext';

// Components
import AdditionalChecks from './form/components/AdditionalChecks';
import Comment from './form/components/Comment';
import Trace from './form/components/Trace';

// Util(s)
import CredibilityChecksUtil from '../../../../../../../utils/Person/credibilityChecksUtil';

const AddOtherChecks = ({ passenger, passengerPositionedAt, setModalVisible, setAdditionalModalVisible, containerId }) => {
  const otherChecksRef = useRef(null);
  const selectedChecks = useRef([]); // Keeps track of selected options that are to be operated on at a later point of execution
  const { formData, setFormData } = useFormData();
  const { setReturnToElementId } = useTasks();
  const { errors } = useChecksValidation();

  const toNotRequired = (onChange) => {
    return {
      value: 'NOT_REQUIRED',
      label: 'Credibility checks not necessary',
      children: (
        <Comment
          id={COMPONENT_IDS.NOT_REQUIRED}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          label="Provide a reason"
          containerId={containerId}
          formData={formData}
          required
          error={errors?.[containerId]?.[`${COMPONENT_IDS.NOT_REQUIRED}${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toAdditionalChecks = (onChange) => {
    return {
      value: 'OTHER',
      label: 'Additional credibility check/s',
      children: (
        <AdditionalChecks
          id={COMPONENT_IDS.OTHER}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          errors={errors?.[containerId]}
        />
      ),
    };
  };

  const toCentaur = (onChange) => {
    return {
      value: 'CENTAUR',
      label: 'Centaur',
      children: (
        <Trace
          id={COMPONENT_IDS.CENTAUR_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.CENTAUR}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.CENTAUR_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.CENTAUR_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toOpenSource = (onChange) => {
    return {
      value: 'OPEN_SOURCE',
      label: 'Open source',
      children: (
        <Trace
          id={COMPONENT_IDS.OPEN_SOURCE_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.OPEN_SOURCE}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.OPEN_SOURCE_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.OPEN_SOURCE_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toSip = (onChange) => {
    return {
      value: 'SIP',
      label: 'SIP',
      children: (
        <Trace
          id={COMPONENT_IDS.SIP_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.SIP}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.SIP_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.SIP_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toHmrc = (onChange) => {
    return {
      value: 'HMRC',
      label: 'HMRC',
      children: (
        <Trace
          id={COMPONENT_IDS.HMRC_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.HMRC}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.HMRC_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.HMRC_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toEntitySearch = (onChange) => {
    return {
      value: 'ENTITY_SEARCH',
      label: 'Entity search',
      children: (
        <Trace
          id={COMPONENT_IDS.ENTITY_SEARCH_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.ENTITY_SEARCH}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.ENTITY_SEARCH_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.ENTITY_SEARCH_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toPega = (onChange) => {
    return {
      value: 'PEGA',
      label: 'PEGA',
      children: (
        <Trace
          id={COMPONENT_IDS.PEGA_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.PEGA}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.PEGA_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.PEGA_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toInterpol = (onChange) => {
    return {
      value: 'INTERPOL',
      label: 'i24/7 (Interpol)',
      children: (
        <Trace
          id={COMPONENT_IDS.INTERPOL_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.INTERPOL}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.INTERPOL_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.INTERPOL_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toDva = (onChange) => {
    return {
      value: 'DVA',
      label: 'DVA',
      children: (
        <Trace
          id={COMPONENT_IDS.DVA_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.DVA}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.DVA_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.DVA_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toCrs = (onChange) => {
    return {
      value: 'CRS',
      label: 'CRS',
      children: (
        <Trace
          id={COMPONENT_IDS.CRS_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.CRS}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.CRS_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.CRS_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toAtlas = (onChange) => {
    return {
      value: 'ATLAS',
      label: 'Atlas',
      children: (
        <Trace
          id={COMPONENT_IDS.ATLAS_TRACE}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={SYSTEM_CHECKS.ATLAS}
          error={errors?.[containerId]?.[`${COMPONENT_IDS.ATLAS_TRACE}${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${COMPONENT_IDS.ATLAS_TRACE}Comment${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const isCheckSystem = (name) => {
    return SYSTEM_CHECKS[name?.toUpperCase()] === name.toUpperCase();
  };

  const onChange = (event) => {
    const { target } = event;
    let cleanedName = target.name;
    // If the target name is not one of the system checks, remove the character at the end of target.name
    if (!isCheckSystem(target.name)) {
      cleanedName = cleanedName.slice(0, -1);
    }

    setFormData((prev) => {
      return {
        ...prev,
        [containerId]: {
          ...prev?.[containerId],
          otherChecks: {
            ...prev?.[containerId]?.otherChecks,
            [cleanedName]: target.value,
          },
        },
      };
    });
  };

  const toCheckOptions = () => {
    const checks = CredibilityChecksUtil.getChecksWithoutPnc(passenger);
    const dedupedSystems = [...new Set(checks.map(({ system }) => system))];

    return dedupedSystems.map((system) => {
      switch (system) {
        case SYSTEM_CHECKS.ATLAS:
          return toAtlas(onChange);
        case SYSTEM_CHECKS.CRS:
          return toCrs(onChange);
        case SYSTEM_CHECKS.CENTAUR:
          return toCentaur(onChange);
        case SYSTEM_CHECKS.DVA:
          return toDva(onChange);
        case SYSTEM_CHECKS.ENTITY_SEARCH:
          return toEntitySearch(onChange);
        case SYSTEM_CHECKS.HMRC:
          return toHmrc(onChange);
        case SYSTEM_CHECKS.INTERPOL:
          return toInterpol(onChange);
        case SYSTEM_CHECKS.OPEN_SOURCE:
          return toOpenSource(onChange);
        case SYSTEM_CHECKS.OTHER:
          return toAdditionalChecks(onChange);
        case SYSTEM_CHECKS.PEGA:
          return toPega(onChange);
        case SYSTEM_CHECKS.SIP:
          return toSip(onChange);
        default: {
          return null;
        }
      }
    }).filter((option) => !!option);
  };

  const setupWarningModal = () => {
    setReturnToElementId(otherChecksRef.current.id);
    setModalVisible(true);
  };

  const isAdditionalChecksUnselectedAndContainsMoreThanOneEntry = (checks) => {
    // Checks if additional checks has been selected
    if (checks?.includes(STRINGS.FORM.OTHER) && !selectedChecks.current.includes(STRINGS.FORM.OTHER)) {
      // Add and then do nothing
      selectedChecks.current = [
        ...selectedChecks.current,
        ...checks,
      ];
      return false;
    }

    // Checks if additional checks has been unselected
    if (!checks?.includes(STRINGS.FORM.OTHER) && selectedChecks.current.includes(STRINGS.FORM.OTHER)) {
      selectedChecks.current = [
        ...selectedChecks.current.filter((check) => check !== STRINGS.FORM.OTHER),
      ];

      const otherSystemsEntries = formData?.[containerId]?.[OTHER_CHECKS_FORM_PREFIX]?.[COMPONENT_IDS.OTHER];
      return otherSystemsEntries?.length > 1;
    }

    return false;
  };

  const hasChecksAndChecksNotRequired = (checks) => {
    return checks?.length && checks?.length > 1 && checks?.includes(STRINGS.FORM.NOT_REQUIRED);
  };

  const hasPncOutcomeAndChecksNotRequired = (checks, pncOutcome) => {
    return pncOutcome && checks?.length && checks?.includes(STRINGS.FORM.NOT_REQUIRED);
  };

  const handleOnChecksChanged = () => {
    const checks = formData?.[containerId]?.otherChecks?.checks || [];
    const pncOutcome = formData?.[containerId]?.pnc?.pncOutcome;

    // See logic in AddPncCheckResult which unselects the checks not necessary checkbox
    if (hasPncOutcomeAndChecksNotRequired(checks, pncOutcome)) {
      setupWarningModal();
      return;
    }

    if (hasChecksAndChecksNotRequired(checks)) {
      // Handle when first selection is the option that discards others.
      if (checks[0] === STRINGS.FORM.NOT_REQUIRED) {
        const formDataCopy = { ...formData };
        formDataCopy[containerId].otherChecks.checks = checks.filter((check) => check !== STRINGS.FORM.NOT_REQUIRED);
        setFormData((prev) => ({ ...prev, ...formDataCopy }));
      } else {
        setupWarningModal();
        return;
      }
    }

    const isAdditionalChecksUnselectedWithMoreThanOneEntry = isAdditionalChecksUnselectedAndContainsMoreThanOneEntry(checks);
    if (isAdditionalChecksUnselectedWithMoreThanOneEntry && !checks?.includes(STRINGS.FORM.NOT_REQUIRED)) {
      setReturnToElementId(otherChecksRef.current.id);
      setAdditionalModalVisible(true);
    }
  };

  useEffect(() => {
    handleOnChecksChanged();
  }, [formData]);

  return (
    <div
      ref={otherChecksRef}
      id={`passenger-${passengerPositionedAt}-other-checks`}
    >
      <Heading size="s">Other Checks</Heading>
      <FormGroup
        id={`otherChecks${passengerPositionedAt}`}
        label={<></>}
      >
        <Checkboxes
          id={`${COMPONENT_IDS.CHECKS}${passengerPositionedAt}`}
          fieldId={`${COMPONENT_IDS.CHECKS}${passengerPositionedAt}`}
          options={[
            ...toCheckOptions(),
            'or',
            toNotRequired(onChange),
          ].filter((item) => item)}
          onChange={onChange}
          value={formData?.[containerId]?.otherChecks?.[COMPONENT_IDS.CHECKS]}
        />
      </FormGroup>
    </div>
  );
};

AddOtherChecks.propTypes = {
  passenger: PropTypes.shape({}).isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  setAdditionalModalVisible: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
};

export default AddOtherChecks;
