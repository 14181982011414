// Global import(s)
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

// Context(s)
import { useKeycloak } from '../../../../../context/Keycloak';
import { useTasks } from '../../../../../context/TasksContext';
import { TabContext } from '../../../../../context/TabContext';

// Component(s)
import Checkbox from '../../../../../components/Checkboxes/Checkbox';
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import TaskListCard from './TaskListCard';

// Util(s)
import { getTaskId } from '../../../../../utils/Task/taskUtil';
import { FILTER_TAB_ID } from '../../../../../utils/constants';

const TaskCardWrapper = ({ task, motionKey, redirectPath }) => {
  const keycloak = useKeycloak();
  const currentUser = keycloak.tokenParsed.email;
  const taskId = getTaskId(task);
  const { isSelecting, onSelection, onDeleteSelection, selectedForDismissal, selectedForDelete } = useTasks();
  const { selectedFilterTab } = useContext(TabContext);

  const taskCardAnimations = {
    exit: {
      opacity: 0,
      y: 0,
      transition: { duration: 0.8, ease: 'easeInOut' },
    },
    enter: {
      opacity: 1,
      y: 200,
      transition: { duration: 1, ease: 'easeInOut' },
    },
  };

  return (
    <motion.div
      layout
      {...taskCardAnimations}
      className="task-list-card"
      key={motionKey}
    >
      <ComponentWrapper show={isSelecting}>
        <div className="task-list-card-checkbox">
          <Checkbox
            key={`checkbox-${taskId}`}
            id={`checkbox-${taskId}`}
            className="govuk-checkboxes--small"
            name={`checkbox-${taskId}`}
            onChange={({ target }) => {
              const { id } = target;
              const selectedId = id?.split('checkbox-')[1];
              onSelection({ target: { id: selectedId } });
            }}
            selected={selectedForDismissal.includes(`${taskId}`)}
          />
        </div>
      </ComponentWrapper>
      <ComponentWrapper show={selectedFilterTab === FILTER_TAB_ID.TOOLS}>
        <div className="task-list-card-checkbox">
          <Checkbox
            key={`checkbox-${taskId}`}
            id={`checkbox-${taskId}`}
            className="govuk-checkboxes--small"
            name={`checkbox-${taskId}`}
            onChange={({ target }) => {
              const { id } = target;
              const selectedId = id?.split('checkbox-')[1];
              onDeleteSelection({ target: { id: selectedId } });
            }}
            selected={selectedForDelete.includes(`${taskId}`)}
          />
        </div>
      </ComponentWrapper>
      <TaskListCard
        key={task.id}
        task={task}
        currentUser={currentUser}
        redirectPath={redirectPath}
      />
    </motion.div>
  );
};

export default TaskCardWrapper;

TaskCardWrapper.propTypes = {
  task: PropTypes.shape({}).isRequired,
  motionKey: PropTypes.any.isRequired,
  redirectPath: PropTypes.string.isRequired,
};
