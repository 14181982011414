import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const EditTargetButton = ({ onClick }) => {
  return (
    <Button
      id="edit-target-button"
      classModifiers="secondary"
      onClick={onClick}
      aria-label="edit previously issued target"
    >
      Edit target
    </Button>
  );
};

EditTargetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditTargetButton;
