import React from 'react';

// Util(s)
import getVoyageComponent from '../../helper/getVoyageComponent';
import { MovementUtil } from '../../../../../utils';

const VoyageSection = ({ targetTask }) => {
  const mode = MovementUtil.movementMode(targetTask);
  return (
    <section className="task-list-voyage-section">
      <div className="govuk-grid-row task-list-voyage-summary">
        {getVoyageComponent(mode, targetTask)}
      </div>
    </section>
  );
};

export default VoyageSection;
