import React from 'react';

import TargetingIndicators from '../../shared/TargetingIndicators';
import Booking from '../../shared/Booking';
import GroupOccupants from './GroupOccupants';
import Document from '../../shared/Document';
import PrimaryTraveller from './PrimaryTraveller';

const TouristGroupVersionDetails = ({ version, versionDiff }) => {
  return (
    <div className="govuk-task-details-grid govuk-!-padding-top-4">
      <div className="govuk-grid-column-one-third">
        <div>
          <TargetingIndicators version={version} classModifiers={['bottom-border-thin']} />
          <PrimaryTraveller version={version} versionDiff={versionDiff} classModifiers={['bottom-border-thin']} />
          <Document version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Booking version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <GroupOccupants version={version} versionDiff={versionDiff} />
        </div>
      </div>
    </div>
  );
};

export default TouristGroupVersionDetails;
