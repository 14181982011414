import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';

// Config(s)
import { COMPONENT_IDS, OTHER_CHECKS_FORM_PREFIX } from '../../constants';

// Component(s)
import AdditionalCheck from './AdditionalCheck';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { sortBy } from '../../../../../../../../../utils/Common/commonUtil';

// Styling
import './AdditionalChecks.scss';

const AdditionalChecks = ({ id, uniqueId, onChange, containerId, formData, errors }) => {
  const [components, setComponents] = useState([]);
  const [value, setValue] = useState(formData?.[containerId]?.[OTHER_CHECKS_FORM_PREFIX]?.[COMPONENT_IDS.OTHER] || []);

  const onRemove = useCallback((itemIndex) => {
    setComponents((prev) => ([
      ...prev.filter((_, index) => index !== itemIndex),
    ]));

    setValue((prev) => ([
      ...[...prev.filter((_, index) => index !== itemIndex)]
        .map((data, index) => ({ ...data, dataId: index }))
        .sort(sortBy('dataId')),
    ]));
  }, [components, value]);

  const reportChange = useCallback((data, dataValueIndex) => {
    // Maintain the order of the data by sorting by the custom id given to each data item
    setValue((prev) => ([
      ...[...prev.filter((_, index) => index !== dataValueIndex), data].sort(sortBy('dataId')),
    ]));
  }, [value]);

  const onAddAnother = useCallback(() => {
    setComponents((prev) => ([
      ...prev,
      <AdditionalCheck
        key={prev.length}
        passengerPositionedAt={uniqueId}
        componentId="otherSystem"
        containerId={containerId}
        onChange={reportChange}
      />,
    ]));
  }, [components, setComponents]);

  useEffect(() => {
    // Report all data to parent component
    onChange({
      target: {
        name: `${id}${uniqueId}`,
        value,
      },
    });
  }, [value]);

  useEffect(() => {
    if (value.length) {
      setComponents(value.map(() => (
        <AdditionalCheck
          key={value.dataId}
          passengerPositionedAt={uniqueId}
          componentId="otherSystem"
          containerId={containerId}
          onChange={reportChange}
        />
      )));
      return;
    }

    setComponents([<AdditionalCheck
      key={0}
      passengerPositionedAt={uniqueId}
      componentId="otherSystem"
      containerId={containerId}
      onChange={reportChange}
    />]);
  }, []);

  return (
    <div id="additional-credibility-checks">
      {components.map((component, index) => {
        const elementWithUniqueId = React.cloneElement(component, {
          uniqueId: index,
          value: value[index],
          errors,
        });
        const key = `other-check-${index}`;

        return (
          <div
            key={key}
            id={`other-check-${index}`}
            className="other-check-item"
          >
            <div className="check-item">
              {elementWithUniqueId}
            </div>
            <ComponentWrapper show={components.length > 1}>
              <Button
                id={`remove-check-${index}`}
                className="govuk-!-margin-top-0"
                classModifiers="secondary"
                onClick={() => onRemove(index)}
              >
                Remove
              </Button>
            </ComponentWrapper>
          </div>
        );
      })}
      <Button
        id="add-another-check"
        className="govuk-!-margin-top-4"
        classModifiers="secondary"
        onClick={onAddAnother}
      >
        Add another
      </Button>
    </div>
  );
};

AdditionalChecks.propTypes = {
  containerId: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  formData: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  uniqueId: PropTypes.number,
};

AdditionalChecks.defaultProps = {
  errors: null,
  formData: null,
  uniqueId: null,
};

export default memo(AdditionalChecks);
