import { Checkboxes, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';
import config from '../../../../../../../../utils/config';

// Services
import AxiosRequests from '../../../../../../../../api/axiosRequests';

// Hooks
import { useAxiosInstance } from '../../../../../../../../utils/Axios/axiosInstance';
import { useKeycloak } from '../../../../../../../../context/Keycloak';

import './SelectPnrAccess.scss';

const SelectPnrAccess = ({ componentErrors, onChange, formData, taskId }) => {
  const [opts, setOpts] = useState([]);
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  const getPnrAccess = async () => {
    await AxiosRequests.pnrElements(apiClient, taskId)
      .then((response) => {
        setOpts(response);
      })
      .catch((e) => console.error(e));
  };

  const createPnrOptions = () => {
    return opts.map(({ name, type }) => ({ label: name, value: type }));
  };

  useEffect(() => {
    getPnrAccess();
  }, []);

  return (
    <FormGroup
      id={COMPONENT_IDS.PNR_DATA}
      label={<Heading id="select-pnr-data" size="m">Select PNR data to include in the target sheet</Heading>}
      error={componentErrors?.[COMPONENT_IDS.PNR_DATA]?.message}
    >
      <p>
        Include only strictly necessary data.
        <br />
        API data, baggage data, seat number and full itinerary are included by default.
      </p>
      <Checkboxes
        className="govuk-checkboxes--small"
        id={COMPONENT_IDS.PNR_DATA}
        fieldId={COMPONENT_IDS.PNR_DATA}
        options={createPnrOptions()}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.PNR_DATA]}
      />
    </FormGroup>
  );
};

SelectPnrAccess.propTypes = {
  componentErrors: PropTypes.shape({}),
  informationSheet: PropTypes.shape({}),
  onChange: PropTypes.func,
  taskId: PropTypes.string.isRequired,
  formData: PropTypes.shape({}),
};

SelectPnrAccess.defaultProps = {
  componentErrors: null,
  formData: null,
  informationSheet: {},
  onChange: () => {},
};

export default memo(SelectPnrAccess);
