// Config(s)
import { DOCUMENT_TYPES, TEXT_DEFAULTS, TIME_FORMATS } from '../../../../../../../../utils/constants';

// Util(s)
import DateTimeUtil from '../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import PersonUtil from '../../../../../../../../utils/Person/Uplift/personUtil';

const toNationality = (nationality, nationalities) => {
  if (!nationality || !nationalities) {
    return null;
  }

  const found = nationalities.find((item) => item.iso31661alpha2 === nationality);
  if (!found) {
    return null;
  }

  return {
    ...found,
    label: found.name,
    value: found.id,
  };
};

const toDocument = (document, nationalities) => {
  if (!document) {
    return null;
  }

  const toDocumentNumber = () => {
    return document?.number || null;
  };

  const toDocumentExpiry = () => {
    const date = document?.expiry || null;
    return DateTimeUtil.format.asUTC(date, TIME_FORMATS.UTC, TEXT_DEFAULTS.DATE.DMY2);
  };

  const toPassportDetails = () => {
    return {
      number: toDocumentNumber(),
      expiry: toDocumentExpiry(),
      nationality: toNationality(document?.countryOfIssue, nationalities),
    };
  };

  return {
    type: document?.type || null,
    passportDetails: document?.type === DOCUMENT_TYPES.PASSPORT ? toPassportDetails() : null,
    otherTypeDetail: document?.type === DOCUMENT_TYPES.OTHER ? document?.otherTypeDetail : null,
  };
};

const toPerson = (person, nationalities, genders) => {
  const toGender = () => {
    if (!genders || !person?.gender) {
      return null;
    }

    const found = genders.find((item) => item.id === person.gender);
    if (!found) {
      return null;
    }

    return {
      ...found,
      label: found.name,
      value: found.id,
    };
  };

  return {
    id: Date.now() + Math.random().toString(),
    name: {
      first: person?.name?.first || null,
      last: person?.name?.last || null,
      full: PersonUtil.formattedName(person?.name?.first)(person?.name?.last) || null,
    },
    role: person?.role || null,
    dateOfBirth: DateTimeUtil.format.asUTC(person?.dateOfBirth, TIME_FORMATS.UTC, TEXT_DEFAULTS.DATE.DMY2),
    gender: toGender(),
    nationality: toNationality(person?.nationality, nationalities),
    document: toDocument(person?.document, nationalities),
    ofInterest: [`${person?.ofInterest}`],
  };
};

const toVehicle = (vehicle) => {
  if (!vehicle) {
    return null;
  }

  return {
    type: vehicle?.type || null,
    registration: vehicle?.registration || null,
  };
};

const toPort = (icao, iata) => {
  if (icao) {
    return 'ICAO';
  }

  if (iata) {
    return 'IATA';
  }

  return null;
};

const toJuncture = (juncture) => {
  return {
    port: toPort(juncture?.icao, juncture?.iata),
    icao: juncture?.icao || null,
    iata: juncture?.iata || null,
    date: DateTimeUtil.format.asUTC(juncture?.time, TIME_FORMATS.UTC, 'DD-MM-YYYY'),
    time: DateTimeUtil.format.asUTC(juncture?.time, TIME_FORMATS.UTC, 'HH:mm'),
  };
};

const toReferences = (references) => {
  if (!references) {
    return null;
  }

  return {
    sgarId: references?.sgarId || null,
    caseFileReference: references?.caseFileReference || null,
  };
};

const toOccupants = (occupants) => {
  if (!occupants) {
    return null;
  }

  return {
    crewCount: String(occupants?.numberOfCrew) || null,
    passengerCount: String(occupants?.numberOfPassengers) || null,
  };
};

const toAutoPopulationPayload = (task, nationalities, genders) => {
  if (!task) {
    return null;
  }

  return {
    journey: {
      departure: toJuncture(task.movement?.journey?.departure),
      arrival: toJuncture(task.movement?.journey?.arrival),
    },
    aircraft: toVehicle(task.movement?.vehicle),
    references: toReferences(task.movement.references),
    occupants: toOccupants(task.movement.occupants),
    direction: task.movement?.journey?.direction,
    persons: PersonUtil.allPersons(task).map((person) => toPerson(person, nationalities, genders)),
  };
};

export default toAutoPopulationPayload;
