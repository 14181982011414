// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Styling
import './CreateTaskButton.scss';

const CreateTaskButton = ({ onClick, ...props }) => {
  return (
    <Button
      {...props}
      id="create-task"
      aria-label="create task"
      onClick={onClick}
    >
      Create task
    </Button>
  );
};

CreateTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateTaskButton;
