import React, { createContext, useContext, useMemo, useRef } from 'react';

const ApplicationContext = createContext({});

/**
 * This context file contains is for holding state data that does not change
 * frequently and can be accessible throughout the app.
 */
const ApplicationContextProvider = ({ children }) => {
  const targetInformationSheetData = useRef({});

  const getInformationSheet = () => {
    return targetInformationSheetData.current;
  };

  const storeInformationSheet = (data) => {
    targetInformationSheetData.current = data;
  };

  const value = useMemo(() => ({
    getInformationSheet,
    storeInformationSheet,
  }), [targetInformationSheetData.current]);

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};

const useApplication = () => useContext(ApplicationContext);

export { ApplicationContext, ApplicationContextProvider, useApplication };
