/* eslint-disable jsx-a11y/anchor-is-valid */
import * as pluralise from 'pluralise';
import { ButtonGroup, Button, Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';

const NoJustification = ({ pendingApprovals, onOpenAndCloseAction, toCreateJustification }) => {
  return (
    <div className="govuk-grid-column">
      <p className="govuk-body-m govuk-!-font-weight-bold govuk-!-margin-bottom-1 govuk-!-margin-top-2">You have no current approved justifications</p>
      {pendingApprovals ? (
        <div>
          <span id="pending-approvals" className="govuk-body-s">
            You have {pluralise.withCount(pendingApprovals, '% justification', '% justifications')} waiting for approval.
          </span>
          <span className="govuk-body-s">&nbsp;Once approved, they will appear here</span>
        </div>
      ) : null}
      <ButtonGroup id="form-actions" className="govuk-!-margin-top-3 govuk-!-margin-bottom-0">
        <Button id="create-justification" onClick={() => toCreateJustification()}>Create new justification</Button>
        <Link id="close" onClick={() => onOpenAndCloseAction()}>Cancel and close</Link>
      </ButtonGroup>
    </div>
  );
};

export default NoJustification;
