import React from 'react';

import Timeline from './Timeline';

const BorderEvent = ({ borderEvent }) => {
  if (!borderEvent) {
    return <p className="govuk-body">Loading...</p>;
  }

  if (Array.isArray(borderEvent.submissions) && !borderEvent?.submissions?.length) {
    return <p className="govuk-body">Border event not available</p>;
  }

  return (
    <Timeline
      submissions={borderEvent.submissions}
      attachments={borderEvent.attachments || []}
    />
  );
};

export default BorderEvent;
