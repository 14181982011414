import { useEffect, useState } from 'react';

// Util(s)
import { addEventListener, removeEventListener } from './helper/events';

/**
 * Determines if element is vertically in the viewport.
 *
 * @param {*} elementRef The reference to the element
 * @param {{
 *   initialInView: boolean
 *   threshold: number
 * }} options Hook options to determine if target Ref is in hidden.
 * @returns {boolean} Boolean value on whether element is vertically in the viewport.
 */
const useIsVerticallyInViewport = (elementRef, options = {}) => {
  const { initialInView = true, threshold = 0 } = options; // threshold is in pixels
  const [isVerticallyInViewPort, setIsVerticallyInViewPort] = useState(initialInView);

  const handleEvent = () => {
    if (!elementRef.current) {
      return;
    }

    const rect = elementRef.current.getBoundingClientRect();
    const viewPortTop = threshold;
    const viewPortHeight = ((window.innerHeight || document.documentElement.clientHeight) - threshold);
    setIsVerticallyInViewPort(rect.top >= viewPortTop && rect.bottom <= viewPortHeight);
  };

  useEffect(() => {
    addEventListener([
      { type: 'scroll', handler: handleEvent, options: { passive: true } },
      { type: 'resize', handler: handleEvent, options: { passive: true } },
    ],
    );
    handleEvent();

    return () => {
      removeEventListener([
        { type: 'scroll', handler: handleEvent },
        { type: 'resize', handler: handleEvent },
      ]);
    };
  }, []);

  return isVerticallyInViewPort;
};

export default useIsVerticallyInViewport;
