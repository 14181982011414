// Config(s)
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';

const EXCEPTIONS = [
  LOCAL_STORAGE_KEYS.CACHED_CREDIBILITY_CHECKS(),
  LOCAL_STORAGE_KEYS.LAST_KNOWN_LOCATION,
  LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION,
];

const containsException = (key) => {
  return EXCEPTIONS.some((exception) => key.startsWith(exception));
};

const clearLocalStorageWithExceptions = () => {
  const keys = Object.keys(localStorage)
    .filter((localStorageKey) => !containsException(localStorageKey));
  keys.forEach((key) => localStorage.removeItem(key));
};

export default clearLocalStorageWithExceptions;
