import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useStompClient } from 'react-stomp-hooks';

// Contexts(s)
import { useKeycloak } from './Keycloak';

// Util(s)
import { updateTokenIfRequired } from '../utils/Axios/axiosInstance';

const StompContext = createContext({});

const StompProvider = ({ children }) => {
  const keycloak = useKeycloak();
  const [client, setClient] = useState(null);
  const stompClient = useStompClient();

  useEffect(() => {
    if (stompClient) {
      setClient(stompClient);
    }
  }, [stompClient]);

  useEffect(() => {
    if (client?.connected) {
      client.beforeConnect = async () => {
        console.info('token refresh');
        client.connectHeaders = {
          Authorization: await updateTokenIfRequired(keycloak),
        };
        await Promise.resolve();
      };
    }
  }, [client]);

  const value = useMemo(() => ({
    client,
  }), [client]);

  return (
    <StompContext.Provider value={value}>{children}</StompContext.Provider>
  );
};

StompContext.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStompSession = () => useContext(StompContext);

export { StompContext, StompProvider, useStompSession };
