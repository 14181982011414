// Global import(s)
import PropTypes from 'prop-types';
import React, { useRef, forwardRef } from 'react';

// Hook(s)
import { composeRefs } from '../../utils/Hooks/useComposedRefs';
import useStickyContainer, { STICKY_CONTAINER_PROPS } from './Hooks/useStickyContainer';

const StickyContainer = forwardRef((props, ref) => {
  const { children, top, as: Element = 'div' } = props;
  const StickyElement = Element;

  const stickyContainerRef = useRef(null);

  const { top: stickyTop, position } = useStickyContainer({
    elRef: stickyContainerRef,
    top,
  });
  const mergedRefs = composeRefs(ref, stickyContainerRef);

  return (
    <StickyElement
      id="sticky-container"
      ref={mergedRefs}
      style={{
        top: stickyTop,
        position,
        height: 'fit-content',
      }}
      {...props}
    >
      {children}
    </StickyElement>
  );
});

StickyContainer.propTypes = {
  children: PropTypes.node.isRequired,
  top: PropTypes.number,
  as: PropTypes.elementType,
};

StickyContainer.defaultProps = {
  top: STICKY_CONTAINER_PROPS.TOP_PADDING,
  as: 'div',
};

export default StickyContainer;
