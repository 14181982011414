// Config(s)
import { DEFAULT_ERROR } from './constants';

// Util(s)
import { toChecksContainerId } from '../CredibilityCheck';

const validateRootChecks = (entityData, entityIndex) => {
  if (!entityData?.pnc?.pncOutcome && !entityData?.otherChecks?.checks?.length) {
    return {
      componentId: toChecksContainerId(entityIndex),
      message: DEFAULT_ERROR,
    };
  }

  return null;
};

export default validateRootChecks;
