import React from 'react';

import SpinnerItem from './SpinnerItem';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ loading = true, children }) => {
  if (!loading) {
    return children;
  }

  return (
    <div className="loading-spinner">
      <div className="loading-spinner--inner">
        <svg
          version="1.1"
          fill="#0b0c0c"
          width="50px"
          height="50px"
          className="icon-loading"
          viewBox="-25 -25 50 50"
          preserveAspectRatio="xMidYMid meet"
        >
          <title>Loading</title>
          <SpinnerItem transform="rotate(0, 0, 2) translate(10 0)" style={{ animationDelay: '0ms' }} />
          <SpinnerItem transform="rotate(30, 0, 2) translate(10 0)" style={{ animationDelay: '83ms' }} />
          <SpinnerItem transform="rotate(60, 0, 2) translate(10 0)" style={{ animationDelay: '166ms' }} />
          <SpinnerItem transform="rotate(90, 0, 2) translate(10 0)" style={{ animationDelay: '249ms' }} />
          <SpinnerItem transform="rotate(120, 0, 2) translate(10 0)" style={{ animationDelay: '332ms' }} />
          <SpinnerItem transform="rotate(150, 0, 2) translate(10 0)" style={{ animationDelay: '415ms' }} />
          <SpinnerItem transform="rotate(180, 0, 2) translate(10 0)" style={{ animationDelay: '498ms' }} />
          <SpinnerItem transform="rotate(210, 0, 2) translate(10 0)" style={{ animationDelay: '581ms' }} />
          <SpinnerItem transform="rotate(240, 0, 2) translate(10 0)" style={{ animationDelay: '664ms' }} />
          <SpinnerItem transform="rotate(270, 0, 2) translate(10 0)" style={{ animationDelay: '747ms' }} />
          <SpinnerItem transform="rotate(300, 0, 2) translate(10 0)" style={{ animationDelay: '830ms' }} />
          <SpinnerItem transform="rotate(330, 0, 2) translate(10 0)" style={{ animationDelay: '913ms' }} />
        </svg>
        <div className="loading-spinner--overlay" />
      </div>
      {children}
    </div>
  );
};

export default LoadingSpinner;
