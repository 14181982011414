import { DATE_FORMATS, STRINGS, UNKNOWN_TIME_DATA } from '../constants';

import { getFormattedDate } from '../Datetime/datetimeUtil';
import { isNotNumber } from '../Number/numberUtil';
import { getHiddenValue, shouldHideDate, shouldHideString } from '../Hiding/hidingUtil';

const getFlightTimeObject = (milliseconds) => {
  if (!milliseconds && milliseconds !== 0) {
    return UNKNOWN_TIME_DATA;
  }

  if (isNotNumber(milliseconds)) {
    return UNKNOWN_TIME_DATA;
  }

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  return { hours, minutes, seconds };
};

const toFormattedDepartureDateTime = (journey, dateFormat = DATE_FORMATS.LONG) => {
  if (!journey?.departure?.time) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideDate(journey.departure.time)) {
    return null;
  }

  return getFormattedDate(journey.departure.time, dateFormat);
};

const toFormattedArrivalDateTime = (journey, dateFormat = DATE_FORMATS.LONG) => {
  if (!journey?.arrival?.time) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideDate(journey.arrival.time)) {
    return null;
  }

  return getFormattedDate(journey.arrival.time, dateFormat);
};

const getJourneyDuration = (journey) => {
  if (!journey?.duration && journey?.duration !== 0) {
    return STRINGS.UNKNOWN;
  }

  return journey.duration;
};

const toFormattedFlightTime = (journey) => {
  const duration = getJourneyDuration(journey);
  if (duration === STRINGS.UNKNOWN) {
    return STRINGS.UNKNOWN;
  }

  if (duration === 0) {
    return getHiddenValue();
  }

  const time = getFlightTimeObject(duration);
  if (!time.hours && !time.minutes) {
    return STRINGS.UNKNOWN;
  }
  return `${time.hours}h ${time.minutes}m`;
};

const hasItinerary = (journey) => {
  return !!journey?.itinerary;
};

const getMovementItinerary = (journey) => {
  if (hasItinerary(journey)) {
    return journey.itinerary;
  }
  return null;
};

const getDepartureTime = (journey) => {
  if (!journey?.departure?.time) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideDate(journey.departure.time)) {
    return null;
  }

  return journey.departure.time;
};

const getArrivalTime = (journey) => {
  if (!journey?.arrival?.time) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideDate(journey.arrival.time)) {
    return null;
  }
  return journey.arrival.time;
};

const getDepartureLocation = (journey) => {
  if (!journey?.departure?.location) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(journey.departure.location)) {
    return null;
  }
  return journey.departure.location;
};

const getArrivalLocation = (journey) => {
  if (!journey?.arrival?.location) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(journey.arrival.location)) {
    return null;
  }
  return journey.arrival.location;
};

const getMovementDirection = (journey) => {
  if (shouldHideString(journey?.direction)) {
    return getHiddenValue();
  }

  return journey?.direction || undefined;
};

const getRoute = (journey) => {
  return journey?.route;
};

const hasJourney = (targetTask) => {
  return !!targetTask?.movement?.journey;
};

const getJourney = (targetTask) => {
  if (hasJourney(targetTask)) {
    return targetTask.movement.journey;
  }
  return null;
};

const JourneyUtil = {
  arrivalLoc: getArrivalLocation,
  arrivalTime: getArrivalTime,
  departureLoc: getDepartureLocation,
  departureTime: getDepartureTime,
  direction: getMovementDirection,
  formatDepartureTime: toFormattedDepartureDateTime,
  formatArrivalTime: toFormattedArrivalDateTime,
  flightDuration: getJourneyDuration,
  flightTimeObject: getFlightTimeObject,
  formatFlightTime: toFormattedFlightTime,
  get: getJourney,
  movementRoute: getRoute,
  movementItinerary: getMovementItinerary,
};

export default JourneyUtil;

export {
  getRoute,
  getJourney,
  getDepartureTime,
  getArrivalTime,
  toFormattedDepartureDateTime,
  toFormattedArrivalDateTime,
  getDepartureLocation,
  getArrivalLocation,
  getJourneyDuration,
  toFormattedFlightTime,
  getFlightTimeObject,
  getMovementDirection,
};
