import FormRenderer, { Utils } from '@ukhomeoffice/cop-react-form-renderer';
import { MultiSelectAutocomplete } from '@ukhomeoffice/cop-react-components';
import React, { useContext } from 'react';

// Config(s)
import { COMPONENT_TYPES, FORM_ACTIONS } from '../../../utils/constants';
import config from '../../../utils/config';

// Context(s)
import { ApplicationContext } from '../../../context/ApplicationContext';

// Hook(s)
import { useKeycloak } from '../../../context/Keycloak';
import { useAxiosInstance, updateTokenIfRequired } from '../../../utils/Axios/axiosInstance';
import { setTabTitle, TAB_TITLES } from '../../../utils/Hooks/useSetTabTitle';

// Component(s)
import SBTChecks from './Components/SBTChecks';

// Util(s)
import FormUtils from '../../../utils/Form/ReactForm';
import { TargetInformationUtil } from '../../../utils';

const ReactForm = ({ form,
  formattedPreFillData,
  saveTisData,
  onFinish,
  setSubmitted,
  setLoaderVisibility,
  onCancel,
  onChange,
  onSubmit: _onSubmit,
  uploadDocument = true,
  overrideSubmit = false,
  viewOnly }) => {
  const keycloak = useKeycloak();
  const { storeInformationSheet } = useContext(ApplicationContext);
  const uploadApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);

  const onGetComponent = (component, wrap) => {
    const attrs = Utils.Component.clean(component, ['clearable', 'fieldId', 'dynamicOptions', 'multi', 'openOnClick', 'closeOnSelect']);
    if (component.type === COMPONENT_TYPES.AUTOCOMPLETE) {
      const multiSelect = (
        <MultiSelectAutocomplete
          className="hods-multi-select-autocomplete"
          {...component}
        />
      );
      if (wrap) {
        return Utils.Component.wrap(attrs, multiSelect);
      }
      return multiSelect;
    }
    if (component.type === COMPONENT_TYPES.SELECTORS_SUMMARY) {
      const selectorsTable = (
        <SBTChecks
          {...component}
        />
      );
      if (wrap) {
        return Utils.Component.wrap(attrs, selectorsTable);
      }
      return selectorsTable;
    }
    return null;
  };

  const internalCleanSubmission = (payload) => {
    return FormUtils.cleanSubmission(form, payload, keycloak.tokenParsed.email);
  };

  const onSubmit = async (submissionPayload) => {
    await _onSubmit({ data: submissionPayload }, form);
  };

  const toInternalOnSubmit = () => {
    if (overrideSubmit) {
      return (type, payload, onSuccess) => {
        const submissionPayload = internalCleanSubmission(payload);
        onSubmit(submissionPayload);
        onSuccess(submissionPayload);
      };
    }

    return async (type, payload, onSuccess) => {
      if (type === FORM_ACTIONS.NEXT) {
        if (saveTisData) {
          storeInformationSheet(TargetInformationUtil.convertToPrefill(payload));
        }
        // Do nothing.
        return onSuccess(payload);
      }

      if (type === FORM_ACTIONS.SILENT_SUBMIT) {
        const submissionPayload = internalCleanSubmission(payload);
        onSubmit(submissionPayload);
        return onSuccess(payload);
      }

      if (type === FORM_ACTIONS.FINISH) {
        if (typeof onFinish === 'function') {
          onFinish();
        }
        setSubmitted(true);
        setLoaderVisibility(false);
      }

      setLoaderVisibility(true);
      try {
        const submissionPayload = internalCleanSubmission(payload);
        if (uploadDocument) {
          await FormUtils.uploadDocuments(uploadApiClient, submissionPayload);
        }
        await onSubmit(submissionPayload);
        onSuccess(submissionPayload);
        setSubmitted(true);
      } finally {
        if (typeof onFinish === 'function') {
          onFinish();
        }
        setLoaderVisibility(false);
      }
    };
  };

  return (
    <FormRenderer
      {...form}
      data={formattedPreFillData?.data}
      viewOnly={viewOnly}
      hooks={{
        onGetComponent,
        // eslint-disable-next-line no-unused-vars
        onPageChange: (pageId, _) => {
          switch (pageId) {
            case 'recipientDetails':
              setTabTitle(TAB_TITLES.RECIPIENT_DETAILS);
              window.scrollTo({ top: 0, left: 0 });
              break;
            case 'cerberus-roro-target-information-sheet':
            case 'info':
              setTabTitle(`${TAB_TITLES.TARGET_INFORMATION_SHEET} (RoRo)`);
              break;
            case 'working-from-approved-site':
              setTabTitle(TAB_TITLES.WORKING_FROM_APPROVED_SITE);
              break;
            case 'can-view-pnr-data':
              setTabTitle(TAB_TITLES.MASK_PNR);
              break;
            case 'withdrawTarget':
            case 'reason-for-withdrawing':
              setTabTitle(TAB_TITLES.WITHDRAW_TARGET);
              break;
            default:
              break;
          }
        },
        onRequest: async (req) => {
          const token = await updateTokenIfRequired(keycloak);
          return FormUtils.formHooks.onRequest(req, token);
        },
        onSubmit: toInternalOnSubmit(),
        onCancel: (data) => onCancel(data),
        onChange,
      }}
    />
  );
};

export default ReactForm;
