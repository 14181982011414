import { STRINGS } from '../constants';

import StringUtil from '../String/stringUtil';

const getBookerAddress = (booker) => {
  if (!booker) {
    return STRINGS.UNKNOWN;
  }
  return StringUtil.format.address(booker.address) || STRINGS.UNKNOWN;
};

const getBookerName = (booker) => {
  if (!booker) {
    return STRINGS.UNKNOWN;
  }
  return booker?.name || STRINGS.UNKNOWN;
};

const getBooker = (targetTask) => {
  return targetTask?.movement?.booker || undefined;
};

const BookerUtil = {
  get: getBooker,
  address: getBookerAddress,
  name: getBookerName,
};

export default BookerUtil;
