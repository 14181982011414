/* eslint-disable no-param-reassign */
const setupComponentError = (component, errors) => {
  if (!component) {
    return;
  }

  const error = errors.find(({ id }) => id === component.id);
  if (error) {
    component.error = error?.error;
    return;
  }

  // Remove component error if non was found
  delete component.error;
};

export default setupComponentError;
