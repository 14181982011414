// Global import(s)
import { useQueries } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Config(s)
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchFilterCounts = (requestParams, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [data, setData] = useState({
    data: [],
    isLoading: false,
  });
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const combineChargesQueryResults = (combinedQueries) => {
    const combinedResults = combinedQueries?.map((combinedQuery) => combinedQuery?.data) || [];

    const mergedResults = combinedResults.reduce((prev, current) => [...prev, ...current], []);

    const isLoading = combinedResults.reduce((prev, current) => ([...prev, current?.pending]), []).some((item) => !!item);
    return { data: mergedResults, isLoading };
  };

  const getFilterCounts = async (params) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return [];
    }

    return AxiosRequests.filterCounts(apiClient, params);
  };

  const combinedQueries = useQueries({
    queries: requestParams.map((requestParam, index) => ({
      queryKey: [QUERY_KEYS.FILTER_COUNT, requestParam, index],
      queryFn: () => getFilterCounts(requestParam),
      initialData: [],
      enabled,
    })),
  });

  useDeepCompareEffect(() => {}, [requestParams]);

  useDeepCompareEffect(() => {
    const { data: results, isLoading } = combineChargesQueryResults(combinedQueries);
    setData((prev) => ({
      ...prev,
      data: results,
      isLoading,
    }));

    return () => AxiosRequests.cancel(source);
  }, [combinedQueries]);

  return { filterCounts: data.data, isLoading: data.isLoading };
};

export default useFetchFilterCounts;
