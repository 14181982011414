import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Config
import { SCRIM_TYPE } from '../../../../../../../utils/constants';

// Context(s)
import { useScrim } from '../../../../../../../context/ScrimContext';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import RenderForm from '../../../../../../../components/RenderForm/RenderForm';
import StickyContainer from '../../../../../../../components/StickyContainer/StickyContainer';

// Styling
import './PanelForm.scss';

const PanelForm = ({
  form,
  viewOnly,
  preFillData,
  onSubmit,
  onChange,
  onCancel,
  uploadDocument,
  overrideSubmit,
  modal,
  style,
  ...props
}) => {
  const { openScrim, closeScrim, isScrimOpen } = useScrim();

  useEffect(() => {
    openScrim(isScrimOpen && modal ? SCRIM_TYPE.FULL : SCRIM_TYPE.PARTIAL);

    // This is required to clean up the scrim if this is unmounted
    return () => {
      closeScrim();
    };
  }, [modal]);

  return (
    <StickyContainer>
      <div
        className="panel-form-container govuk-grid-row"
        {...props}
        style={{ ...style }}
      >
        <div id="panel-form" className="panel-form govuk-grid-column-full">
          <RenderForm
            form={form}
            viewOnly={viewOnly}
            preFillData={preFillData}
            onSubmit={onSubmit}
            onChange={onChange}
            onCancel={onCancel}
            uploadDocument={uploadDocument}
            overrideSubmit={overrideSubmit}
          />
          <ComponentWrapper show={!!modal}>{modal}</ComponentWrapper>
        </div>
      </div>
    </StickyContainer>
  );
};

PanelForm.propTypes = {
  form: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  preFillData: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  uploadDocument: PropTypes.bool,
  modal: PropTypes.node,
  style: PropTypes.shape({}),
};

PanelForm.defaultProps = {
  preFillData: null,
  uploadDocument: false,
  modal: null,
  style: {},
};

export default PanelForm;
