const getTrailerNationality = (trailer) => {
  if (!trailer) {
    return undefined;
  }
  return trailer?.nationality?.nationality || undefined;
};

const getTrailerType = (trailer) => {
  if (!trailer) {
    return undefined;
  }
  return trailer?.type?.name || undefined;
};

const getTrailerRegistration = (trailer) => {
  return trailer?.registration || undefined;
};

const getTrailer = (informationSheet) => {
  return informationSheet?.movement?.trailer || undefined;
};

const TargetTrailerUtil = {
  get: getTrailer,
  nationality: getTrailerNationality,
  registration: getTrailerRegistration,
  type: getTrailerType,
};

export default TargetTrailerUtil;
