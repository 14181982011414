import PropTypes from 'prop-types';
import React, { useMemo, createContext, useContext, useState } from 'react';

const ScrimContext = createContext({});

const ScrimProvider = ({ children }) => {
  const [scrimTypeStack, setScrimTypeStack] = useState([]);

  const addToScrimTypeStack = (scrimType) => {
    setScrimTypeStack((prev) => ([
      ...prev,
      scrimType,
    ]));
  };

  const removeLastFromScrimTypeStack = () => {
    setScrimTypeStack((prev) => prev.slice(0, -1));
  };

  const value = useMemo(() => ({
    currentScrimType: scrimTypeStack.at(-1),
    openScrim: addToScrimTypeStack,
    closeScrim: removeLastFromScrimTypeStack,
    isScrimOpen: scrimTypeStack.length > 0,
  }), [scrimTypeStack, addToScrimTypeStack, removeLastFromScrimTypeStack]);

  return (
    <ScrimContext.Provider value={value}>{children}</ScrimContext.Provider>
  );
};

ScrimProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useScrim = () => useContext(ScrimContext);

export { ScrimContext, ScrimProvider, useScrim };
