const getName = (consignee) => {
  return consignee?.name || undefined;
};

const getAddress = (consignee) => {
  return consignee?.address || undefined;
};

const getConsignee = (targetTask) => {
  return targetTask?.movement?.consignee || undefined;
};

const ConsigneeUtil = {
  address: getAddress,
  get: getConsignee,
  name: getName,
};

export default ConsigneeUtil;

export {
  getAddress,
  getConsignee,
  getName,
};
