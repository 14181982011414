import React from 'react';

import TargetingIndicators from '../shared/TargetingIndicators';
import Vehicle from '../shared/Vehicle';
import Trailer from '../shared/Trailer';
import Goods from '../shared/Goods';
import Haulier from '../shared/Haulier';
import Account from '../shared/Account';
import Booking from '../shared/Booking';
import Occupants from '../shared/Occupants';

const AccompaniedVersionDetails = ({ version, versionDiff }) => {
  const bottomBorderClass = 'bottom-border-thin';

  return (
    <div className="govuk-task-details-grid govuk-!-padding-top-4">
      <div className="govuk-grid-column-one-third">
        <div>
          <TargetingIndicators version={version} classModifiers={[bottomBorderClass]} />
          <Vehicle version={version} versionDiff={versionDiff} classModifiers={[bottomBorderClass]} />
          <Trailer version={version} versionDiff={versionDiff} classModifiers={[bottomBorderClass]} />
          <Goods version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Haulier version={version} versionDiff={versionDiff} classModifiers={[bottomBorderClass]} />
          <Account version={version} versionDiff={versionDiff} classModifiers={[bottomBorderClass]} />
          <Booking version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Occupants version={version} versionDiff={versionDiff} classModifiers={['govuk-!-margin-bottom-2']} />
        </div>
      </div>
    </div>
  );
};

export default AccompaniedVersionDetails;
