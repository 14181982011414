import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';

import toUserAndTimestamp from './helper/toUserAndTimestamp';
import { StringUtil } from '../../utils';

const LinkLog = ({ activity }) => {
  const { content } = activity;

  if (!content?.text) {
    return null;
  }

  return (
    <div key={activity?.id}>
      <div className="activity-container">
        {toUserAndTimestamp(activity)}
        <p id="log-body" className="govuk-body govuk-!-padding-bottom-1">
          <Link
            href={content?.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {StringUtil.unescape(content?.text)}
          </Link>
        </p>
      </div>
      <hr className="govuk-section-break govuk-section-break--m govuk-section-break--visible" />
    </div>
  );
};

export default LinkLog;
