// Config(s)
import { DIRECTION } from '../../../../../../../../../utils/TargetInformation/constants';

// eslint-disable-next-line import/prefer-default-export
export const toDepartureArrivalLabel = (direction) => {
  if (direction === DIRECTION.OUTBOUND) {
    return 'to departure';
  }

  if (direction === DIRECTION.INBOUND) {
    return 'to arrival';
  }

  return null;
};
