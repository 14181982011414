import format from 'format-number';

const isANumber = (number) => {
  if (!number && number !== 0) {
    return false;
  }
  if (typeof number === 'number') {
    return true;
  }
  return !isNaN(number);
};

const isNotNumber = (number) => {
  if (!number && number !== 0) {
    return true;
  }
  return isNaN(number);
};

const resetToZeroIfRequired = (value) => {
  if (!value || value < 0) {
    return 0;
  }

  return value;
};

// This will add a comma to long numbers e.g. 10000 would be returned as 10,000.
const formatWithCommas = (value) => {
  if (!value || isNotNumber(value)) {
    return value;
  }

  return format(null)(value, { noSeparator: false });
};

const isGreaterThanZero = (value) => {
  if (isNotNumber(value)) {
    return false;
  }
  return parseInt(value, 10) > 0;
};

const hasZeroCount = (content) => {
  return content === '0' || content === 0;
};

const NumberUtil = {
  checkZeroCount: hasZeroCount,
  greaterThanZero: isGreaterThanZero,
  isNumber: isANumber,
  notANumber: isNotNumber,
  withComma: formatWithCommas,
  zeroIfRequired: resetToZeroIfRequired,
};

export default NumberUtil;

export { formatWithCommas, hasZeroCount, isGreaterThanZero, isANumber, isNotNumber, resetToZeroIfRequired };
