// Global import(s)
import { useSubscription } from 'react-stomp-hooks';

// Config(s)
import config from '../../../../../utils/config';

const useListPageSubscription = (onAction) => {
  if (!config.enableWebSocket) {
    // Do not subscribe to any topic
    return;
  }

  useSubscription('/topic/new-tab-targeting-task-status-updates', (message) => {
    onAction(JSON.parse(message.body));
  });
};

export default useListPageSubscription;
