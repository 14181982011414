const cleanAttributes = (componentAttrs, attributes) => {
  if (!componentAttrs) {
    return componentAttrs;
  }

  const _componentAttrs = {
    ...componentAttrs,
  };

  if (Array.isArray(attributes)) {
    attributes.forEach((attribute) => delete _componentAttrs[attribute]);
    return _componentAttrs;
  }

  delete _componentAttrs[attributes];
  return _componentAttrs;
};

export default cleanAttributes;
