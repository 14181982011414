import { Button, NotificationBanner } from '@ukhomeoffice/cop-react-components';
import React, { useState } from 'react';

import toJustificationOptions from './helper/toJustificationOptions';

import './AddJustification.scss';

const AddJustification = ({ isMaskingEnabled, justifications, getTaskWithJustification, toCreateJustification }) => {
  const [expanded, setExpanded] = useState(false);

  const onOpenAndCloseAction = () => {
    setExpanded(!expanded);
  };

  if (!isMaskingEnabled) {
    return null;
  }

  return (
    <div className="govuk-grid-column">
      <NotificationBanner
        id="add-justification-banner"
        className="add-justification-banner"
        title="PNR data over 6 months old is currently hidden"
      >
        {!expanded
          ? (
            <Button
              id="add-justification"
              className="govuk-!-margin-bottom-3"
              onClick={onOpenAndCloseAction}
            >
              Add justification to show hidden data
            </Button>
          )
          : (
            <Button
              id="close"
              classModifiers="secondary"
              className="govuk-!-margin-bottom-3"
              onClick={onOpenAndCloseAction}
            >
              Cancel and close
            </Button>
          )}
        {expanded
          && (
            <div className="add-justification-content-container govuk-!-margin-top-2 govuk-!-padding-top-2">
              {toJustificationOptions(justifications, onOpenAndCloseAction, toCreateJustification, getTaskWithJustification)}
            </div>
          )}
      </NotificationBanner>

    </div>
  );
};

export default AddJustification;
