// Global import(s)
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import PreviousTasksCount from '../../../../../components/PreviousTasks/PreviousTasksCount';

// Util(s)
import { BaggageUtil, BookingUtil, CommonUtil, DocumentUtil, FlightUtil, JourneyUtil, MovementUtil, PersonUtil } from '../../../../../utils';

const AirpaxMovementSection = ({ person, baggage, booking, journey, flight, document, otherPersons, movementType, hidingEnabled }) => {
  const lastName = PersonUtil.lastname(person);
  const firstName = PersonUtil.firstname(person);
  const gender = PersonUtil.gender(person);
  const dob = PersonUtil.dob(person);
  const nationality = PersonUtil.nationality(person);
  const formattedNumberOfCheckedBags = BaggageUtil.format.bagCount(BaggageUtil.bagCount(baggage));
  const formattedBaggageWeight = BaggageUtil.format.weight(BaggageUtil.weight(baggage));
  const checkInAt = BookingUtil.checkInAt(booking);
  const checkInText = BookingUtil.format.checkInText(checkInAt);
  const seatNumber = FlightUtil.seatNumber(flight);
  const documentNumber = DocumentUtil.docNumber(document);
  const docValidity = DocumentUtil.docValidity(document);
  const documentExpiry = DocumentUtil.docExpiry(document);
  const documentCountry = DocumentUtil.docCountry(document);
  const bookingReference = BookingUtil.bookingRef(booking);
  const bookedAt = BookingUtil.bookedAt(booking);
  const formattedBookedAt = BookingUtil.format.bookingDate(bookedAt);
  const departureTime = JourneyUtil.departureTime(journey);
  const route = MovementUtil.convertMovementRoute(JourneyUtil.movementRoute(journey));
  const personNumberOfPreviousTasks = CommonUtil.getNumberOfPreviousTasks(person);
  const documentNumberOfPreviousTasks = CommonUtil.getNumberOfPreviousTasks(document);
  const totalPersonsNumberOfPreviousTasks = CommonUtil.getTotalNumberOfPreviousTasks(otherPersons);
  const otherPersonsElementBlock = PersonUtil.toOthers(otherPersons, hidingEnabled);
  const bookedPrior = BookingUtil.format.bookedPrior(bookedAt, departureTime);

  return (
    <>
      <div className="govuk-grid-item">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            {movementType}
          </p>
          <ComponentWrapper show={firstName || lastName}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <ComponentWrapper show={lastName}>
                <li className="govuk-!-font-weight-bold">{`${lastName},`} </li>
              </ComponentWrapper>
              <ComponentWrapper show={firstName}>
                <li className="govuk-!-font-weight-regular">{firstName}</li>
              </ComponentWrapper>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={gender || dob || nationality}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-regular">{CommonUtil.commaSeparated(gender, dob, nationality)} </li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={formattedNumberOfCheckedBags || formattedBaggageWeight}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{CommonUtil.commaSeparated(formattedNumberOfCheckedBags, formattedBaggageWeight)}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={checkInText || seatNumber}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{CommonUtil.commaSeparated(checkInText, seatNumber)}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={personNumberOfPreviousTasks}>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={personNumberOfPreviousTasks} />
            </ul>
          </ComponentWrapper>
        </div>
      </div>

      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Document
          </p>
          <ComponentWrapper show={documentNumber || nationality}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{CommonUtil.spaceSeparated(documentNumber, nationality ? `(${PersonUtil.nationality(person)})` : null)}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={docValidity}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{docValidity}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={documentExpiry}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{documentExpiry}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={documentCountry}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{documentCountry}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={documentNumberOfPreviousTasks}>
            <ul className="govuk-body-s govuk-list">
              <PreviousTasksCount numberOfPreviousTasks={documentNumberOfPreviousTasks} />
            </ul>
          </ComponentWrapper>
        </div>
      </div>

      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Booking
          </p>
          <ComponentWrapper show={bookingReference}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-bold">{bookingReference}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={formattedBookedAt}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-regular">{formattedBookedAt}</li>
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={bookedAt || departureTime}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0 secondary-text">
              <li className="govuk-!-font-weight-regular">{bookedPrior}</li>
            </ul>
          </ComponentWrapper>
        </div>
      </div>

      <div className="govuk-grid-item vertical-dotted-line">
        <div>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Co-travellers
          </p>
          <ComponentWrapper show={otherPersonsElementBlock}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              {otherPersonsElementBlock}
            </ul>
          </ComponentWrapper>
          <ComponentWrapper show={totalPersonsNumberOfPreviousTasks}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <PreviousTasksCount numberOfPreviousTasks={totalPersonsNumberOfPreviousTasks} />
            </ul>
          </ComponentWrapper>
          <p className="govuk-body-s govuk-!-margin-bottom-1 govuk-!-font-weight-regular secondary-text">
            Itinerary
          </p>
          <ComponentWrapper show={route}>
            <ul className="govuk-body-s govuk-list govuk-!-margin-bottom-0">
              <li className="govuk-!-font-weight-regular">{route}</li>
            </ul>
          </ComponentWrapper>
        </div>
      </div>
    </>
  );
};

export default AirpaxMovementSection;
