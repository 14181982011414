import { Label, Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_CLASS = 'govuk-checkboxes__item';

const Checkbox = ({ id, label, name, onChange, selected, classBlock, classModifiers, className, ...props }) => {
  const classes = Utils.classBuilder(classBlock, classModifiers, className);
  return (
    <div className={classes()} {...props}>
      <input
        className="govuk-checkboxes__input"
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={selected}
      />
      <Label
        id={id}
        className="govuk-label govuk-checkboxes__label"
        htmlFor={id}
        aria-label={id}
        hideOptionalSuffix
      >
        {label}
      </Label>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  classBlock: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  selected: false,
  classBlock: DEFAULT_CLASS,
  classModifiers: [],
  className: '',
};
