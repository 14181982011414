// Global imports
import React, { useEffect } from 'react';

// Context
import { useView } from '../../context/ViewContext';

const withDisabledStickyContainer = (BaseComponent) => {
  return (props) => {
    const { setDisableStickyContainer } = useView();

    useEffect(() => {
      setDisableStickyContainer(true);

      return () => setDisableStickyContainer(false);
    }, []);

    return <BaseComponent {...props} />;
  };
};

export default withDisabledStickyContainer;
