const toFormattedIndicatorMatch = (entity) => {
  const cleanString = (value) => {
    if (!value) {
      return value;
    }

    if (value.startsWith('[') && value.endsWith(']')) {
      return value.substring(1, value.length - 1);
    }

    return value;
  };

  return [entity.entity, entity.descriptor, entity.operator, cleanString(entity.value)].join(' ');
};

export default toFormattedIndicatorMatch;
