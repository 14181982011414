// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

import './ExtendedListRow.scss';

const ExtendedListRow = ({ id, activeClass, type, values }) => {
  return (
    <div
      id={`${id}-extended-summary-list__row`}
      className={classNames(
        'govuk-extended-summary-list__row',
        `govuk-extended-summary-type-${type}`,
        activeClass !== '' && activeClass,
      )}
    >
      {values.map((value, idx) => {
        if (Array.isArray(value)) {
          if (value[1] === undefined || value[1] === null) {
            return <div key={`${id}-${idx}`}>&nbsp;</div>;
          }
          return (
            <div className="content" key={`${id}-${idx}`}>
              <span className="font--grey label govuk-extended-summary-list__key">{value[0]}</span>
              <span className="information govuk-extended-summary-list__value">{value[1]}</span>
            </div>
          );
        }
        return (
          <div key={`${id}-${idx}`}><span className="govuk-extended-summary-list__value">{value}</span></div>
        );
      })}
    </div>
  );
};

ExtendedListRow.propTypes = {
  activeClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

ExtendedListRow.defaultProps = {
  activeClass: '',
};

export default memo(ExtendedListRow);
