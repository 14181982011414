// Global imports
import Heading from '@ukhomeoffice/cop-react-components/dist/Heading';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import { OccupantsUtil } from '../../../../../../../utils';

const PersonsOnBoardCount = ({ informationSheet }) => {
  const occupants = OccupantsUtil.get(informationSheet);
  const numberOfCrew = OccupantsUtil.crewTotal(occupants);
  const numberOfPassengers = OccupantsUtil.passengersTotal(occupants);

  if (!numberOfCrew && !numberOfPassengers) {
    return null;
  }

  const panelData = (() => {
    return [
      numberOfCrew && {
        id: 'crew-count',
        label: 'Crew',
        value: numberOfCrew,
      },
      numberOfPassengers && {
        id: 'passengers-count',
        label: 'Passenger',
        value: numberOfPassengers,
      },
    ];
  })();

  return (
    <div id="pre-arrival-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">Number of persons on board</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
      </div>
    </div>
  );
};

PersonsOnBoardCount.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(PersonsOnBoardCount);
