import setupComponentError from './setupComponentError';

const setupComponentErrors = (component, errors) => {
  if (component.data?.options) {
    component.data?.options?.forEach((option) => {
      if (Array.isArray(option.nested)) {
        option.nested.forEach((opt) => setupComponentErrors(opt, errors));
      }
    });
  }

  setupComponentError(component, errors);
};

export default setupComponentErrors;
