import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styling
import './LabelField.scss';

const LabelField = ({ value, classNames: _classNames, ...props }) => {
  const DEFAULT_CLASS = 'label-field';
  const classes = classNames(DEFAULT_CLASS, _classNames);

  if (!value) {
    return null;
  }

  return <p {...props} className={classes}>{value}</p>;
};

LabelField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

LabelField.defaultProps = {
  value: null,
  classNames: null,
};

export default LabelField;
