// Config(s)
import { CONTENT_TYPE, GROUP_MEMBER, TARGET_PAGE_ACCESS_CONTROL, VIEW } from '../../constants';

// Util(s)
import { toUniqueById } from '../commonUtil';

const getAdditionalPhotoContent = (entity) => {
  return entity?.additionalContents?.find((addition) => addition.type === CONTENT_TYPE.PHOTO) || null;
};

const getAdditionalTextContents = (entity) => {
  const contents = entity?.additionalContents?.filter((addition) => addition.type === CONTENT_TYPE.TEXT) || [];
  return toUniqueById(contents);
};

const isRCCUAirpaxView = (view) => {
  return view === VIEW.AIRPAX_RCCU;
};

const isHigherOfficerAirpaxView = (view) => {
  return view === VIEW.AIRPAX_HO;
};

const isInterceptingOfficerAirpaxView = (view) => {
  return view === VIEW.AIRPAX_IO;
};

const isViewTargetAirpax = (view) => {
  return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU].includes(view);
};

const isViewTargetRoro = (view) => {
  return [VIEW.RORO_IO].includes(view);
};

const isSeniorOfficersAirpaxView = (view) => {
  return isRCCUAirpaxView(view) || isHigherOfficerAirpaxView(view);
};

const isAllowedToViewTargetPage = (groupMember, path) => {
  return !!TARGET_PAGE_ACCESS_CONTROL[groupMember]?.includes(path);
};

const getTargetListAuthorisationCheck = (canRead, userGroupMember, location, userRole) => {
  return !canRead
    || userGroupMember !== GROUP_MEMBER.NONE
    || isAllowedToViewTargetPage(userGroupMember, location?.pathname)
    || !userRole;
};

const TargetCommonUtil = {
  additionalPhotoContent: getAdditionalPhotoContent,
  additionalTextContents: getAdditionalTextContents,
  canViewTargetPage: isAllowedToViewTargetPage,
  isViewTargetAirpax,
  isViewTargetRoro,
  isRCCUAirpaxView,
  isSeniorOfficersAirpaxView,
  isHigherOfficerAirpaxView,
  isInterceptingOfficerAirpaxView,
  targetListAuthorisationCheck: getTargetListAuthorisationCheck,
};

export default TargetCommonUtil;

export {
  isAllowedToViewTargetPage,
  getAdditionalPhotoContent,
  getAdditionalTextContents,
  TARGET_PAGE_ACCESS_CONTROL,
};
