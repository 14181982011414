// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { EUROPE_LONDON, MOVEMENT_MODES } from '../../../../../../../utils/constants';

// Component(s)
import SummaryListRow from '../../generics/SummaryListRow';

// Util(s)
import DateTimeUtil from '../../../../../../../utils/Datetime/datetimeUtil';
import TargetMovementUtil from '../../../../../../../utils/Movement/Target/targetMovementUtil';
import PersonUtil from '../../../../../../../utils/Person/personUtil';
import TargetInformationUtil from '../../../../../../../utils/TargetInformation/targetInformationUtil';

const TargetingDetails = ({ informationSheet, mode }) => {
  const toIssueDetailsHeader = () => {
    if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
      return 'Alert issue details';
    }

    return 'Target issue details';
  };

  const toIssuedLabel = () => {
    if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
      return 'Alert issued';
    }

    return 'Target issued';
  };

  const toReferenceLabel = () => {
    if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
      return 'Alert reference';
    }

    return 'Target reference';
  };

  const panelData = (() => {
    return [
      {
        id: 'targeting-hub',
        label: 'Targeting hub',
        value: TargetInformationUtil.informationSheet.targetDetails.issuingHub(informationSheet),
      },
      {
        id: 'targeter-name',
        label: 'Targeter',
        value: (() => {
          const user = TargetInformationUtil.informationSheet.targetDetails.submittingUser(informationSheet);
          return PersonUtil.formattedName(user?.firstName)(user?.lastName);
        })(),
      },
      {
        id: 'telephone',
        label: 'Telephone',
        value: TargetInformationUtil.informationSheet.targetDetails.issuingHubTel(informationSheet),
      },
      {
        id: 'issue-date',
        label: toIssuedLabel(),
        value: DateTimeUtil.timezoneFormatted(
          TargetInformationUtil.informationSheet.targetDetails.issueDate(informationSheet),
          EUROPE_LONDON,
          'DD MMM YYYY [at] HH:mm',
        ),
      },
      {
        id: 'target-reference',
        label: toReferenceLabel(),
        value: TargetMovementUtil.targetId(informationSheet),
      },
    ];
  })();

  return (
    <div id="targeting-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-one-half">
        <Heading size="m">{toIssueDetailsHeader()}</Heading>
        <dl className="govuk-summary-list">
          {panelData
            ?.filter((item) => !!item)
            ?.map((item) => (
              <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
            ))}
        </dl>
      </div>
    </div>
  );
};

TargetingDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
};

export default memo(TargetingDetails);
