import { Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as pluralise from 'pluralise';

// Constants
import { DATE_FORMATS, EUROPE_LONDON, MOVEMENT_MODES } from '../../../../../utils/constants';

// Components
import Accordion from '../../../../../components/Accordion/Accordion';
import SelectorMatches from './SelectorMatches';
import RuleMatches from './RuleMatches';
import TaskSummary from './TaskSummary';

// Utils
import { DateTimeUtil, MovementUtil, TaskVersionUtil } from '../../../../../utils';
import getVersionDetails from '../../helper/getVersionDetails';

const ENTITIES_TO_EXCLUDE = ['id', 'mode', 'otherPersons', 'status'];

/**
 * Modifies the top level nodes within the movement node.
 * @param movement The data node within the versions object
 * @param entitiesToRemove An array containing entries to remove from the movement node.
 * @returns A new object with nodes removed.
 */
const withoutEntities = (movement, entitiesToRemove) => {
  if (!movement) {
    return undefined;
  }
  const data = _.cloneDeep(movement);
  entitiesToRemove.forEach((entity) => delete data[entity]);
  return data;
};

const getEntitiesToExclude = (mode) => {
  if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
    return ENTITIES_TO_EXCLUDE;
  }
  return ENTITIES_TO_EXCLUDE.filter((e) => e !== 'otherPersons');
};

const getPreviousVersion = (taskVersions, index) => {
  if (taskVersions.length === 1 || index === taskVersions.length - 1) {
    return undefined;
  }
  return taskVersions[index + 1];
};

const getVersionDiff = (mode, versions, currentVersion, index, forChangesDiff = true) => {
  const previousVersion = getPreviousVersion(versions, index);
  if (!previousVersion) {
    return undefined;
  }

  if (forChangesDiff) {
    /*
    * Remove entities which are not required for determining the count of
    * changes between versions prior to performing a diff between the versions.
    */
    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      return TaskVersionUtil.diffForChanges(
        withoutEntities(currentVersion?.movement, getEntitiesToExclude(mode)),
        withoutEntities(previousVersion?.movement, getEntitiesToExclude(mode)),
      );
    }
    return TaskVersionUtil.diffForChanges(
      withoutEntities(currentVersion?.movement, getEntitiesToExclude(mode)),
      withoutEntities(previousVersion?.movement, getEntitiesToExclude(mode)),
    );
  }
  return TaskVersionUtil.diff(currentVersion, previousVersion);
};

const toVersionDetails = (version, versionDiff) => {
  const mode = MovementUtil.movementMode(version);
  return (
    <>
      <TaskSummary version={version} />
      {getVersionDetails(mode, version, versionDiff)}
      <div>
        <SelectorMatches version={version} />
      </div>
      <div>
        <RuleMatches version={version} />
      </div>
    </>
  );
};

const TaskVersions = ({ taskVersions, taskId }) => {
  dayjs.extend(utc);
  return (
    <Accordion
      className="task-versions"
      id={`task-versions-${taskId}`}
      items={
        taskVersions.map((version, index) => {
          const mode = MovementUtil.movementMode(version);
          const threatLevel = version.risks.highestThreatLevel;
          // Change data here is not the same as the change data for entities/ entity components.
          const changeData = getVersionDiff(mode, taskVersions, version, index);
          const versionDiff = getVersionDiff(mode, taskVersions, version, index, false);
          return {
            expanded: index === 0,
            heading: `Version ${version.number}${index === 0 ? ' (latest)' : ''}`,
            summary: (
              <>
                <div className="task-versions--left">
                  <div className="govuk-caption-m">{DateTimeUtil.timezoneFormatted(version.createdAt, EUROPE_LONDON, DATE_FORMATS.LONG)}</div>
                </div>
                <div className="task-versions--right">
                  <ul className="govuk-list">
                    <li>
                      {`${pluralise
                        .withCount(TaskVersionUtil.countChanges(changeData), '% change', '% changes', 'No changes')} in this version`}
                    </li>
                    {threatLevel?.type === 'RULE'
                      && <li>Highest threat level is <Tag className="tag tag--risk-tier" text={threatLevel.value} /></li>}
                    {threatLevel?.type === 'SELECTOR'
                      && <li>Highest threat level is <Tag className="tag tag--risk-tier" text={`Category ${threatLevel.value}`} /></li> }
                    {!threatLevel && <li>No rule matches</li>}
                  </ul>
                </div>
              </>
            ),
            children: toVersionDetails(version, versionDiff),
          };
        })
      }
    />
  );
};

export default TaskVersions;
