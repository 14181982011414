import React from 'react';
import { useKeycloak } from '../../../../context/Keycloak';
import { useAxiosInstance } from '../../../../utils/Axios/axiosInstance';

// Service
import AxiosRequests from '../../../../api/axiosRequests';

// Components
import RenderForm from '../../../../components/RenderForm/RenderForm';
import Layout from '../../../../components/Layout/Layout';

// Hook
import useSetTabTitle, { TAB_TITLES } from '../../../../utils/Hooks/useSetTabTitle';

// Utils
import config from '../../../../utils/config';

// JSON
import viewPnrData from '../../../../forms/viewPnrData';

const RESPONSE = {
  YES: 'YES',
  NO: 'NO',
};

const LoginJustification = ({ setDisplayForm }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  let shouldDisplayForm = false;

  useSetTabTitle(TAB_TITLES.DO_YOU_WANT_TO_VIEW_PNR);

  const onFinish = () => {
    setDisplayForm(shouldDisplayForm);
  };

  return (
    <Layout>
      <div className="govuk-width-container pnr-access-form-container">
        <main className="govuk-main-wrapper govuk-main-wrapper--auto-spacing" role="main">
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-full">
              <RenderForm
                onSubmit={
                  async ({ data }) => {
                    const { YES, NO } = RESPONSE;
                    const payload = {
                      requested: data?.viewPnrData?.toUpperCase() === YES || data?.viewPnrData?.toUpperCase() === NO,
                      secureLocation: data?.approvedSite?.toUpperCase() === YES || false,
                    };
                    try {
                      await AxiosRequests.initialPnrForm(apiClient, payload)
                        .then((response) => {
                          shouldDisplayForm = !response.data.submitted;
                        });
                    } catch (e) {
                      onFinish();
                    }
                  }
                }
                onFinish={() => onFinish()}
                form={viewPnrData}
              />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default LoginJustification;
