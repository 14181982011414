// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Config(s)
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

/**
 * Fetch an image.
 *
 * @param imageUrl The url to the image
 * @returns {{isLoading: boolean, url: never}}
 */
const useFetchImage = (imageUrl) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const fileUploadClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);

  const fetchImage = async () => {
    if (!imageUrl) {
      return null;
    }

    return AxiosRequests.blobData(fileUploadClient, imageUrl)
      .then((data) => URL.createObjectURL(data))
      .catch(() => null);
  };

  const { data: url, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.IMAGE, imageUrl],
    queryFn: () => fetchImage(),
    initialData: null,
  });

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  return { url, isLoading };
};

export default useFetchImage;
