const toTransformedFieldName = (fieldName) => {
  if (!fieldName) {
    return null;
  }

  const lowerCasedFieldName = fieldName.toLowerCase();
  if (lowerCasedFieldName.includes('systemchecked')) {
    return 'systemChecked';
  }

  if (lowerCasedFieldName.includes('tracecomment')) {
    return 'comment';
  }

  return 'trace';
};

export default toTransformedFieldName;
