// Global import(s)
import airports from '@nitro-land/airport-codes';

// Config(s)
import { STRINGS } from '../../constants';

const toFormattedRoute = (journey) => {
  return journey?.route?.split(' - ')?.join(' to ') || null;
};

const findByIata = (iata, attr) => {
  return airports.findWhere({ iata })?.get(attr) || null;
};

const findbyIcao = (icao, attr) => {
  return airports.findWhere({ icao })?.get(attr) || null;
};

/**
 * Returns a formatted airport city from provided token.
 *
 * @param {*} locationCode The location code, this can either be an iata or icao code.
 * @returns {*|null} A formatted airport city (or null if it can not be found).
 */
const toFormattedAirportCity = (locationCode) => {
  if (!locationCode) {
    return STRINGS.UNKNOWN;
  }

  const token = 'city';
  return findByIata(locationCode, token) || findbyIcao(locationCode, token) || STRINGS.UNKNOWN;
};

/**
 * Returns a formatted airport name from provided token.
 *
 * @param locationCode The location code, this can either be an iata or icao code.
 * @returns {*|null} A formatted airport name (or null if it can not be found).
 */
const toFormattedAirportName = (locationCode) => {
  if (!locationCode) {
    return null;
  }

  const token = 'name';
  return findByIata(locationCode, token) || findbyIcao(locationCode, token) || null;
};

/**
 * Returns a formatted airport country from provided token.
 *
 * @param {*} locationCode The location code, this can either be iata or icao code.
 * @returns {*|null} A formatted airport country (or null if it can not be found).
 */
const toFormattedAirportCountry = (locationCode) => {
  if (!locationCode) {
    return STRINGS.UNKNOWN;
  }

  const token = 'country';
  return findByIata(locationCode, token) || findbyIcao(locationCode, token) || STRINGS.UNKNOWN;
};

const getRoute = (journey) => {
  return journey?.route || null;
};

const getJourneyId = (journey) => {
  return journey?.id || null;
};

const getArrivalCountry = (journey) => {
  return journey?.arrival?.country || null;
};

const getArrivalLocation = (journey) => {
  return journey?.arrival?.location || null;
};

const getArrivalDate = (journey) => {
  return journey?.arrival?.time || null;
};

const getDepartureCountry = (journey) => {
  return journey?.departure?.country || null;
};

const getDepartureLocation = (journey) => {
  return journey?.departure?.location || null;
};

const getDepartureDate = (journey) => {
  return journey?.departure?.time || null;
};

const getDirection = (journey) => {
  return journey?.direction || null;
};

const getJourney = (informationSheet) => {
  return informationSheet?.movement?.journey || null;
};

const TargetJourneyUtil = {
  arrivalCountry: getArrivalCountry,
  arrivalDate: getArrivalDate,
  arrivalLocation: getArrivalLocation,
  departureCountry: getDepartureCountry,
  departureDate: getDepartureDate,
  departureLocation: getDepartureLocation,
  direction: getDirection,
  get: getJourney,
  id: getJourneyId,
  route: getRoute,
  format: {
    airportName: toFormattedAirportName,
    airportCity: toFormattedAirportCity,
    airportCountry: toFormattedAirportCountry,
    route: toFormattedRoute,
  },
};

export default TargetJourneyUtil;

export {
  getArrivalCountry,
  getArrivalDate,
  getArrivalLocation,
  getDepartureCountry,
  getDepartureDate,
  getDepartureLocation,
  getDirection,
  getJourney,
  getJourneyId,
  getRoute,
  toFormattedAirportName,
  toFormattedAirportCity,
  toFormattedAirportCountry,
  toFormattedRoute,
};
