import { useCallback } from 'react';

/**
 * Set a given ref to a given value
 * This utility takes care of different types of refs: callback refs and RefObject(s)
 */
const setRef = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    ref.current = value;
  }
};

/**
 * A utility to compose multiple refs together
 * Accepts callback refs and RefObject(s)
 */
const composeRefs = (...refs) => {
  return (node) => refs.forEach((ref) => setRef(ref, node));
};

/**
 * A custom hook that composes multiple refs
 * Accepts callback refs and RefObject(s)
 */
const useComposedRefs = (...refs) => {
  return useCallback(composeRefs(...refs), refs);
};

export { composeRefs, useComposedRefs };
