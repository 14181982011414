/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TARGET_SHEET_ORDER } from '../../../../../../utils/constants';

// Component
import { ArrowUpIcon, ArrowDownIcon } from '../../../../../../components/Icon/Icon';

// Util
import { scrollToElement } from '../../../../../Task/Uplift/TaskDetails/helper/scrollTo';

import './PreviousNext.scss';

const PreviousNext = ({ id, index, size }) => {
  const onNextClick = () => {
    if (index < (size - 1)) {
      scrollToElement(`${id}-next-${index + 1}`);
    } else {
      const position = TARGET_SHEET_ORDER.findIndex((idx) => idx === id);
      const getNextLinkSection = (pos) => {
        const next = document.querySelector(`#${TARGET_SHEET_ORDER[pos + 1]}`);
        if (next !== null) {
          if (pos + 1 === TARGET_SHEET_ORDER.length - 1) {
            return `${TARGET_SHEET_ORDER[pos + 1]}-previous-0`;
          }
          return `${TARGET_SHEET_ORDER[pos + 1]}-next-0`;
        }
        return getNextLinkSection(pos + 1);
      };

      const next = getNextLinkSection(position);
      scrollToElement(next);
    }
  };

  const onPreviousClick = () => {
    if (index < (size - 1) && index >= 0) {
      scrollToElement(`${id}-previous-${index - 1}`);
    } else {
      const position = TARGET_SHEET_ORDER.findIndex((idx) => idx === id);
      const getPreviousLinkSection = (pos) => {
        const previous = document.querySelector(`#${TARGET_SHEET_ORDER[pos - 1]}`);
        if (previous !== null) {
          if (pos - 1 === 0) {
            return `${TARGET_SHEET_ORDER[pos - 1]}-next-0`;
          }
          return `${TARGET_SHEET_ORDER[pos - 1]}-previous-0`;
        }
        return getPreviousLinkSection(pos - 1);
      };

      const previous = getPreviousLinkSection(position);
      scrollToElement(previous);
    }
  };

  return (
    <div className="previous-next">
      {id !== TARGET_SHEET_ORDER[0] && (
        <Link
          className="previous-link previous-next-link ho-nav-text-s"
          id={`${id}-previous-${index}`}
          name={`${id}-previous-${index}`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPreviousClick();
          }}
        >
          <ArrowUpIcon /> Previous
        </Link>
      )}
      {id !== TARGET_SHEET_ORDER[TARGET_SHEET_ORDER.length - 1] && (
        <Link
          className="next-link previous-next-link ho-nav-text-s"
          id={`${id}-next-${index}`}
          name={`${id}-next-${index}`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onNextClick();
          }}
        >
          <ArrowDownIcon /> Next
        </Link>
      )}
    </div>
  );
};

PreviousNext.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  size: PropTypes.number,
};

PreviousNext.defaultProps = {
  index: 0,
  size: 1,
};

export default PreviousNext;
