// Global import(s)
import { useQuery } from '@tanstack/react-query';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const useFetchJustifications = (isMasked) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const initialData = {
    data: [],
    pendingApprovals: 0,
  };
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();

  const getJustifications = async () => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return initialData;
    }

    return AxiosRequests.justifications(apiClient);
  };

  const { data: justifications, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.JUSTIFICATIONS],
    queryFn: () => getJustifications(),
    initialData,
    enabled: !!isMasked,
  });

  return { justifications, isLoading };
};

export default useFetchJustifications;
