// Config(s)
import FIELDS from '../../routes/Task/TaskDetails/helper/Fields';
import { STRINGS, TAB_ID, TASK_STATUS } from '../constants';

// Util(s)
import DatetimeUtil from '../Datetime/datetimeUtil';
import NumberUtil from '../Number/numberUtil';

const INVALID_VALUES = [
  STRINGS.UNKNOWN,
  null,
  undefined,
  'Invalid Date',
  'NaN'];

const formatCamelToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

const formatCamelToUpperCase = (str) => {
  return formatCamelToSnakeCase(str)
    .replace(/-/g, '_')
    .replace(/([a-z])/g, (letter) => letter.toUpperCase());
};

const formatUpperToCamelCase = (str) => {
  return str.toLowerCase()
    .split('_')
    .map((c, i) => {
      return i === 0 ? c : `${c[0].toUpperCase()}${c.slice(1)}`;
    })
    .join('');
};

const formatSnakeCaseToCamel = (str) => {
  return str.replace(/[-_\s]+(.)?/g, (match, ch) => (ch ? ch.toUpperCase() : ''));
};

const formatCamelToHyphenCase = (str) => {
  if (!str) {
    return str;
  }

  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

const formatCamelToLowerCase = (str) => {
  if (!str) {
    return str;
  }

  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
    .replace(/-/g, ' ');
};

const toTitleCase = (str) => {
  if (!str || typeof str !== 'string') {
    return null;
  }

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const formatToTwoDigits = (value, maxLength, fillString) => {
  if (NumberUtil.isNumber(value)) {
    return value.padStart(maxLength, fillString);
  }
  return '';
};

const formatAddress = (address, canReturnNull = false) => {
  if (!address || address === STRINGS.UNKNOWN) {
    if (canReturnNull) {
      return null;
    }
    return STRINGS.UNKNOWN;
  }
  const ADDRESS_FIELDS = Object.keys(FIELDS.address);
  return ADDRESS_FIELDS
    .map((k) => address?.[k])
    .filter((k) => !!k)
    .join(', ');
};

const capitalizeString = (value) => {
  if (!value) {
    return value;
  }
  return value.toUpperCase();
};

const formatTaskStatusToCamelCase = (status) => {
  if (!status) {
    return status;
  }

  switch (status) {
    case TASK_STATUS.IN_PROGRESS: {
      return TAB_ID.IN_PROGRESS;
    }
    case TASK_STATUS.SELECTED_FOR_DISMISSAL: {
      return TAB_ID.SELECTED_FOR_DISMISSAL;
    }
    default: {
      return status.toLowerCase();
    }
  }
};

const formatTaskStatusToSnakeCase = (status) => {
  if (!status) {
    return status;
  }

  switch (status) {
    case 'inProgress':
      return TASK_STATUS.IN_PROGRESS;
    case 'selectedForDismissal':
      return TASK_STATUS.SELECTED_FOR_DISMISSAL;
    default:
      return status.toUpperCase();
  }
};

const formatTaskStatusToWord = (status) => {
  if (!status) {
    return status;
  }

  switch (status) {
    case TAB_ID.IN_PROGRESS:
    case TASK_STATUS.IN_PROGRESS: {
      return 'in progress';
    }
    case TAB_ID.COMPLETE:
    case TASK_STATUS.COMPLETE: {
      return 'completed';
    }
    case TAB_ID.SELECTED_FOR_DISMISSAL:
    case TASK_STATUS.SELECTED_FOR_DISMISSAL: {
      return 'selected for dismissal';
    }
    default: {
      return status.toLowerCase();
    }
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string) {
    return string;
  }
  if (NumberUtil.isNumber(string)) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

const formatVoyageText = (dateTime) => {
  const time = DatetimeUtil.relativeTime(dateTime);
  const isPastDate = DatetimeUtil.isPast(dateTime);
  if (isPastDate !== STRINGS.UNKNOWN) {
    if (isPastDate) {
      return `arrived ${time}`.trim();
    }
    return `arriving in ${time.replace(STRINGS.BEFORE_TRAVEL_TEXT, '')}`.trim();
  }
  return STRINGS.UNKNOWN;
};

const forceString = (input) => {
  return String(input);
};

const escapeString = (input) => {
  const regex = [
    { regex: /\\/g, replace: '\\\\' },
    { regex: /\n/g, replace: '\\n' },
    { regex: /"/g, replace: '\\"' },
  ];
  if (!input && input !== 0) {
    return '';
  }
  let inputAsText = forceString(input);
  regex.forEach((r) => {
    inputAsText = inputAsText.replace(r.regex, r.replace);
  });
  return inputAsText;
};

const unescapeString = (input) => {
  const SPACE = ' ';
  const regex = [
    { regex: /\\\\n/g, replace: SPACE },
    { regex: /\\n/g, replace: SPACE },
  ];
  if (!input && input !== 0) {
    return '';
  }
  let inputAsText = forceString(input);
  regex.forEach((r) => {
    inputAsText = inputAsText.replace(r.regex, r.replace);
  });
  return inputAsText;
};

const stringifyValues = (arr) => {
  return arr.map((v) => String(v).toUpperCase());
};

const replaceInvalidValues = (value) => {
  const stringValues = stringifyValues(INVALID_VALUES);
  if (stringValues.includes(String(value).toUpperCase())) {
    return '';
  }
  return value;
};

const booleanToString = (value) => {
  return value ? STRINGS.YES : STRINGS.NO;
};

const convertStringToBool = (value) => {
  switch (value) {
    case 'yes': {
      return true;
    }
    case 'no': {
      return false;
    }
    default:
      return false;
  }
};

const StringUtil = {
  boolToString: booleanToString,
  capitalize: capitalizeString,
  capitalizeFirst: capitalizeFirstLetter,
  escape: escapeString,
  padStart: formatToTwoDigits,
  replaceInvalid: replaceInvalidValues,
  stringToBool: convertStringToBool,
  unescape: unescapeString,
  voyageText: formatVoyageText,
  format: {
    address: formatAddress,
    camelCase: formatTaskStatusToCamelCase,
    camelToHyphenCase: formatCamelToHyphenCase,
    camelToLowerCase: formatCamelToLowerCase,
    camelToSnakeCase: formatCamelToSnakeCase,
    camelToUpperCase: formatCamelToUpperCase,
    snakeCase: formatTaskStatusToSnakeCase,
    snakeCaseToCamel: formatSnakeCaseToCamel,
    titleCase: toTitleCase,
    upperToCamelCase: formatUpperToCamelCase,
    word: formatTaskStatusToWord,
  },
};

export default StringUtil;

export {
  booleanToString,
  capitalizeFirstLetter,
  capitalizeString,
  convertStringToBool,
  escapeString,
  formatAddress,
  formatCamelToHyphenCase,
  formatTaskStatusToCamelCase,
  formatTaskStatusToSnakeCase,
  formatTaskStatusToWord,
  formatCamelToLowerCase,
  formatCamelToSnakeCase,
  formatCamelToUpperCase,
  formatToTwoDigits,
  formatUpperToCamelCase,
  formatVoyageText,
  replaceInvalidValues,
  toTitleCase,
  unescapeString,
};
