import React from 'react';

import { useKeycloak } from '../../../../../context/Keycloak';
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';

// Services
import AxiosRequests from '../../../../../api/axiosRequests';

// Config
import config from '../../../../../utils/config';

// Components
import RenderForm from '../../../../../components/RenderForm/RenderForm';

// Hook
import useSetTabTitle, { TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';

// Form
import relistTask from '../../../../../forms/relistTaskCerberus';

const RelistTask = ({ taskId, isSubmitted, onCancel, setSubmitted, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  useSetTabTitle(TAB_TITLES.RELIST_TASK);

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={relistTask}
      preFillData={{ taskId }}
      onSubmit={async ({ data: { reasonForRelisting } }) => {
        await AxiosRequests.relistTask(apiClient, taskId, { reason: reasonForRelisting });
        setSubmitted();
      }}
      onCancel={onCancel}
    />
  );
};

export default RelistTask;
