// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Components
import AirpaxSummary from './airpax/Summary';
import GeneralAviationSummary from './general_aviation/Summary';

// Contexts
import { useTask } from '../../../../../../../context/TaskContext';

// Utils
import { isGaSubMode } from '../../../../../../../utils/Task/taskUtil';

const TaskSummary = ({ task }) => {
  const { subMode } = useTask();

  if (isGaSubMode(subMode)) {
    return <GeneralAviationSummary task={task} />;
  }

  return <AirpaxSummary task={task} />;
};

TaskSummary.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default TaskSummary;
