import { useEffect, useState } from 'react';

// Hooks(s)
import useRefDataFetch from './useRefDataFetch';

// Util
import { isGaSubMode } from '../Task/taskUtil';

const useFetchTargetRecipients = (subMode) => {
  const [targetRecipients, setTargetRecipients] = useState([]);

  const { data, isLoading } = useRefDataFetch('/v2/entities/groups', {
    params: {
      limit: 100,
      skip: 0,
      sort: 'displayname.asc',
      filter: isGaSubMode(subMode) ? 'grouptypeid=eq.13' : 'grouptypeid=eq.6',
      mode: 'dataOnly',
      validDateTime: true,
    },
  });

  useEffect(() => {
    setTargetRecipients(data?.map((group) => {
      return {
        ...group,
        customName: group.displayname,
        telephone: null, // TODO: Default to null for now we have no telephone number associated with each group.
      };
    }) || []);
  }, [data]);

  return { targetRecipients, isLoading };
};

export default useFetchTargetRecipients;
