// Global import(s)
import { ButtonGroup, InsetText } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Config
import config from '../../../../utils/config';
import { CREDIBILITY_FORM_PREFIX } from './components/shared/checks/constants';
import { DATE_FORMATS, FORM_ACTIONS, TARGET_OPERATION, TASK_STATUS } from '../../../../utils/constants';
import { TASKS_TABS } from '../../TaskList/constants';

// Contexts
import { useChecksValidation } from '../../../../context/ChecksValidationContext';
import { useErrorAlert } from '../../../../context/ErrorAlertContext';
import { useFormData } from '../../../../context/FormDataContext';
import { useKeycloak } from '../../../../context/Keycloak';
import { usePermission } from '../../../../context/PermissionContext';
import { useTabs } from '../../../../context/TabContext';
import { useTask } from '../../../../context/TaskContext';
import { useTasks } from '../../../../context/TasksContext';

// Components
import ActionedStatus from './components/shared/status/ActionedStatus';
import AddDetail from './components/shared/content/AddDetail';
import AddJustification from '../../TaskDetails/components/airpax/justification/AddJustification';
import AddNote from './components/shared/notes/AddNote';
import AddPncCheckResults from './components/shared/checks/AddPncCheckResults';
import AddTaskNoteButton from './components/shared/buttons/AddTaskNoteButton';
import CancelButton from './components/shared/buttons/CancelButton';
import CancelEditModal from './CancelEditModal';
import ClaimTaskButton from '../TaskList/components/shared/buttons/ClaimTaskButton';
import CloseTaskButton from './components/shared/buttons/CloseTaskButton';
import CompleteAssessment from './components/shared/assessments/CompleteAssessment';
import CompleteAssessmentButton from './components/shared/buttons/CompleteAssessmentButton';
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';
import ContinueButton from './components/shared/buttons/ContinueButton';
import CreateAlertButton from './components/shared/buttons/CreateAlertButton';
import CreateTarget from './components/shared/target/CreateTarget';
import CreateTargetButton from './components/shared/buttons/CreateTargetButton';
import CredibilityChecks from './components/shared/checks/CredibilityChecks';
import DetailsTab from './DetailsTab';
import EditDetail from './components/shared/content/EditDetail';
import EditTargetButton from './components/shared/buttons/EditTargetButton';
import EditTaskNote from './components/shared/notes/EditTaskNote';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Movement from './components/shared/movement/Movement';
import PageHeaderActions from '../../../../components/Headers/PageHeaderActions';
import RelistTaskButton from './components/shared/buttons/RelistTaskButton';
import SaveAndCloseButton from './components/shared/buttons/SaveAndCloseButton';
import TaskActionedBy from './components/shared/status/TaskActionedBy';
import TaskActionsToolbar from './components/shared/toolbar/TaskActionsToolbar';
import TaskActivity from './components/shared/activity/TaskActivity';
import TaskAssessedBy from './components/shared/status/TaskAssessedBy';
import TaskClaimedBy from './components/shared/status/TaskClaimedBy';
import TaskNotes from './components/shared/notes/TaskNotes';
import TaskSummary from './components/shared/summary/TaskSummary';
import ThreatType from './components/shared/threat/ThreatType';
import UnclaimTaskButton from '../TaskList/components/shared/buttons/UnclaimTaskButton';
import UpdateButton from './components/shared/buttons/UpdateButton';
import UpdateGeneralAviationTask from './components/shared/task/UpdateGeneralAviationTask';
import UpdateTargetButton from './components/shared/buttons/UpdateTargetButton';
import UpdateTaskButton from './components/shared/buttons/UpdateTaskButton';

// Hooks
import { setTabTitle, TAB_TITLES } from '../../../../utils/Hooks/useSetTabTitle';
import useScreenSize from '../../../../utils/Hooks/useScreenSize';
import useIsHiddenInViewport from '../../../../utils/Hooks/useIsHiddenInViewport';
import useIsVerticallyInViewPort from '../../../../utils/Hooks/useIsVerticallyInViewport';
import useScrollDirection from '../../../../utils/Hooks/useScrollDirection';

// Utils
import PersonUtil from '../../../../utils/Person/Uplift/personUtil';
import validateCredibilityChecks from './components/shared/checks/validate/validateCredibilityChecks';
import { CommonUtil, DateTimeUtil } from '../../../../utils';
import { isGaSubMode, isIdpSubMode } from '../../../../utils/Task/taskUtil';
import { scrollToElement } from './helper/scrollTo';
import { toMainContentClassname } from '../../../../components/Utils/Component/toClassName';
import { useAxiosInstance } from '../../../../utils/Axios/axiosInstance';
import { updateCredibilityChecks, getCredibilityChecksPayload } from './helper/updateCredibilityChecks';
import toAdditionalContentAndCredibilityChecksPayload from './helper/toAdditionalContentAndCredibilityChecksPayload';
import { toOtherChecksData, toPncData } from './components/shared/checks/helper/toFormCredibilityChecks';

// Services
import AxiosRequests from '../../../../api/axiosRequests';

const InternalTaskDetailsPage = ({ targetSheet, hasBorderEvent, borderEvent, justifications, setJustificationId, goToJustificationUI, movementIds }) => {
  const { taskId } = useParams();
  const { canUpdateTask } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isAddNoteFormOpen, setIsAddNoteFormOpen] = useState(false);
  const [isAddDetailFormOpen, setIsAddDetailFormOpen] = useState(false);
  const [isAddOrEditDetailFormOpen, setIsAddOrEditDetailFormOpen] = useState(false);
  const [isAddPncCheckResultsOpen, setIsAddPncCheckResultsOpen] = useState(false);
  const [isCompleteAssessmentFormOpen, setIsCompleteAssessmentFormOpen] = useState(false);
  const [isCreateTargetFormOpen, setIsCreateTargetFormOpen] = useState(false);
  const [passengerPosition, setPassengerPosition] = useState(0);
  const [submissionPayload, setSubmissionPayload] = useState(null);
  const [areCancelling, setAreCancelling] = useState(false);
  const { returnToElementId, setReturnToElementId, returnToElement, setReturnToElement } = useTasks();
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { formData, setFormData } = useFormData();
  const { setSelectedTab, setTabIndex } = useTabs();
  const { addError, clearErrors } = useChecksValidation();
  const { setErrors: setSummaryErrors } = useErrorAlert();
  const { task,
    originalTask,
    setOriginalTask,
    isUpdateTask,
    setIsUpdateTask,
    isEditTarget,
    setIsEditTarget,
    isAddDetail,
    setIsAddDetail,
    taskState: { isClaimedByUser, isTargetIssued, isDismissedByTargeter, isWithdrawnByTargeter, isCompletedByFrontline },
    subMode,
    isEditNoteFormOpen,
    isEditDetailFormOpen,
    setTask,
    setAdditionalContent,
    additionalContent,
    setCredibilityChecks,
    credibilityChecks,
    isEditsMade,
    setIsEditsMade } = useTask();
  const toolbarRef = useRef(null);
  const taskActionsRef = useRef(null);
  const addNoteRef = useRef(null);
  const addDetailRef = useRef(null);
  const isTaskActionsInViewport = useIsVerticallyInViewPort(taskActionsRef, {
    initialInView: true,
  });
  const isAddNoteHiddenInViewport = useIsHiddenInViewport(toolbarRef, addNoteRef);
  const isAddDetailHiddenInViewport = useIsHiddenInViewport(toolbarRef, addDetailRef);
  const { scrollDirection, Direction } = useScrollDirection();
  const canUnclaim = task?.status === TASK_STATUS.IN_PROGRESS && canUpdateTask;
  const canClaim = task?.status === TASK_STATUS.NEW && canUpdateTask;
  const canShowAddNoteButton = (isClaimedByUser || isTargetIssued) && !isUpdateTask;
  const canShowAddDetailButton = ((isClaimedByUser && !isTargetIssued) || isUpdateTask);
  const canShowAddEditDetailButton = ((isClaimedByUser && !isTargetIssued) || canClaim);
  const passengers = useMemo(() => PersonUtil.addRoleLabel(PersonUtil.allPersons(task)), [task]);

  const findFirstTargetReceivedTimeStamp = () => {
    const foundActivity = task.activities.find((_activity) => _activity.content === 'target received');
    if (!foundActivity) {
      return null;
    }

    return foundActivity.timestamp;
  };

  const toActionedAtTimestamp = () => {
    // The task with a received status does not change the fact that the target has been issued.
    if (task.status === TASK_STATUS.RECEIVED) {
      return findFirstTargetReceivedTimeStamp();
    }

    if (isTargetIssued || isWithdrawnByTargeter || isDismissedByTargeter) {
      return task?.targeterAction?.timestamp;
    }

    if (isCompletedByFrontline) {
      return task.frontLineAction?.form?.submissionDate;
    }

    return null;
  };

  const updateTabTitle = () => {
    setTabTitle(isUpdateTask ? TAB_TITLES.UPDATE_TASK_DETAILS : TAB_TITLES.TASK_DETAILS);
  };

  const areFormsClosed = () => {
    return !isAddNoteFormOpen && !isAddDetailFormOpen && !isAddPncCheckResultsOpen && !isCompleteAssessmentFormOpen && !isCreateTargetFormOpen;
  };

  const toTaskDetailsHeading = () => {
    if (isAddDetail && (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED) && !isEditTarget) {
      return 'Update task details';
    }

    return 'Task details';
  };

  const getCredibilityCheckData = () => {
    let data = {};
    passengers?.forEach((passenger, index) => {
      const adjustedIndex = index + 1;
      const containerId = `${CREDIBILITY_FORM_PREFIX}${adjustedIndex}`;

      data = {
        ...data,
        [containerId]: {
          ...data?.[containerId],
          entityId: CommonUtil.entityId(passenger),
          pnc: toPncData(passenger),
          otherChecks: toOtherChecksData(passenger),
        },
      };
    });

    return data;
  };

  const saveCredibilityChecks = async () => {
    if (JSON.stringify(getCredibilityCheckData()) !== JSON.stringify(credibilityChecks)) {
      const payload = getCredibilityChecksPayload(formData, subMode);

      setSubmissionPayload(payload);
    }
  };

  const hasCredibilityChecksErrors = (errors) => {
    return errors && errors?.length;
  };

  const onCreateTargetOrCompleteAssessment = async (callback, triggerType) => {
    setIsInProgress(true);
    const errors = await validateCredibilityChecks(addError, setSummaryErrors, formData, isIdpSubMode(subMode), triggerType);

    if (!hasCredibilityChecksErrors(errors)) {
      await saveCredibilityChecks()
        .then(() => {
          clearErrors();
          setSummaryErrors([]);
          setIsInProgress(false);
          callback();
        })
        .catch(() => setIsInProgress(false));

      return;
    }

    setIsInProgress(false);
  };

  const onCloseTask = () => {
    const tabId = CommonUtil.findTabByStatus(TASKS_TABS, task?.status)?.id;
    setSelectedTab(tabId);
    const tabIndex = CommonUtil.findTabIndexByStatus(TASKS_TABS, task?.status);
    setTabIndex(tabIndex);
    navigate(CommonUtil.getListPath(location.pathname));
  };

  const performRequest = async (submittedTaskId, payload) => {
    return AxiosRequests.saveChecks(apiClient, submittedTaskId, payload);
  };

  const onSaveAndClose = async () => {
    setIsInProgress(true);

    await performRequest(taskId, toAdditionalContentAndCredibilityChecksPayload(additionalContent, getCredibilityChecksPayload(credibilityChecks, subMode)))
      .then(() => {
        setFormData(null);
        setIsInProgress(false);
        setCredibilityChecks(null);
        setAdditionalContent(null);
        onCloseTask();
      })
      .catch((exception) => {
        console.error(exception.message);
        setIsInProgress(false);
      });
  };

  const onAddTaskNote = () => setIsAddNoteFormOpen(true);

  const onAddTaskDetail = () => setIsAddDetailFormOpen(true);

  const onAddEditTaskDetail = () => setIsAddOrEditDetailFormOpen(true);
  const onContinueTarget = () => {
    setIsCreateTargetFormOpen(true);
  };

  const onContinueOrCreateTarget = async () => {
    await onCreateTargetOrCompleteAssessment(() => onContinueTarget(), FORM_ACTIONS.CREATE_TARGET);
  };

  const onUpdateTask = () => {
    setIsEditTarget(false);
    setIsUpdateTask(true);
    setIsAddDetail(true);
  };

  const onCompleteAssessment = async () => {
    await onCreateTargetOrCompleteAssessment(() => setIsCompleteAssessmentFormOpen(true), FORM_ACTIONS.COMPLETE_ASSESSMENT);
  };

  const onEditTarget = async () => {
    setIsEditTarget(true);
    setIsAddDetail(false);
    await onCreateTargetOrCompleteAssessment(() => setIsCreateTargetFormOpen(true), FORM_ACTIONS.CREATE_TARGET);
  };

  const onCancel = () => {
    if (originalTask) {
      setTask(originalTask);
      setOriginalTask(null);
    }
    setCredibilityChecks(null);
    setAdditionalContent(null);
    setIsUpdateTask(false);
    setFormData(null);
    setAreCancelling(false);
    setIsEditsMade(false);
    setIsAddDetail(false);
  };

  const onCancelling = () => {
    setAreCancelling(true);
    const credibilityChecksData = getCredibilityCheckData();

    if ((originalTask && (JSON.stringify(originalTask) !== JSON.stringify(task)))) {
      setIsEditsMade(true);
    } else if (JSON.stringify(credibilityChecksData) !== JSON.stringify(credibilityChecks)) {
      setIsEditsMade(true);
    } else {
      onCancel();
    }
  };

  const onCancelCancel = () => {
    setIsEditsMade(false);
    setAreCancelling(false);
  };

  useLayoutEffect(() => {
    if (returnToElementId) {
      scrollToElement(returnToElementId, null);
      setReturnToElement(false);
    }
  }, [returnToElement]);

  useEffect(() => {
    // Clear the marker for the element to scroll to when the flag to trigger the scroll
    // to element is false and there is an element id already set.
    if (!returnToElement && returnToElementId) {
      setReturnToElementId(null);
    }
  }, [returnToElement]);

  useEffect(() => {
    if (areFormsClosed()) {
      updateTabTitle();
    }
  }, [isAddNoteFormOpen, isAddDetailFormOpen, isAddPncCheckResultsOpen, isCompleteAssessmentFormOpen, isCreateTargetFormOpen]);

  const checkIfOriginalTaskSet = () => !!originalTask;

  useEffect(() => {
    if (submissionPayload && submissionPayload.length > 0) {
      const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
      const user = {
        active: true,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name,
        email: keycloak.tokenParsed.email,
      };

      setTask(updateCredibilityChecks(task, submissionPayload, user, dateTimeStamp));
      setCredibilityChecks(credibilityChecks);
    }
  }, [submissionPayload]);

  useEffect(() => {
    if (!checkIfOriginalTaskSet()) {
      setOriginalTask(JSON.parse(JSON.stringify(task)));
    }
  }, [task]);

  if (isInProgress) {
    return <LoadingSpinner />;
  }

  if (!task) {
    return null;
  }

  if (isCreateTargetFormOpen) {
    const suffix = isGaSubMode(subMode) ? 'alert' : 'target';

    let headingLabel = '';

    if (isUpdateTask && (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED) && !isEditTarget) {
      headingLabel = `Reissue ${suffix}`;
    } else if (isEditTarget) {
      headingLabel = `Edit ${suffix}`;
    } else {
      headingLabel = `Create ${suffix}`;
    }

    const submitLabel = (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED) ? `Reissue ${suffix}` : `Send ${suffix}`;
    return (
      <CreateTarget
        id="create-target-container"
        taskId={taskId}
        task={task}
        headingLabel={headingLabel}
        submitLabel={submitLabel}
        actionType={isUpdateTask ? TARGET_OPERATION.EDIT : TARGET_OPERATION.CREATE}
        onClose={() => {
          setIsCreateTargetFormOpen(false);
        }}
      />
    );
  }

  return (
    <div id="page-container" className="govuk-grid-row">
      <div
        id="details-container"
        className={`govuk-grid-column${toMainContentClassname(isNineteenTwentyOrMorePixels)}`}
      >
        <ComponentWrapper show={!isTaskActionsInViewport}>
          <TaskActionsToolbar
            ref={toolbarRef}
            isScrollingUp={scrollDirection === Direction.UP}
            isFormOpen={isAddNoteFormOpen || isAddDetailFormOpen || isAddPncCheckResultsOpen}
            isAddNoteHiddenInViewport={isAddNoteHiddenInViewport}
            isAddDetailHiddenInViewport={isAddDetailHiddenInViewport}
            actions={{ // Also update TaskActionsToolbar
              canShowAddNoteButton,
              canShowAddDetailButton,
              onAddTaskNote,
              onAddTaskDetail,
              onAddEditTaskDetail,
              onSaveAndClose,
              onCloseTask,
              onContinueOrCreateTarget,
              onUpdateTask,
              onCompleteAssessment,
              onEditTarget,
              onCancelling,
            }}
          />
        </ComponentWrapper>

        {/* Heading */}
        <div className="govuk-grid-row">
          <AddJustification
            isMaskingEnabled={!task?.hidingEnabled && task?.maskingEnabled}
            justifications={justifications}
            getTaskWithJustification={setJustificationId}
            toCreateJustification={goToJustificationUI}
          />
          <div className="govuk-grid-column-full">
            <PageHeaderActions
              id="page-title"
              heading={toTaskDetailsHeading()}
            >
              {/* Also update TaskActionsToolbar  */}
              <div ref={taskActionsRef}>
                <ButtonGroup className="govuk-!-padding-top-2 task-action-buttons">
                  <ComponentWrapper show={isClaimedByUser && !isTargetIssued}>
                    <SaveAndCloseButton onClick={onSaveAndClose} />
                  </ComponentWrapper>
                  <ComponentWrapper show={((!isClaimedByUser || isTargetIssued || isEditTarget) && !isAddDetail)}>
                    <CloseTaskButton onClick={onCloseTask} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isAddDetail && isTargetIssued}>
                    <ContinueButton onClick={onContinueOrCreateTarget} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isClaimedByUser && !isTargetIssued && !isGaSubMode(subMode)}>
                    <CreateTargetButton onClick={onContinueOrCreateTarget} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isClaimedByUser && !isTargetIssued && isGaSubMode(subMode)}>
                    <CreateAlertButton onClick={onContinueOrCreateTarget} />
                  </ComponentWrapper>
                  <ComponentWrapper show={false}>
                    <UpdateButton onClick={null} />
                  </ComponentWrapper>
                  <ComponentWrapper show={(isTargetIssued && !isAddDetail && task.status !== TASK_STATUS.COMPLETE)}>
                    <UpdateTaskButton onClick={onUpdateTask} />
                  </ComponentWrapper>
                  <ComponentWrapper show={false}>
                    {/* TODO: Find out what this button does. */}
                    <UpdateTargetButton onClick={() => { }} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isDismissedByTargeter}>
                    {/* TODO: Find out what this button does. */}
                    <RelistTaskButton onClick={() => { }} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isClaimedByUser && !isTargetIssued}>
                    <CompleteAssessmentButton onClick={onCompleteAssessment} />
                  </ComponentWrapper>
                  <ComponentWrapper show={(isTargetIssued && !isAddDetail && task.status !== TASK_STATUS.COMPLETE)}>
                    <EditTargetButton onClick={onEditTarget} />
                  </ComponentWrapper>
                  <ComponentWrapper show={isAddDetail && (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED)}>
                    <CancelButton onClick={onCancelling} />
                  </ComponentWrapper>
                </ButtonGroup>
              </div>
            </PageHeaderActions>
          </div>
        </div>

        {/* Show cancel modal if required */}
        <ComponentWrapper show={areCancelling && isEditsMade}>
          <CancelEditModal onCancel={onCancelCancel} onProceed={onCancel} />
        </ComponentWrapper>

        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ComponentWrapper show={isUpdateTask && isAddDetail}>
              <InsetText id="task-edit-inset">
                Updating task details and/or credibility check results, apart from the HMRC check, will require reissue of the target sheet to share the updates with recipients.
              </InsetText>
            </ComponentWrapper>
            <ActionedStatus
              actionedAt={toActionedAtTimestamp()}
              taskStatus={task.status}
              targeterAction={task.targeterAction}
              frontlineAction={task.frontLineAction}
              isDismissedByTargeter={isDismissedByTargeter}
              isWithdrawnByTargeter={isWithdrawnByTargeter}
            />
            <p id="task-id-claimant-info">
              <span id="task-id" className="font--grey">{`Task ID: ${taskId}`}</span>
              <ComponentWrapper show={task.status === TASK_STATUS.ISSUED}>
                <TaskActionedBy actioner={task.targeterAction?.user} />
              </ComponentWrapper>
              <ComponentWrapper show={[TASK_STATUS.NEW, TASK_STATUS.IN_PROGRESS].includes(task.status)}>
                <TaskClaimedBy claimedBy={task?.claimedBy} />
              </ComponentWrapper>
              <ComponentWrapper show={[TASK_STATUS.RECEIVED, TASK_STATUS.COMPLETE].includes(task.status)}>
                <TaskAssessedBy assessor={task.targeterAction?.user} />
              </ComponentWrapper>
              <ComponentWrapper show={canClaim}>
                <ClaimTaskButton
                  id={`claim-task-${taskId}`}
                  redirectPath={`${CommonUtil.getListPath(location.pathname)}/${task.id}`}
                  taskId={task.id}
                  isTaskDetails
                  className="govuk-link link-button"
                />
              </ComponentWrapper>
              <ComponentWrapper show={canUnclaim}>
                <UnclaimTaskButton
                  id={`unclaim-task-${taskId}`}
                  redirectPath={CommonUtil.getListPath(location.pathname)}
                  taskId={task.id}
                  className="govuk-link link-button"
                  isTaskDetails
                />
              </ComponentWrapper>
            </p>
          </div>
        </div>

        {/* Task Summary */}
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <TaskSummary task={task} />
          </div>
        </div>

        {/* Task Activity */}
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <TaskActivity activities={task.activities} />
          </div>

          <div className="govuk-grid-column-one-third float-right" ref={addNoteRef}>
            <ButtonGroup className="task-action-buttons">
              <ComponentWrapper show={canShowAddNoteButton}>
                <AddTaskNoteButton onClick={onAddTaskNote} />
              </ComponentWrapper>
            </ButtonGroup>
          </div>
        </div>

        {/* Task Notes */}
        <ComponentWrapper show={task?.targeterNotes?.length}>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-full">
              <TaskNotes task={task} />
            </div>
          </div>
        </ComponentWrapper>

        {/* Threat Type, Movement, Credibility Checks */}
        <DetailsTab
          isWithdrawnByTargeter={isWithdrawnByTargeter}
          targetSheet={targetSheet}
          borderEvent={borderEvent}
          hasBorderEvent={hasBorderEvent}
        >
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-full">
              <ComponentWrapper show={!isGaSubMode(subMode)}>
                <ThreatType task={task} />
              </ComponentWrapper>
            </div>
            <div className="govuk-grid-column-full">
              <ComponentWrapper show>
                <Movement
                  canShowAddDetailButton={!isGaSubMode(subMode) ? canShowAddDetailButton : canShowAddEditDetailButton}
                  movementIds={movementIds}
                  onAddTaskDetail={!isGaSubMode(subMode) ? onAddTaskDetail : onAddEditTaskDetail}
                  ref={addDetailRef}
                />
              </ComponentWrapper>
            </div>
            <div className="govuk-grid-column-full">
              <CredibilityChecks
                setPassengerPosition={setPassengerPosition}
                setIsAddPncCheckResultsOpen={setIsAddPncCheckResultsOpen}
                passengers={passengers}
              />
            </div>
            <div className="govuk-grid-column-full">
              <Link
                id={`view-details-${taskId}`}
                aria-label={`View task message history for ${task.id}`}
                className="govuk-link govuk-link--no-visited-state"
                to={`/v2/tasks/airpax/${task.id}/message-history`}
              >
                Task and message history
              </Link>
            </div>
          </div>
        </DetailsTab>
      </div>

      {/* Panel Fullscreen forms */}
      <ComponentWrapper show={isAddDetailFormOpen}>
        <AddDetail id="add-detail-container" onClose={() => setIsAddDetailFormOpen(false)} />
      </ComponentWrapper>
      <ComponentWrapper show={isAddOrEditDetailFormOpen}>
        <UpdateGeneralAviationTask id="update-general-aviation-container" onClose={() => setIsAddOrEditDetailFormOpen(false)} />
      </ComponentWrapper>
      <ComponentWrapper show={isAddNoteFormOpen}>
        <AddNote id="add-note-container" onClose={() => setIsAddNoteFormOpen(false)} />
      </ComponentWrapper>
      <ComponentWrapper show={isCompleteAssessmentFormOpen}>
        <CompleteAssessment
          id="complete-assessment-container"
          person={PersonUtil.get(task)}
          onClose={() => setIsCompleteAssessmentFormOpen(false)}
        />
      </ComponentWrapper>
      <ComponentWrapper show={isAddPncCheckResultsOpen}>
        <AddPncCheckResults
          id="pnc-check-container"
          passengerPositionedAt={passengerPosition}
          onClose={() => {
            setIsAddPncCheckResultsOpen(false);
            setReturnToElement(true);
          }}
          person={passengers[passengerPosition - 1]}
          containerId={`${CREDIBILITY_FORM_PREFIX}${passengerPosition}`}
        />
      </ComponentWrapper>
      <ComponentWrapper show={isEditNoteFormOpen}>
        <EditTaskNote id="edit-note-container" />
      </ComponentWrapper>
      <ComponentWrapper show={isEditDetailFormOpen}>
        <EditDetail id="edit-content-container" />
      </ComponentWrapper>
    </div>
  );
};

InternalTaskDetailsPage.propTypes = {
  targetSheet: PropTypes.shape({}),
  borderEvent: PropTypes.shape({}),
  hasBorderEvent: PropTypes.bool.isRequired,
  justifications: PropTypes.arrayOf(PropTypes.shape({})),
  setJustificationId: PropTypes.func.isRequired,
  goToJustificationUI: PropTypes.func.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InternalTaskDetailsPage.defaultProps = {
  targetSheet: null,
  borderEvent: null,
  justifications: {
    data: [],
    pendingApprovals: 0,
  },
};

export default InternalTaskDetailsPage;
