// Config(s)
import { STATUS_CODES } from '../constants';

const hasError = (errors, formattedError) => {
  if (!errors) {
    return false;
  }

  return !!errors.filter(({ error }) => error === formattedError).length;
};

const in400Range = (status) => {
  return status >= STATUS_CODES.RANGE_400 && status < STATUS_CODES.RANGE_500;
};

const toFormattedError = (error) => {
  const status = error?.response?.status;
  if (in400Range(status)) {
    return error?.response?.data?.detail ? `${status} - ${error?.response?.data?.detail}` : error.message;
  }
  return error.message;
};

const ErrorHandler = {
  format: toFormattedError,
  containsError: hasError,
};

export default ErrorHandler;

export { toFormattedError, hasError };
